import React, { useState } from 'react'
import { Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom"

import Checkbox from '@mui/material/Checkbox';
import "../../../src/Style/Auth.css";

import { StartupInnovation, CurrentStage } from "./dropdown";
import { FILE_URL } from '../../shared/constant';

const Venture = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <TextField
                fullWidth label="DPIIT Registration No" id="fullWidth"
                value={props.data.dpiitRegisterNo}
                onChange={(e) => props.handleChange(e, "dpiitRegisterNo", null, props.section)}
            />
            <br />
            <br />

            <Typography className='headLineLeft'>Upload DPIIT Certificate(In Pdf Only)</Typography>

            <TextField

                type="file"
                label={" "}
                fullWidth
                onChange={(e) => props.handleChange(e, "dpiitCertificate", "file", props.section)}
            />
            {
                props.data.dpiitCertificateUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props?.data?.dpiitCertificateUrl?.includes("https") ? props?.data?.dpiitCertificateUrl : FILE_URL + props?.data?.dpiitCertificateUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }

            <br />
            <br />
            <Typography className='headLineLeft' >Declaration*</Typography>
            <Box style={{ border: "1px solid #c9c9c9", borderRadius: "4px" }}>
                <Checkbox
                    {...label}
                    onClick={(e) => props.handleChange(e, "isDeclare", "checkbox", props.section)}
                    required
                    checked={props.data.isDeclare}
                />
                <span style={{ textAlign: "left" }}>I here by declare that the start-up is not formed by splitting up or reconstruction of an existing business </span>
            </Box>
            <br />


            <Box>
                <TextField
                    fullWidth
                    select

                    label="Types Of Startup Innovation"
                    value={props.data.startupInnovation}
                    onChange={(e) => props.handleChange(e, "startupInnovation", null, props.section)}
                    id="fullWidth"
                    required
                    style={{ textAlign: 'left' }}
                >
                    {
                        StartupInnovation.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Box>
            <br />


            <TextField
                id="outlined-select-currency"
                fullWidth
                select
                style={{ textAlign: 'left' }}
                label="Current Stage"
                value={props.data.currentStage}
                onChange={(e) => props.handleChange(e, "currentStage", null, props.section)}
                required
            >
                {CurrentStage.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <br />
            <br />
            <TextField
                id="outlined-select-currency"
                fullWidth
                select
                style={{ textAlign: 'left' }}
                label="Services"
                required={true}
                value={props.data.serviceId}
                onChange={(e) => props.handleChange(e, "serviceId", null, props.section)}
            >
                {props.services.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
            <br />
            <br />

            <TextField
                id="outlined-select-currency"
                fullWidth
                select

                label="Industry of startup"
                value={props.data.industryId}
                onChange={(e) => props.handleChange(e, "industryId", null, props.section)}
                required
                style={{ textAlign: 'left' }}
            >
                {props.industryList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
            <br />
            <br />
            <TextField
                id="outlined-select-currency"
                fullWidth
                select

                label="Sector"
                value={props.data.sectorId}
                onChange={(e) => props.handleChange(e, "sectorId", null, props.section)}
                required
                style={{ textAlign: 'left' }}
            >
                {props.allSector.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
            {/* <br />
            <br /> */}
        </>
    )
}

export default Venture;