import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import ViewApplicationTable from './ViewApplicationTable'
// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const ViewApplication = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <ViewApplicationTable />
        {/* <ViewApplicationTableCopy /> */}
      </div>

    </>
  )
}

export default ViewApplication