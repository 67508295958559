import React, { useState } from 'react';
import NumberOFFounders from './Number-of-Founders';
import Data from "../Data.json"
import { Dialog, DialogContentText, DialogTitle, DialogActions, DialogContent, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';

function Dailogs(props) {
    const [FounderData, setFounderData] = useState(Data);

    

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <Dialog open={true} onClose={props.handleClose}>
                    <DialogContent>
                        <NumberOFFounders
                            dropdown={props.dropdown}
                            handleClose={props.handleClose}
                            data={props.data}
                            selectedId={props.selectedId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose}
                            color="primary"
                            variant="outlined" >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <br />
        </div>
    );
}
export default Dailogs;
