import React, { useState } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom"
import Saidebar from './Saidebar';
import BasicDetails from './Basic-Details';
import EntityVentureDetails from './Entity-Venture-Details';
import TeamDetails from './Team-Details';
import ConceptIdeaOfStartup from "./Concept-Idea-of-Startup";
import BusinessModel from './Business-model';
import TurnoverAndEmployment from './Turnover-and-Employment';
// import DocumentUpload from './Document-Upload';
import Declaration from './Declaration';
import ReviewForm from './ReviewForm';
import PreRegister from './Pre-Register';
import Footer from "../Componets/footer"


const Main = () => {
    const { id } = useParams()

    return (
        <>
            <Box className='bg'  >
                <Box className='form-ui1' sx={{ height: "100%",   }}>

                
                        <Grid container spacing={5}>
                            <Grid  item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                // borderRadius:"5%",
                                
                                borderRadius: "30px 0px 0px 30px !important"
                            }}
                            >
                                <Saidebar />
                                {
                                id === "basicDetails" ?   <Box sx={{ marginLeft: 6 ,marginTop: 140, marginBottom:5, }}>
                                <Footer />
                            </Box>
                            
                                    : id === "entityVentureDetails" ?   <Box sx={{ marginLeft: 6 ,marginTop: 30, marginBottom:5, }}>
                                    <Footer />
                                </Box>
                                        : id === "conceptIdeasOfStartup" ?   <Box sx={{ marginLeft: 6 ,marginTop: 40, marginBottom:5, }}>
                                        <Footer />
                                    </Box>
                                            : id === "businessModels" ?   <Box sx={{ marginLeft: 6 ,marginTop: 15, marginBottom:5, }}>
                                            <Footer />
                                        </Box>
                                                : id === "turnoverAndEmployment" ?   <Box sx={{ marginLeft: 6 ,marginTop: 20, marginBottom:5, }}>
                                                <Footer />
                                            </Box>
                                                    // :id === "documentUpload"? <DocumentUpload />
                                                    : id === "reviewForm" ?   <Box sx={{ marginLeft: 6 ,marginTop: 440, marginBottom:5, }}>
                                                    <Footer />
                                                </Box>
                                                        : id === "declaration" ?   <Box sx={{ marginLeft: 6 ,marginTop: 20, marginBottom:5, }}>
                                                        <Footer />
                                                    </Box>
                                                            : id === "teamDetails" ?   <Box sx={{ marginLeft: 6 ,marginTop: 20, marginBottom:5, }}>
                                                            <Footer />
                                                        </Box> :  <Box sx={{ marginLeft: 6 ,marginTop: 20, marginBottom:5, }}>
                            <Footer />
                        </Box>
                                }
                              
                            </Grid>

                            <Grid item xs={7}  >
                                
                                {
                                id === "basicDetails" ? <BasicDetails />
                                    : id === "entityVentureDetails" ? <EntityVentureDetails />
                                        : id === "conceptIdeasOfStartup" ? <ConceptIdeaOfStartup />
                                            : id === "businessModels" ? <BusinessModel />
                                                : id === "turnoverAndEmployment" ? <TurnoverAndEmployment />
                                                    // :id === "documentUpload"? <DocumentUpload />
                                                    : id === "reviewForm" ? <ReviewForm />
                                                        : id === "declaration" ? <Declaration />
                                                            : id === "teamDetails" ? <TeamDetails /> : <BasicDetails />
                                }
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            

        </>
    )
}

export default Main;