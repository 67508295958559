export const StartupInnovation = [
    {
        label: 'Technology Innovation',
        value: 'technology',
    },
    {
        label: 'Business Model Innovation',
        value: 'businessModel',
    }
]

export const CurrentStage = [
    {
        label: 'Select',
        value: '',
    },
    {
        label: 'Ideation',
        value: 'ideation',
    },
    {
        label: 'Validation/Pilot/Beta',
        value: 'validation',
    },
    {
        label: 'Early Traction',
        value: 'Early Traction',
    },
    {
        label: 'Scaling/Growth',
        value: 'Scaling/Growth',
    },

];

export const Services = [
    {
        value: 'Government',
        label: 'Government',
    },
    {
        value: 'Hyperlocal',
        label: 'Hyperlocal',
    },

    {
        value: 'Discovery',
        label: 'Discovery',
    },
    {
        value: 'Location based service',
        label: 'Location based service',
    },
    {
        value: 'Manufacturing',
        label: 'Manufacturing',
    },
    {
        value: 'Market place',
        label: 'Market place',
    },
    {
        value: 'Mobile',
        label: 'Mobile',
    },
    {
        value: 'offline',
        label: 'offline',
    },
    {
        value: 'Online aggregator',
        label: 'Online aggregator',
    },
    {
        value: 'peer to peer',
        label: 'peer to peer',
    },
    {
        value: 'Platform',
        label: 'Platform',
    },
    {
        value: 'consulting',
        label: 'consulting',
    },
    {
        value: 'consumer internet',
        label: 'consumer internet',
    },
    {
        value: 'engineering',
        label: 'engineering',
    },
    {
        value: 'ecommerce',
        label: 'ecommerce',
    },
    {
        value: 'others',
        label: 'others',
    },
    {
        value: 'rental',
        label: 'rental',
    },
    {
        value: 'Enterprise Mobility',
        label: 'Enterprise Mobility',
    },
    {
        value: 'research',
        label: 'research',
    },
    {
        value: 'sharing economy',
        label: 'sharing economy',
    },
    {
        value: 'social enterprise',
        label: 'social enterprise',
    },
    {
        value: 'SaaS',
        label: 'SaaS',
    },
    {
        value: 'Subscription Commerce',
        label: 'Subscription Commerce',
    },


];

export const districtsName = [
    {
        value: 'Araria',
        label: 'Araria',
    },
    {
        value: 'Aurangabad',
        label: 'Aurangabad',
    },

    {
        value: 'Begusarai ',
        label: 'Begusarai',
    },
    {
        value: 'Bhagalpur',
        label: 'Bhagalpur',
    },
    {
        value: 'Buxar',
        label: 'Buxar',
    },


];


export const YesNo = [
    {
        value: 'Select',
        label: '',
    },
    {
        value: 'YES',
        label: 'Yes',
    },
    {
        value: 'NO',
        label: 'No',
    },

];



export const NatureOfEntity = [
    {
        value: 'Select',
        label: '',
    },
    {
        value: 'Private Limited company',
        label: 'Private Limited company',
    },
    {
        value: 'Limited Liability Partnership',
        label: 'Limited Liability Partnership',
    },
    {
        value: 'Registered Partnership',
        label: 'Registered Partnership',
    },

];
