import { apiFunction } from "../../../apiCalls/functions";

export const getAccountData = async (url) => {
    return await apiFunction("account/details", "get", {}, true,false, null)
}

export const getAllRecords = async (url) => {
    return await apiFunction("/account/all-records", "get", {}, true,false, null)
}

export const getAllRecordsData = async (url) => {
    return await apiFunction("/account/all-records", "get", {}, true,false, null)
}
export const finalSubmit = async (data) => {
    return await apiFunction("/acknowledgment/final-submit-and-generating-acknowledgementNo", "post", data, true,false, null)
}

export const getApplicationList = async (data) => {
    return await apiFunction("admin/application/list", "post", data, false, true ,null)
}