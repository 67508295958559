import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import TableData from './TableData';
import { Link } from "react-router-dom";
import { getAccountData, getAllRecords } from "./basicAction"
import Dailogs from "./teamDetails/table";

import moment from "moment";
import ViewFile from "./teamDetails/files";
import { loaderStart, loaderEnd } from "./loader"
import { FILE_URL } from "../shared/constant";
import { Visibility } from "@mui/icons-material";

const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('review').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;

}


const ReviewForm = () => {
    const [data, setData] = useState([]);
    const [isRegEntity, setIsRegEntity] = useState(true)
    const [acknowledgement, setacknowledgement] = useState()

    useEffect(() => {
        getInitialData();
        getAllRecord();

    }, [])

    // const CustomFileComponent = ({ inputRef, value, ...rest }) => {

    //     // return <div style={{ "padding": "10px" }}><ViewFile ref={inputRef} file={value} /></div>
    // };

    const getInitialData = async () => {
        loaderStart()
        let AccountData = await getAccountData()
        console.log(AccountData)
        if (AccountData.status) {
            setIsRegEntity(AccountData.data.isRegisteredEntity == "yes" ? true : false)
        }
        loaderEnd()
    }

    const getAllRecord = async () => {
        let data = await getAllRecords()
        setData(data);
        // console.log(data.data.acknowledgement);
        setacknowledgement(data);
    }


    // console.log(data.casteCertificateUpload)
    return (
        <Box>
            <Box id="review" style={{ width: "90%", marginLeft: "43px",  }}>

                <br />

                <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "692px", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Review Form</Typography>

                {/* ======== BASIC DETAILS ========  */}
                <Typography sx={{ color: "#EB4747", width: "90%", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Basic Details</Typography>
                <br />
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="https://picsum.photos/200" alt="application_image" />
                </div> */}
                <TextField color="warning"
                    value={data?.data?.nameOfStartUp}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} fullWidth label="Name of startup" id="fullWidth" />
                <br />
                <br />

                <Box sx={{ display: "flex", justifyContent: "space-between" }} color="warning">
                    <TextField value={data?.data?.firstName}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} style={{ width: 217 }} id="outlined-basic" label="First Name*" variant="outlined" color="warning" name="firstName" />
                    <TextField value={data?.data?.middleName}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} style={{ width: 217 }} id="outlined-basic" label="Middle Name" variant="outlined" color="warning" name="middleName" />
                    <TextField value={data?.data?.lastName}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} style={{ width: 217 }} id="outlined-basic" label="Last Name" variant="outlined" color="warning" name="lastName" />
                </Box>
                <br />
                <TextField value={data?.data?.mobileNumber}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Mobile No*" id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.email}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Email*" id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.adhaarNo}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Aadhar No.*" id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.panCardNo}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Pan No." id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.website}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Website Url" id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.fatherOrGuardianName}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Father's/Guardian Name*" id="fullWidth" />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.dateOfBirth ? moment(data?.data?.basicDetails?.dateOfBirth).format("YYYY-MM-DD") : ""}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" fullWidth label="Date Of Birth" id="fullWidth" type="date" />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.higherEducation?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Education*" id="fullWidth" fullWidth />
                <br />
                <br />

                
                <TextField value={data?.data?.basicDetails?.otherInstitute }
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Institute*" id="fullWidth" fullWidth />
                <br />
                <br />

                <TextField color="warning" value={data?.data?.basicDetails?.gender?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} label="Gender*" id="fullWidth" fullWidth />
                <br />
                <br />
                {/* <br /> */}

                <Typography sx={{ width: "600px", display: "flex", justifyContent: "flex-start", fontWeight: "600", color: "#474747" }}>Permanent Address</Typography>
                <br />
                <TextField color="warning" value={data?.data?.basicDetails?.addresses?.length > 0 ? data?.data?.basicDetails?.addresses[data?.data?.basicDetails?.addresses?.length - 1]['addressLine1'] : ""}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} label="Address Line 1" id="fullWidth" fullWidth />
                <br />
                <br />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <TextField value={data?.data?.basicDetails?.addresses?.length > 0 ? data?.data?.basicDetails?.addresses[data?.data?.basicDetails?.addresses?.length - 1]['district']['state']['name'] : ""}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} color="warning" sx={{ width: "159px" }} label="State*" id="fullWidth" />
                    <TextField value={data?.data?.basicDetails?.addresses?.length > 0 ? data?.data?.basicDetails?.addresses[data?.data?.basicDetails?.addresses?.length - 1]['district']['name'] : ""}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} color="warning" sx={{ width: "159px" }} label="District*" id="fullWidth" />
                    <TextField value={data?.data?.basicDetails?.addresses?.length > 0 ? data?.data?.basicDetails?.addresses[data?.data?.basicDetails?.addresses?.length - 1]['city']['name'] : ""}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} color="warning" sx={{ width: "159px" }} label="City*" id="fullWidth" />
                    <TextField value={data?.data?.basicDetails?.addresses?.length > 0 ? data?.data?.basicDetails?.addresses[data?.data?.basicDetails?.addresses?.length - 1]['pincode'] : ""}
                        InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true, disabled: true
                        }} color="warning" sx={{ width: "159px" }} label="Pin Code*" id="fullWidth" />
                </Box>

                <br />
                {/* <TextField color="warning" value={data?.data?.basicDetails?.religion?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} label="Religion" id="fullWidth" fullWidth /> */}
                {/* <br /> */}
                <br />
                <TextField color="warning" value={data?.data?.basicDetails?.category?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} label="Category" id="fullWidth" fullWidth />
                <br />
                <br />
                {/* <TextField color="warning" value={data?.data?.basicDetails?.casteCertificateUpload}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        inputComponent: CustomFileComponent,
                        readOnly: true,
                    }} label="Caste Certificate" id="fullWidth" fullWidth /> */}
                {/* <br /> */}
                {/* <ViewFile file={data?.data?.basicDetails?.casteCertificateUpload} /> */}
                {/* {
                    data?.data?.basicDetails?.casteCertificateUpload ? <div className="view-file" >
                        <a target="_blank" rel='noreferrer' href={data?.data?.basicDetails?.casteCertificateUpload?.includes("https") ? data?.data?.basicDetails?.casteCertificateUpload : FILE_URL + data?.data?.basicDetails?.casteCertificateUpload}>
                            View Caste Certificate
                        </a>
                    </div> : ""
                } */}


                <br />
                {/* 
                <TextField value={data?.data?.basicDetails?.photoUpload}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        inputComponent: CustomFileComponent,
                        readOnly: true,
                    }} label="Passport Size Photo" id="fullWidth" fullWidth /> */}
                {/* <br /> */}
                {/* <div className="view-file" >
                    <a target="_blank" rel='noreferrer' href={data?.data?.basicDetails?.photoUpload?.includes("https") ? data?.data?.basicDetails?.photoUpload : FILE_URL + data?.data?.basicDetails?.photoUpload}>
                        Passport Size Photo
                    </a>
                </div> */}
                <br />
                {/* <br /> */}
                {
                    isRegEntity
                        ?
                        <>
                            <Typography sx={{ color: "#EB4747", width: "692px", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Entity Venture</Typography>
                            <br />
                            <TextField value={data?.data?.basicDetails?.registeredEntity?.natureOfEntity?.name}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} color="warning" label="Nature of Entity" id="fullWidth" fullWidth />
                            <br />
                            <br />

                            {/* <div className="view-file" >
                                <a target="_blank" rel='noreferrer' href={data?.data?.basicDetails?.registeredEntity?.proofOfEntityUpload?.includes("https") ? data?.data?.basicDetails?.registeredEntity?.proofOfEntityUpload : FILE_URL + data?.data?.basicDetails?.registeredEntity?.proofOfEntityUpload}>
                                    Nature of Entity Document
                                </a>
                            </div> */}

                            <br />
                            <TextField value={data?.data?.basicDetails?.registeredEntity?.dateOfRegistration ? moment(data?.data?.basicDetails?.registeredEntity?.dateOfRegistration).format("YYYY-MM-DD") : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} color="warning" label="Date of Registration" id="fullWidth" fullWidth />
                            <br />
                            <br />
                            <TextField value={data?.data?.basicDetails?.registeredEntity?.roc?.name}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} color="warning" label="ROC Bihar Only" id="fullWidth" fullWidth />
                            <br />
                            <br />
                            <TextField value={data?.data?.basicDetails?.registeredEntity?.udyamiAadharNo}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} color="warning" label="Udyami Aadhar" id="fullWidth" fullWidth />
                            <br />
                            <br />
                        </>
                        :
                        null
                }
                {/* ======== ENTITY VENTURE ========  */}
                {/* <Typography sx={{ color: "#EB4747", width: "692px", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Entity Venture</Typography>
                <br />

                <TextField value={data?.data?.basicDetails?.registeredEntity?.natureOfEntity?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true,
                    }} color="warning" label="Nature of Entity" id="fullWidth" fullWidth />
                <br />
                <br /> */}


                {/* <TextField value={data?.data?.basicDetails?.registeredEntity?.proofOfEntityUpload}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        inputComponent: CustomFileComponent,
                        readOnly: true,
                    }} color="warning" label="Proof of entity" id="fullWidth" fullWidth />
                <br />
                <br /> */}
                {/* <div className="view-file" >
                    <a target="_blank" rel='noreferrer' href={FILE_URL + data?.data?.basicDetails?.registeredEntity?.natureOfEntity?.name}>
                <div className="view-file" >
                    <a target="_blank" rel='noreferrer' href={FILE_URL + data?.data?.basicDetails?.registeredEntity?.proofOfEntityUpload}>
                        Nature of Entity
                    </a>
                </div>
                <br /> */}
                {/* <br/> */}

                {/* <TextField value={data?.data?.basicDetails?.registeredEntity?.dateOfRegistration ? moment(data?.data?.basicDetails?.registeredEntity?.dateOfRegistration).format("YYYY-MM-DD") : ""}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true,
                    }} color="warning" label="Date of Registration" id="fullWidth" fullWidth />
                <br />
                <br /> */}

                {/* <TextField value={data?.data?.basicDetails?.registeredEntity?.roc?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true,
                    }} color="warning" label="ROC Bihar Only" id="fullWidth" fullWidth />
                <br />
                <br /> */}

                {/* <TextField value={data?.data?.basicDetails?.registeredEntity?.udyamiAadharNo}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true,
                    }} color="warning" label="Udyami Aadhar" id="fullWidth" fullWidth />
                <br />
                <br /> */}

                {/* <TextField color="warning" label="Office in Bihar" id="fullWidth" fullWidth />
                <br />
                <br /> */}

                <TextField value={data?.data?.basicDetails?.venture?.dpiitRegisterNo}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="DPIIT Registration No" id="fullWidth" fullWidth />
                <br />
                <br />

                {/* <TextField value={data?.data?.basicDetails?.venture?.dpiitCertificate}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        inputComponent: CustomFileComponent,
                        readOnly: true,
                    }} color="warning" label="DPIIT Certificate" id="fullWidth" fullWidth />
          
                {/* {
                    data?.data?.basicDetails?.venture?.dpiitCertificate ?
                        <><div className="view-file" >
                            <a target="_blank" rel='noreferrer' href={data?.data?.basicDetails?.venture?.dpiitCertificate?.includes("https") ? data?.data?.basicDetails?.venture?.dpiitCertificate : FILE_URL + data?.data?.basicDetails?.venture?.dpiitCertificate}>
                                DPIIT Certificate
                            </a>
                        </div>
                            <br /></> : ''
                } */}


                <TextField value={data?.data?.basicDetails?.venture?.startupInnovation}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Type Of Startup Innovation" id="fullWidth" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.venture?.currentStage}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Current Stage" id="fullWidth" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.venture?.service?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Services" id="fullWidth" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.venture?.sector?.industry?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Industry Startup" id="fullWidth" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.venture?.sector?.name}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} color="warning" label="Sector" id="fullWidth" fullWidth />
                <br />
                {/* <br />
                <br /> */}

                {/* TEAM DETAILS */}
                <Typography sx={{ color: "#EB4747", width: "90%", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Founder Details</Typography>
                <br />
                <Dailogs data={data?.data?.basicDetails?.teams ? data?.data?.basicDetails?.teams : []} />
                {/* <br />
                <br /> */}

                {/* CONCEPT & IDEAS OF STARTUP */}
                <Typography sx={{ color: "#EB4747", width: "90%", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Concept &#38; Idea of Startup</Typography>
                <br />
                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.innovativeText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="A brief note on innovation in idea" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.uniqueText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="A brief note on uniqueness/ differentiator such as process/ scale/ brand etc, in idea" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.CreateHighEmployementeText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="A brief note on the potential of high employment through the Startup" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.GeneratemassiveWealthText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="A brief note on the potential of generating massive wealth through the Startup" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.improveQualityText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="A brief note on the capability of development or improvement of Products or Process or Services" fullWidth />
                <br />
                <br />

                <TextField value={data?.data?.basicDetails?.conceptOrIdea?.successStoriesText}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        readOnly: true, disabled: true
                    }} id="outlined-multiline-flexible" multiline color="warning" label="Success stories of your Idea / prototypes / growth plans" fullWidth />
                <br />
                <br />

                {/* <TextField value={data?.data?.basicDetails?.conceptOrIdea?.successStories}
                    InputLabelProps={{ shrink: true }} InputProps={{
                        inputComponent: CustomFileComponent,
                        readOnly: true,
                    }} 
                    id="outlined-multiline-flexible" color="warning" label="Video of duration 2 minutes mentioning your Startup Idea" fullWidth /> */}
                {/* <div className="view-file" >
                    <a target="_blank" rel='noreferrer' href={data?.data?.basicDetails?.conceptOrIdea?.successStories?.includes("https") ? data?.data?.basicDetails?.conceptOrIdea?.successStories : FILE_URL + data?.data?.basicDetails?.conceptOrIdea?.successStories}>
                        Video of duration 2 minutes mentioning your Startup Idea
                    </a>
                </div> */}
                {/* <br /> */}

                {
                    data?.data?.isRegisteredEntity == "yes"
                        ?
                        <>
                            <br />
                            <br />
                            {/* BUSINESS MODEL */}
                            <Typography sx={{ color: "#EB4747", width: "692px", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Business Models</Typography>
                            <br />

                            <TextField value={data?.data?.basicDetails?.bussinessModel?.isOfficeInBihar ? "Yes" : "No"}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" multiline color="warning" label="Do you have an office in Bihar?" fullWidth />
                            <br />
                            <br />

                            <TextField value={data?.data?.basicDetails?.bussinessModel?.addressLine1}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" multiline color="warning" label="Address Line 1" fullWidth />
                            <br />
                            <br />
                            <TextField value={data?.data?.basicDetails?.bussinessModel?.addressLine2}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" multiline color="warning" label="Address Line 2" fullWidth />
                            <br />
                            <br />
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                                <TextField value={data?.data?.basicDetails?.bussinessModel?.district?.name ? data?.data?.basicDetails?.bussinessModel?.district?.name : ''}
                                    InputLabelProps={{ shrink: true }} InputProps={{
                                        readOnly: true, disabled: true
                                    }} color="warning" sx={{ width: "200px" }} label="District*" id="fullWidth" />
                                <TextField value={data?.data?.basicDetails?.bussinessModel?.city?.name}
                                    InputLabelProps={{ shrink: true }} InputProps={{
                                        readOnly: true, disabled: true
                                    }} color="warning" sx={{ width: "200px" }} label="City*" id="fullWidth" />
                                <TextField value={data?.data?.basicDetails?.bussinessModel?.pincode}
                                    InputLabelProps={{ shrink: true }} InputProps={{
                                        readOnly: true, disabled: true
                                    }} color="warning" sx={{ width: "200px" }} label="Pin Code*" id="fullWidth" />
                            </Box>
                            <br />

                            {/* <TextField value={data?.data?.basicDetails?.bussinessModel?.form1}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" color="warning" label="Business Model (FORM I)" fullWidth /> */}
                            {/* <br />
                            <br /> */}

                            {/* <TextField value={data?.data?.basicDetails?.bussinessModel?.form2}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" color="warning" label="Team Skill Set (FORM II)" fullWidth /> */}
                            <br />
                            <br />
                        </>
                        : null
                }

                {/* <TextField id="outlined-multiline-flexible" multiline color="warning" label="Cost structure/ Upfront cost ( Capital Expenditure Description )"  fullWidth />
                <br />
                <br /> */}
                <br />

                {/* <Typography sx={{width:"600px", display:"flex", justifyContent:"flex-start", fontWeight:"600", color:"#474747"}}>Team Skill Sets</Typography>
                <br />

                <TextField id="outlined-multiline-flexible" multiline color="warning" label="Finance"  fullWidth />
                <br />
                <br />

                <TextField id="outlined-multiline-flexible" multiline color="warning" label="Marketing"  fullWidth />
                <br />
                <br />

                <TextField id="outlined-multiline-flexible" multiline color="warning" label="Technical"  fullWidth />
                <br />
                <br />
                <TextField id="outlined-multiline-flexible" multiline color="warning" label="Domain Expert"  fullWidth />
                <br />
                <br />
                <br /> */}

                {/* TURNOVER AND EMPLOYMENT */}
                {
                    data?.data?.isRegisteredEntity == "yes"
                        ?
                        <>
                            <Typography sx={{ color: "#EB4747", width: "692px", fontSize: 18, borderBottom: "1px solid #EB4747", }} >Turnover and Employment</Typography>
                            <br />

                            <TextField id="outlined-multiline-flexible" value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['turnover'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} multiline color="warning" label="Turnover (Revenue) in the last FY (INR Crores )" fullWidth />
                            <br />
                            <br />

                            {/* <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['balanceSheet'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" color="warning" label="Balance Sheet" fullWidth /> */}
                            {/* <br />
                            <br /> */}

                            {/* <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['totalEmployees'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true,
                                }} id="outlined-multiline-flexible" multiline color="warning" label="Total Employee strength" fullWidth />
                            <br />
                            <br /> */}

                            {/* <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['form3'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" color="warning" label="Total Employee strength (Form III)" fullWidth /> */}
                            {/* <br />
                            <br /> */}

                            <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['alreadyRaisedFund'] : "No"}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" multiline color="warning" label="Already raised Fund? (Such as Startup India/Meity/Atal/AI/VC etc)*" fullWidth />
                            <br />
                            <br />

                            <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['raisedFundAmount'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" multiline color="warning" label="If Already Fund (Yes) Turnover (Revenue) in the last FY (INR Lakhs)" fullWidth />
                            <br />
                            <br />

                            {/* <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['form4'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true, disabled: true
                                }} id="outlined-multiline-flexible" color="warning" label="Fund raised (Form IV)" fullWidth /> */}
                            {/* <br />
                            <br /> */}

                            {/* <TextField value={data?.data?.basicDetails?.turnovers?.length > 0 ? data?.data?.basicDetails?.turnovers[data?.data?.basicDetails?.turnovers?.length - 1]['form5'] : ""}
                                InputLabelProps={{ shrink: true }} InputProps={{
                                    inputComponent: CustomFileComponent,
                                    readOnly: true,
                                }} id="outlined-multiline-flexible" color="warning" label="Fund raised (Form V)" fullWidth />
                            <br />
                            <br /> */}
                        </>
                        : null

                }


                <Button style={{ backgroundColor: "#EB4747" }} className="printing" variant="contained" fullWidth onClick={Print} >Print</Button>
                <br />
                <br />



                {
                    acknowledgement?.data.acknowledgement ?
                        <Button disabled={true} style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth  >Proceed to Next</Button>
                        :
                        <Link style={{ textDecoration: "none" }} to='/main/declaration'>
                            <Button style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth  >Proceed to Next</Button>
                        </Link>

                }
                   <br />
                   <br />



            </Box>

{/* 
            <Box style={{ marginLeft: "-1260px", marginTop: "-35px", marginBottom: "40px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}
        </Box>
    )
}

export default ReviewForm;

