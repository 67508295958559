import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
    Checkbox,
    OutlinedInput,
    FormControl,
    ListItemText
} from "@mui/material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useNavigate, Link } from "react-router-dom";
import TableData from './TableData';
import { getAccountData, getAllRecords } from "./basicAction"
import Dailogs from "./teamDetails/table";

import moment from "moment";
import ViewFile from "./teamDetails/files";
import { loaderStart, loaderEnd } from "../loader"
import { FILE_URL } from "../../shared/constant";
import Address from "./basic/address";
import UniversityContent from "./universityContent";
import IndustriesContent from "./industriesContent";

const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('review').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
}

const initialValue1 = {
    panCardNo: ""
};

const initialValue2 = {
    "addressLine1": "",
    "addressLine2": "",
    "cityId": "",
    stateId: "",
    "districtId": "",
    "pincode": null,
    "addressType": "permanent"
};

const initialValue3 = {
    firstName: "",
    mobileNumber: "",
    email: "",
    adhaarNo: "",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const startupIndustries = [
    'Value1',
    'Value2',
    'Value3',
    'Value4'
];
const startupSector = [
    'Value1',
    'Value2',
    'Value3',
    'Value4'
];
const initialValue = {
    incubatorType: ""
};
const ReviewForm = () => {
    //post api for add details
    const [token, setToken] = useState(null)
    const [basicDetails, setBasicDetails] = useState(initialValue1);
    const [addressDetails, setAddressDetails] = useState(initialValue2);
    //get api for auto fill details
    const [autoFillDeatils, setAutoFillDeatils] = useState(initialValue3);
    const [incubator, setIncubator] = useState(initialValue);
    const [startupSectorSelect, setStartupSectorSelect] = React.useState([]);
    const [startupIndustriesSelect, setStartupIndustriesSelect] = React.useState([]);
    const { incubatorType } = incubator;
    const onValue = (e) => {
        // console.log(e.target.value);
        setBasicDetails({ ...basicDetails, [e.target.name]: e.target.value });
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStartupSectorSelect(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const onValueFile = (e) => {
        // let data=e.target.files[0]
        // console.log(e.target.files[0]);
        setBasicDetails({ ...basicDetails, [e.target.name]: e.target.files[0] });
        // console.log(basicDetails, "helllooo");
    };

    const [data, setData] = useState([]);
    const [isRegEntity, setIsRegEntity] = useState(true)
    useEffect(() => {
        getInitialData();

    }, [])

    const CustomFileComponent = ({ inputRef, value, ...rest }) => {

        return <div style={{ "padding": "10px" }}><ViewFile ref={inputRef} file={value} /></div>
    };

    const getInitialData = async () => {
        loaderStart()
        let data = await getAllRecords()
        setData(data)
        let AccountData = await getAccountData()
        // console.log(AccountData)
        if (AccountData.status) {
            setIsRegEntity(AccountData.data.isRegisteredEntity == "yes" ? true : false)
        }
        loaderEnd()
    }

    return (
        <Box>
            <Box id="review" className='sub_box_incubator'>

                <Typography className='box_heading_incubator' sx={{ fontWeight: "bold", fontSize: 20 }} >Review Form</Typography>
                <br />
                {/* ======== BASIC DETAILS ========  */}
                <Typography className='box_heading_incubator' sx={{ width: "200px", fontSize: 18 }} >Basic Details</Typography>
                <br />
                <Grid container spacing={2} sx={{ color: 'text.primary' }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            type="text"
                            fullWidth
                            label="Name of personnel"
                            name="firstName"
                            value={autoFillDeatils.firstName}
                            disabled
                        // onChange={(e) => onValue(e)}
                        />
                    </Grid>
                </Grid>

                <div className="">
                    <br />
                    <TextField
                        fullWidth
                        disabled
                        label="Mobile No*"
                        id="fullWidth"
                        variant="outlined"

                        value={autoFillDeatils.mobileNumber}
                        name="mobileNumber"
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />
                    <TextField
                        fullWidth
                        disabled
                        label="Email*"
                        id="fullWidth"
                        variant="outlined"

                        value={autoFillDeatils.email}
                        name="email"
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />
                    <TextField
                        fullWidth
                        label="Aadhar No.*"
                        id="fullWidth"
                        variant="outlined"
                        type="number"

                        disabled
                        value={autoFillDeatils.adhaarNo}
                        name="adhaarNo"
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />
                    <TextField
                        fullWidth
                        label="PAN No"
                        id="fullWidth"
                        variant="outlined"
                        type="text"
                        // inputProps={{ pattern: "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/" }}
                        // inputProps: {{pattern: '[a-z]'}}
                        value={basicDetails.panCardNo}
                        name="panCardNo"
                        onChange={(e) => onValue(e)}

                    />
                    <br />
                    <br />
                    <TextField
                        fullWidth
                        label="Incubator Name"
                        id="fullWidth"
                        variant="outlined"
                        type="text"
                        // inputProps={{ pattern: "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/" }}
                        // inputProps: {{pattern: '[a-z]'}}
                        value={basicDetails.panCardNo}
                        name="panCardNo"
                        onChange={(e) => onValue(e)}

                    /><br /><br />
                    <Address
                        basicDetails={addressDetails}
                        setBasicDetails={setAddressDetails}
                        token={token}
                        onValue={onValue}
                    />
                    <br />

                </div>
                <Typography className='box_heading_incubator' sx={{ width: "200px", fontSize: 18 }} >Incubator Details</Typography><br />
                <Grid container spacing={2} sx={{ color: 'text.primary' }}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            id="incubator_type_id"
                            value={incubatorType}
                            label="Type of Incubator"
                            onChange={(e) => onValue(e)}
                            name="incubatorType"
                        >
                            <MenuItem value="university">University/College/Institute</MenuItem>
                            <MenuItem value="industry">Industry Association/Private company</MenuItem>
                        </TextField><br /><br />
                        <UniversityContent />
                        <IndustriesContent />
                        <TextField
                            select
                            fullWidth
                            id="incubator_type_id"
                            value={incubatorType}
                            label="Incorporation Structure"
                            onChange={(e) => onValue(e)}
                            name="incubatorType"
                        >
                            <MenuItem value={1}>LLP</MenuItem>
                            <MenuItem value={2}>Pvt. Ltd</MenuItem>
                            <MenuItem value={2}>Partnership Firm</MenuItem>
                            <MenuItem value={2}>Proprietorship Firm</MenuItem>
                            <MenuItem value={2}>Trust</MenuItem>
                            <MenuItem value={2}>Society</MenuItem>
                        </TextField><br /><br />
                        <TextField
                            fullWidth
                            label="Incorporation No"
                            id="fullWidth"
                            value=""
                            type="text"
                            onChange={(e) => { }}
                        />
                        <br /><br />
                        <TextField
                            fullWidth
                            name="someDate"
                            label="Incorporation Date"
                            InputLabelProps={{ shrink: true, required: true }}
                            type="date"
                        /><br /><br />
                        <TextField
                            fullWidth
                            label="Total Years of existence from Incorporation date"
                            id="fullWidth"
                            value=""
                            type="text"
                            onChange={(e) => { }}
                        /><br /><br />
                        <TextField
                            type="file"
                            label={" "}
                            inputProps={{ accept: "application/pdf" }}
                            name="casteCertificateUpload"
                            fullWidth
                            onChange={(e) => { }}
                        /><br /><br />
                        <TextField
                            fullWidth
                            label="Website URL"
                            id="fullWidth"
                            value=""
                            type="text"
                            onChange={(e) => { }}
                        /><br /><br />
                        <TextField
                            fullWidth
                            label="Linkdin"
                            id="fullWidth"
                            value=""
                            type="text"
                            onChange={(e) => { }}
                        /><br /><br />
                        <div style={{ textAlign: "left" }}>
                            <label>Select stages of startup you are interested in working with</label><br />
                            <FormControlLabel
                                control={
                                    <Checkbox name="Ideation" />
                                }
                                label="Ideation"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="Validation" />
                                }
                                label="Validation"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="Early Traction" />
                                }
                                label="Early Traction"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="Scaling" />
                                }
                                label="Scaling"
                            /><br /><br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Select Industry of startup you are interested in working with</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={startupIndustriesSelect}
                                    input={<OutlinedInput label="Tag" />}
                                    onChange={handleChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {startupIndustries.map((val) => (
                                        <MenuItem key={val} value={val}>
                                            <Checkbox checked={startupIndustriesSelect.indexOf(val) > -1} />
                                            <ListItemText primary={val} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl><br /><br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Select Sector of startup you are interested in working with</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={startupIndustriesSelect}
                                    input={<OutlinedInput label="Tag" />}
                                    onChange={handleChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {startupIndustries.map((val) => (
                                        <MenuItem key={val} value={val}>
                                            <Checkbox checked={startupIndustriesSelect.indexOf(val) > -1} />
                                            <ListItemText primary={val} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl><br /><br />
                            <TextField
                                fullWidth
                                label="Contact Person Name"
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                fullWidth
                                label="Contact Person Mobile no"
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                fullWidth
                                label="Contact person email id"
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label="Laboratory /workshop facilities"
                                    control={<Radio />} />
                            </RadioGroup><br />
                            <TextField
                                fullWidth
                                label="No. of Startups incubated "
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                multiline
                                maxRows={4}
                                fullWidth
                                label="Brief of Project Incubated"
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label="Created Startup/Innovation/Entrepreneurship Development cell"
                                    control={<Radio />} />
                            </RadioGroup><br />
                            <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label="Government funded (Yes/No)"
                                    control={<Radio />} />
                            </RadioGroup><br />
                            <TextField
                                fullWidth
                                label="Infrastructure details"
                                id="fullWidth"
                                value=""
                                onChange={(e) => { }}
                            /><br /><br />
                            <label>Upload Undertaking with photo of facility</label>
                            <TextField type="file" label={" "}
                                inputProps={{ accept: " application/pdf" }} name="casteCertificateUpload" fullWidth
                                onChange={(e) => { }}
                            /><br /><br />
                            <label>Upload details of each employees/staff dedicated for Startup incubation with experience</label>
                            <TextField type="file" label={" "}
                                inputProps={{ accept: " application/pdf" }} name="casteCertificateUpload" fullWidth
                                onChange={(e) => { }}
                            />
                        </div>
                        <br />
                        <Link style={{ textDecoration: "none" }} to='/incubator/main/declaration'>

                            <Button type="submit" className="btn_class" variant="contained" fullWidth>Proceed to Next</Button>

                        </Link>
                    </Grid>
                </Grid>

            </Box>


            <Box className="page_design_develop_incu" style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box>
        </Box>
    )
}

export default ReviewForm;