import { Card } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../Header/Header'
import { checkUser } from '../../basicAction'
import { actionNotifier } from '../../notification'

const StartupIndiaLandingPage = () => {
  const [startupIndia, setStartupIndia] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    const currentURL = window.location.href
    const hash = currentURL.substring(currentURL.indexOf('#') + 1);
    let result = hash.split('&')
    result = result[0].split('=')
    // console.log(result[1])
    localStorage.setItem("startup-india", JSON.stringify(result[1]))
    onStartupIndia()
  }, [])

  const onStartupIndia = async () => {
    let token = localStorage.getItem("startup-india")
    token = JSON.parse(token)
    // console.log(token)
    const config = {
      headers: {
        'x-auth-token': token,
      }
    };
    await axios.get(`https://api.startupindia.gov.in/sih/api/auth/sso/services/get/userDetails`, config)
      .then(async result => {
        console.log(result.data)
        setStartupIndia(result.data)
        let data = await checkUser({ email: result.data.email })
        setData(data);
      })
      .catch(error => {
        console.log(error.response.data);
        actionNotifier.error(error.response.data.message)
      })
  }

  return (
    <>
      <Header />
      <Card sx={{ width: '400px', height: '150px', margin: 'auto', padding: '20px', marginTop: '10px' }}>

        {/* {startupIndia?.name.toLowerCase().replace(/(_\w)/g, (w) => w.toUpperCase().substr(1))}<br />  */}
        {data?.data ? <h5>Hi, Please Login To Startup Bihar see your details</h5>
          :
          <h5>Hi, Please complete your profile to register with Startup Bihar</h5>}

        <Link to={data?.data ? '/login' : '/preregister'}>
          {data?.data ? 'Login Here' : 'Edit profile'}
        </Link>
      </Card>
      <div style={{ visibility: 'hidden' }}>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
        <h1>fghj</h1>
      </div>
    </>
  )
}

export default StartupIndiaLandingPage