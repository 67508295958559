import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, MenuItem, Grid, Typography } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom"
import Navbar from './Navbar';
import HindiForm from '../assets/pdf/Form I-IV in Hindi.pdf'
import EnglishForm from '../assets/pdf/Form I-IV in English.pdf'
import policy from '../assets/pdf/Bihar Startup Policy 2022 (1).pdf';


import "../../src/Style/Authentication.css"

import Footer from "./footer"
import { useSelector } from 'react-redux';

const HowToApply = () => {
    const navigate = useNavigate()
    const MarksCalculate = useSelector((store) => store.MarksSubmitted.MarksSubmitted);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);

    const handleSubmit = () => {
        if (checked) {
            navigate("/preregister")
        }
    }

    
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            // pageLanguage: 'en',
            includedLanguages: 'en,hi', // include this for selected languages
            // layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          'google_translate_element'
        );
      };
  
    useEffect(() => {
        let script = document.createElement('script');
         script  = document.createElement('script');
         script.setAttribute(
          'src',
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(script);
        window.googleTranslateElementInit = googleTranslateElementInit;
        // console.log('i fire once');

        if(!MarksCalculate >= 8 || MarksCalculate == null ){
            navigate("/apply-new")
        }
        
        
      }, []);

    return (
        <Box className='backround-ui'>
            <Grid item container spacing={1}>
                <Box className='card-ui' sx={{ height: "200vh", marginBottom: "50px" }} >
                    <Grid item xs={4} >
                        <Navbar />
                        <Box sx={{ marginTop: 85 }}>
                            <Footer />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                    <div className="col-sm-4">
                    <span   id="google_translate_element"  ></span>
                    </div>
              
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red" }}><b><u>GENERAL INSTRUCTIONS</u></b></h6></div>
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                            <ol>
                                <li><p >Applicants are required to apply online through website:  <strong>startup.indbih.com</strong>.</p></li>
                                <li><p>Applicants applying online need NOT send hard copy of the Online Application filled by them online or any other document/certificate/testimonial to the Department. However, they are advised to take the printout of the Online Application and retain it for further communication. </p></li>
                                <li><p>The Applicants applying under Startup Bihar should ensure that they fulfill all eligibility conditions as per the Startup Bihar Policy 2022. </p></li>
                                <li><p>Department of Industries can ask for the original documents to verify at any stages of the selection process.</p></li>
                            </ol>
                        </div>

                        <div style={{ textAlign: "justify", marginLeft: 65, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>

                            <h6 style={{ textAlign: "center", color: "red" }}><b> <u>Essential Documents to be made ready before applying </u></b> </h6><b></b>
                            <p>Before starting to fill up the on-line application, keep ready with you the following details/documents / Information </p>
                        </div>
                        <div style={{ textAlign: "justify", marginLeft: 80, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>

                            <ol style={{ listStyleType: 'square' }}>
                                <li>Valid Email ID (the email should be valid for at least 1 year).</li>
                                <li>Mobile no. linked with Aadhar.</li>
                                <li>Passport size photo.</li>
                                <li>Scanned Image of Aadhar card and Pan card.</li>
                                <li>Scanned Image of Caste certificate ( Not required for general category)</li>
                                <li>Scanned Image of educational qualification Certificate.</li>
                                <li>Scanned Image of Proof of entity (If entity is registered).</li>
                                <li>Scanned Image of Balance Sheet.</li>
                                <li>Scanned Image of given format filled with details and signature.</li>
                            </ol>
                        </div>
                        <div style={{ textAlign: "justify", marginLeft: 65, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red" }}><b> <u>How to Apply </u></b> </h6><b></b>
                        </div>
                        <div style={{ textAlign: "left", marginLeft: 65, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                            <b> 1 :</b>  Read the Information carefully.<br />

                            <b> 2 :</b> Download the below format and fill it with your signature.<br />
                            <div>
                                <a href={EnglishForm} download style={{ color: "blue", marginLeft: "10%" }}> <b> Download Format in English.</b>  </a>
                                <a href={HindiForm} download style={{ color: "blue", marginLeft: "10%" }}> <b> Download Format in Hindi.</b>  </a>
                                </div>
                            <br />

                            <b> 3 :</b> Register your startup.<br />

                            <p> <b> 4 :</b> Fill Application form with all required information. </p>

                            <b> 5 :</b><strong> Password will be sent to your registered Mobile number and email id.</strong><br />

                            <b> 6 :</b> Upload scanned Image of all required documents, photo and format.<br />

                            <b> 7 :</b> Preview your application.<br />

                            <b> 8 :</b> Final Submission<br />
                            <b> 9 :</b> Print Application and Acknowledgement Slip.<br />
                        </div>
                        <br />
                        <h6 style={{ textAlign: "center", color: "red" }}><b> <u>Documents Uploading Instructions </u></b> </h6><b></b>
                        <div style={{ textAlign: "left", marginLeft: 65, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                            <p> <b>Upload Photograph :</b> Photograph must be a recent passport size color picture. Image should only be in .jpg or .jpeg format. Size of file should be not be more than 200 kb. </p>
                            <p> <b>Upload Documents :</b>Upload Documents : Documents should only be in .pdf format. Size of file should not be more than 5 Mb of a single file. </p>
                        </div>
                        {/* <a href={sop} download style={{ color: "blue", marginLeft: "10%" }}> <b> Link to download SOP.</b>  </a>
                        <br /> */}
                        <a href={policy} download style={{ color: "blue", marginLeft: "10%" }}> <b> Link to download Startup Policy.</b>  </a>
                        <br />
                        <br />

                        <form style={{marginBottom:20}}>
                            <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>I have read all the instructions carefully.	</span>
                            </div>

                            {/* <Box style={{display: "flex", justifyContent:"space-between"}}>
                        <TextField id="outlined-basic" label="Name" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Place" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Date" disabled variant="outlined" />
                    </Box> */}


                            <br />
                            <Button
                                style={checked ? { backgroundColor: "#EB4747", color: "white" } : {}}
                                variant="contained"
                                onClick={() => handleSubmit()} 
                                disabled={!checked}
                            >Accept and Proceed</Button>
                        </form>
                    </Grid>
                </Box>
                
            </Grid>
        </Box>

    )
}

export default HowToApply