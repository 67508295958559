

import { apiFunction } from "../../apiCalls/functions";

export const getDropdown = async (url) => {
    return await apiFunction(url, "get", {}, true, null)
}

export const fetchVenture = async () => {
    return await apiFunction("venture", "get", {}, true, null)
}
export const addUpdateVenture = async (data, id) => {
    var formData = new FormData();
    for (var key in data) {
        formData.append(key, data[key]);
    }
    formData.delete('isDeclare');
    if (data.isDeclare) {
        formData.append("isDeclare", true);
    }
    for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }
    if (id) {
        return await apiFunction("venture", "put", formData, true, null)
    }
    else {
        return await apiFunction("venture", "post", formData, true, null)
    }
}

export const fetchRegEntity = async () => {
    return await apiFunction("registered-entity", "get", {}, true, null)
}
export const fetchConceptAndIdea = async () => {
    return await apiFunction("conceptoridea", "get", {}, true, null)
}
export const fetchBusinessModel = async () => {
    return await apiFunction("business-model", "get", {}, true, null)
}
export const fetchTurnOver = async () => {
    return await apiFunction("turnover", "get", {}, true, null)
}
export const addUpdateRegEntity = async (data, id) => {
    var formData = new FormData();
    for (var key in data) {
        formData.append(key, data[key]);
    }
    if (id) {
        return await apiFunction("registered-entity", "put", formData, true, null)
    }
    else {
        return await apiFunction("registered-entity", "post", formData, true, null)
    }
}


export const getAllDropdown = async () => {
    let district = await getDropdown("/data/state/5/district")
    let natureOfEntity = await getDropdown("/data/nature-of-entity/")
    let industryList = await getDropdown("/data/industry")
    let services = await getDropdown("/data/service/")

    return {
        district: district.data,
        natureOfEntity: natureOfEntity.data,
        industryList: industryList.data,
        services: services.data
    }
}