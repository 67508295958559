import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  TextField,
  Typography,
  TextareaAutosize,
  
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {

  scheduleMeeting,
  meetingList,
  updateMeeting,
  addingFinalRemarks
} from "../../basicAction";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Modal from "@mui/material/Modal";
import DataTable from "react-data-table-component";
import Linkify from 'react-linkify';
import { useSelector, useDispatch } from 'react-redux';

const MeetingInfo = (props) => {
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const flags = useSelector((store) => store.recognition.recognition);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [search, setSearch] = useState("");
  const [filteredResults, setFilteredResults] = useState();

  const [selectedDate, handleDateChange] = useState(
    new Date("2022-09-24T00:00:00.000Z")
  );
  const [state, setState] = useState({
    meetingMode: "",
    meetingAt: "",
    meetingLink: "",
    status: "rescheduled",
    finalRemarks:""
  });
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [applicationId, setApplicationid] = useState();
  const [scheduleList, setScheduleList] = useState();
  const [scheduleDetails, setScheduleDetails] = useState();
  const [isRecognition, setIsRecognition] = useState(false)

  const { id } = useParams();

  useEffect(() => {
    
    scheduleLists();
//     const string = "hei dude, check this link http:://google.com and http:://youtube.com"
// const matches = string.match(/\bhttp?::\/\/\S+/gi);
// console.log(matches);

  }, [page, rowsPerPage, search,flags]);

  const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('meetinginfo').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;

  }


  const options = [
    {
      value: "offline",
      id: 1,
    },
    {
      value: "online",
      id: 2,
    },
  ];

 

  const scheduleLists = async () => {
    if(flags == true){
      let val = {
      isRecognition:true
      }
      let data = await meetingList(id,val);
      setScheduleList(data?.data);
      setScheduleDetails(data?.data[0]?.application?.account);
      console.log(data);
      setFilteredResults(data?.data)
    }
    else{
      let val = {
        isRecognition:false
        }
        let data = await meetingList(id,val);
        setScheduleList(data?.data);
        setScheduleDetails(data?.data[0]?.application?.account);
        console.log(data);
        setFilteredResults(data?.data)
    }
    


  };

  
  const handleChanges = (e, name) => {
    setState({ ...state, [name]: e.target.value });
  };




 

  const handleChangeRowsPerPage = (event) => {
    loaderStart();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(page);
    loaderEnd();
  };

  const sumbitApplication = async (e) => {
    e.preventDefault();
    let date = changeTzToNormalDefault(selectedDate).date;
    let time = changeTzToNormalDefault(selectedDate).time;
    let combineDate = date + " " + time;
  
    let data = {
      meetingMode: state.meetingMode,
      meetingAt: combineDate,
      meetingLink: state.meetingLink,
      status: state.status,
      applicationId: id,
    };
    let data2 = await updateMeeting(data,applicationId);
    

    if (data2.status == true) {
      setModal(false);

      actionNotifier.success("Meeting is Scheduled");
      window.location.reload();
    } else {
      // alert('fail')
    }
  };

  
  const sumbitFinalRemarks = async (e) => {

    e.preventDefault();
    let data = {
      remarks: state.finalRemarks,
      status: "done",
      applicationId: id,
    };
    let data2 = await addingFinalRemarks(data,applicationId);
    

    if (data2.status == true) {
      
      setModal1(false);
      actionNotifier.success("Final Remarks is Updated");
      window.location.reload();
    } else {
    }
  };



  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const onChangeHandler = (event) => {
    setSearch(event.target.value);

    console.log(event.target.value);
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };



 



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const columns = [
    {
      id: "Id",
      name: "Id",
      // selector: (row,index)=>index+1,
      cell: (row, index) => index + 1,
     

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Meeting Scheduled Time",
      cell: (row) => row?.meetingAt ?
      new Date(row?.meetingAt).toUTCString().slice(0, -3):'',
  

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Meeting Mode",
      cell: (row) =>
      row?.meetingMode,
     
    },
 
    {
      name: "Message",
      cell: (row) =>
      <center>  <Linkify>{row?.meetingLink}</Linkify></center>
  ,
    
       
     
    } 
,    {
      name: "Final Remarks",
      cell: (row) =>  row?.status == "scheduled" || row?.status == "rescheduled"
      ? 
      <Button
         onClick={() => {
           setModal1(true);
           setApplicationid(row?.id);
         }}
       >
         <FaEye />Remarks</Button>:row?.remarks
      ,
     
    },
    {
      name: "Action",
      cell:(row) =>  row?.status == "done" ?
      <Button
      onClick={() => {
        setModal(true);
        setApplicationid(row?.id);
      }}
      disabled
    >
      <FaEye />
      Update
    </Button>:
    <Button
      onClick={() => {
        setModal(true);
        setApplicationid(row?.id);
      }}
    >
      <FaEye />
      Update
    </Button>,
      
    },
    {
      name: "Status",
      cell:(row) =>  row?.status == "done" ?
      "Complete":row?.status
    ,
      

      // format: (value) => value.toLocaleString('en-US'),
    },
    
   
  ];

  return (
    <>
      <div id="meetinginfo" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
      
          sx={{
            width: "96%",
            height: 95,
            backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
           
          }}
        >
          {/* download excel Start Here*/}
          <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "",
                flexDirection: "column",
                
              }}
            >
              <Typography><b>Applicant Name</b> : {scheduleDetails?.firstName}</Typography>
              <Typography><b>Name Of Startup.</b> : {scheduleDetails?.nameOfStartUp ? scheduleDetails?.nameOfStartUp : "Not Mentioned"}</Typography>
              <Typography><b>Acknowledgement No.</b> : {scheduleDetails?.acknowledgement?.acknowledgmentNo}</Typography>

             
             
             
              {/* <Typography><b>Name of Startup</b> : {scheduleDetails?.nameOfStartUp}</Typography> */}
             

            </div>
            <div style={{ display: "flex",
                alignItems: "Right",
                justifyContent: "",
                flexDirection: "column",}}>
              <Button style={{ backgroundColor: "#EB4747", width: "70%",  alignItems: "center", }} className="printing" variant="contained" fullWidth onClick={Print}  >Print</Button>
              </div>
             
         

        </Box>
        <Box
        
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "98%",
            height: "500px",
            marginTop: "10px",
          }}
        >
            <TableContainer component={Paper}>


  <DataTable
  className="dataTables_wrapper"
  component={Paper}
  fixedHeader
  fixedHeaderScrollHeight="500px"
  // title=" Recommended Table"
  columns={columns ? columns : ''}
  data={
    filteredResults ? filteredResults : ''
  }
  pagination
  subHeader
  // subHeaderComponent={
  //   <>
  //     <input
  //       type="text"
  //       placeholder="Search Here"
  //       className="w-25 form-Control"
  //       onChange={onChangeHandler}
  //     />

     
  //   </>
  // }
  subHeaderAlign="right"
  value={search}
  
  

  // rowsPerPage={rowsPerPage}
  paginationComponentOptions={paginationComponentOptions}
  // paginationPerPage={
  //   SsuList ? SsuList?.length : assigneeTable?.count
  // }
  
paginationTotalRows={scheduleList ? filteredResults?.length :scheduleList?.length}
  paginationPerPage={8}
// onChangeRowsPerPage={(val) => setRowsPerPage(val)}
paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
// onChangePage={(val) => setPage(val)}
/>


</TableContainer>
        </Box>


      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Update Meeting
            </Typography>
            <br />

            <form>
              
              <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">
                  Meeting Mode
                </InputLabel>
                <Select
                style={{height:"60px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Schedule Meeting"
                  required
                  value={state.meetingMode}
                  onChange={(e) => handleChanges(e, "meetingMode", null)}
                >
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
             

              <Typography
                // onClick={() => resendOTP()} sx={{ cursor: "pointer" }}
                className="headLineLeft"
              >
                {/* Resend OTP */}
                <b>Select Meeting Date & Time</b>
              </Typography>
             
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <DateTimePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  placeholder="Select Date&Time"
                />
              </MuiPickersUtilsProvider>
          
              <br />
              <br />
              <Typography></Typography>
              <TextareaAutosize
                
                style={{height:"50px" , width:"100%", padding:"10px"}}
                id="outlined-basic"
                label={"Message"}
                variant="outlined"
                required
                value={state.meetingLink}
                type="text"
                placeholder="Message"
              
                onChange={(e) => handleChanges(e, "meetingLink", null)}
                multiline={true}
              />
              <br />
              <br />
             

              <Button
                onClick={sumbitApplication}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Update Meeting
              </Button>
            </form>
          </Box>
        </Modal>
      ) : null}

{modal1 ? (
        <Modal
          open={modal1}
          onClose={() => setModal1(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Final Remarks
            </Typography>
            <br />

            <form>
     


              <TextareaAutosize
                id="outlined-basic"
                label={"Final Remarks"}
                variant="outlined"
                required
                value={state?.finalRemarks}
                type="text"
                style={{ width: "100%" }}
                onChange={(e) => handleChanges(e, "finalRemarks", null)}
              />
              <br />
              <br />    

              <Button
                onClick={sumbitFinalRemarks}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Update
              </Button>
            </form>
          </Box>
        </Modal>
      ) : null}

    </>
  );
};

export default MeetingInfo;
