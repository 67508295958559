import { ConstructionOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Card,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { getRoleName } from "../../../apiCalls/functions";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getAllPermissionList,
  getApplicationList,
  searchApplication,
  getDownloadedExcel,
  getAssigneeList,
  addAssignee1and2,
  updateAssignee1and2,
  getAssignedApplicationList,
  getAssignedApplicationDownloadedExcel,
  getPendingCsv,
  searchAssignees,
} from "../../basicAction";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { useSelector, useDispatch } from "react-redux";
import { recognition } from "../actions";

const ViewApplicationTable = (props) => {
  // const flags = useSelector((store) => store.recognition.recognition);
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState();
  const [search2, setSearch2] = useState();
  const [searchCategory, setSearchCategory] = useState();
  const [filterAssignee, setFilterAssignee] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(false);

  const [permission, setPermission] = useState([]);
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [assApp, setassApp] = useState();
  const [assigneeTable, setAssigneeTable] = useState();

  const [assigned, setAssigned] = useState("unassigned");
  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [Assignee1arr, setAssignee1arr] = useState();
  const [Assignee2arr, setAssignee2arr] = useState();

  const [DataAssineeAll, setDataAssineeAll] = useState();

  // const [id, setId] = useState(11)

  useEffect(() => {
    getAllPermission();
    getAllApplication();
    GetAssignedList();
    searchData();
    assigneeList();
    searchAssigneed();

    // getRoles()
  }, [
    rowsPerPage,
    page,
    search,
    assigned,
    flags,
    filterAssignee,
    searchCategory,
    newDataFlag,
  ]);

  useEffect(() => {
    try {
      if (search2 !== "All") {
        const result = assigneeTable?.rows?.filter((list) => {
          return (
            list.account?.acknowledgement?.acknowledgmentNo
              .toLowerCase()
              .match(search2.toLowerCase()) ||
            list.account?.mobileNumber
              .toLowerCase()
              .match(search2.toLowerCase()) ||
            list.account?.firstName
              .toLowerCase()
              .match(search2.toLowerCase()) ||
            list?.direct_account?.Application?.assignedTo1?.name
              .toLowerCase()
              .match(search2.toLowerCase()) ||
            list?.direct_account?.Application?.assignedTo1?.name
              .toLowerCase()
              .match(search2.toLowerCase())
          );
        });
        // :
        //  assignee?.data?.filter((list) => {
        //   return (
        //      list.name.toLowerCase().match(search2.toLowerCase())
        //   );
        // });

        setFilteredResultsAasinTable(result);
        console.log(result);
      } else {
        setFilteredResultsAasinTable(assigneeTable?.rows);
      }
    } catch {
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
  }, [search2]);

  const onValue = (e, index, section) => {
    let _allapplication = { ...allapplication };
    // alert(_allapplication.rows[index][section] = e.target.value)
    _allapplication.rows[index][section] = e.target.value;
    console.log(_allapplication);
    setAllapplication(_allapplication);
  };

  const AddValuesAll = async () => {
    let _allapplication = { ...allapplication };
    for (let [index, value] of _allapplication?.rows?.entries()) {
      // value[index][section] = e.target.value;
      let val = {
        assignedTo1Id: value.assignee1,
        assignedTo2Id: value.assignee2,
        assignRemarks: "",
      };
      let id = value.direct_account?.id;
      let data = await addAssignee1and2({ id: id }, val);
      if (data.status) {
        actionNotifier.success("Application Assigned Successfully");
        setassApp(data.data);
        // window.location.reload();
        console.log(data.data);
      } else {
        actionNotifier.error(data.message);
      }
    }
  };

  const onValueAssigneeFilter = (e, index, section) => {
    console.log(e.target.value);
    let _DataAssineeAll = { ...DataAssineeAll };
    // alert(DataAssineeAll.rows[index][section] = e.target.value)
    _DataAssineeAll.rows[index][section] = e.target.value;
    console.log(DataAssineeAll);
    setDataAssineeAll(_DataAssineeAll);
  };

  const getAllApplication = async () => {
    if (flags == true) {
      let val = {
        search: search,
        isRecognition: true,
      };

      let arr = [];
      let data2 = await getAssigneeList();
      if (data2.status) {
        for (let [index, value] of data2?.data?.entries()) {
          arr.push({ name: value.name, id: value.id });
        }

        const middleIndex = Math.ceil(arr.length / 2);

        var firstHalf = arr.splice(0, middleIndex);
        var secondHalf = arr.splice(-middleIndex);
        setAssignee1arr(firstHalf);
        setAssignee2arr(secondHalf);
        console.log(firstHalf);
        console.log(secondHalf);
        setAssignee(data2);
      }

      loaderStart();
      let data =
        newDataFlag == true
          ? await getApplicationList(
              { assigned: assigned, page: page, items: rowsPerPage, round: 2 },
              val
            )
          : await getApplicationList(
              { assigned: assigned, page: page, items: rowsPerPage, round: 1 },
              val
            );

      if (data.status) {
        var _assigned = [...data?.data?.rows];
        var temp_assigned = [];
        _assigned.forEach((e) => {
          temp_assigned.push({
            ...e,
            assignee1: e.direct_account?.Application?.assignedTo1Id,
            assignee2: e.direct_account?.Application?.assignedTo2Id,
          });
        });
        console.log(temp_assigned);
        // setAllapplication({ ...data?.data, rows: temp_assigned })
        let allApplicationArray = [];

        if (assigned == "unassigned") {
          data?.data?.rows?.forEach(function (value, index) {
            if (index % 2 === 0) {
              allApplicationArray.push({
                ...value,
                assignee1: [...firstHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
                assignee2: [...secondHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
              });
            } else if (index % 2 === 1) {
              allApplicationArray.push({
                ...value,
                assignee1: [...secondHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
                assignee2: [...firstHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
              });
            }

            console.log(allApplicationArray);
          });
        }

        // const ids = allApplicationArray?.map(o => o.id)
        // const filtered = allApplicationArray?.filter(({id}, index) => !ids.includes(id, index + 1))

        // console.log(allApplicationArray);
        setAllapplication(
          assigned == "unassigned"
            ? { ...data?.data, rows: allApplicationArray }
            : { ...data?.data, rows: temp_assigned }
        );
      }
      loaderEnd();
    } else {
      let val = {
        search: search,
        isRecognition: false,
      };

      let arr = [];
      let data2 = await getAssigneeList();
      if (data2.status) {
        for (let [index, value] of data2?.data?.entries()) {
          arr.push({ name: value.name, id: value.id });
        }

        const middleIndex = Math.ceil(arr.length / 2);

        var firstHalf = arr.splice(0, middleIndex);
        var secondHalf = arr.splice(-middleIndex);
        setAssignee1arr(firstHalf);
        setAssignee2arr(secondHalf);
        console.log(firstHalf);
        console.log(secondHalf);
        setAssignee(data2);
      }

      loaderStart();
      let data =
        newDataFlag == true
          ? await getApplicationList(
              { assigned: assigned, page: page, items: rowsPerPage, round: 2 },
              val
            )
          : await getApplicationList(
              { assigned: assigned, page: page, items: rowsPerPage, round: 1 },
              val
            );

      if (data.status) {
        let _assigned = [...data?.data?.rows];
        let temp_assigned = [];
        _assigned.forEach((e) => {
          temp_assigned.push({
            ...e,
            assignee1: e.direct_account?.Application?.assignedTo1Id,
            assignee2: e.direct_account?.Application?.assignedTo2Id,
          });
        });
        console.log(temp_assigned);
        // setAllapplication({ ...data.data, rows: temp_assigned });

        let allApplicationArray = [];

        if (assigned == "unassigned") {
          data?.data?.rows?.forEach(function (value, index) {
            if (index % 2 === 0) {
              allApplicationArray.push({
                ...value,
                assignee1: [...firstHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
                assignee2: [...secondHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
              });
            } else if (index % 2 === 1) {
              allApplicationArray.push({
                ...value,
                assignee1: [...secondHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
                assignee2: [...firstHalf]
                  .sort(() => 0.5 - Math.random())
                  .splice(0, 1)[0]?.id,
              });
            }

            console.log(allApplicationArray);
          });
        }

        setAllapplication(
          assigned == "unassigned"
            ? { ...data?.data, rows: allApplicationArray }
            : { ...data?.data, rows: temp_assigned }
        );
      }
      loaderEnd();
    }
  };

  const GetAssignedList = async () => {
    loaderStart();
    if (flags == true) {
      let val = {
        search: search,
        isRecognition: true,
      };
      let data =
        newDataFlag == true
          ? await getAssignedApplicationList(
              { page: page, items: 50000, round: 2 },
              val
            )
          : await getAssignedApplicationList(
              { page: page, items: 50000, round: 1 },
              val
            );

      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
        console.log(data.data);
      }
    } else {
      let val = {
        search: search,
        isRecognition: false,
      };
      let data =
        newDataFlag == true
          ? await getAssignedApplicationList(
              { page: page, items: 50000, round: 2 },
              val
            )
          : await getAssignedApplicationList(
              { page: page, items: 50000, round: 1 },
              val
            );
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
        // console.log(data.data.rows)
      }
    }

    loaderEnd();
  };

  const searchAssigneed = async () => {
    loaderStart();
    if (flags == true) {
      let val = {
        search: searchCategory,
        isRecognition: true,
      };
      let data = await searchAssignees(val);

      if (data.status) {
        var _assigned = [...data?.data?.rows];
        var temp_assigned = [];
        _assigned.forEach((e) => {
          temp_assigned.push({
            ...e,
            assignee1: e.assignedTo1Id,
            assignee2: e.assignedTo2Id,
          });
        });
        console.log(temp_assigned);
        setDataAssineeAll({ ...data?.data, rows: temp_assigned });
      }
    } else {
      let val = {
        search: searchCategory,
        isRecognition: false,
      };
      let data = await searchAssignees(val);
      if (data.status) {
        let _assigned = [...data?.data?.rows];
        let temp_assigned = [];
        _assigned.forEach((e) => {
          temp_assigned.push({
            ...e,
            assignee1: e.assignedTo1Id,
            assignee2: e.assignedTo2Id,
          });
        });
        console.log(temp_assigned);
        setDataAssineeAll({ ...data?.data, rows: temp_assigned });
      }
    }

    loaderEnd();
  };

  const getAllPermission = async () => {
    let data = await getAllPermissionList();
    if (data.status) {
      // console.log(data)
      setPermission(data.data);
    }
  };

  const onChangeHandler = (event) => {
    setSearch2(event.target.value);
    if (!assigneeTable) {
      setSearch(event.target.value);
    }
  };

  const downloadExcelForSsu = async () => {
    if (flags == true) {
      let val = {
        isRecognition: true,
      };
      loaderStart();
      const data =  newDataFlag ?    await getDownloadedExcel({ assigned: assigned, round:2}, val) : 
      await getDownloadedExcel({ assigned: assigned, round:1}, val)

      if (data.status == false) {
        actionNotifier.error("Download Failed ! Please Try again");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    } else {
      let val = {
        isRecognition: false,
      };
      loaderStart();
      const data = newDataFlag ?    await getDownloadedExcel({ assigned: assigned, round:2}, val) : 
      await getDownloadedExcel({ assigned: assigned, round:1}, val)

      if (data.status == false) {
        actionNotifier.error("Download Failed ! Please Try again");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }

    // return data
  };

  const downloadAssignedTableCsv = async () => {
    if (flags == true) {
      let val = {
        isRecognition: true,
      };
      loaderStart();
      const data = newDataFlag == true
      ? await getPendingCsv({round:2},val) : await getPendingCsv({round:1},val)


      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    } else {
      let val = {
        isRecognition: false,
      };
      loaderStart();
      const data = newDataFlag == true
      ? await getPendingCsv({round:2},val) : await getPendingCsv({round:1},val)

      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
  };

  const searchData = async () => {
    let val = {
      search: search,
    };
    let data = await searchApplication(val);
    if (data.status) {
      setFilData(data.data);
    }
    // setSearch("")
  };
  // add assignee api call here
  const addAssignee = async (index) => {
    // console.log(allapplication[index])

    if (assigned == "assigned"  && categoryFilter == true) {
      let val = {
        assignedTo1Id: DataAssineeAll.rows[index]?.assignee1,
        assignedTo2Id: DataAssineeAll.rows[index]?.assignee2,
        assignRemarks: "",
      };
      let id = DataAssineeAll.rows[index]?.account?.id;
      let data = await addAssignee1and2({ id: id }, val);
      if (data.status) {
        actionNotifier.success("Application Assigned Successfully");
        setassApp(data.data);
        window.location.reload();
        console.log(data.data);
      } else {
        actionNotifier.error(data.message);
      }
    } else {
      let val = {
        assignedTo1Id: allapplication.rows[index]?.assignee1,
        assignedTo2Id: allapplication.rows[index]?.assignee2,
        assignRemarks: "",
      };
      let id = allapplication.rows[index]?.direct_account?.id;
      let data = await addAssignee1and2({ id: id }, val);
      if (data.status) {
        actionNotifier.success("Application Assigned Successfully");
        setassApp(data.data);
        window.location.reload();
        console.log(data.data);
      } else {
        actionNotifier.error(data.message);
      }
    }
  };

  // update assignee api call here
  const updateAssignee = async (index) => {
    // console.log(allapplication)
    if (assigned == "assigned"  && categoryFilter == true) {
      let val = {
        assignedTo1Id: DataAssineeAll.rows[index]?.assignee1,
        assignedTo2Id: DataAssineeAll.rows[index]?.assignee2,
        assignRemarks: "",
      };
      let id = DataAssineeAll.rows[index]?.account?.id;

      let data = await updateAssignee1and2({ id: id }, val);
      if (data.status) {
        actionNotifier.success("Application Updated Successfully");
      } else {
        actionNotifier.error(data.message);
      }
    } else {
      let val = {
        assignedTo1Id: allapplication.rows[index]?.assignee1,
        assignedTo2Id: allapplication.rows[index]?.assignee2,
        assignRemarks: "",
      };
      let id = allapplication.rows[index]?.direct_account?.id;

      let data = await updateAssignee1and2({ id: id }, val);
      if (data.status) {
        actionNotifier.success("Application Updated Successfully");
      } else {
        actionNotifier.error(data.message);
      }
    }
  };
  // assignee dropdown api call here
  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      setAssignee(data);
      console.log(data.data);
    }
  };

  const checkassigned = () => {
    if (assigned == "unassigned") {
      setAssigned("assigned");
    } else {
      setAssigned("unassigned");
    }
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allapplication?.count.length) : 0;

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeCategory = (event) => {
    setCategoryFilter(true);
    setFilterAssignee(event.target.value);
    setSearchCategory(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    loaderStart();

    setRowsPerPage(event.target.value);
    setPage(page);
    loaderEnd();
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  let assign_application = permission.filter(
    (a) => a.name === "assign_application"
  );
  console.log("Per Name", assign_application);

  let applicant_view = permission.filter((a) => a.name === "applicant_view");

  let applicant_list = permission.filter((a) => a.name === "applicant_list");

  let applicant_list_export_to_csv = permission.filter(
    (a) => a.name === "applicant_list_export_to_csv"
  );

  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      selector: (row, index) => row?.id,
      cell: (row, index) => index + 1,
      width: "90px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      cell: (row) => row?.acknowledgmentNo,
      width: "140px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Submission Date",
      cell: (row) => changeTzToNormalDefault(row?.createdAt).date,
      width: "150px",
    },
    {
      name: "Name of Startup",
      cell: (row) => row?.direct_account?.nameOfStartUp,
    },
    {
      name: "Name",
      cell: (row) =>
        row?.direct_account?.firstName + "  " + row.direct_account?.lastName,
    },
    {
      name: "Phone No.",
      cell: (row) => row?.direct_account?.mobileNumber,
      width: "130px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      cell: (row) => (row?.direct_account?.StartUpDocuments ? "Yes" : "No"),
      width: "180px",
    },

    assign_application?.length > 0
      ? {
          name: "Assignee 1.",
          cell: (row, index) => (
            <>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={row?.assignee1}
                  name="assignee"
                  label="Assignee"
                  onChange={(e) => onValue(e, index, "assignee1")}
                  color="warning"
                >
                  {assignee?.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ),
          width: "200px",
        }
      : "",

    assign_application?.length > 0
      ? {
          name: "Assignee 2.",
          cell: (row, index) =>
            assign_application?.length > 0 ? (
              <>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Assignee 2</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row?.assignee2}
                    name="assignee"
                    label="Assignee"
                    onChange={(e) => onValue(e, index, "assignee2")}
                    color="warning"
                  >
                    {assignee?.data?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            ),
          width: "200px",
        }
      : "",

    {
      name: "Action",
      cell: (row) =>
        applicant_view?.length > 0 ? (
          <>
            <NavLink target={"_blank"} to={`/view-application/${row.id}`}>
              <Button>
                <FaEye />
                View
              </Button>
            </NavLink>
          </>
        ) : (
          ""
        ),
      width: "130px",
    },

    assigned && assigned == "assigned"
      ? {
          name: "Action",
          cell: (row, index) => (
            <Button variant="outlined" onClick={() => updateAssignee(index)}>
              {" "}
              Update
            </Button>
          ),

          width: "130px",
        }
      : "",
  ];

  const columnss = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      cell: (row, index) => index + 1,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      cell: (row) => row?.account?.acknowledgement?.acknowledgmentNo,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Submission Date",
      cell: (row) =>
        changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
    },
    {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
    },
    {
      name: "Name",
      cell: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "130px",
    },
    {
      name: "Phone No.",
      cell: (row) => row?.account?.mobileNumber,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      cell: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "180px",
    },

    {
      name: "Action",
      cell: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
    },
  ];

  const columnsFilter = [
    {
      id: "Sl. No",
      name: "Sl. No",
      selector: (row, index) => row?.id,
      cell: (row, index) => index + 1,
      width: "90px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      cell: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "140px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Submission Date",
      cell: (row) =>
        changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width: "150px",
    },
    {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
    },
    {
      name: "Name",
      cell: (row) => row?.account?.firstName + "  " + row.account?.lastName,
    },
    {
      name: "Phone No.",
      cell: (row) => row?.account?.mobileNumber,
      width: "130px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      cell: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "180px",
    },

    assign_application?.length > 0
      ? {
          name: "Assignee 1.",
          cell: (row, index) => (
            <>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={row?.assignee1}
                  name="assignee"
                  label="Assignee"
                  onChange={(e) => onValueAssigneeFilter(e, index, "assignee1")}
                  color="warning"
                >
                  {assignee?.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ),
          width: "200px",
        }
      : "",

    assign_application?.length > 0
      ? {
          name: "Assignee 2.",
          cell: (row, index) =>
            assign_application?.length > 0 ? (
              <>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Assignee 2</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={row?.assignee2}
                    name="assignee"
                    label="Assignee"
                    onChange={(e) =>
                      onValueAssigneeFilter(e, index, "assignee2")
                    }
                    color="warning"
                  >
                    {assignee?.data?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            ),
          width: "200px",
        }
      : "",

    {
      name: "Action",
      cell: (row) =>
        applicant_view?.length > 0 ? (
          <>
            <NavLink
              target={"_blank"}
              to={`/view-application/${row?.account?.acknowledgement?.id}`}
            >
              <Button>
                <FaEye />
                View
              </Button>
            </NavLink>
          </>
        ) : (
          ""
        ),
      width: "130px",
    },

    {
      name: "Action",
      cell: (row, index) =>
        assigned && (assigned == "assigned") & !assigneeTable ? (
          <Button variant="outlined" onClick={() => updateAssignee(index)}>
            {" "}
            Update
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => addAssignee(index)}>
            {" "}
            Add
          </Button>
        ),
      width: "130px",
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "80%",
            height: 55,
            backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          {/* download excel Start Here*/}

          {assign_application?.length > 0 ? (
            <Button variant="text" onClick={downloadExcelForSsu}>
              Click here to download excel
            </Button>
          ) : (
            <Button variant="text" onClick={downloadAssignedTableCsv}>
              Click here to download excel
            </Button>
          )}

          {/* Download excel ends here */}

          {/* search Component Start here */}
          <input
            type="text"
            name="name"
            onChange={onChangeHandler}
            value={search ? search : search2}
            placeholder="Search..."
          />
          {!assigneeTable ? (
            <Button
              variant="outlined"
              style={{
                backgroundColor: "blue",
                color: "white",
                marginRight: 180,
              }}
              onClick={() => {
                setCategoryFilter(false);
              }}
            >
              Reset
            </Button>
          ) : (
            ""
          )}

          {/* 
                {
                 !assigneeTable ?

                  <Select
                  sx={{marginLeft:3}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Filter"
                    onChange={handleChangeCategory}
                    displayEmpty
                    style={{ textAlign: "left",height:35 }}
                  >
                    <InputLabel>Filter Assignee</InputLabel>
                    
                    {
                                   
                                   assignee? assignee?.data?.map((option, index) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  )) : 
                                  assignee?.data?.map((option, index) => (
                                    <MenuItem key={option.id} value={option.name}>
                                      {option.name}
                                    </MenuItem>
                                  ))
                                }
                  </Select> : ''
                } */}

{!assigneeTable && assigned !== "assigned" ? (
            <Button
              variant="outlined"
              style={{
                backgroundColor: "blue",
                color: "white",
                marginRight: 180,
              }}
              onClick={() => {
                AddValuesAll();
              }}
            >
              Add Assignee
            </Button>
          ) : (
            "  "
          )}

          {assign_application?.length > 0 ? (
            <Button variant="contained" onClick={checkassigned}>
              {assigned ==  "assigned"
                ? "New application"
                : "Assigned Application"}
            </Button>
          ) : (
            ""
          )}

         
        </Box>

        {!assigneeTable ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "85%",
              height: "100vh",
              marginRight: 5,
            }}
          >
            <TableContainer component={Paper}>
              <DataTable
                className="dataTables_wrapper"
                component={Paper}
                fixedHeader
                fixedHeaderScrollHeight="499px"
                // title=" Recommended Table"
                columns={
                  assigned == "assigned" && categoryFilter == true
                    ? columnsFilter
                    : columns
                }
                data={
                  assigned == "assigned" && categoryFilter == true
                    ? DataAssineeAll?.rows
                    : allapplication?.rows
                }
                pagination
                //  value={search}
                onChange={onChangeHandler}
                //  rowsPerPage={rowsPerPage}
                paginationTotalRows={
                  categoryFilter == true
                    ? DataAssineeAll?.count
                    : allapplication?.count
                    ? allapplication?.count
                    : ""
                }
                paginationComponentOptions={paginationComponentOptions}
                //  paginationPerPage={SsuScheduled?.count}

                paginationPerPage={8}
                onChangeRowsPerPage={(val) => setRowsPerPage(val)}
                paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
                onChangePage={(val) => setPage(val)}
                paginationDefaultPage={page}
                paginationServer
              />
            </TableContainer>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "85%",
              marginTop: "1px",
              height: "100vh",
              marginLeft: 8,
            }}
          >
            <TableContainer component={Paper}>
              <DataTable
                className="dataTables_wrapper"
                component={Paper}
                fixedHeader
                fixedHeaderScrollHeight="500px"
                // title=" Recommended Table"
                columns={columnss ? columnss : ""}
                data={
                  filteredResultsAasinTable ? filteredResultsAasinTable : ""
                }
                pagination
                value={search}
                // onChange={onChangeHandler}

                // rowsPerPage={rowsPerPage}
                paginationComponentOptions={paginationComponentOptions}
                // paginationRowsPerPageOptions={[5, 10, 50, 100, 200, 300, 500]}
                // paginationPerPage={
                //   SsuList ? SsuList?.length : assigneeTable?.count
                // }
                paginationTotalRows={
                  assigneeTable
                    ? filteredResultsAasinTable?.length
                    : assigneeTable?.count
                }
                // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
                paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
                // onChangePage={(val) => setPage(val)}
                // paginationDefaultPage={page}
                // paginationServer
              />
            </TableContainer>
          </Box>
        )}
      </div>
    </>
  );
};

export default ViewApplicationTable;
