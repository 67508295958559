import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import logo from "../../Image/logo.png";
import moment from "moment"
import { finalSubmit, getAllRecords } from "./basicAction"
import { actionNotifier } from "../notification"
import { Navigate } from "react-router-dom";

const IncuDeclaration = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [acknowledgementNo, setAcknowledgementNo] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    const nullWhitespace = (value) => {
        return value ? value + "  " : "";
    }
    const [show, setShow] = useState(false);
    const [name1, setName1] = useState(localStorage.getItem("startup-india-token") ? nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.firstName) +
        nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.middleName) + nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.lastName) : "");
    const [name2, setName2] = useState(localStorage.getItem("startup-india-token") ? nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.firstName) +
        nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.middleName) + nullWhitespace(JSON.parse(localStorage.getItem("startup-india-token"))?.data?.lastName) : "");
    const [place, setPlace] = useState("");
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

    useEffect(() => {
        getInitialData();

    }, [])


    const getInitialData = async () => {
        let data = await getAllRecords()
        if (data.status) {
            // console.log(data.data)
            if (data?.data?.acknowledgement?.acknowledgmentNo) {
                // console.log(data?.data?.acknowledgement)
                setAcknowledgementNo(data?.data?.acknowledgement?.acknowledgmentNo)
                setChecked(data?.data?.basicDetails?.FinalSubmitDeclaration?.isDeclared)
                setName1(data?.data?.basicDetails?.FinalSubmitDeclaration?.name1)
                setName2(data?.data?.basicDetails?.FinalSubmitDeclaration?.name2)
                setDate(moment(data?.data?.basicDetails?.FinalSubmitDeclaration?.date).format("YYYY-MM-DD"))
                setPlace(data?.data?.basicDetails?.FinalSubmitDeclaration?.place)
                setShow(true);
            }
        }
    }

    const dataSubmit = () => {
        return checked ? setIsDisabled(true) : setIsDisabled(false);
    };

    const onCheckboxClick = () => {
        setChecked(!checked);
        return dataSubmit();
    };



    let finalSubmitData = async () => {
        let allData = JSON.parse(localStorage.getItem("startup-india-token"))
        const fullName = allData.data.firstName + " " + allData.data.lastName
        if (place) {
            let data = {
                isDeclared: checked,
                name1: fullName,
                name2: fullName,
                place: place,
                date: date,
             
            }
            // console.log(data)
            let res = await finalSubmit(data);
            if (res.status) {
                setAcknowledgementNo(res.data.acknowledgementNo);
                setShow(true);
            }
            else {
                actionNotifier.error(res.message)
            }
        }
        else {
            actionNotifier.error("Enter Place to proceed")
        }

    }



    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('slip').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    return (
        <Box>
            <Box style={{ width: "694px", marginLeft: "43px" }}>
                <br />
                <br />
                <Typography  className='box_heading_incubator' sx={{ fontWeight: "bold", fontSize: 20 }} >Declaration</Typography>

                <br />
                <br />

                {/* 000000000000000000000000000000000000000000   CHECKBOX TO ACCEPT   000000000000000000000000000000000000000000000 */}
                {!show && <form>
                    <div style={{ textAlign: "left", fontWeight: "600" }}>
                        <Checkbox value={checked} onClick={onCheckboxClick} {...label} id="check" />
                        <span>I,<TextField style={{ paddingLeft: 10 }} InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true,
                        }} id="standard-basic" value={name1} label="" variant="standard" /> hereby declare that all the information submitted by me in the application form is correct, true and valid. I will present the supporting documents as and when required.	</span>
                    </div>
                    <br />
                    <br />
                    <br />

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField value={name2} InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: true,
                            }} id="outlined-basic" label="Name" variant="outlined" />
                        <TextField value={place} onChange={(e => {
                            setPlace(e.target.value)
                        })} InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: false,
                        }} id="outlined-basic" label="Place" variant="outlined" />
                        <TextField value={date} InputLabelProps={{ shrink: true }} InputProps={{
                            readOnly: true,
                        }} id="outlined-basic" label="Date" variant="outlined" />
                    </Box>
                    <br />
                    <br />
                    <Button  className="btn_class" variant="contained" fullWidth disabled={isDisabled} onClick={() => finalSubmitData(true)} >final submit</Button>
                </form>
                }

                <br />
                <br />

                {/* 00000000000000000000000000000000000000000000 A SLIP GENERATE FORM 00000000000000000000000000000000000000000*/}

                {
                    show ?
                        <Box>
                            <div>
                                <div id="slip">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img style={{ height: "50px", width: "230px" }} src={logo} alt="Paris" className="logo"></img>
                                    </div>
                                    <br />
                                    <Typography sx={{ textAlign: "center", fontSize: 20 }} >StartUp Bihar</Typography>
                                    <Typography sx={{ textAlign: "center", fontSize: 15 }}>Government of Bihar</Typography>
                                    {/* <Typography sx={{ textAlign:"center", fontSize:15 }}>Chief Minister OBC / SC / ST / WOMEN / YOUNG udyami scheme</Typography> */}
                                    <br />
                                    <br />
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Name: <TextField InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} id="standard-basic" value={name1} label="" variant="standard" />
                                        </div>
                                        <div>
                                            date: <TextField InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true,
                                                }} type="date" value={date} id="standard-basic" label="" variant="standard" />
                                        </div>
                                    </Box>
                                    <br />
                                    <br />
                                    <Typography sx={{ textAlign: "center" }}>We have received your application under</Typography>
                                    <Typography sx={{ textAlign: "center" }}>StartUp Bihar</Typography>

                                    <br />
                                    <br />
                                    <Typography sx={{ textAlign: "center" }}>You will receive further information by SMS/eMail</Typography>
                                    <Typography sx={{ textAlign: "center" }}>Your acknowledgement is </Typography>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            readOnly: true,
                                        }} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} id="standard-basic" label="" value={acknowledgementNo} variant="standard" />
                                </div>

                                <br />
                                <br />
                                <br />
                                <Button style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth onClick={Print} >Print</Button>
                                <br />
                                <br />
                                <Button style={{ backgroundColor: "#EB4747" }} onClick={()=>{
                                    Navigate("/")
                                }} variant="contained" fullWidth  >Home</Button>
                            </div>
                        </Box> : null
                }
            </Box>
            <Box className="page_design_develop_incu" style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box>
        </Box>
    )
}

export default IncuDeclaration;