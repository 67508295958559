export const recognition = (status) => {
      try
    {
      localStorage.setItem('stateRecog', status);
      return {
      type: 'FLAG',
      payload: status,
    };
    }
    catch
    {
      console.log('error')
    }
    
  };

  export const AcknowledgementId = (regid) => {
    try
  {
    localStorage.setItem('acknoId', regid);
    return {
    type: 'REGID',
    payload: regid,
  };
  }
  catch
  {
    console.log('error')
  }
  
};

  export const newData = (status) => {
    try
  {
    localStorage.setItem('state', status);
    return {
    type: 'FLAG2',
    payload: status,
  };
  }
  catch
  {
    console.log('error')
  }
  
};

export const CoworkingGet = (statusworking) => {
  try
{
  localStorage.setItem('CoworkingFlag', statusworking);
  return {
  type: 'CoworkingFlag',
  payload: statusworking,
};
}
catch
{
  console.log('error')
}

};

export const MarksSubmitted = (marks) => {
  try
{
  localStorage.setItem('stateMarks', marks);
  return {
  type: 'MARKS',
  payload: marks,
};
}
catch
{
  console.log('error')
}

};


   
