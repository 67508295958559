import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import './style.css'
import newGif from '../../StartupIndia/new-gif.gif';
import Evaluationtest from '../../assets/pdf/Evaluation_test.pdf'
import EvaluationResult from'../../assets/pdf/Evaluation_result.pdf'
import Evaluationtest12Dec from'../../assets/pdf/Startup_result_12122022.pdf'
const CarouselContainer = (props) => {

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <ul className="notification-event"    >
                    <h5 style={{color:"white"}}> <marquee> 
                        
                      
                       
                    {/* <a style={{color:"white"}} href={EvaluationResult}>Startup Evaluation Test Result Held on 4th November 2022
                    
                    </a> */}
                  
                       
                       <a style={{color:"white"}} href={Evaluationtest12Dec}>Result of Start-Up Evaluation Test held on 12th December 2022
                        <img src={newGif} style={{ height: "25px",marginRight:'20px' }} alt="New" /> 
                        
                    
                   
                  
                    </a>
                       </marquee>
                       </h5>

                       
                      
                      
                     
                      {/* <li>Portal will be opened for submission of application for a period of one (1) month starting from 29th July, 2022</li> */}
                    </ul>
                   
                  </div>
            <div className="row w-100 justify-content-center">
                <div className="col-sm-11 text-center">
                    <h2 style={{ boxShadow: "0 12px 14px gray, 0 13px 13px white", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "0px", padding: "1%", color: "#fff", fontSize: "5rem", fontWeight: "bold", textShadow: "10px 6px 7px rgba(255,219,10,0.2)", marginTop: "10%" }}>Welcome To Startup Bihar<sup><span style={{ color: "#ff0000", fontSize: "30px" }}>2022</span></sup></h2>
                </div>
            </div>
            {/* <div className="carousel-indicators">
                {carouselItem.map((item, index) => {
                    return (
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className="active" aria-current="true" aria-label="Slide 1"></button>
                    );
                })}


            </div>
            <div className="carousel-inner">
                {carouselItem.map((item, index) => (
                    <CarouselItem active={active == index ? true : false} img={item.img} txt1={item.txt1} txt2={item.txt2} />
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
                */}
        </div >
    )
}

export default CarouselContainer;