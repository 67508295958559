import React, { useState } from 'react'
import { Box, TextField, Select, MenuItem, Grid, Typography, Stack,Button, Modal } from '@mui/material';
import { useNavigate } from "react-router-dom"
import Navbar from './Navbar';
import "../../src/Style/Authentication.css"
import InputLabel from '@mui/material/InputLabel';
import Footer from "./footer"
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
// import { Button } from 'bootstrap';
import { MarksSubmitted } from '../Componets/DepartmentDashboard/actions';
import {
    getCoworkingInfo,
    addCoworking
  } from "./basicAction/index";
import { actionNotifier } from './notification';
import { useDispatch } from 'react-redux';


const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height:200,
    bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    borderRadius: 12,
    p: 2,
  };
  const whichStartup = [


    {
        value: 'पापड़ उद्योग',
        label: 'पापड़ उद्योग ',
    },
    {
        value: 'अचार उद्योग',
        label: 'अचार उद्योग',
    },
    {
        value: '0',
        label: 'किराना स्टोर',
    },
    {
        value: '1',
        label: 'ओला कैब',
    },

];

const creativity = [


    {
        value: '1',
        label: 'हाँ ',
    },
    {
        value: '0',
        label: 'नहीं ',
    },
    

];

const startupTech = [


    {
        value: '1',
        label: 'हाँ ',
    },
    {
        value: '0',
        label: 'नहीं ',
    },
];

const startupGrowth = [


    {
        value: '0',
        label: 'हाँ ',
    },
    {
        value: '1',
        label: 'नहीं ',
    },
   

];

const startupProduct = [


    
    {
        value: '1',
        label: 'हाँ ',
    },
    {
        value: '0',
        label: 'नहीं ',
    },
];

const startupInnovation = [

    {
        value: '1',
        label: 'हाँ ',
    },
    {
        value: '0',
        label: 'नहीं ',
    },
];


const startupCompare = [

    {
        value: '0',
        label: 'हाँ ',
    },
    {
        value: '1',
        label: 'नहीं ',
    },
];

const startupLimited = [

    {
        value: '1',
        label: 'हाँ ',
    },
    {
        value: '0',
        label: 'नहीं ',
    },
];

const startupPlanning = [

    {
        value: '0',
        label: 'हाँ ',
    },
    {
        value: '1',
        label: 'नहीं ',
    },
];

const startupCafe = [

    {
        value: '0',
        label: 'हाँ ',
    },
    {
        value: '1',
        label: 'नहीं ',
    },
];

const initialState = {
    whichStartup: null,
    creativity: null,
    startupTech: null,
    startupGrowth: null,
    startupProduct: null,
    startupInnovation: null,
    startupCompare: null,
    startupLimited: null,
    startupPlanning: null,
    startupCafe: null,
  };
export default function QuestionAnswerRecog() {
    const dispatch = useDispatch();
    const [category, setCategory] = useState();
    const [modalData, setModalData] = useState(false);
    const [details, setDetails] = useState();
    const [registration, setRegistration] = useState();
    const [Id, setId] = useState();
    const [totals, setTotals] = useState();
    const [state, setState] = useState({
    ...initialState,
    
  });

    const navigate = useNavigate()

    
    useEffect(() => {
      
    }, [category,Id]);

    const handleChange = (name, value, i) => {
        // console.log()
        let _state = { ...state };
        _state[name] = value;
        setState(_state);
        console.log(_state)
      };
    
    

      const addForm = async(e) => {

        if(state?.creativity  
           
            == null ){

                actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें ")
            }
          else if (state?.startupCafe == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें ")
          }
          else if (state?.startupCompare == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें ")
        }
        else if (state?.startupGrowth == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
        else if (state?.startupInnovation == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
        else if (state?.startupLimited == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
        else if (state?.startupPlanning == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
        else if (state?.startupProduct == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
        else if (state?.startupTech == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        } else if (state?.whichStartup == null){
            actionNotifier.error("कृपया सभी प्रश्नों के उत्तर दें")
        }
            else{
                const values = Object.values(state);
                const onlyNumbers = values.filter(value => /^-?\d+\.?\d*$/.test(value));
                // console.log(onlyNumbers)
                const sum = onlyNumbers.reduce((accumulator, value) => {
                return accumulator + value;
                }, 0);
             console.log( sum )
             setTotals(sum)
             setModalData(true);
             dispatch(MarksSubmitted(sum)); 
         
            }

  
           
  
           

      };




    return (
        <Box className='backround-ui'>
            <Box className='form-ui1' >
                <Grid container spacing={8} >
                    
                        <Grid item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <Navbar />
                            <Box sx={{ marginLeft: 2,marginTop:140 }}>
                                <Footer />
                            </Box>

                        </Grid>

                        <Grid item xs={8} >
                        <Box  >
                            <br/>
                            
                                    <Typography sx={{ marginLeft: "60px", color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "90%", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }}>निचे दिए गए  10 प्रश्न में से 8 का सही उत्तर करना अनिवार्य है। इस के बाद ही स्टार्ट-उप पोर्टल पे पंजीकृत कर पाएंगे ।</Typography>
                                    <Stack
                                        // className='form-ui'
                                        sx={{ '& > :not(style)': { m: 1, width: '80ch' }, display: "flex", flexDirection: "column", marginLeft: "50px !important" }}
                                        noValidate
                                        autoComplete="off"

                                    ><br />
                                        
                                            <>
                                          
                                        <br />
                                  
                                        <Box style={{
                                            // display: "flex",
                                            justifyContent: "center",
                                            width: "90%", 
                                        }}>
                                            <Typography className='formHeadLineRight' ><b>इनमें से कौन स्टार्टअप है ?</b> </Typography>
                                      
                                            <TextField  id="outlined-basic"
                                             style={{ textAlign: 'left' }}
                                                required
                                                type="select"
                                                select
                                                label="select "
                                                variant="outlined"
                                                fullWidth
                                                // value={state?.coWorkingSpaceCategory}
                                                name="whichStartup"
                                                
                                                onChange={(e) => {
                                                    handleChange("whichStartup", Number(e.target.value));
                                                    setCategory(e.target.value);
                                                  
                                                    
                                                }
                                                    }
                                               
                                            >
                                                 {whichStartup?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <br />
                                            <br />
                                            <br/>
                                            <br/>

                                            

                                            <Typography className='formHeadLineRight'><b>स्टार्टअप के लिए नयापन और क्रिएटिविटी बहुत आवश्यक होता है ?</b> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select "
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.noOfSeatAllocated}
                                                name="creativity"
                                                onChange={(e) => {
                                                    handleChange("creativity", Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                {creativity?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <br />
                                            <br />
                                            <br/>
                                            <br/>

                                            <Typography className='formHeadLineRight'> <b>स्टार्टअप में तकनिकी विकास, बिज़नेस डेवलपमेंट, मैनेजमेंट के लिए स्किल्ड लोगो की जरुरत पड़ती है?</b> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                select
                                                required
                                                // value={state?.subscriptionType}
                                                name="startupTech"
                                                onChange={(e) => {
                                                    handleChange("startupTech",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                                
                                            >
                                                  {startupTech?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <br />
                                            <br />
                                            <br/>
                                            <br/>
                                            <Typography className='formHeadLineRight'> <b>स्टार्टअप की वृद्धि की दर पारंपरिक व्यवसाय की तुलना में कम होती है? </b> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.categoryId}
                                                name="startupGrowth"
                                                onChange={(e) => {
                                                    handleChange("startupGrowth",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                  {startupGrowth?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <br/>
                                            <br/>

                                           
                                           
                                            <br/>
                                            <br/>
                                            <Typography className='formHeadLineRight'> <Typography className='formHeadLineRight'><b>पुराने तरीकों से चलने वाले व्यवसाय जिसका मूल उद्देश्य प्रोडक्ट का प्रोडक्शन और उसकी मार्केटिंग करना है पारंपरिक व्यवसाय कहलाता है ?</b> </Typography> </Typography>
                                            <TextField
                                              style={{ textAlign: 'left' }}
                                                
                                                id="outlined-basic" type="select"
                                                label="select"
                                                select
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.genderId}
                                                name="startupProduct"
                                                onChange={(e) => {
                                                    handleChange("startupProduct",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupProduct?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>    
                                            <br />
                                            <br />
                                            <br/>
                                            <br/>

                                            <Typography className='formHeadLineRight'> <Typography className='formHeadLineRight'><b>स्टार्टअप में आगे बढ़ने के लिए इनोवेशन और क्रिएटिविटी बहुत जरुरी है?</b> </Typography> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="startupInnovation"
                                                onChange={(e) => {
                                                    handleChange("startupInnovation",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupInnovation?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>    

                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            
                                            <Typography className='formHeadLineRight'> <Typography className='formHeadLineRight'><b>शुरुआत में पारंपरिक व्यवसाय की वृद्धि की दर स्टार्टअप की तुलना में कम होती है ?</b>  </Typography> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="startupCompare"
                                                onChange={(e) => {
                                                    handleChange("startupCompare",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupCompare?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>    
                                               <br/>
                                               <br/>
                                               <br/>
                                            <br/>
                                            <Typography className='formHeadLineRight'> <Typography className='formHeadLineRight'><b>पारंपरिक व्यवसाय में आय के सीमित स्रोत होते है ?</b>   </Typography> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="startupLimited"
                                                onChange={(e) => {
                                                    handleChange("startupLimited",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupLimited?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>  

                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            
                                            <Typography className='formHeadLineRight'> <Typography className='formHeadLineRight'> <b>स्टार्टअप को शुरुआत से ही प्लानिंग व स्ट्रेटेजी नहीं  बनानी पड़ती है ? </b> </Typography> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="startupPlanning"
                                                onChange={(e) => {
                                                    handleChange("startupPlanning",  Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupPlanning?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            
                                             <br/>
                                             <br/>
                                             <br/>
                                            <br/>
                                            <Typography className='formHeadLineRight'><Typography className='formHeadLineRight'>  <b>क्या साइबर कैफ़े ,बेकरी फैक्ट्री,कपड़ा दुकान  स्टार्टअप है ?</b> </Typography> </Typography>
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="select"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="startupCafe"
                                                onChange={(e) => {
                                                    handleChange("startupCafe",  Number(e.target.value));
                                                  
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {startupCafe?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>  
   

                                        </Box>
                                        <br />
                                        <br />
                                        <div style={{ margin: "auto", marginTop: 20,  marginLeft: "-26px",width: "100%"  }}>
                                            <Button onClick={()=>{
                                                addForm()
                                            }}    type="submit" style={{ backgroundColor: "#EB4747", width: "80%" }} variant="contained" fullWidth>Submit </Button>
                                        </div>
                                        <br />
                                        <br />
                                            </>

                                         


                                        

                                        

                                       
                                       
                                    </Stack>
                                </Box>
                        </Grid>
                    
                </Grid>
            </Box>
            {modalData ? (
        <Modal
          open={modalData}
          onClose={() => setModalData(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
           
            

          <Grid item xs={8}>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            {/* <h6 style={{ textAlign: "center", color: "red",fontSize:30 }}><b><u>Total Marks</u></b></h6> */}
                            </div>
                            <br/>
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
   <p >
                                    
   {
            totals >= 8 ?
            "आपने 10 में से  " +    Number.parseInt(totals)   +  "  अंक प्राप्त किए हैं। आप स्टार्टअप बिहार में पंजीकरण के लिए आगे बढ़ सकते हैं।" 
            : "आपने 10 में से   "+  Number.parseInt(totals) + "  अंक प्राप्त किए हैं।  कृपया प्रश्नों का फिर से उत्तर दें।"
        }


                                  
                                  
                                    </p>
                               
                            </div>


                        <form style={{marginBottom:20}}>
                         


                            <br />
                            <Button
                                style={details ? { backgroundColor: "#EB4747", color: "white",marginLeft :250} : {marginLeft :250}}
                                variant="contained"
                                onClick={() => totals >= 8 ? navigate('/how-to-apply-recognation') : navigate('/apply-new-recog') } 
                                // disabled={!checked}
                            >OK</Button>
                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}
        </Box>
        
        
    );
    
}