import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
  Button,
  Box,
} from "@mui/material";
import {
  AcknowledgementId
} from "../actions";
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { getAllPermissionList, viewApplicationList, getSsuList,getAdminProfileDetail,getTimelineDetails } from "../../basicAction";
import Sidebar from "../../Sidebar/Sidebar";
import DepartmentHeader from "../DepartmentHeader";
import { changeTzToNormalDefault } from "../../../shared/functions";
import { FILE_URL } from "../../../../src/shared/constant";
import "./viewpage.css";
import { actionNotifier } from "../../notification";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { margin } from "@mui/system";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

const ViewSingleApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [timeData, setTimeData] = useState()
  const [mydata, setMydata] = useState();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [permission, setPermission] = useState([])
  const [permission2, setPermission2] = useState([])
  const [SsuList, setSsuList] = useState();
  const [panCard, setPanCard] = useState("")
  const [companyProof, setCompanyProof] = useState("")
  const [businessModelF1, setBusinessmodelF1] = useState("")
  const [businessModelF2, setBusinessmodelF2] = useState("")
  const [turnoverBalSheet, setTurnOverBalSheet] = useState("")
  const [turnOverF3, setTurnOverF3] = useState("")
  const [turnOverF4, setTurnOverF4] = useState("")
  const [casteCertificate, setCasteCertificate] = useState("")
  const [qualification, setQualification] = useState("")
  const [profile, setProfile] = useState("");
  const [siac, setSiac] = useState("");
  const [modal1, setModal1] = useState(false)
  
  

  const [state, setState] = useState({
    categorySelected: "",
    totalMarks: "",
    remarks: "",
  });

  const { id } = useParams();

  const viewFile = async (file, setFrame) => {
    await axios
      .get(file,
        {},
        {
          responseType: 'blob',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      // .then((response) => response.blob())
      .then((blob) => {
        console.log(blob.data);
        const file = new Blob(
          [blob.data],
          { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // if (iframe?.src) iframe.src = file;
        setFrame(fileURL)
      })
      .catch((error) => console.log(error));
  }



  useEffect(() => {
    viewApplication();
    getAllPermission();
    ssulist();
    getAdminProfile();
    timelineReports();
  }, []);

  // view application api call here
  const viewApplication = async () => {
    let data = await viewApplicationList(id);
    // console.log(data.data);
    if (data.data?.direct_account?.basicDetails?.panCardUpload?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.panCardUpload, setPanCard)
    } else {
      setPanCard(FILE_URL +
        data.data?.direct_account?.basicDetails?.panCardUpload)
    }

    if (data.data?.direct_account?.basicDetails
      ?.casteCertificateUpload?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails
        ?.casteCertificateUpload, setCasteCertificate)
    } else {
      setCasteCertificate(FILE_URL + data.data?.direct_account?.basicDetails
        ?.casteCertificateUpload)
    }

    if (data.data?.direct_account?.basicDetails?.qualificationUpload?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.qualificationUpload, setQualification)
    } else {
      setQualification(FILE_URL + data.data?.direct_account?.basicDetails?.qualificationUpload)
    }

    if (data.data?.direct_account?.basicDetails?.registeredEntity
      ?.proofOfEntityUpload?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.registeredEntity
        ?.proofOfEntityUpload, setCompanyProof)
    } else {
      setCompanyProof(FILE_URL +
        data.data?.direct_account?.basicDetails?.registeredEntity?.proofOfEntityUpload)
    }

    if (data.data?.direct_account?.basicDetails?.bussinessModel?.form1?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.bussinessModel?.form1, setBusinessmodelF1)
    } else {
      setBusinessmodelF1(FILE_URL + data.data?.direct_account?.basicDetails?.bussinessModel?.form1)
    }

    if (data.data?.direct_account?.basicDetails?.bussinessModel?.form2?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.bussinessModel?.form2, setBusinessmodelF2)
    } else {
      setBusinessmodelF2(FILE_URL + data.data?.direct_account?.basicDetails?.bussinessModel?.form2)
    }

    if (data.data?.direct_account?.basicDetails?.turnovers[0]
      ?.balanceSheet?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.turnovers[0]
        ?.balanceSheet, setTurnOverBalSheet)
    } else {
      setTurnOverBalSheet(FILE_URL + data.data?.direct_account?.basicDetails?.turnovers[0]
        ?.balanceSheet)
    }

    if (data.data?.direct_account?.basicDetails?.turnovers[0]?.form3?.includes("https")) {
      await viewFile(data.data?.direct_account?.basicDetails?.turnovers[0]?.form3, setTurnOverF3)
    } else {
      setTurnOverF3(FILE_URL + data.data?.direct_account?.basicDetails?.turnovers[0]?.form3)
    }

    if (data.data?.direct_account?.basicDetails?.turnovers[0]?.form4?.includes("https")) {
      await viewFile(data?.data?.direct_account?.basicDetails?.turnovers[0]?.form4, setTurnOverF4)
    } else {
      setTurnOverF4(FILE_URL + data.data?.direct_account?.basicDetails?.turnovers[0]?.form4)
    }

    
    if (data?.data?.direct_account?.StartUpDocuments?.siacDoc?.includes("https")) {
      await viewFile(data?.data?.direct_account?.StartUpDocuments?.siacDoc, setSiac)
    } else {
      setTurnOverF4(FILE_URL + data?.data?.direct_account?.StartUpDocuments?.siacDoc )
    }


    console.log(mydata?.direct_account?.basicDetails?.qualificationUpload)
    setMydata({ ...data.data });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height:780,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

  const getAllPermission = async () => {
    let data = await getAllPermissionList()
    if (data.status) {
      setPermission(data?.data[0]?.Permissions[0]?.write)
      setPermission2(data?.data[1]?.Permissions[0]?.read)
      // console.log(permission)

    }
  }

  const timelineReports = async () => {
    let timeline = await getTimelineDetails(mydata?.direct_account?.id)
    console.log(mydata?.direct_account?.id)
    setTimeData(timeline);
}

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    console.log(data.data.Role.name);
    if (data.status) {
      
      // setPermission(data.data)
      setProfile(data.data.Role.name);
      
    }
  };

  const ssulist = async () => {
    let data = {
      categories: ['a', 'b', 'c', 'd'],
    };
    let ssulist = await getSsuList(data);
    setSsuList(ssulist.data);
    // console.log(ssulist.data)

  }

  const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('applications').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  // console.log(mydata?.direct_account?.StartUpDocuments?.siacDoc)

  return (
    <>
      <DepartmentHeader />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ display: "flex", flexDirection: "column", width: "90%" }} id="applications">


          <div style={{ display:"flex",marginTop:"20px" }} >
            

          <Button style={{ backgroundColor: "#EB4747", width: "10%",  }} className="printing" variant="contained" fullWidth onClick={Print} >Print </Button>
          <div style={{ paddingLeft:"50%" }} >
            {
                mydata?.direct_account?.Application?.isEvaluated == true || mydata?.direct_account?.Application?.isEvaluated2 == true ?
                <Card style={{ width: "100%", background: "Blue", marginBottom: "0px",marginTop: "10px" }}>

                  <NavLink target={"_blank"} to={`/evaluation/submit/${mydata?.direct_account?.Application?.id}`}>
                    <Button style={{ color: "White", fontWeight: "bold" }} 

                    >Evaluated Sheet</Button>
                  </NavLink>
                </Card> : null
            }
            </div>

            <div style={{ paddingLeft:"1%" }} >

           
{
   mydata?.direct_account?.Application?.isEvaluated == true || mydata?.direct_account?.Application?.isEvaluated2 == true ?
   <Card style={{ width: "100%", background: "Blue", marginBottom: "0px",marginTop: "10px" }}>

   <NavLink target={"_blank"} to={`/meetinglist/${mydata?.direct_account?.Application?.id}`}>
     <Button style={{ color: "White", fontWeight: "bold" }}

     >Meeting Details</Button>
   </NavLink>
 </Card> : null
}



</div>

<div style={{ paddingLeft:"1%" }} >

           
{
  <Card style={{ width: "100%", background: "Blue", marginBottom: "0px",marginTop: "10px" }}>

     
        <Button style={{ color: "White", fontWeight: "bold" }} onClick={() => {setModal1(true); timelineReports();}}

        >Timeline Details</Button>
      
    </Card>
}



</div>
            
       
          </div>
          
       
          <div className="content">
            <Card className="rightcard">
              <CardContent>
                <Typography variant="h5" component="h2" align="left">
                  Details
                </Typography>
                <hr />
                <div className="column">
                  <span>Registration Number</span>
                  <span>{mydata?.acknowledgmentNo}</span>
                </div>
                <div className="column">
                  <span>Name of Startup</span>
                  <span>{mydata?.direct_account?.nameOfStartUp}</span>
                </div>
                <div className="column">
                  <span>Date Of Submission</span>
                  <span>{changeTzToNormalDefault(mydata?.createdAt).date}</span>
                </div>
                <div className="column">
                  <span>Name</span>
                  <span>
                    {mydata?.direct_account?.firstName}{" "}
                    {mydata?.direct_account?.lastName}
                  </span>
                </div>
                <div className="column">
                  <span>Date Of Birth</span>
                  <span>
                    {
                      changeTzToNormalDefault(
                        mydata?.direct_account?.basicDetails?.dateOfBirth
                      ).date
                    }
                  </span>
                </div>
                <div className="column">
                  <span>Guardian Name</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.fatherOrGuardianName}
                  </span>
                </div>
                <div className="column">
                  <span>Aadhar Number</span>
                  <span>{mydata?.direct_account?.adhaarNo}</span>
                </div>
                <div className="column">
                  <span>Phone Number</span>
                  <span>{mydata?.direct_account?.mobileNumber}</span>
                </div>
                <div className="column">
                  <span>Email</span>
                  <span>{mydata?.direct_account?.email}</span>
                </div>
                <div className="column">
                  <span>Website</span>
                  <span>{mydata?.direct_account?.basicDetails?.website}</span>
                </div>
                <div className="column">
                  <span>Higher Education</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.higherEducation
                        ?.name
                    }
                  </span>
                </div>
                <div className="column">
                  <span>Pan</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.panCardNo
                      ? mydata?.direct_account?.basicDetails?.panCardNo
                      : "N/a"}
                  </span>
                </div>
                <div className="column">
                  <span>Category</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.category?.name}
                  </span>
                </div>
                <div className="column">
                  <span>Gender</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.gender?.name}
                  </span>
                </div>
                <div className="column">
                  <span>Religion</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.religion?.name}
                  </span>
                </div>
                <div className="column">
                  <span>Is Startup Greater than 10 year</span>
                  <span>{mydata?.direct_account?.isGreaterThan10Year}</span>
                </div>
                <div className="column">
                  <span>Is turnover More than 100 Cr</span>
                  <span>
                    {mydata?.direct_account?.isTurnoverGreaterThan100Crores}
                  </span>
                </div>

                <div className="column">
                  <span>Is Entity Registered </span>
                  <span>
                    {mydata?.direct_account?.isRegisteredEntity}
                  </span>
                </div>
              </CardContent>
            </Card>

            <Card className="leftcard">
              <CardContent>
                <Typography variant="h5" component="h2" align="left">
                  Founder Detail
                </Typography>
                <hr />

                {/* team detail table */}
                <div
                  style={{
                    width: "90%",
                    overflowX: "auto",
                    fontSize: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Aadhar</TableCell>
                          <TableCell align="right">Pan</TableCell>
                          <TableCell align="right">
                            <center>Date Of Birth</center>
                          </TableCell>
                          <TableCell align="right">Qualification</TableCell>
                          <TableCell align="right">Gender</TableCell>
                          <TableCell align="right">category</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mydata &&
                          mydata?.direct_account?.basicDetails?.teams?.map(
                            (info,index) => (
                              <TableRow
                                key={info.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {info.name}
                                </TableCell>

                                <TableCell align="right">
                                  {info.aadharNo}
                                  <br />
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={mydata?.direct_account?.basicDetails
                                      ?.teams[index]?.aadharUpload?.includes('https') ? mydata?.direct_account?.basicDetails
                                        ?.teams[index]?.aadharUpload :
                                      FILE_URL +
                                      mydata?.direct_account?.basicDetails
                                        ?.teams[index]?.aadharUpload
                                    }
                                  >
                                    View File
                                  </a>
                                </TableCell>

                                <TableCell align="right">
                                  {info.panCardNo}
                                  <br />
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={mydata?.direct_account?.basicDetails?.teams[index]?.panUpload?.includes("https") ? mydata?.direct_account?.basicDetails?.teams[index]?.panUpload :
                                      FILE_URL +
                                      mydata?.direct_account?.basicDetails
                                        ?.teams[index]?.panUpload
                                    }
                                  >
                                    View File
                                  </a>
                                </TableCell>

                                <TableCell align="right">
                                  {
                                    changeTzToNormalDefault(info.dateOfBirth)
                                      .date
                                  }
                                </TableCell>
                                <TableCell align="right">
                                  {info.qaulification}
                                  <br />
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={mydata?.direct_account?.basicDetails
                                      ?.teams[index]?.qaulificationUpload?.includes("https") ? mydata?.direct_account?.basicDetails
                                        ?.teams[index]?.qaulificationUpload :
                                      FILE_URL +
                                      mydata?.direct_account?.basicDetails
                                        ?.teams[index]?.qaulificationUpload
                                    }
                                  >
                                    View File
                                  </a>
                                </TableCell>
                                <TableCell align="right">
                                  {info.gender?.name}
                                </TableCell>
                                <TableCell align="right">
                                  {info.category?.name}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Typography variant="h5" component="h2" align="left">
                  Address Detail
                </Typography>
                <hr />
                <div className="column2">
                  <span>Address</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.addresses[0]
                        ?.addressLine1
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>District</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.addresses[0]
                        ?.district.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>City</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.addresses[0]?.city
                        .name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Pincode</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.addresses[0]
                        ?.pincode
                    }
                  </span>
                </div>

                <Typography variant="h5" component="h2" align="left">
                  Company Detail
                </Typography>
                <hr />
                <div className="column2">
                  <span>Nature of Entity</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.registeredEntity
                        ?.natureOfEntity?.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Date Of Registration</span>
                  <span>
                    {
                      changeTzToNormalDefault(
                        mydata?.direct_account?.basicDetails?.registeredEntity
                          ?.dateOfRegistration
                      ).date
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>R.O.C</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.registeredEntity
                        ?.roc?.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Udyami Aadhar No</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.registeredEntity
                      ?.udyamiAadharNo
                      ? mydata?.direct_account?.basicDetails?.registeredEntity
                        ?.udyamiAadharNo
                      : "N/a"}
                  </span>
                </div>
                <div className="column2">
                  <span>Current Stage</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.venture?.currentStage
                      ? mydata?.direct_account?.basicDetails?.venture
                        ?.currentStage
                      : "Not Available"}
                  </span>
                </div>
                <div className="column2">
                  <span>Startup Innovation</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.venture
                      ?.startupInnovation
                      ? mydata?.direct_account?.basicDetails?.venture
                        ?.startupInnovation
                      : "Not Available"}
                  </span>
                </div>
                <div className="column2">
                  <span>Service</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.venture?.service
                      ?.name
                      ? mydata?.direct_account?.basicDetails?.venture?.service
                        ?.name
                      : "Not Available"}
                  </span>
                </div>
                <div className="column2">
                  <span>Industry</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.venture?.sector
                        ?.industry.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Sector</span>
                  <span>
                    {mydata?.direct_account?.basicDetails?.venture?.sector?.name
                      ? mydata?.direct_account?.basicDetails?.venture?.sector
                        ?.name
                      : "Not Available"}
                  </span>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="turnover">
            <Card>
              <Typography variant="h5" component="h2" align="left">
                Turnover Detail
              </Typography>
              <hr />
              <CardContent>
                <div className="column2">
                  <span>Total Turnover</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.turnovers[0]
                        ?.turnover
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Total Employee Strength</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.turnovers[0]
                        ?.totalEmployees
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Raised Fund Amount</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.turnovers[0]
                        ?.raisedFundAmount
                    }
                  </span>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="businessmodel">
            <Card>
              <Typography variant="h5" component="h2" align="left">
                Business Model
              </Typography>
              <hr />
              <CardContent>
                <div className="column2">
                  <span>Office Address</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.bussinessModel
                        ?.addressLine1
                    }
                  </span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.bussinessModel
                        ?.addressLine2
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>District</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.bussinessModel
                        ?.district?.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>City</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.bussinessModel?.city
                        ?.name
                    }
                  </span>
                </div>
                <div className="column2">
                  <span>Pin Code</span>
                  <span>
                    {
                      mydata?.direct_account?.basicDetails?.bussinessModel
                        ?.pincode
                    }
                  </span>
                </div>
              </CardContent>
            </Card>
          </div>

          {mydata?.direct_account?.StartUpDocuments?.id ? (
            <div className="businessmodel">
              <Card>
                <Typography variant="h5" component="h2" align="left">
                  Startup Policy 2017 Details
                </Typography>
                <hr />
                <CardContent>
                  <div className="column2">
                    <span>Name Of Startup</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.nameOfStartUp
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Name Of Application</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.nameOfApplicant
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Incubator Name</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.incubatorName
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Amount Sanctioned</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.amountSanction
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>First Installment Release</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.firstInstalmentReleased
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Sector</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.sector
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>District</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.district
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Registration Id</span>
                    <span>
                      {
                        mydata?.direct_account?.StartUpDocuments?.startUp
                          ?.registrationId
                      }
                    </span>
                  </div>
                  <div className="column2">
                    <span>Phone</span>
                    <span>
                      {mydata?.direct_account?.StartUpDocuments?.startUp?.phone}
                    </span>
                  </div>
                  <div className="column2">
                    <span>Email</span>
                    <span>
                      {mydata?.direct_account?.StartUpDocuments?.startUp?.email}
                    </span>
                  </div>
                </CardContent>
              </Card>
            </div>
          ) : (
            ""
          )}

          <div className="conceptidea">
            <Card>
              <Typography variant="h5" component="h2" align="left">
                Concept And Idea Detail
              </Typography>
              <hr />
              <CardContent>
                <Typography variant="h6" component="p" mb={1} align="left">
                  A Brief Note on innovation in idea
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.innovativeText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  A Brief Note on Uniqueness in idea
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.uniqueText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  A Brief Note on Potential of high employement through Startup
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.CreateHighEmployementeText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  A Brief Note on Potential of generating wealth through Startup
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.GeneratemassiveWealthText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  A Brief Note on Capability of development of products
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.improveQualityText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  Success Stories of Idea prototype and Growth plan
                </Typography>
                <Typography align="justify" mb={2}>
                  {
                    mydata?.direct_account?.basicDetails?.conceptOrIdea
                      ?.successStoriesText
                  }
                </Typography>
                <Typography variant="h6" component="p" mb={1} align="left">
                  Video Of Success Stories
                </Typography>
                <Typography align="left">
                  {mydata?.direct_account?.basicDetails?.conceptOrIdea
                    ?.successStories ? (
                    <>
                      {/* <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.conceptOrIdea
                          ?.successStories?.includes("sgp1.digitaloceanspaces.com") ? mydata?.direct_account?.basicDetails?.conceptOrIdea
                          ?.successStories :
                          FILE_URL +
                          mydata?.direct_account?.basicDetails?.conceptOrIdea
                            ?.successStories
                        }
                      >
                        Click here To view Video
                      </a> */}

                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.conceptOrIdea
                          ?.successStories?.includes("sgp1") ? mydata?.direct_account?.basicDetails?.conceptOrIdea?.successStories :
                          FILE_URL +
                          mydata?.direct_account?.basicDetails?.conceptOrIdea
                            ?.successStories
                        }
                      >
                        Click here To view Video
                      </a>
                    </>
                  ) : (
                    "Video Not Available"
                  )}
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className="conceptidea">
            <Card>
              <Typography variant="h5" component="h2" align="left">
                Uploaded Documents
              </Typography>
              <hr />
              <CardContent>
                {mydata?.direct_account?.basicDetails?.photoUpload ? (
                  <>
                    <Typography align="left">Profile Photo</Typography>
                    <Typography align="left" mb={2}>
                      <img
                        alt="passport_photo"
                        style={{ width: "20%", height: "20%" }}
                        src={mydata?.direct_account?.basicDetails?.photoUpload?.includes("https") ? mydata?.direct_account?.basicDetails?.photoUpload :
                          FILE_URL +
                          mydata?.direct_account?.basicDetails?.photoUpload
                        }

                      ></img>
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.panCardUpload ? (
                  <>
                    <Typography align="left">Pan Card</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={panCard}
                        title="Pan Card"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.panCardUpload?.includes("https") ? mydata?.direct_account?.basicDetails?.panCardUpload :
                          FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload
                        }
                      >
                        View Pan card
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails
                  ?.casteCertificateUpload ? (
                  <>
                    <Typography align="left">Caste Certificate</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={casteCertificate}
                        title="Caste Certificate"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails
                          ?.casteCertificateUpload?.includes("https") ? mydata?.direct_account?.basicDetails
                          ?.casteCertificateUpload :
                          FILE_URL + mydata?.direct_account?.basicDetails
                            ?.casteCertificateUpload
                        }
                      >
                        View Caste Certificate
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.qualificationUpload ? (
                  <>
                    <Typography align="left">Qualification</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={qualification}
                        title="Qualification"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.qualificationUpload?.includes("https") ? mydata?.direct_account?.basicDetails?.qualificationUpload :
                          FILE_URL + mydata?.direct_account?.basicDetails?.qualificationUpload
                        }
                      >
                        View Qualification Certificate
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.registeredEntity
                  ?.proofOfEntityUpload ? (
                  <>
                    <Typography align="left">Company Proof</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={companyProof}
                        title="Company Proof"
                      ></iframe>
                      {/* </a> */}
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.registeredEntity
                          ?.proofOfEntityUpload?.includes("https") ? mydata?.direct_account?.basicDetails?.registeredEntity
                          ?.proofOfEntityUpload :
                          FILE_URL + mydata?.direct_account?.basicDetails?.registeredEntity
                            ?.proofOfEntityUpload
                        }
                      >
                        View Company Proof Certificate
                      </a>
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.bussinessModel?.form1 ? (
                  <>
                    <>
                      <Typography align="left">
                        Business Model Form 1
                      </Typography>
                      <Typography align="left">
                        {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                        <iframe
                          style={{ width: "100%", height: "500px" }}
                          src={businessModelF1}
                          title="Business Model Form 1"
                        ></iframe>
                        <br />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={mydata?.direct_account?.basicDetails?.bussinessModel?.form1?.includes("https") ? mydata?.direct_account?.basicDetails?.bussinessModel?.form1 :
                            FILE_URL + mydata?.direct_account?.basicDetails?.bussinessModel?.form1
                          }
                        >
                          View Business Model Form 1
                        </a>
                        {/* </a> */}
                      </Typography>
                    </>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.bussinessModel?.form2 ? (
                  <>
                    <>
                      <Typography align="left">
                      Team skill sets (Form II)
                      </Typography>
                      <Typography align="left">
                        {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                        <iframe
                          style={{ width: "100%", height: "500px" }}
                          src={businessModelF2}
                          title="Team skill sets (Form II)"
                        ></iframe>
                        <br />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={mydata?.direct_account?.basicDetails?.bussinessModel?.form2?.includes("https") ? mydata?.direct_account?.basicDetails?.bussinessModel?.form2 :
                            FILE_URL + mydata?.direct_account?.basicDetails?.bussinessModel?.form2
                          }
                        >
                          View Team skill sets (Form II)
                        </a>
                        {/* </a> */}
                      </Typography>
                    </>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.turnovers[0]
                  ?.balanceSheet ? (
                  <>
                    <Typography align="left">Turnover Balance Sheet</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        // src={
                        //   FILE_URL +
                        //   mydata?.direct_account?.basicDetails?.turnovers[0]
                        //     ?.balanceSheet
                        // }
                        src={turnoverBalSheet}
                        title="Turnover Balance Sheet"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.turnovers[0]
                           ? mydata?.direct_account?.basicDetails?.turnovers[0]
                          ?.balanceSheet :
                          FILE_URL + mydata?.direct_account?.basicDetails?.turnovers[0]
                            ?.balanceSheet
                        }
                      >
                        View Turnover Balance Sheet
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

{
  mydata?.direct_account?.StartUpDocuments?.siacDoc ?(
    <>
    <Typography align="left">SIAC CERTIFICATE</Typography>
    <Typography align="left">
      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
      <iframe
        style={{ width: "100%", height: "500px" }}
        src={siac}
        title="SIAC CERTIFICATE"
      ></iframe>
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href={mydata?.direct_account?.StartUpDocuments?.siacDoc.includes("https") ? mydata?.direct_account?.StartUpDocuments?.siacDoc :
          FILE_URL + mydata?.direct_account?.StartUpDocuments?.siacDoc
        }
      >
        SIAC CERTIFICATE
      </a>
      {/* </a> */}
    </Typography>
    </>
  ):("")
}

                {mydata?.direct_account?.basicDetails?.turnovers[0]?.form3 ? (
                  <>
                    <Typography align="left">Total Employee strength (Form III)</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={turnOverF3}
                        title="Total Employee strength (Form III)"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.turnovers[0]?.form3?.includes("https") ? mydata?.direct_account?.basicDetails?.turnovers[0]?.form3 :
                          FILE_URL + mydata?.direct_account?.basicDetails?.turnovers[0]?.form3
                        }
                      >
                        View Total Employee strength (Form III)
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {mydata?.direct_account?.basicDetails?.turnovers[0]?.form4 ? (
                  <>
                    <Typography align="left">Fund raised (Form IV)</Typography>
                    <Typography align="left">
                      {/* <a target="_blank" rel='noreferrer' href={FILE_URL + mydata?.direct_account?.basicDetails?.panCardUpload}> */}
                      <iframe
                        style={{ width: "100%", height: "500px" }}
                        src={turnOverF4}
                        title="Fund raised (Form IV)"
                      ></iframe>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={mydata?.direct_account?.basicDetails?.turnovers[0]?.form4?.includes("https") ? mydata?.direct_account?.basicDetails?.turnovers[0]?.form4 :
                          FILE_URL + mydata?.direct_account?.basicDetails?.turnovers[0]?.form4
                        }
                      >
                        View Fund raised (Form IV)
                      </a>
                      {/* </a> */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {/* <Typography align='left'>
                  Aadhar Proof
                </Typography>
                <Typography align='left'>
                  <iframe style={{ width: '100%', height: '500px', }} src={FILE_URL + mydata?.direct_account?.basicDetails?.teams[0]?.aadharUpload} title="Aadhar Proof">
                  </iframe>
                </Typography> */}
                {/* {mydata?} */}
                {/* {
                  mydata?.direct_account?.StartUpDocuments?.siacDoc ?
                    <>
                      <Typography align='left'>
                        SIAC Doc
                      </Typography>
                      <Typography align='left'>
                        <iframe style={{ width: '100%', height: '500px', }} src={FILE_URL + mydata?.direct_account?.StartUpDocuments?.siacDoc} title="Siac Doc">
                        </iframe>
                      </Typography>
                    </> :
                    ""
                } */}
              </CardContent>
            </Card>
          </div>


          {
             mydata?.direct_account?.Application?.isEvaluated == true || mydata?.direct_account?.Application?.isEvaluated2 == true
             || profile?.username !== 'psc' ||profile?.username !== 'SMIC'
              ? <div>
                <center>

                  {
                    permission == false ?
                      null : <Card style={{ width: "20%", background: "Red", marginBottom: "20px" }}>

                        <NavLink target={"_blank"} to={`/evaluation/${mydata?.direct_account?.Application?.id}`}>
                          <Button onClick={() => {
                            dispatch(AcknowledgementId(mydata?.id)); 
                          }} style={{ color: "White", fontWeight: "bold" }}

                          >Evaluate </Button>
                        </NavLink>
                      </Card>
                  }
                </center>
              </div> : <div>
                <center>

                 {
                  profile?.username !== 'psc' ||profile?.username !== 'SMIC' ?
                  <Card style={{ width: "20%", background: "Red", marginBottom: "20px" }}>

                  <NavLink target={"_blank"} to={`/evaluation/${mydata?.direct_account?.Application?.id}`}>
                    <Button onClick={() => {
                      dispatch(AcknowledgementId(mydata?.id)); 
                    }} style={{ color: "White", fontWeight: "bold" }}

                    >Evaluate</Button>
                  </NavLink>
                </Card>:''
                 }

                 





                </center>
              </div>}



          {/* }
           */}

        </div>


      </div>
      {
                    modal1
                        ?
                        <Modal
                            open={modal1}
                            onClose={() => setModal1(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            
                        >
                            <Box sx={style2}>
                            <Typography style={{  marginLeft: 370, color:"Purple",marginTop:12 }} id="modal-modal-title" variant="h4" component="h2">
                                 <u><b> Timeline</b></u> 
                                </Typography>
                            <VerticalTimeline>
{timeData?.data?.Application?.assignedTo1?.name || timeData?.data?.Application?.assignedTo2?.name || timeData?.data == null   ? 

<VerticalTimelineElement
className="vertical-timeline-element--work"
contentStyle={{ background: 'yellow', color: 'black', height:"100px" }}
contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
// date={row ? row?.meetingAt ? new Date(row?.meetingAt).toUTCString().slice(0,-3) : null :"2022"  }
// date="2010 - 2011"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff',  }}

>
<u><h5 className="vertical-timeline-element-title">  { timeData?.data == null   ?  'Application Pending': 'Assigned To '}  </h5></u>
<p> {timeData?.data?.Application?.assignedTo1?.name    ? timeData?.data?.Application?.assignedTo1?.name : ''} , {timeData?.data?.Application?.assignedTo2?.name ? timeData?.data?.Application?.assignedTo2?.name : ''}</p>


</VerticalTimelineElement> :''
}
                           
           {timeData?.data?.Application?.isEvaluated == true || timeData?.data?.Application?.isEvaluated2 == true || timeData?.data?.Application?.applicationCategory1.name  || timeData?.data?.Application?.isEvaluated2 == true || timeData?.data?.Application?.applicationCategory2.name ?
             <VerticalTimelineElement
             className="vertical-timeline-element--work"
             contentStyle={{ background: 'yellow', color: 'black', height:"150px" }}
             contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
             // date={row ? row?.meetingAt ? new Date(row?.meetingAt).toUTCString().slice(0,-3) : null :"2022"  }
             // date="2010 - 2011"
             iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
             
           >
             <u><h5 className="vertical-timeline-element-title">  {timeData?.data?.Application?.isEvaluated == true ||timeData?.data?.Application?.isEvaluated2 == true   ? ' Evaluated': ''} </h5></u>
        
             <p>{timeData?.data?.Application?.applicationCategory.name ? 'Category  :    '+ timeData?.data?.Application?.applicationCategory.name + '  ,  '+ timeData?.data?.Application?.assignedTo1?.name  : '' }</p>
             <p>{timeData?.data?.Application?.applicationCategory2.name ? 'Category  :    '+ timeData?.data?.Application?.applicationCategory2.name + '  ,  '+  timeData?.data?.Application?.assignedTo2?.name  : '' }</p>
         
           </VerticalTimelineElement>:''
          
          }                   
                         
                  
                    
                 
 


{timeData?.data?.Application?.Meetings[timeData?.data?.Application?.Meetings.length - 1]?.status ?
  <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ background: 'yellow', color: 'black',height:"100px" }}
  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
  // date={meetingDetails ? dateTime ? dateTime : null :"2022"  }
  // date="2010 - 2011"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  
>
  <u><h5 className="vertical-timeline-element-title">Latest Meeting Detail</h5></u>
  <p>{timeData?.data?.Application?.Meetings[timeData?.data?.Application?.Meetings.length - 1]?.status ? timeData?.data?.Application?.Meetings[timeData?.data?.Application?.Meetings.length - 1]?.status : '' }</p>

</VerticalTimelineElement> :''
}

{timeData?.data?.Application?.Lms[timeData?.data?.Application?.Lms.length - 1]?.status && timeData?.data?.Application?.applicationCategory?.name == 'b'  ?
  <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ background: 'yellow', color: 'black',height:"100px" }}
  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
  // date={meetingDetails ? dateTime ? dateTime : null :"2022"  }
  // date="2010 - 2011"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  
>
  <u><h5 className="vertical-timeline-element-title">L.M.S</h5></u>
  <p>{timeData?.data?.Application?.Lms[timeData?.data?.Application?.Lms.length - 1]?.status ? timeData?.data?.Application?.Lms[timeData?.data?.Application?.Lms.length - 1]?.status :'' }</p>

</VerticalTimelineElement>:''
}

  
  
  
 
  

</VerticalTimeline>
                            </Box>
                        </Modal>
                        : null

                }
    </>
  );
};

export default ViewSingleApplication;