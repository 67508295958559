import React, { useEffect } from 'react';

const LogoutDepartment = () => {
    useEffect(() => {
        localStorage.removeItem('department-startup-india-token');
        window.open("/department-login", "_self")
    }, [])

    return (
        <>
            <h1>&nbsp;</h1>
        </>
    );
}

export default LogoutDepartment;

