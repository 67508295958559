import React, { useState } from 'react'
import { Box, Button, Checkbox, MenuItem, Grid, Typography } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom"
import Navbar from './Navbar';
import HindiForm from '../assets/pdf/Form I-IV in Hindi.pdf'
import EnglishForm from '../assets/pdf/Form I-IV in English.pdf'
import policy from '../assets/pdf/Bihar Startup Policy 2022 (1).pdf';
import img1 from './QuestionImages/pagenew1.png'
import img2 from './QuestionImages/page2.png'
import img3 from './QuestionImages/page3.png'
import img4 from './QuestionImages/page4.png'
import img5 from './QuestionImages/page5.png'
import img6 from './QuestionImages/page6.png'
import img7 from './QuestionImages/page7.png'
import img8 from './QuestionImages/page8.png'
import img9 from './QuestionImages/pagenew2.png'


import "../../src/Style/Authentication.css"

import Footer from "./footer"

const ApplyNewRecog = () => {
    const navigate = useNavigate()

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);

    const handleSubmit = () => {
        if (checked) {
            navigate("/questionform")
        }
    }

    return (
        <Box className='backround-ui'>
            <Grid item container spacing={1}>
                <Box className='card-ui' sx={{ height: "200vh", marginBottom: "50px" }} >
                    <Grid item xs={4} >
                        <Navbar />
                        <Box sx={{ marginLeft: 2 , marginTop: 380 }}>
                            <Footer />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red" }}><b><u>निर्देश<br/>
</u></b></h6>
<h6 style={{ textAlign: "center", color: "red" }}>
कृपया ध्यान से पारम्परिक वयवसाय एवं स्टार्टअप में अंतर पूरा पढ़े। इसके बाद आप से 10 प्रश्न पूछे जायेंगे जिनमे से 8 का सही उत्तर करना अनिवार्य है। इस के बाद ही स्टार्ट-उप पोर्टल पे पंजीकृत कर पाएंगे अन्यथा नहीं।
</h6>
</div>
<div style={{ textAlign: "justify", marginLeft: 20, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                            <ul>
                                <li><img style={{width:"111%"}} src={img1} /></li>
                                <li><img style={{width:"111%"}} src={img2} /></li>
                                <li><img style={{width:"111%"}} src={img3} /></li>
                                <li><img style={{width:"111%"}} src={img4} /></li>
                                <li><img style={{width:"111%"}} src={img5} /></li>
                                <li><img style={{width:"111%"}} src={img6} /></li>
                                <li><img style={{width:"111%"}} src={img7} /></li>
                                <li><img style={{width:"111%"}} src={img8} /></li>
                                <li><img style={{width:"111%"}} src={img9} /></li>

                            </ul>
                        </div>

                     

                        <form style={{marginBottom:20}}>
                            <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>
मैंने पारंपरिक व्यवसाय और स्टार्टअप के बीच के अंतर को ध्यान से पढ़ा और समझा है।

</span>
                            </div>



                            <br />
                            <Button
                                style={checked ? { backgroundColor: "#EB4747", color: "white" } : {}}
                                variant="contained"
                                onClick={() => handleSubmit()} 
                                disabled={!checked}
                            >टेस्ट के लिए यहां क्लिक करें</Button>
                        </form>
                    </Grid>
                </Box>
                
            </Grid>
        </Box>

    )
}

export default ApplyNewRecog