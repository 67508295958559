import React, { useEffect } from 'react';
import "./table.css"
function Dailogs(props) {


    const genData = () => {
        let _data = []
        props.data.map((info, i) => {
            _data.push(<tr>
                <td>{i + 1}</td>
                <td>{info.name}</td>
                <td>{info.aadharNo}</td>
                <td>{info.panCardNo}</td>
                <td>{info.aadharNo}</td>
                <td>{info.qaulification}</td>
                <td>{info.industryExperience}</td>
                <td>{info.gender?.name}</td>
                <td>{info.category?.name}</td>
                <td>
                    <span style={{ cursor: "pointer" }} onClick={() => props.handleEdit(info)}>
                        <i class="fa fa-edit" aria-hidden="true"></i></span>&nbsp;
                    <span style={{ cursor: "pointer" }} onClick={() => props.handleDelete(info)}>
                        <i class="fa fa-trash" aria-hidden="true"></i></span>
                </td>
            </tr>)
        });

        return _data;
    }

    return (
        <div>
            <div style={{  width: '100%', overflowX: 'auto', paddingBottom:15}}>
                <table className="founder"> 
                    <thead>
                        <tr style={{ fontSize: "14px" }}>
                            {/* <th>Id</th> */}
                            <th>Name</th>
                            <th>Aadhar No</th>
                            <th>Pan No*</th>
                            <th>Date of Birth</th>
                            <th>Qualification</th>
                            <th>Industry </th>
                            <th>Gender</th>
                            <th>Category </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{genData()}</tbody>
                </table>
            </div>
        </div>
    );
}
export default Dailogs;
