import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  InputLabel,
  Select,
  Box,
  TextField,
  MenuItem,
  FormControl,
  Button,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  Paper,
  TextareaAutosize,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import {

  getAssignedApplicationList,
  getEvaluatedDetails,
  getAssignedApplicationListEvaluated,
  

} from "../../basicAction";
import { loaderEnd, loaderStart } from "../../loader";
import { changeTzToNormalDefault } from "../../../shared/functions";


const EvaluationSubmit = (props) => {
  const { title } = props;
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalMarks, setTotalMarks] = useState(0);
  const [AssigneeTable, setAssigneeTable] = useState();
  const [alreadyEvaluated, setAlreadyEvaluated] = useState();
  const [accountDetails, setAccountDetails] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [form1Marks, setForm1Marks] = React.useState();
  const [adding, setAdding] = useState([]);
  const [adding2, setAdding2] = useState([]);
  const [assigned, setAssigned] = useState("assigne1")

  const { id } = useParams();

  let array1 = [];
  let array2 = [];

  console.log(title)

  useEffect(() => {

    GetAssignedList();
    evaluatedDetails();

    // evaluationCriteria2()
  }, [assigned]);

  const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('evaluation').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;

  }



  const evaluatedDetails = async () => {
    let data = await getEvaluatedDetails(id);

    if(assigned == 'assigne1'){
      setAlreadyEvaluated(data?.data?.data);
    
      if(array1?.length <=1){
        data?.data?.data?.Evaluations?.map((row,
          index1)=><p>{
            row?.EvaluationCriteria?.id  <= 5 ? array1.push(row?.marks): row?.EvaluationCriteria?.id
          }</p>)
    
      }

      setAdding(array1)
  
        if(array2.length <=1){
          data.data?.data?.Evaluations?.map((row,
            index1)=><p>{
              row?.EvaluationCriteria?.id  > 5 ? array2.push(row?.marks): row?.EvaluationCriteria?.id
            }</p>)
           
        }

        setAdding2(array2)
    }else{
      
      setAlreadyEvaluated(data?.data?.data2);
    
      if(array1?.length <=1){
        data?.data?.data2?.Evaluations?.map((row,
          index1)=><p>{
            row?.EvaluationCriteria?.id  <= 5 ? array1.push(row?.marks): row?.EvaluationCriteria?.id
          }</p>)
    
      }

      setAdding(array1)
  
        if(array2.length <=1){
          data.data?.data2?.Evaluations?.map((row,
            index1)=><p>{
              row?.EvaluationCriteria?.id  > 5 ? array2.push(row?.marks): row?.EvaluationCriteria?.id
            }</p>)
           
        }

        setAdding2(array2)
    }
    
        
         
     
  };

  const GetAssignedList = async () => {
    loaderStart()
    let data = await getAssignedApplicationListEvaluated({ page: page, items: 50000, list: 'evaluated' },)
    if (data.status) {
      setAssigneeTable(data.data.rows[0].Evaluations)
      setAccountDetails(data.data.rows[0].account)
      setCategoryDetails(data.data.rows[0].applicationCategory)
      console.log(data.data)
    }
    loaderEnd()
  }

  const checkassigned = () => {
    if (assigned == "assigne1") {
      setAssigned("assigne2")
    
    }
    else {
      setAssigned("assigne1")
  


    }
  }

 




  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  console.log()
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}

          id="evaluation"
        >
          {/* download excel Start Here*/}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "99%",
              height: "100%",
              marginTop: "20px",
            }}
          >

            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "",
              }}
            >
              {
                alreadyEvaluated && alreadyEvaluated ?
                  <Typography>Applicant Name :<b>{alreadyEvaluated?.account?.firstName}</b>  </Typography>
                  :
                  null

              }


            </div>

            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "",
              }}
            >
              {
                alreadyEvaluated && alreadyEvaluated ?
                  <Typography>Applicant Registration No :  <b>{alreadyEvaluated?.account?.acknowledgement?.acknowledgmentNo}</b></Typography>
                  :
                 null

              }
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "",
              }}
            >
              {/* {
                alreadyEvaluated && alreadyEvaluated ?
                  <Typography>Evaluated By :  <b>{alreadyEvaluated?.Evaluations[0]?.evaluatedBy?.name}</b></Typography>
                  :
                  null

              } */}
            </div>

            
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "",
              }}
            >
              {
                alreadyEvaluated && alreadyEvaluated ?
                  <Typography>Evaluated Date :  <b>{changeTzToNormalDefault(alreadyEvaluated?.evaluatedAt).date}</b></Typography>
                  :
                  null  

              }
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "",
              }}
            >
              {
                 alreadyEvaluated?.isEvaluated == true ||  alreadyEvaluated?.isEvaluated2 == true  ?
                  <Typography>Evaluated By:  <b>{ assigned == "assigne1" ? alreadyEvaluated?.assignedTo1?.name :  alreadyEvaluated?.assignedTo2?.name }</b></Typography>
                  :
                ""

              }
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
              }}
            >


              <Button style={{ backgroundColor: "#EB4747", width: "8%", marginTop: "5px", alignItems: "center",marginRight:12  }} className="printing" variant="contained" fullWidth onClick={Print} >Print</Button>
            
              <Button style={{ backgroundColor: "#EB4747", width: "8%", marginTop: "5px", alignItems: "center", }}  variant="contained" fullWidth onClick={checkassigned} >{assigned == "assigne1" ? "Assignee-1" : "Assignee-2"}</Button>
             
            </div>
            

            {/* <hr /> */}

            {/* <hr /> */}
            <TableContainer component={Paper}>
              <Table aria-label="Simple Table">
                <TableHead style={{ background: "#4DB1C3", border: "none" }}>
                  <TableRow style={{ height: 10 }}>
                    <TableCell>
                      <center>PARAMETERS</center>
                    </TableCell>
                    <TableCell>
                      <center>Evaluation Criteria</center>
                    </TableCell>
                    <TableCell>
                      <center>MARKS</center>
                    </TableCell>
                    <TableCell>
                      <center>LEVEL</center>
                    </TableCell>
                    {/* <TableCell>
                      <center>REMARKS</center>
                    </TableCell> */}



                    {/* <TableCell><center>Reviews</center></TableCell>
                  <TableCell><center>Remarks</center></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alreadyEvaluated?.Evaluations?.map((row, index1) => (
                    // rows?.Evaluations?.map((rowing,index)=>(
                    //   console.log(rowing)
                    //     )),

                    <TableRow
                      key={index1}
                      sx={{
                        "&:last-child td , &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{row?.EvaluationCriteria?.question} </TableCell>
                      {
                        row?.EvaluationCriteria?.id == 1
                          || row?.EvaluationCriteria?.id == 2 ||
                          row?.EvaluationCriteria?.id == 3 ||
                          row?.EvaluationCriteria?.id == 4 ||
                          row?.EvaluationCriteria?.id == 5 ?
                          <TableCell>
                            Marking from 0-10 Nil- 0 marks Low-(1-3) marks Average -
                            (4-6) marks Good – (7-9) marks Excellent- (10 marks){" "}
                          </TableCell>

                          :



                          <TableCell>
                            {row?.EvaluationCriteria?.id == 6 ? (
                              <p>
                                (0-5) marks <br />
                                LessThan 1 lakh - 0 marks 1-10 lakh – (1-2) marks
                                <br /> 11-20 lakh–(3-4) marks MoreThan 20 lakh-
                                (4-5) marks
                                <br />{" "}
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 7 ? (
                              <p>
                                (0-2) marks
                                <br /> (Ratio of Turnover/ CAPEX) (Greater the
                                ratio, more the marks)
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 8 ? (
                              <p>
                                (0-2) marks
                                <br /> (Ratio of Turnover/ OPEX) Greater the ratio,
                                more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 9 ? (
                              <p>
                                (0-2) marks
                                <br /> (Ratio of Turnover/ Resource Expense) Greater
                                the ratio, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 10 ? (
                              <p>
                                (0-2) marks <br />
                                (Ratio of Turnover/ Activity Expense) Greater the
                                ratio, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 11 ? (
                              <p>
                                (0-2) marks
                                <br /> Earlier the breakeven more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 12 ? (
                              <p>
                                (0-2) marks
                                <br /> More the RoI more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 13 ? (
                              <p>
                                (0-2) marks
                                <br /> Greater the turnover, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 14 ? (
                              <p>
                                (0-2) marks <br />
                                Greater the turnover, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 15 ? (
                              <p>
                                (0-2) marks
                                <br /> Greater the turnover, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 16 ? (
                              <p>
                                (0-2) marks
                                <br /> Greater the Profit, more the marks
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 17 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 18 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 19 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 20 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 21 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 22 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 23 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 24 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 25 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 26 ? <p>(0-2) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 27 ? (
                              <p>
                                (0-5) marks 0 - 0 marks <br />
                                1-3 – (1-2) marks
                                <br />
                                4-6- (3-4) marks
                                <br />
                                Morethan 7- (4-5) marks
                                <br />
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 28 ? <p>(0-10) marks
 Lessthan 5- 0 marks <br/>
 5-10 – (1-2) marks<br/>
 11-25- (3-5) marks<br/>
 26-50- (6-8) marks<br/>
 Morethan 50- (9-10) marks</p> : null}

                            {row?.EvaluationCriteria?.id == 29 ? (
                              <p>
                                (0-15 marks) 0 Lakh- 0 marks
                                <br />
                                1-10 Lakh- (1-5) marks
                                <br />
                                11-25 Lakh- (6-9) marks
                                <br />
                                26-50 Lakh- (10-12) marks
                                <br />
                                Morethan 50 Lakh- (13-15) marks <br />
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 30 ? (
                              <p>
                                (0-20 marks) <br />
                                (For skill set- 10 marks For certification – 10
                                marks)
                              </p>
                            ) : null}

                            {row?.EvaluationCriteria?.id == 31 ? (
                              <p>
                                (0-5)- marks
                                <br />
                                If applied for patent- 2 marks
                                <br />
                                If availed patent – another 3 marks
                                <br />
                                No – 0 marks
                                <br />
                              </p>
                            ) : null}
                          </TableCell>
                      }

                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">

                          </InputLabel>
                          <TextField
                            required
                            value={row?.marks}
                            type="number"
                            fullWidth
                          />




                        </FormControl>
                      </TableCell>
                      {
                        row?.EvaluationCriteria?.id == 1
                          || row?.EvaluationCriteria?.id == 2 ||
                          row?.EvaluationCriteria?.id == 3 ||
                          row?.EvaluationCriteria?.id == 4 ||
                          row?.EvaluationCriteria?.id == 5


                          ?
                          <TableCell  >

                            {row?.marks == 0 ? "NIL" : null}

                            {row?.marks == 1 ? "LOW" : null}

                            {row?.marks == 2 ? "LOW" : null}
                            {row?.marks == 3 ? "LOW" : null}

                            {row?.marks == 4 ? "AVERAGE" : null}

                            {row?.marks == 5 ? "AVERAGE" : null}

                            {row?.marks == 6 ? "AVERAGE" : null}

                            {row?.marks == 7 ? "GOOD" : null}

                            {row?.marks == 8 ? "GOOD" : null}

                            {row?.marks == 9 ? "GOOD" : null}

                            {row?.marks == 10 ? "EXCELLENT" : null}
                          </TableCell> : <TableCell>.</TableCell>
                      }

                      {/* <TableCell>
                        <TextField
                          type="text"
                          required
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          name="remarks"
                          value={row?.description}
                         

                        />
                      </TableCell> */}



                      {/* <Button onClick={() => AddEvaluation(index)}> add</Button> */}
                    </TableRow>
                  ))}

                </TableBody>
              </Table>



            </TableContainer>

            {
              alreadyEvaluated && alreadyEvaluated?.Evaluations ?
                <Grid
                  container
                  spacing={6}
                  sx={{ color: "text.primary", marginTop: "10px" }}
                >
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "space-between", paddingLeft: "90px", alignItems: "center" }}>


                    <Card style={{}}>

                      

                      <span><b>Total Marks Form 1</b></span>
                      
                      {adding?.length >= 1 ? (
                      <TextField
                        required
                        value={adding?.reduce((a, b) => {
                          return a + b;
                        })}
                        disabled={true}
                        type="text"
                        fullWidth
                        // label="Total Marks"
                      />
                    ) : (
                      <TextField
                        required
                        value={0}
                        disabled={true}
                        type="text"
                        fullWidth
                        // label="Total Marks"
                      />
                    )}

                      <br />

                      <span><b>Total marks Form 2</b></span>
                      
                      {adding2?.length >= 1 ? (
                      <TextField
                        required
                        value={adding2?.reduce((a, b) => {
                          return a + b;
                        })}
                        disabled={true}
                        type="text"
                        fullWidth
                        // label="Total Marks"
                      />
                    ) : (
                      <TextField
                        required
                        value={0}
                        disabled={true}
                        type="text"
                        fullWidth
                        // label="Total Marks"
                      />
                    )}

                      <br />

                      <span><b>Final Marks</b></span>
                      
                      <TextField
                        required
                        value={
                          
                          alreadyEvaluated?.Evaluations?.reduce((sum, i) => (
                          sum += i.marks + totalMarks
                        ), 0)
                      
                      
                      }
                        type="text"
                        fullWidth
                      // disabled={true}
                      // label="Total"

                      />

                      <br />

                      

                      <span> <b>Category</b></span>
                      <TextField
                        required
                        value={ assigned == "assigne1" ? alreadyEvaluated?.applicationCategory?.name :alreadyEvaluated?.applicationCategory2?.name ? alreadyEvaluated?.applicationCategory2?.name : ''  }
                        type="text"
                        fullWidth
                      // disabled={true}
                      // label="Category"

                      />
                      <br />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span><b>Remarks</b></span>
                        <TextareaAutosize
                          required
                          value={assigned == "assigne1" ? alreadyEvaluated?.evaluationRemarks : alreadyEvaluated?.evaluationRemarks2 ? alreadyEvaluated?.evaluationRemarks2 : '' }
                          type="text"
                          fullWidth
                        />
                      </div>

                    </Card>

                    
                  </Grid>
                </Grid> :

                <Grid
                  container
                  spacing={6}
                  sx={{ color: "text.primary", marginTop: "10px" }}
                >
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "space-between", paddingLeft: "90px", alignItems: "center" }}>


                    <Card style={{}}>

                      <span><b>Total marks</b></span>
                      <TextField
                        required
                        value={AssigneeTable?.reduce((sum, i) => (
                          sum += i.marks + totalMarks
                        ), 0)}
                        type="text"
                        fullWidth
                      // disabled={true}
                      // label="Total"

                      />

                      <br />

                      <span> <b>Category</b></span>
                      <TextField
                        required
                        value={categoryDetails?.name}
                        type="text"
                        fullWidth
                      // disabled={true}
                      // label="Category"

                      />
                      <br />

                      <span><b>Remarks</b></span>
                      <TextField
                        required
                        value={AssigneeTable?.evaluationRemarks ? AssigneeTable?.evaluationRemarks : "Remarks Not Available"}
                        type="text"
                        fullWidth
                      />
                    </Card>
                  </Grid>
                </Grid>
            }
            <div style={{ marginTop: "20px" }}>
              <center>
                <Card
                  style={{
                    width: "20%",
                    background: "Red",
                    marginBottom: "20px",
                  }}
                >

                  <Button
                    style={{ color: "White", fontWeight: "bold" }}
                    disabled={true}
                  >
                    Already Evaluated
                  </Button>

                </Card>
              </center>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default EvaluationSubmit;
