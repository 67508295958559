import moment from 'moment';

export const changeTzToNormalDefault = (date) => {
  if (date) {
    let sDate = moment(date);
    sDate = sDate.format('YYYY-MM-DD HH:mm')
    // console.log(sDate)
    return {
      date: sDate.split(" ")[0],
      time: sDate.split(" ")[1]
    }
  }
  else {
    return {
      date: "",
      time: ""
    }
  }
}

export const changeTzToNormalDefaultS = (date) => {
  if (date) {
    let sDate = moment(date);
    sDate = sDate.format('YYYY-MM-DD HH:mm')
    // console.log(sDate)
    return {
      date: sDate.split(" ")[0],
      time: sDate.split(" ")[1]
    }
  }
  else {
    return {
      date: "",
      time: ""
    }
  }
}

export const changeTzToNormalDefaultdate = (date) => {
  if (date) {
    let sDate = moment(date);
    sDate = sDate.format('DD-MM-YYYY HH:mm')
    // console.log(sDate)
    return {
      date: sDate.split(" ")[0],
      time: sDate.split(" ")[1]
    }
  }
  else {
    return {
      date: "",
      time: ""
    }
  }
}