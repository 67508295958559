import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  TextareaAutosize,
  Typography,
  TextField,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  recommendedSelect,
  getSmicPending,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  approveSmic,
  getEvaluatedListCsvSsu,
  getAssignedApplicationDownloadedExcel,
  getAdminProfileDetail,
  rejectSmic
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  AcknowledgementId
} from "../actions";
import Sidebar from "../../Sidebar/Sidebar";
import DepartmentHeader from "../DepartmentHeader";


const inititalValue = {
appid:0,
boolean:"",
reamrks:""
};


const SmicPending = (props) => {
  const dispatch = useDispatch();
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  const [state,setState]=useState(inititalValue)
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [dateSelector, setDateSelector] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [evaluateDetails, setEvaluateDetails] = useState();
  const [total, setTotal] = useState(0);
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [startDate, handleDateChange] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [modal, setModal] = useState(false);
  const [endDate, handleDateChange2] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [profile, setProfile] = useState("");
  const [isRecognition, setIsRecognition] = useState(false)
  const [filteredResults, setFilteredResults] = useState([]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [datesFil, setDatesFil] = useState(true);

  const [appId, setAppId] = useState();
  const [boolean, setBoolean] = useState('');
  const [remarks, setRemarks] = useState();

 


  const { id } = useParams();


  useEffect(() => {
    // getAllApplication();
    ssulist();
    // assigneeList();
    // GetAssignedList();
    // getAdminProfile();

    // alert(newDataFlag);

  }, [page, rowsPerPage,isRecognition,flags,newDataFlag]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())

  useEffect(() => {
    try{
      if (search !== "All") {
  
        const result = SsuList
          ? SsuList?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()): 
         
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
          : assigneeTable?.rows?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()):
                 list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            });
            SsuList?
        setFilteredResults(result)
        :
        setFilteredResultsAasinTable(result);
      
    } else {
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
    }
    catch{
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
   
  }, [search]);




  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };





  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.application?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

 
     {
      name: "Name of Startup",
      cell: (row) => row?.application?.account?.nameOfStartUp,
      width: "150px",
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.application?.account?.firstName + "  " + row?.application?.account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.application?.account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.application?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.application?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
   
    {
      name: "Category1",
      selector: (row) => row?.application?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },



    {
      name: "Assignee2.",
      cell: (row) => row?.application?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,
    },

    {
      name: "Category2",
      selector: (row) => row?.application?.applicationCategory2?.name,
      width: "139px",
      sortable: true,
    },



   


    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.application?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    // {
    //   name: "Action",
    //   selector: (row) =>
        
         
    //         <Button  variant="outlined" onClick={ () => {
    //           setAppId(row?.id);
    //           setBoolean(true)
    //           // setModal(true)
    //           recommendedSend(true,row?.id);
    //         }           }>
             
    //           Approve
    //         </Button>
       
    //    ,
    //   width: "180px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    // {
    //   name: "Action",
    //   selector: (row) =>
        
          
    //         <Button variant="outlined" onClick={ () => {
              
    //           // setAppId(row?.id);
    //           // setBoolean(false)
    //           // setModal(true)
    //           recommendedSend(false,row?.id);
    //         } }>
              
    //           Reject
    //         </Button>
          
    //    ,
    //   width: "200px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },
  ];
  



  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      // console.log(data.data)
      setAssignee(data);
    }
  };




  const downloadAssignedTableCsv = async () => {
    if(flags == true){

      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
  
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
      
      };

  const downloadAssignedTableCsvSsu = async () => {

    if(flags == true){
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 

  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
   
  };



  const ssulist = async () => {

    if(flags == true){
      let data1 = {
        // categories: ["a", "b"],
        isRecognition:true
      };
  
      let ssulist = newDataFlag == true ? await getSmicPending({
        round:2
      },data1) :
      await getSmicPending({
        round:1
      },data1)
      // console.log(categoryMenu.categories)
      setSsuList(ssulist?.data?.data?.rows);
      setFilteredResults(ssulist?.data?.rows);
      setSSuTotal(ssulist?.data?.data?.rows);
    }else{
      let data1 = {
        // categories: ["a", "b"],
        isRecognition:false
      };
  
      let ssulist = newDataFlag == true ? await getSmicPending({round:2},data1) :
      await getSmicPending({round:1},data1)
      console.log(ssulist)
      setSsuList(ssulist?.data?.data?.rows);
      setFilteredResults(ssulist?.data?.rows);
      setSSuTotal(ssulist?.data?.data?.rows);
    }
 
    
  };


  const recommendedSend = async (status)=>{

         if(status == true){
          let val = {
            applicationId :appId ? appId : ''
          }
          let data = newDataFlag == true ? await approveSmic({round:2 },val) :
          await approveSmic({round:1 },val)
          if(data.status){

            actionNotifier.success("Application Submitted")
            ssulist();
            setModal(false);
          }else{
            actionNotifier.error("Application Already Submitted")
            ssulist();
            setModal(false);
          }
         }else if (status == false){
          let val = {
            applicationId :appId ? appId : ''
          }
          let data2 = newDataFlag == true ? await rejectSmic({round:2 },val) :
          await rejectSmic({round:1 },val)
         
          if(data2.status){

            actionNotifier.success("Application Submitted")
            ssulist();
            setModal(false);
          }else{
            actionNotifier.error("Application Already Submitted")
            ssulist();
            setModal(false);
          }
         }
    

  

   
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  

  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    
    setSearch(event.target.value);

    console.log(event.target.value);
    
  };



  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let ids = selectedRows?.map((row) => {
      return {
        applicationId:row?.id
      }
    })
console.log(ids)
setAppId(ids);


    
  };

  return (
    <>
     <DepartmentHeader />
    <div style={{ display: 'flex' }}>
        <Sidebar />
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
        


        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>

              <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                filteredResults ? filteredResults : ''
              }
              pagination
              subHeader
              subHeaderComponent={
                <>
                     {SsuList && SsuList ? (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          ) : (
            <Button onClick={downloadAssignedTableCsv} variant="text">
              Click here to download excel
            </Button>
          )}

 <Button sx={{marginLeft:2}} variant="outlined" onClick={()=>{
recommendedSend(true)
 } }>
             Approved
            </Button>

            <Button sx={{marginLeft:2}} variant="outlined" onClick={()=>{
recommendedSend(false)
 } }>
             Reject
            </Button>
          
          

<input
                    type="text"
                    placeholder="Search Here"
                    className="w-19 form-Control"
                    onChange={onChangeHandler}
                    style={{marginRight:18}}
                  />
                 


               
                
                  
       

                  <Select
                  sx={{marginLeft:3}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Filter"
                    onChange={handleChangeCategory}
                    displayEmpty
                    style={{ textAlign: "left",height:35 }}
                  >
                    <InputLabel>Filter Categories</InputLabel>

                    {/* <MenuItem value={10}>Start up</MenuItem> */}
                    <MenuItem value={"a"}>A</MenuItem>
                    <MenuItem value={"b"}>B</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>

               
              

                

           
              

                 
                </>
              }
              subHeaderAlign="center"
              value={search}
              selectableRows
              onSelectedRowsChange={handleChange}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
            paginationTotalRows={SsuList ? filteredResults?.length : assigneeTable?.count}
            paginationPerPage={8}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />
          
          
          </TableContainer>
        </Box>
        
      </div>
      </div>
      {/* {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Submit Your Remarks
            </Typography>
            <br />           
             
            <TextareaAutosize
            style={{width:'100%'}}
					type="text" label="Remarks"
					// value={state.name}
          fullwidth
					required
					onChange={(e) => setRemarks(e.target.value)}
          multiline={true}
				/>
             
            
             
<br/><br/>
              <Button
                onClick={()=>{
                  recommendedSend(true);
                }}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Submit 
              </Button>
            
          </Box>
        </Modal>
      ) : null} */}

    </>
  );
};

export default SmicPending;
