import React from 'react';

import { FILE_URL } from "../../shared/constant"

import "../../../src/Style/Auth.css";

const ViewFile = (props) => {
    return (
        <>
            {
                props?.file
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel='noreferrer' href={props?.file?.includes("https") ? props?.file : FILE_URL + props?.file}>
                            View File
                        </a>
                    </div>
                    : null

            }
        </>
    )
}

export default ViewFile