import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const initialValue = {
    incubatorType: ""
};
const UniversityContent = () => {
    const [incubator, setIncubator] = useState(initialValue);
    const onValue = (e) => {
        setIncubator({ ...incubator, [e.target.name]: e.target.value });
        console.log(incubator)
    };
    return (
        <>
            <TextField select fullWidth id="incubator_type_id" value="" label="Course Provided" onChange={(e) => onValue(e)}
                name="courseProvided"
            >
                <MenuItem value={1}>LLB</MenuItem>
                <MenuItem value={2}>MBBS</MenuItem>
                <MenuItem value={2}>M.Tech</MenuItem>
                <MenuItem value={2}>M.E.</MenuItem>
                <MenuItem value={2}>MBA</MenuItem>
                <MenuItem value={2}>LLM</MenuItem>
                <MenuItem value={2}>Phd</MenuItem>
                <MenuItem value={2}>others</MenuItem>
            </TextField><br /><br />
            <TextField select fullWidth id="incubator_type_id" value="" label="Affiliated with *" onChange={(e) => onValue(e)}
                name="courseProvided"
            >
                <MenuItem value={1}>AICTE</MenuItem>
                <MenuItem value={2}>UGC</MenuItem>
                <MenuItem value={2}>GoI</MenuItem>
                <MenuItem value={2}>State</MenuItem>
            </TextField><br /><br />
            <TextField fullWidth label="No. of faculties dedicated for startup incubation" id="fullWidth" value="" onChange={(e) => {
            }}
            /><br /><br />
            <TextField fullWidth label="No. of Faculty availed mentorship certification course approved under the policy by PSC"
                id="fullWidth" value="" onChange={(e) => { }}
            /><br /><br />
            <div style={{ textAlign: "left" }}>
                <label>Upload the certificate of each faculty in one pdf</label>
                <TextField type="file" label={" "}
                    inputProps={{ accept: " application/pdf" }} name="casteCertificateUpload" fullWidth
                    onChange={(e) => { }}
                /></div>
            <br /><br />
            <TextField fullWidth label="Dedicated Space for startup eco system(in Sqr ft) " id="fullWidth" value=""
                onChange={(e) => { }}
            /><br /><br />
        </>
    )
}

export default UniversityContent;
