import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import EvaluationSubmit from './EvaluationSubmit'

// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const EvaluationMain = () => {

  return (
    <>
      {/* <DepartmentHeader /> */}
      {/* main content */}
      <div style={{ display: 'flex' }}>
        {/* <Sidebar /> */}
        <EvaluationSubmit/>
        
      
      </div>

    </>
  )
}

export default EvaluationMain










