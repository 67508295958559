import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import "../../../src/Style/Auth.css";
import { REACT_APP_API_URL } from "../../shared/constant";
const Address = (props) => {

    // console.log(props.basicDetails.stateId)

    //get api for stare
    const [states, setSatets] = useState([{label:"", value:"1"}]);

    //get api for districts
    const [districts, setDistricts] = useState([{label:"", value:"1"}]);

    //get api for Cites
    const [Cityes, setCity] = useState([{label:"", value:"1"}]);

    useEffect(() => {
        if (props.token) {
            StateApi();
        }
    }, [props.token]);

    useEffect(() => {
        if (props.basicDetails.stateId) {
            DistrictsApi(props.basicDetails.stateId)
        }
    }, [props.basicDetails.stateId]);

    useEffect(() => {
        if (props.basicDetails.districtId) {
            CitiesApi(props.basicDetails.districtId)
        }
    }, [props.basicDetails.districtId]);
    //get api for state Name
    const StateApi = () => {
        axios
            .get(`${REACT_APP_API_URL}data/state`, {
                headers: { Authorization: `Bearer ${props.token}` },
            })
            .then((response) => response.data)
            .then((data) => {
                setSatets(data);
                // console.log(data,"state");
            });
    };

    //get api for districts Name
    const DistrictsApi = (id) => {
        axios
            .get(
                `${REACT_APP_API_URL}data/state/${id}/district`,
                { headers: { Authorization: `Bearer ${props.token}` } }
            )
            .then((response) => response.data)
            .then((data) => {
                setDistricts(data);
                // console.log(data, "district");
            });
    };

    //get api for  cities name
    const CitiesApi = (id) => {
        axios
            .get(
                `${REACT_APP_API_URL}data/district/${id}/city`,
                { headers: { Authorization: `Bearer ${props.token}` } }
            )
            .then((response) => response.data)
            .then((data) => {
                setCity(data);
                // console.log(data, "citiy");
            });
    };

    return (
        <>
            <TextField
                color="warning"
                multiline
                fullWidth
                label="Address Line"
                id="fullWidth"
                required
                value={props.basicDetails.addressLine1}
                onChange={(e) => {
                    props.setBasicDetails({ ...props.basicDetails, addressLine1: e.target.value })
                }}
                style={{ textAlign: 'left' }}
            />
            <br />
            <br />

            <Box sx={{ display: "flex", justifyContent: "space-between", gap:2 }}>
                <TextField
                    select
                    color="warning"
                    value={props.basicDetails.stateId}
                    fullWidth
                    label="State"
                    style={{ textAlign: 'left' }}
                    required
                    onChange={(e) => {
                        props.setBasicDetails({ ...props.basicDetails, stateId: e.target.value })
                    }}
                >
                    {
                        states?.data?.map((val, index) => (
                            <MenuItem key={index} value={val.id}>
                                {val.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    select
                    color="warning"
                    labelId="district-name"
                    id="district-name"
                    value={props.basicDetails.districtId}
                    fullWidth
                    required
                    style={{ textAlign: 'left' }}
                    label="District"
                    onChange={(e) => {
                        props.setBasicDetails({ ...props.basicDetails, districtId: e.target.value })
                    }}
                >
                    {
                        districts?.data?.map((val) => (
                            <MenuItem key={val.name} value={val.id}>
                                {val.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    select
                    color="warning"
                    labelId="district-name"
                    id="district-name"
                    value={props.basicDetails.cityId}
                    fullWidth
                    required
                    label="City"
                    style={{ textAlign: 'left' }}
                    onChange={(e) => {
                        props.setBasicDetails({ ...props.basicDetails, cityId: e.target.value })
                    }}
                >
                    {
                        Cityes?.data?.map((option) => (
                            <MenuItem key={option.name} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Box>
            <br />
            <TextField
                label="Pin code"
                color="warning"
                fullWidth
                required
                name="pincode"
                value={props.basicDetails.pincode}
                onChange={(e) => props.setBasicDetails({ ...props.basicDetails, pincode: e.target.value })}
            />
            <br />
            <br />
        </>
    )
}

export default Address;