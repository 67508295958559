import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  InputLabel,
  Select,
  Box,
  TextField,
  MenuItem,
  FormControl,
  Button,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  Paper,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  searchApplication,
  viewApplicationList,
  getEvaluationCriteria,
  addUpdateEvaluation,
  addApplicationCategory,
  submitEvaluate,
  getAssignedApplicationList,
  getEvaluatedDetails,
  getSsuList,
  getAdminProfileDetail,
  searchGlobal,
} from "../../basicAction";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import { useSelector } from "react-redux";

const Evaluation = () => {
  // const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));

  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [criteria, setCriteria] = useState();
  const [criteria2, setCriteria2] = useState();
  const [totalMarks1, setTotalMarks1] = useState([""]);
  const [totalMarks, setTotalMarks] = useState([""]);
  const [adding, setAdding] = useState([]);
  const [adding2, setAdding2] = useState([]);
  const [CategoryId, setCategoryId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [remarks2, setRemarks2] = useState("");
  const [reamarksSubmit, setRemarksSubmit] = useState("");
  const [marks, setMarks] = useState("");
  const [marks2, setMarks2] = useState("");
  const [evaId, setEvaId] = useState();
  const [evaId2, setEvaId2] = useState();
  const [alreadyEvaluated, setAlreadyEvaluated] = useState();
  const [AssigneeTable, setAssigneeTable] = useState();
  const [updateEvaluation, setUpdateEvauation] = useState();
  const [total, setTotal] = useState(0);
  const [merge, setMerge] = useState();
  const [SsuList, setSsuList] = useState();
  const [profile, setProfile] = useState("");
  const [userId, setId] = useState();
  const [assigneeId, setAssigneeId] = useState();

  const { id } = useParams();

  useEffect(() => {
    viewApplication();
    evaluationCriteria();
    GetAssignedList();
    evaluatedDetails();
    ssulist();
    getAdminProfile();

    // evaluationCriteria2()
  }, [newDataFlag]);

  // console.log( userId == id && assigneeId == profile?.id && alreadyEvaluated?.data?.isEvaluated2 == true )

  let array1 = [];
  let array2 = [];

  const options2 = [
    {
      value: "A",
      id: 1,
    },
    {
      value: "B",
      id: 2,
    },
    {
      value: "C",
      id: 3,
    },
    {
      value: "D",
      id: 4,
    },
  ];

  // const getAdminProfile = async () => {
  //   let data = await getAdminProfileDetail();
  //   if (data.status) {
  //     // console.log(data);
  //     // setPermission(data.data)
  //     setProfile(data.data);
  //   }
  // };

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById("evaluation").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      // console.log(data);
      // setPermission(data.data)
      setProfile(data?.data);
      console.log(data?.data?.Role?.name);
    }
  };

  // view application api call here
  const viewApplication = async () => {

    let getAcknowledgementid = JSON.parse(localStorage.getItem('acknoId'))
    let data = await viewApplicationList(getAcknowledgementid);
    setUserDetails({ ...data?.data});

  

    // if(data?.data?.id == id){
    //     setId(data?.data?.id);
    //     setAssigneeId(data?.data2?.direct_account?.Application?.assignedTo2Id);
    // }
  };

  const evaluatedDetails = async () => {
    let data = await getEvaluatedDetails(id);

    
    setAlreadyEvaluated(data?.data?.data);
    if (data?.data?.data2?.id == id) {
      
      console.log(data?.data);
      // setAlreadyEvaluated(data?.data?.data);
      setId(data?.data?.data2?.id);
      // setAssigneeId(data?.data?.data2?.assignedTo2Id);
    }
    

  };

  const evaluationCriteria = async () => {
    let criteria = await getEvaluationCriteria(id);

    setCriteria(criteria?.data[0].EvaluationCriteria);

    setCriteria2(criteria?.data[1]?.EvaluationCriteria);

    setMerge(criteria?.data);
    console.log(criteria.data[0]?.EvaluationCriteria);
    

    if (array1?.length <= 1) {
      criteria?.data[0]?.EvaluationCriteria?.map((row, index1) =>
        array1.push(
          row?.Evaluations
          .filter(item => item.evaluatedById == profile?.id)
          .reduce((acc, cur) => cur.marks, 0)
      
        )
      );
    }

    setAdding(array1);
    console.log(array1);

    if (array2?.length <= 1) {
      criteria?.data[1]?.EvaluationCriteria?.map((row, index1) =>
        array2.push(
          row?.Evaluations
          .filter(item => item.evaluatedById == profile?.id)
          .reduce((acc, cur) => cur.marks, 0)
      
        )
      );
    }

    setAdding2(array2);
    console.log(adding2);
  };

  const ssulist = async () => {
    let val = {
      categories: ["a", "b", "c", "d"],
    };
    let ssulist =  newDataFlag == true ? await getSsuList({
      round:2
    },val) :
    await getSsuList({
      round:1
    },val);

    setSsuList(ssulist.data);
  };

  const GetAssignedList = async () => {
    loaderStart();
    let data =  newDataFlag == true ? await getAssignedApplicationList({
      page: page,
      items: 50000,
      round:2
    }) :

     await getAssignedApplicationList({
      page: page,
      items: 50000,
      round:1
    })

    if (data?.status) {
      setAssigneeTable(data?.data?.rows);
      data?.data?.rows?.forEach(function (e) {
        if (data?.data?.count !== 0 && e.id == id) {
          setId(e.id);
          setAssigneeId(e?.assignedTo2Id);
        }
      });
    } 

    loaderEnd();
  };

  const sum = async () => {
    let sum = 0;
    let sum2 = 0;

    if (criteria) {
      for (const [i, v] of criteria?.entries()) {
        let eva = v.Evaluations;
        //  console.log(criteria)

        for (let i = 0; i < eva.length; i++) {
          sum = sum + eva[eva?.length - 1 ? eva?.length - 1 : 0].marks;
        }
      }
    } else {
      setTotalMarks1(sum);
    }

    if (criteria2) {
      for (const [i, v] of criteria2?.entries()) {
        // console.log(i, v.Evaluations)
        let eva2 = v.Evaluations;

        for (let i = 0; i < eva2.length; i++) {
          sum2 = sum2 + eva2[eva2?.length - 1 ? eva2?.length - 1 : 0].marks;
          // console.log(sum2)
        }
      }
      setTotalMarks(sum2);
      setTotalMarks1(sum);
    } else {
      setTotalMarks1(sum);
      setTotalMarks(sum2);
    }
  };

  const onRemarks = async (e, index1) => {
    setRemarks(e.target.value); 

    let _criteria = [...criteria];
    let data = {
      marks: _criteria[index1].Evaluations[0].marks,
      evaluationCriteriaId: _criteria[index1].id,
      description: e.target.value,
    };

    let data2 = await addUpdateEvaluation(id, data);

    if (data2.status == true) {
      evaluationCriteria();
    } else {
      // alert('fail')
    }

    // setMarks(e.target.value);
    // setEvaId(_criteria[index1].id);
  };

  const onRemarksSubmit = async (e) => {
    // console.log(e.target.value);
    setRemarksSubmit(e.target.value);
    // console.log(index1)
  };

  const onRemarks2 = async (e, index1) => {
    setRemarks2(e.target.value);

    let _criteria2 = [...criteria2];
    let data = {
      marks: _criteria2[index1].Evaluations[0].marks,
      evaluationCriteriaId: _criteria2[index1].id,
      description: e.target.value,
    };

    let data2 = await addUpdateEvaluation(id, data);

    if (data2.status == true) {
      evaluationCriteria();
    } else {
    }
  };

  const onValue = async (e, index1) => {
    let _criteria = [...criteria];
    let data = {
      marks: e.target.value,
      evaluationCriteriaId: _criteria[index1].id,
      // description: remarks,
    };
    console.log(_criteria);

    console.log(_criteria);

    let data2 = await addUpdateEvaluation(id, data);
    sum();

    if (data2.status == true) {
      evaluationCriteria();
      actionNotifier.success("Marks Added Successfully");
    } else {
      // alert('fail')
    }

    setMarks(e.target.value);
    setEvaId(_criteria[index1].id);
  };

  const onValue2 = async (e, index1) => {
    let _criteria2 = [...criteria2];

    let data = {
      marks: e.target.value,
      evaluationCriteriaId: _criteria2[index1].id,
      // description: remarks2,
    };

    let data2 = await addUpdateEvaluation(id, data);
    sum();
    actionNotifier.success("Marks Added Successfully");

    if (data2.status == true) {
      evaluationCriteria();
    } else {
      // alert('fail')
    }
    // console.log(e.target.value);
    setMarks2(e.target.value);
    setEvaId2(_criteria2[index1].id);
  };

  const onChangeCategory = async (e) => {
    console.log(userId == id && assigneeId == profile?.id);

    if (userId == id && assigneeId == profile?.id) {
      let data = {
        applicationCategoryId2: e.target.value,
      };
      let data2 = await addApplicationCategory(id, data);

      setCategoryId(data.applicationCategoryId2);

      if (data2.status == true) {
        // alert('success')
      } else {
        // alert('fail')
      }
    } else {
      //  alert(userId == id && assigneeId == profile?.id )
      let data = {
        applicationCategoryId: e.target.value,
      };
      let data2 = await addApplicationCategory(id, data);

      setCategoryId(data.applicationCategoryId);

      if (data2.status == true) {
        // alert('success')
      } else {
        // alert('fail')
      }
    }
  };

  const sumbitApplication = async (e) => {
    console.log(userId == id && assigneeId == profile?.id);
    
    if (userId == id && assigneeId == profile?.id) {
     
      if (totalMarks + totalMarks1 == "" || 0 || null ) {
      
        loaderStart();
       
          let _criteria = [...criteria];
          // console.log(_criteria);
          for (let [index, value] of _criteria?.entries()) {
           
            let data = {
              marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0 ]?.marks,
              evaluationCriteriaId: value?.id,
         
              // description: remarks,
            };
            console.log(value)
            
            let data2 = await addUpdateEvaluation(id, data);
          } 
        
       
        if(criteria2) {
          let _criteria2 = [...criteria2];
          for (let [index, value] of _criteria2?.entries()) {
            let data = {
              marks: value?.Evaluations[0]?.marks,
              evaluationCriteriaId: value?.id,
              // description: remarks,
            }; 
            let data2 = await addUpdateEvaluation(id, data);
          } 
        }
      
        sum();

        let data = {
          evaluationTotalMarks: totalMarks + totalMarks1  == '' ? 0 : totalMarks,
          evaluationRemarks2: reamarksSubmit,
        };
      
        let data2 = await submitEvaluate(id, data);
        // let data2 = "hi"

        if (data2.status == true) {
          // alert('success')
          actionNotifier.success("Application Submitted Succesfully");
          loaderEnd();
          setUpdateEvauation(data2.status);
          // actionNotifier.success("Redirecting to submitted apllications page");
          // window.location.href = "/recommended";
        } else {
          // alert('fail')
          actionNotifier.error("Fill all marks then submit application");
        }
      }
       else {
        loaderStart();
        let _criteria = [...criteria];
        // console.log(_criteria);
        for (let [index, value] of _criteria?.entries()) {
         
          let data = {
            marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0 ]?.marks,
            evaluationCriteriaId: value?.id,
       
            // description: remarks,
          };
          console.log(value)
          
          let data2 = await addUpdateEvaluation(id, data);
        } 
      
     
      if(criteria2) {
        let _criteria2 = [...criteria2];
        for (let [index, value] of _criteria2?.entries()) {
          let data = {
            marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0]?.marks,
            evaluationCriteriaId: value?.id,
            // description: remarks,
          }; 
          let data2 = await addUpdateEvaluation(id, data);
        } 
      }
      sum();
    
        let data = {
          evaluationTotalMarks: totalMarks,
          evaluationRemarks2: reamarksSubmit,
        };
        // loaderStart();
        let data2 = await submitEvaluate(id, data);
        // actionNotifier.success("Application Updated Succesfully");

        if (data2.status == true) {
          // alert('success')
          actionNotifier.success("Application Submitted Succesfully");
          loaderEnd();
          setUpdateEvauation(data2.status);
          // actionNotifier.success("Redirecting to submitted apllications page");
          // window.location.href = "/recommended";
        } else {
          // alert('fail')
          actionNotifier.error("Fill all marks then submit application");
        }
      }
    } 
    else {
      if (totalMarks + totalMarks1 == null || 0 || ""  ) {
        loaderStart();
        
          let _criteria = [...criteria];
     
          // console.log(_criteria);
          for (let [index, value] of _criteria?.entries()) {
            let data = {
              marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0]?.marks,
              evaluationCriteriaId: value?.id,
              // description: remarks,
            };
            let data2 = await addUpdateEvaluation(id, data);
          }
        
      
        if(criteria2){
          let _criteria2 = [...criteria2];
          for (let [index, value] of _criteria2?.entries()) {
            let data = {
              marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0]?.marks,
              evaluationCriteriaId: value?.id,
              // description: remarks,
            };
            let data2 = await addUpdateEvaluation(id, data);
          }
        }
      
        sum();

        let data = {
          evaluationTotalMarks: totalMarks + totalMarks1  == '' ? 0 : totalMarks,
          evaluationRemarks: reamarksSubmit,
        };
        let data2 = await submitEvaluate(id, data);
        // let data2 = "hi"

        if (data2.status == true) {
          // alert('success')
          actionNotifier.success("Application Submitted Succesfully");
          loaderEnd();
          setUpdateEvauation(data2.status);
          // actionNotifier.success("Redirecting to submitted apllications page");
          // window.location.href = "/recommended";
        } else {
          // alert('fail')
          actionNotifier.error("Fill all marks then submit application");
        }
      }
      loaderStart();
       
      let _criteria = [...criteria];
      // console.log(_criteria);
      for (let [index, value] of _criteria?.entries()) {
       
        let data = {
          marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0 ]?.marks,
          evaluationCriteriaId: value?.id,
     
          // description: remarks,
        };
        console.log(value)
        
        let data2 = await addUpdateEvaluation(id, data);
      } 
    
   
    if(criteria2) {
      let _criteria2 = [...criteria2];
      for (let [index, value] of _criteria2?.entries()) {
        let data = {
          marks: value?.Evaluations[value?.Evaluations?.length -1 ? value?.Evaluations?.length -1 : 0 ]?.marks,
          evaluationCriteriaId: value?.id,
          // description: remarks,
        }; 
        let data2 = await addUpdateEvaluation(id, data);
      } 
    }
  
    sum();


      let data = {
        evaluationTotalMarks: totalMarks + totalMarks1  == '' ? 0 : totalMarks,
        evaluationRemarks: reamarksSubmit,
      };
     
      let data2 = await submitEvaluate(id, data);
      // actionNotifier.success("Application Updated Succesfully");
     
      if (data2.status == true) {
        // alert('success')
        actionNotifier.success("Application Submitted Succesfully");
        loaderEnd();
        setUpdateEvauation(data2.status);
        // actionNotifier.success("Redirecting to submitted apllications page");
        // window.location.href = "/recommended";
      } else {
        // alert('fail')
        actionNotifier.error("Fill all marks then submit application");
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  console.log(userDetails)
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
          id="evaluation"
        >
          {/* download excel Start Here*/}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "99%",
              height: "100%",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "",
              }}
            >
              <Typography>Applicant Name :</Typography>

            <b>{userDetails?.direct_account?.firstName}</b> 
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "",
              }}
            >
              <Typography>Applicant Registration No :</Typography>

             <b>{userDetails?.acknowledgmentNo}</b>
            </div>

            {/* <hr /> */}

            {/* <hr /> */}
            <TableContainer component={Paper}>
              <Table aria-label="Simple Table">
                <TableHead style={{ background: "#4DB1C3", border: "none" }}>
                  <TableRow style={{ height: 10 }}>
                    <TableCell>
                      <center>Parameters</center>
                    </TableCell>
                    <TableCell>
                      <center>Evaluation Criteria</center>
                    </TableCell>
                    <TableCell>
                      <center>Marks</center>
                    </TableCell>
                    <TableCell>
                      <center>Level</center>
                    </TableCell>
                    {/* <TableCell>
                      <center>Remarks</center>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {criteria?.map((row, index1) => (
                    <TableRow
                      key={index1}
                      sx={{
                        "&:last-child td , &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{row?.question} </TableCell>
                      <TableCell>
                        Marking from 0-10 Nil- 0 marks Low-(1-3) marks Average -
                        (4-6) marks Good – (7-9) marks Excellent- (10 marks)
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Marks
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                          
                            }
                            name="Marks"
                            label="Marks"
                            onChange={(e) => onValue(e, index1)}
                            required
                            color="warning"
                          >
                            {[...Array(row?.maxValue + 1)].map(
                              (elementInArray, index) => (
                                <MenuItem value={index}>{index}</MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {       row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 0
                          ? "NIL"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 1
                          ? "LOW"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                          == 2
                          ? "LOW"
                          : null}
                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 3
                          ? "LOW"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 4
                          ? "AVERAGE"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 5
                          ? "AVERAGE"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 6
                          ? "AVERAGE"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 7
                          ? "GOOD"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                          == 8
                          ? "GOOD"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 9
                          ? "GOOD"
                          : null}

                        {     row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                           == 10
                          ? "EXCELLENT"
                          : null}
                      </TableCell>
                      {/* <TableCell>
                        <TextField
                          // value={row?.Evaluations[0]?.description}
                          type="text"
                          // required
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          name="remarks"
                          // label="remarks"
                          onChange={(e) => onRemarks(e, index1)}
                        />
                      </TableCell> */}

                      {/* <Button onClick={() => AddEvaluation(index)}> add</Button> */}
                    </TableRow>
                  ))}
                </TableBody>
                <Grid
                  container
                  spacing={6}
                  sx={{
                    color: "text.primary",
                    marginTop: "10px",
                    marginBottom: "15px",
                    marginLeft: "90%",
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {adding?.length >= 1 ? (
                      <TextField
                        required
                        value={adding?.reduce((a, b) => {
                          return (a || 0) + (b || 0);
                        })}
                        disabled={true}
                        type="text"
                        fullWidth
                        label="Total Marks"
                      />
                    ) : (
                      <TextField
                        required
                        value={0}
                        disabled={true}
                        type="text"
                        fullWidth
                        label="Total Marks"
                      />
                    )}

                    <br />
                  </Grid>
                </Grid>
              </Table>

              <br />
              <br />

              <Table aria-label="Simple Table">
                <TableHead style={{ background: "#4DB1C3", border: "none" }}>
                  {criteria2 ? (
                    <TableRow style={{ height: 10 }}>
                      <TableCell>
                        <center>Parameters</center>
                      </TableCell>
                      <TableCell>
                        <center>Evaluation Criteria</center>
                      </TableCell>
                      <TableCell>
                        <center>Marks</center>
                      </TableCell>
                      {/* <TableCell>
                        <center>Remarks</center>
                      </TableCell> */}
                    </TableRow>
                  ) : null}
                </TableHead>
                <TableBody>
                  {criteria2?.map((row, index1) => (
                    // rows?.Evaluations?.map((rowing,index)=>(
                    //   console.log(rowing)
                    //     )),

                    <TableRow
                      key={index1}
                      sx={{
                        "&:last-child td , &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{row?.question}</TableCell>
                      <TableCell>
                        {row?.id == 6 ? (
                          <p>
                            (0-5) marks <br />
                            LessThan 1 lakh - 0 marks 1-10 lakh – (1-2) marks
                            <br /> 11-20 lakh–(3-4) marks MoreThan 20 lakh-
                            (4-5) marks
                            <br />{" "}
                          </p>
                        ) : null}

                        {row?.id == 7 ? (
                          <p>
                            (0-2) marks
                            <br /> (Ratio of Turnover/ CAPEX) (Greater the
                            ratio, more the marks)
                          </p>
                        ) : null}

                        {row?.id == 8 ? (
                          <p>
                            (0-2) marks
                            <br /> (Ratio of Turnover/ OPEX) Greater the ratio,
                            more the marks
                          </p>
                        ) : null}

                        {row?.id == 9 ? (
                          <p>
                            (0-2) marks
                            <br /> (Ratio of Turnover/ Resource Expense) Greater
                            the ratio, more the marks
                          </p>
                        ) : null}

                        {row?.id == 10 ? (
                          <p>
                            (0-2) marks <br />
                            (Ratio of Turnover/ Activity Expense) Greater the
                            ratio, more the marks
                          </p>
                        ) : null}

                        {row?.id == 11 ? (
                          <p>
                            (0-2) marks
                            <br /> Earlier the breakeven more the marks
                          </p>
                        ) : null}

                        {row?.id == 12 ? (
                          <p>
                            (0-2) marks
                            <br /> More the RoI more the marks
                          </p>
                        ) : null}

                        {row?.id == 13 ? (
                          <p>
                            (0-2) marks
                            <br /> Greater the turnover, more the marks
                          </p>
                        ) : null}

                        {row?.id == 14 ? (
                          <p>
                            (0-2) marks <br />
                            Greater the turnover, more the marks
                          </p>
                        ) : null}

                        {row?.id == 15 ? (
                          <p>
                            (0-2) marks
                            <br /> Greater the turnover, more the marks
                          </p>
                        ) : null}

                        {row?.id == 16 ? (
                          <p>
                            (0-2) marks
                            <br /> Greater the Profit, more the marks
                          </p>
                        ) : null}

                        {row?.id == 17 ? <p>(0-2) marks</p> : null}

                        {row?.id == 18 ? <p>(0-2) marks</p> : null}

                        {row?.id == 19 ? <p>(0-2) marks</p> : null}

                        {row?.id == 20 ? <p>(0-2) marks</p> : null}

                        {row?.id == 21 ? <p>(0-2) marks</p> : null}

                        {row?.id == 22 ? <p>(0-2) marks</p> : null}

                        {row?.id == 23 ? <p>(0-2) marks</p> : null}

                        {row?.id == 24 ? <p>(0-2) marks</p> : null}

                        {row?.id == 25 ? <p>(0-2) marks</p> : null}

                        {row?.id == 26 ? <p>(0-2) marks</p> : null}

                        {row?.id == 27 ? (
                          <p>
                            (0-5) marks 0 - 0 marks <br />
                            1-3 – (1-2) marks
                            <br />
                            4-6- (3-4) marks
                            <br />
                            Morethan 7- (4-5) marks
                            <br />
                          </p>
                        ) : null}

                        {row?.id == 28 ? (
                          <p>
                            (0-10) marks Lessthan 5- 0 marks <br />
                            5-10 – (1-2) marks
                            <br />
                            11-25- (3-5) marks
                            <br />
                            26-50- (6-8) marks
                            <br />
                            Morethan 50- (9-10) marks
                          </p>
                        ) : null}

                        {row?.id == 29 ? (
                          <p>
                            (0-15 marks) 0 Lakh- 0 marks
                            <br />
                            1-10 Lakh- (1-5) marks
                            <br />
                            11-25 Lakh- (6-9) marks
                            <br />
                            26-50 Lakh- (10-12) marks
                            <br />
                            Morethan 50 Lakh- (13-15) marks <br />
                          </p>
                        ) : null}

                        {row?.id == 30 ? (
                          <p>
                            (0-20 marks) <br />
                            (For skill set- 10 marks For certification – 10
                            marks)
                          </p>
                        ) : null}

                        {row?.id == 31 ? (
                          <p>
                            (0-5)- marks
                            <br />
                            If applied for patent- 2 marks
                            <br />
                            If availed patent – another 3 marks
                            <br />
                            No – 0 marks
                            <br />
                          </p>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Marks
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.Evaluations
                              .filter(item => item.evaluatedById == profile?.id)
                              .reduce((acc, cur) => cur.marks, 0)
                          
                            }
                            name="Marks"
                            label="Marks"
                            onChange={(e) => onValue2(e, index1)}
                            required
                            color="warning"
                          >
                            {[...Array(row?.maxValue + 1)].map(
                              (elementInArray, index) => (
                                <MenuItem value={index}>{index}</MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {/* <TableCell>
                        <TextField
                          type="text"
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          name="remarks"
                          label="remarks"
                          onChange={(e) => onRemarks2(e, index1)}
                        />
                      </TableCell> */}

                      {/* <Button onClick={() => AddEvaluation(index)}> add</Button> */}
                    </TableRow>
                  ))}
                </TableBody>

                <Grid
                  container
                  spacing={6}
                  sx={{
                    color: "text.primary",
                    marginTop: "10px",
                    marginBottom: "15px",
                    marginLeft: "70%",
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {adding2.length >= 1 ? (
                      <TextField
                        required
                        value={adding2?.reduce((a, b) => {
                          return (a || 0) + (b || 0);
                        })}
                        disabled={true}
                        type="text"
                        fullWidth
                        label="Total Marks"
                      />
                    ) : (
                      <TextField
                        required
                        value={"Calculating"}
                        disabled={true}
                        type="text"
                        fullWidth
                        label="Total Marks"
                      />
                    )}

                    <br />
                  </Grid>
                </Grid>
              </Table>
            </TableContainer>
            <Grid
              container
              spacing={6}
              sx={{ color: "text.primary", marginTop: "10px" }}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* 
                <TextField
                  required
                  value={totalMarks}
                  disabled={true}
                  type="text"
                  fullWidth
                  label="Total Marks"
                /> */}

                <br />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    required
                    Value={CategoryId}
                    onChange={(e) => onChangeCategory(e)}
                  >
                    {options2.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  value={reamarksSubmit}
                  type="text"
                  fullWidth
                  label="Remarks"
                  onChange={(e) => onRemarksSubmit(e)}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: "20px" }}>
              <center>
                <Card
                  style={{
                    width: "20%",
                    background: "Red",
                    marginBottom: "20px",
                  }}
                >
                  {updateEvaluation == true ? (
                    <>
                      {/* <Button
                      style={{ color: "White", fontWeight: "bold" }}
                      disabled={true}
                      onClick={() => sumbitApplication()}
                    >
                      Final Submit
                    </Button> */}

                      <Button
                        style={{
                          backgroundColor: "#EB4747",
                          width: "80%",
                          marginBottm: "50px",
                        }}
                        className="printing"
                        variant="contained"
                        fullWidth
                        onClick={Print}
                      >
                        Print Evaluated Sheet
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ color: "White", fontWeight: "bold" }}
                      onClick={() => sumbitApplication()}
                      type="submit"
                    >
                      Final Submit
                    </Button>
                  )}
                </Card>
              </center>
            </div>
          </Box>
        </Box>
      </div>  
    </>
  );
};

export default Evaluation;
