import { createStore, combineReducers} from 'redux';
import {flag,flag2,getMarks,getRegId} from './reducers.js';
 
const rootReducer = combineReducers({
  recognition: flag,
  newData : flag2,
  MarksSubmitted:getMarks,
  AcknowledgementId:getRegId
});
 
export const store = createStore(rootReducer);