import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import MeetingInfo from './meetingInfo'

// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const MeetingDetails = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <MeetingInfo/>
        
      
      </div>

    </>
  )
}

export default MeetingDetails










