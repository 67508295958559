import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import './styles.css'
import bsft_role from './bsft_role.png'
import img6 from './img6.png'
const BSFT = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5">
          <h3 className="text-start fw-bold">Bihar Start-up Fund Trust (BSFT)</h3>
          <h5 className="text-start">The Bihar Start-up Fund Trust is an autonomous body constituted under the
            Chairmanship of Development Commissioner, Government of Bihar.</h5>
          <div className="row aligned-center">
            <div className="col-sm-7">
              <img src={img6} className="w-100" />
            </div>
            <div className="col-sm-5">
              <img src={bsft_role} className="w-100" />
            </div>
          </div>          
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default BSFT;
