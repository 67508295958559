import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "../../../src/Style/Auth.css";
import { getToken } from "../../apiCalls/functions"
import Address from "./basic/address"
import Others from "./basic/others"
import { actionNotifier } from "../notification"
import { loaderStart, loaderEnd } from "../loader"
import { getAllDropdown } from "./basic/actions"
import { REACT_APP_API_URL } from "../../shared/constant";

const initialValue1 = {
    panCardNo: ""
};

const initialValue2 = {
    "addressLine1": "",
    "addressLine2": "",
    "cityId": "",
    stateId: "",
    "districtId": "",
    "pincode": null,
    "addressType": "permanent"
};

const initialValue3 = {
    firstName: "",
    mobileNumber: "",
    email: "",
    adhaarNo: "",
};

const BasicDetails = () => {
    const navigate = useNavigate()
    //post api for add details
    const [basicDetails, setBasicDetails] = useState(initialValue1);
    const [addressDetails, setAddressDetails] = useState(initialValue2);
    //get api for auto fill details
    const [autoFillDeatils, setAutoFillDeatils] = useState(initialValue3);
    //get api for show data
    const [update, setUpdate] = useState(null);
    const [token, setToken] = useState(null)
    const [dropdown, setDropdown] = useState({})

    // form onSubmit check
    const onSubmitData = (e) => {
        navigate("/incubator/main/incubatorDetails")
    };

    const onValue = (e) => {
        console.log(e.target.value);
        setBasicDetails({ ...basicDetails, [e.target.name]: e.target.value });
    };
    const onValueFile = (e) => {
        // let data=e.target.files[0]
        console.log(e.target.files[0]);
        setBasicDetails({ ...basicDetails, [e.target.name]: e.target.files[0] });
        // console.log(basicDetails, "helllooo");
    };



    return (
        <Box className='main_box_incubator'>
            <Box className='sub_box_incubator'>
                <Typography className='box_heading_incubator' sx={{ fontWeight: "bold", fontSize: 20 }} >Basic Details</Typography>
                {/* <br /> */}
                <br />
                <form onSubmit={onSubmitData}>
                    <Grid container spacing={2} sx={{ color: 'text.primary' }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                type="text"
                                fullWidth
                                label="Name of personnel"
                                name="firstName"
                                value={autoFillDeatils.firstName}
                                disabled
                            // onChange={(e) => onValue(e)}
                            />
                        </Grid>
                    </Grid>

                    <div className="">
                        <br />
                        <TextField
                            fullWidth
                            disabled
                            label="Mobile No*"
                            id="fullWidth"
                            variant="outlined"

                            value={autoFillDeatils.mobileNumber}
                            name="mobileNumber"
                            onChange={(e) => onValue(e)}
                        />
                        <br />
                        <br />
                        <TextField
                            fullWidth
                            disabled
                            label="Email*"
                            id="fullWidth"
                            variant="outlined"

                            value={autoFillDeatils.email}
                            name="email"
                            onChange={(e) => onValue(e)}
                        />
                        <br />
                        <br />
                        <TextField
                            fullWidth
                            label="Aadhar No.*"
                            id="fullWidth"
                            variant="outlined"
                            type="number"

                            disabled
                            value={autoFillDeatils.adhaarNo}
                            name="adhaarNo"
                            onChange={(e) => onValue(e)}
                        />
                        <br />
                        <br />
                        <TextField
                            fullWidth
                            label="PAN No"
                            id="fullWidth"
                            variant="outlined"
                            type="text"
                            // inputProps={{ pattern: "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/" }}
                            // inputProps: {{pattern: '[a-z]'}}
                            value={basicDetails.panCardNo}
                            name="panCardNo"
                            onChange={(e) => onValue(e)}

                        />
                        <br />
                        <br />
                        <TextField
                            fullWidth
                            label="Incubator Name"
                            id="fullWidth"
                            variant="outlined"
                            type="text"
                            // inputProps={{ pattern: "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/" }}
                            // inputProps: {{pattern: '[a-z]'}}
                            value={basicDetails.panCardNo}
                            name="panCardNo"
                            onChange={(e) => onValue(e)}

                        /><br /><br />
                        <Address
                            basicDetails={addressDetails}
                            setBasicDetails={setAddressDetails}
                            token={token}
                            onValue={onValue}
                        />
                        <br />
                        <Button
                            className="btn_class"
                            variant="contained"
                            fullWidth
                            type={"submit"}
                        >
                            Submit
                        </Button>

                    </div>
                </form>
            </Box>
            <Box className="page_design_develop_incu" style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>
                    Designed & developed by Codebucket Solutions Pvt. Ltd.
                </Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022 Department of Industries Government of Bihar
                </Typography>
            </Box>
        </Box >
    );
};

export default BasicDetails;
