import React, { useState, useEffect } from 'react';
import { Dialog, DialogContentText, Box, TextField, Stack, MenuItem, Button, Grid, Typography } from '@mui/material';
// import { useNavigate } from "react-router-dom"
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
import { actionNotifier } from "../notification"
import { FILE_URL } from "../../shared/constant"
import { loaderStart, loaderEnd } from "../loader"

import "../../../src/Style/Auth.css";


import { addUpdateTeam } from './action';

function NumberOFFounders(props) {

	const [state, setState] = useState({
		name: "",
		aadharNo: "",
		industryExperience: "",
		categoryId: "",
		genderId: "",
		isDifferentlyAbled: "no",
		panCardNo: "",
		panUpload: "",
		qaulification: "",
		finance: "test",
		market: "test",
		technical: "test",
		aadharUpload: "",
		dateOfBirth: "",
		qaulificationUpload: "",
		industryExperienceUpload: "",
	})

	useEffect(() => {
		// console.log(props)
		if (props.data) {
			setState({
				name: props.data.name,
				aadharNo: props.data.aadharNo,
				industryExperience: props.data.industryExperience,
				categoryId: props.data.categoryId,
				genderId: props.data.genderId,
				isDifferentlyAbled: props.data.isDifferentlyAbled,
				panCardNo: props.data.panCardNo,
				panUploadUrl: props.data.panUpload,
				qaulification: props.data.qaulification,
				finance: props.data.finance,
				market: props.data.market,
				technical: props.data.technical,
				aadharUploadUrl: props.data.aadharUpload,
				dateOfBirth: props.data.dateOfBirth.split("T")[0],
				qaulificationUploadUrl: props.data.qaulificationUpload,
				industryExperienceUpload: ""
			})
		}
	}, [props.data])

	const handleChange = (e, name, type) => {
		// console.log(e, name, type)
		let checkbox = false;
		let file = "";
		if (type == "checkbox") {
			if (e.target.checked) {
				checkbox = true;
			}
		}
		if (type == "file") {
			file = e.target.files[0]
		}
		let _state = { ...state }
		if (type == "checkbox") {
			// console.log(_state[name], checkbox)
			_state[name] = checkbox
			// console.log(_state[name], checkbox)
		}
		else if (type == "file")
			_state[name] = file
		else
			_state[name] = e.target.value

		setState(_state)
	}

	const onSubmit = async (event) => {
		loaderStart()
		event.preventDefault();
		let res = await addUpdateTeam(state, props.selectedId ? props.selectedId : null)
		if (res.status) {
			props.handleClose(true)
		}
		else if (res.message == "\nInvalid `.create()` invocation in\n/home/startup-bihar/test/startup-bihar-backend/src/services/user/team.service.js:142:8\n\n  139   ['yes', 'no'],\n  140 );\n  141 const data = await prismaModel.team\n→ 142   .create(\n  Foreign key constraint failed on the field: `accountId`") {
			actionNotifier.error("Please Fill Basic Details Form correctly")
		}
		else if ((res.message).includes("is not a valid adhaar number")) {
			actionNotifier.error("Please enter valid Aadhar Number")
		}

		else if ((res.message).includes("Invalid value for panCardNo")) {
			actionNotifier.error("Please enter valid Pan Card No")
		}

		else if (res.message == "File size for panUpload is too large. Max size is 5 MB") {
			actionNotifier.error("File size for Pan Card is too large. Max size is 5 MB")
		}
		else if (res.message == "File size for aadharUpload is too large. Max size is 5 MB") {
			actionNotifier.error("File size for Aadhar Card is too large. Max size is 5 MB")
		}
		else if (res.message == "File size for qaulificationUpload is too large. Max size is 5 MB") {
			actionNotifier.error("File size for Qualification Upload is too large. Max size is 5 MB")
		}
		else {
			console.log(res.message)
			actionNotifier.error("Please Fill all required fields")
		}
		loaderEnd()
	};

	return (
		<form onSubmit={onSubmit}>
			<Box className='formFounder' sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
				<br />
				<TextField
					type="text" label="Name"
					value={state?.name}
					required
					onChange={(e) => handleChange(e, "name", null)}
				/>
				<br />
				<Grid container spacing={2} sx={{ color: 'text.primary' }}>
					<Grid item xs={6}>
						<TextField
							type="number"
							// label={" "}
							fullWidth
							label="Aadhar No"
							onInput={(e) => {
								e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
							}}
							value={state?.aadharNo}
							required
							onChange={(e) => handleChange(e, "aadharNo", null)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							onChange={(e) => handleChange(e, "aadharUpload", "file")}
							fullWidth
							label={" "}
							type="file"
							onInput={(e) => {
								e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
							}}
							inputProps={{ accept: "application/pdf" }}
							required={state.aadharUploadUrl ? false : true}
						/>
						{
							state.aadharUploadUrl
								?
								<div className="view-file" >
									<a target="_blank" rel="noreferrer" href={state?.aadharUploadUrl?.includes("https") ? state?.aadharUploadUrl : FILE_URL + state?.aadharUploadUrl}>
										View File
									</a>
								</div>
								: null

						}
					</Grid>
				</Grid>
				<br />
				<Grid container spacing={2} sx={{ color: 'text.primary' }}>
					<Grid item xs={6}>
						<TextField
							required
							value={state?.panCardNo}
							// onInput={(e) => {
							// 	e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
							// }}
							onChange={(e) => handleChange(e, "panCardNo", null)}
							type="text" fullWidth label="Pan No" />
					</Grid>
					<Grid item xs={6}>
						{/* <UploadFileIcon type="file" /> */}
						<TextField
							onChange={(e) => handleChange(e, "panUpload", "file")}
							fullWidth label={" "} type="file"
							inputProps={{ accept: "application/pdf" }}
							required={state?.panUploadUrl ? false : true}
						/>
						{
							state.panUploadUrl
								?
								<div className="view-file" >
									<a target="_blank" rel="noreferrer" href={state.panUploadUrl.includes("https") ? state?.panUploadUrl : FILE_URL + state.panUploadUrl}>
										View File
									</a>
								</div>
								: null

						}
					</Grid>
				</Grid>
				<br />
				<TextField
					type="date" label="Date of Birth"
					required
					InputLabelProps={{ shrink: true, required: true }}
					value={state?.dateOfBirth}
					onChange={(e) => handleChange(e, "dateOfBirth", null)}
				/>
				<br />
				<TextField

					label="Industry Experience (In Years)" fullWidth
					type="number"
					required
					value={state?.industryExperience}
					onChange={(e) => handleChange(e, "industryExperience", null)}
				/>
				<br />
				<Grid container spacing={2} sx={{ color: 'text.primary' }}>
					<Grid item xs={6}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Qualification*</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Qualification"
								required
								value={state.qaulification}
								onChange={(e) => handleChange(e, "qaulification", null)}
							>
								{props.dropdown?.education?.map((option) => (
									<MenuItem key={option.name} value={option.name}>
										{option.name}
									</MenuItem>
								))}
							</Select>

						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label={" "} type="file"
							onChange={(e) => handleChange(e, "qaulificationUpload", "file")}
							inputProps={{ accept: "application/pdf" }}
							required={state?.qaulificationUploadUrl ? false : true}
						/>
						{
							state.qaulificationUploadUrl
								?
								<div className="view-file" >
									<a target="_blank" rel="noreferrer" href={state?.qaulificationUploadUrl?.includes("https") ? state?.qaulificationUploadUrl : FILE_URL + state?.qaulificationUploadUrl}>
										View File
									</a>
								</div>
								: null

						}
					</Grid>
				</Grid>
				<br />
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Category*</InputLabel>
					<Select

						labelId="demo-simple-select-label"
						id="demo-simple-select"
						required
						label="Category*"
						value={state.categoryId}
						onChange={(e) => handleChange(e, "categoryId", null)}
					>
						{props.dropdown?.category?.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label1">Gender*</InputLabel>
					<Select

						labelId="demo-simple-select-label1"
						id="demo-simple-select1"
						required
						label="Gender*"
						value={state.genderId}
						onChange={(e) => handleChange(e, "genderId", null)}
					>
						{props.dropdown?.gender?.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label1">Is Differently Abled*</InputLabel>
					<Select

						labelId="demo-simple-select-label1"
						id="demo-simple-select1"
						required
						label="Is Differently Abled*"
						value={state.isDifferentlyAbled} onChange={(e) => handleChange(e, "isDifferentlyAbled", null)}
					>
						<MenuItem value="yes">Yes</MenuItem>
						<MenuItem value="no">No</MenuItem>
					</Select>
				</FormControl>
				<br />
				{/* <br /> */}
				<Button type="submit" style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth  >Submit</Button>
			</Box>
		</form>
	);
}

export default NumberOFFounders;
