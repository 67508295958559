import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import bene1 from './bene1.png'
import bene2 from './bene2.png'
import additional2 from './additional2.png'
import './styles.css'
const BenefitsSupport = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5">
          <div className="row aligned-center">
            <div className="col-sm-12">
              <h2 className="text-start fw-bold">Benefits and Funding Support</h2>
              <h5 className="text-start">Funding support shall be provided to Start-ups in various stages of its business cycle</h5>
              <hr />
            </div>
            <div className="col-sm-6">
              <div className="img-box2 p-5 mt-5">
                <p style={{ textAlign: "justify" }}>
                  <h5>Validation Stage</h5>
                  <span>
                    A Seed Grant of up to Rs 10 Lakhs per
                    Start-up as interest free loan for 10 years
                    shall be provided to start-ups.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-5 text-end">
              <img src={bene2} className="w-100" />
            </div>
            <div className="col-sm-12">

              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-3">Commercialization Stage</h3>
                  <div className="img-box2">
                    <h5>Early Stage Funding Support</h5>
                    <p style={{ textAlign: "justify" }}>
                      <ul className="ul_style">
                        <li>Free of cost Valuation for Start-ups.</li>
                        <li>Facilitate access to Angel Investors.</li>
                        <li>A success fee @2% of investment shall be provided to start-ups for mobilizing investments from state registered angel investors towards early stage funding.</li>
                        <li>Matching Support: A financial assistance up to an amount equal to the amount invested in start-ups by the Angel Groups and Category- I Alternate Investment Funds may be given as loan to the Start-up. The rate of interest and terms and conditions of the loan shall be notified by the SMIC from time to time.</li>
                        <li>Assistance to enrol and participate for Acceleration Program: Assistance up to Rs. 3 Lakhs per Start-upto enrol and participate for National/ International recognized acceleration program. The fees/charges paid for attending the same will be considered eligible for getting assistance.</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="img-box2">
                    <h5>Scale-up Funding Support</h5>
                    <p style={{ textAlign: "justify" }}>
                      <ul className="ul_style">
                        <li>The SMIC may participate in SEBI registered AIFs (Alternate Investment Funds) and Venture Capital Funds. The Venture Capital Fund so created shall invest at least 50% (i.e. equal to twice the contribution made by trust in the VC Fund) in start-ups located in Bihar.</li>
                        <li>SMIC shall facilitate listing and public issue of Start-ups in collaboration with National Level Stock Exchanges such as BSE/ NSE.</li>
                      </ul>
                    </p>
                  </div>
                  <br />
                  <div className="img-box2">
                    <h5>Zero Cost for filing patents - Domestic and Foreign</h5>
                    <p style={{ textAlign: "justify", color: "black" }}>
                      Intellectual Property (IP) is an invaluable business tool for start-ups,
                      or entrepreneurs, providing initial start to one's business.
                      Investors weigh IPs heavily and create
                      good value for venture. To encourage the
                      registration of IPRs (Intellectual Property
                      Right) by Start-ups
                      and provide facilitation support for
                      protection and commercialization of IPRs,
                      Government.

                      <ul className="ul_style">
                        <li>Bear all costs associated towards filing domestic patents.</li>
                        <li>Provide additional financial assistance in form of reimbursement for awarded foreign patents towards filing fees.</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-sm-12">
                  <h5 className="text-start"><b>Additional incentives to SC/ST /Women /Differently abled led Startups</b></h5>
                  <hr />
                </div>
                <div className="col-sm-6 text-end">
                  <img src={additional2} className="w-100" />
                </div>
                <div className="col-sm-6">
                  <div className="img-box2" style={{ marginTop: "120px" }}>
                    <h3>22% of the total corpus for funding
                      start-ups shall be reserved for SC/ST
                      beneficiaries.</h3>
                  </div>
                </div>
              </div>
              <div className="row d-flex flex-row-reverse aligned-center mt-3">
                <div className="col-sm-6">
                  <img src={bene1} className="w-100" />
                </div>
                <div className="col-sm-6">
                  <div className="img-box2" style={{ marginTop: "70px" }}>
                    <h3>Based upon the requirement, the BSFT may
                      relax the eligibility criteria, for certifying startups belonging to SC/ST/ women/differently
                      abled.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default BenefitsSupport;
