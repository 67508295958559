import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography, RadioGroup, FormControlLabel, Radio, makeStyles } from '@mui/material';
import RadioButtonsGroup from "./Radio-Button";
import axios from "axios";
import { getToken } from "../apiCalls/functions"
import { actionNotifier } from "./notification"
import { FILE_URL, REACT_APP_API_URL } from "../shared/constant"
import ViewFile from "./teamDetails/files"
import { useNavigate, Link } from "react-router-dom";
import { loaderStart, loaderEnd } from "./loader"
import EnglishForm from '../assets/pdf/Form I-IV in English.pdf'
import HindiForm from '../assets/pdf/Form I-IV in Hindi.pdf'


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '30%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));



const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#EB4747',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#EB4747',
    },
});

// Inspired by blue printjs
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const initialValue = {
    // revenueGeneration: "",
    // returnOnInvestment: "",
    // valueProposition: "",
    // customerSegment: "",
    // costStructure: "",
    // finance: "",
    // marketing: "",
    // technical: "",
    // domainExpert: "",
    isOfficeInBihar: "yes",
    addressLine1: "",
    addressLine2: "",
    districtId: "",
    cityId: "",
    pincode: "",
    form1: "",
    form2: "",
}


const BusinessModel = () => {
    const navigate = useNavigate()
    const [businessModel, setBusinessModel] = useState(initialValue);
    const [getData, setGetData] = useState([]);
    const [show, setShow] = useState(false);
    const [token, setToken] = useState(null)
    const [update, setUpdate] = useState(null);


    //get api for districts
    const [districts, setDistricts] = useState([{ label: "", value: "1" }]);

    //get api for Cites
    const [Cityes, setCity] = useState([{ label: "", value: "1" }]);
    useEffect(() => {
        let token = getToken()
        setToken(token)
        DistrictsApi(5, token)
        getBusinessModel(token);
    }, [])

    const onValue = (e) => {
        console.log(e.target.value);
        if (e.target.name == "districtId") {
            CitiesApi(e.target.value, token)
        }
        setBusinessModel({ ...businessModel, [e.target.name]: e.target.value })
        console.log(businessModel);
    }
    const onValueFile = (e) => {
        // let data=e.target.files[0]
        console.log(e.target.files[0]);
        setBusinessModel({ ...businessModel, [e.target.name]: e.target.files[0] })
        console.log(businessModel, "helllooo");
    }
    // form onSubmit check
    const onSubmitData = (e) => {
        e.preventDefault()
        if (update) {
            // alert("Put api called here")
            updateBusinessModel()
        }
        else {
            // alert("Post api called here")
            addBusinessModel()
        }
    }
    //get api for districts Name
    const DistrictsApi = (id, token) => {
        axios
            .get(
                `${REACT_APP_API_URL}/data/state/${id}/district`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => response.data)
            .then((data) => {
                setDistricts(data);
                // console.log(data, "district");
            });
    };

    //get api for  cities name
    const CitiesApi = (id, token) => {
        axios
            .get(
                `${REACT_APP_API_URL}/data/district/${id}/city`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => response.data)
            .then((data) => {
                setCity(data);
                // console.log(data, "citiy");
            });
    };
    // put api call for business model
    const updateBusinessModel = async () => {
        loaderStart()
        let _businessModel = { ...businessModel }
        _businessModel.isOfficeInBihar = show ? "yes" : "no"
        var form_data
        form_data = new FormData();
        for (var key in _businessModel) {
            form_data.append(key, _businessModel[key]);
        }
        await axios.put(`${REACT_APP_API_URL}/business-model/`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate("/main/turnoverAndEmployment")
            })
            .catch(error => {
                let _err = error.response.data
                if (_err.message == "is not a valid  pincode") {
                    actionNotifier.error("Please Enter a Valid Pin code")
                }
                actionNotifier.error(_err.message)
            })
        loaderEnd()

    }

    // Post api call for business model
    const addBusinessModel = async () => {
        loaderStart()
        let _businessModel = { ...businessModel }
        _businessModel.isOfficeInBihar = show ? "yes" : "no"
        var form_data
        form_data = new FormData();
        for (var key in _businessModel) {
            form_data.append(key, _businessModel[key]);
        }
        await axios.post(`${REACT_APP_API_URL}/business-model`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate("/main/turnoverAndEmployment")
            })
            .catch(error => {
                let _err = error.response.data
                // actionNotifier.error(_err.message)
                if (_err.message == "\nInvalid `.create()` invocation in\n/home/startup-bihar/test/startup-bihar-backend/src/services/user/business-model.service.js:119:8\n\n  116 ]);\n  117 \n  118 const data = await prismaModel.bussinessModel\n→ 119   .create(\n  Foreign key constraint failed on the field: `accountId`") {
                    actionNotifier.error("Please Fill Basic Details and all the Previous Forms correctly")
                } else if (_err.message == "File size for form1 is too large. Max size is 5 MB") {
                    actionNotifier.error("File size for form1 is too large. Max size is 5 MB")
                }
                else if (_err.message == "File size for form2 is too large. Max size is 5 MB") {
                    actionNotifier.error("File size for form2 is too large. Max size is 5 MB")
                }
                else if (_err.message.includes("is not a valid  pincode")) {
                    actionNotifier.error("Please Enter a Valid Pin code")
                }
                else {
                    console.log(_err.message)
                    actionNotifier.error("Please Fill all required fields")
                }
            })
        loaderEnd()
    }

    const getBusinessModel = async (token) => {
        loaderStart()
        await axios.get(`${REACT_APP_API_URL}/business-model`,
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(result => {
                console.log(result.data)
                if (result.data.status) {
                    setBusinessModel({
                        addressLine1: result.data.data.addressLine1,
                        addressLine2: result.data.data.addressLine2,
                        form1Url: result.data.data.form1,
                        form2Url: result.data.data.form2,
                        cityId: result.data.data.cityId,
                        // cityId: result.data.data?.addresses[0]?.cityId,
                        districtId: result.data.data.districtId,
                        pincode: result.data.data.pincode
                    })
                    setShow(result.data.data.isOfficeInBihar == "yes" ? true : false)
                    setUpdate(true)
                    CitiesApi(result.data.data.districtId, token)
                    // console.log(result.data.data.district.name)
                }
            })
            .catch(error => {
                console.log(error);
            })
        loaderEnd()
    }

    return (
        <Box>
            <Box style={{ width: "694px", marginLeft: "43px" }}>
                <br />
                <form onSubmit={onSubmitData}>
                    <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "692px", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Business Models</Typography>
                    <br />
                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Do you have Office In Bihar?*</Typography>
                    {/* {/* <RadioButtonsGroup  /> */}
                    <Box>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel checked={show ? true : false} value={"yes"} control={<BpRadio />} label="Yes" onClick={() => setShow(true)} />
                            <FormControlLabel checked={!show ? true : false} value={"no"} control={<BpRadio />} label="No" onClick={() => setShow(false)} />
                        </RadioGroup>
                    </Box>
                    <br />
                    <div>
                        {
                            show &&
                            <div>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    // multiline
                                    // minRows={2}
                                    // maxRows={4}
                                    style={{ width: "695px" }}
                                    label={"Address Line 1"}
                                    name="addressLine1"
                                    defaultValue={businessModel.addressLine1}
                                    onChange={(e) => onValue(e)}
                                    required={businessModel.addressLine1 ? false : true}
                                />
                                <br />
                                <br />
                                <TextField
                                    id="outlined-multiline-flexible"
                                    // multiline
                                    // minRows={2}
                                    // maxRows={4}
                                    style={{ width: "695px" }}
                                    label={"Address Line 2"}
                                    name="addressLine2"
                                    defaultValue={businessModel.addressLine2}
                                    required={businessModel.addressLine2 ? false : true}
                                    onChange={(e) => onValue(e)}
                                />
                                <br />
                                <br />
                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                    <TextField
                                        select
                                        labelId="district-name"
                                        id="district-name"
                                        value={businessModel.districtId}
                                        fullWidth
                                        required
                                        label="District"
                                        name="districtId"
                                        onChange={(e) => onValue(e)}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {
                                            districts?.data?.map((val) => (
                                                <MenuItem key={val.name} value={val.id}>
                                                    {val.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <TextField
                                        select
                                        labelId="district-name"
                                        id="district-name"
                                        value={businessModel.cityId}
                                        fullWidth
                                        required
                                        label="City"
                                        name="cityId"
                                        onChange={(e) => onValue(e)}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {
                                            Cityes?.data?.map((option) => (
                                                <MenuItem key={option.name} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Box>
                                <br />
                                {/* <br /> */}

                                <TextField
                                    label="Pin code"
                                    color="warning"
                                    fullWidth
                                    required
                                    name="pincode"
                                    value={businessModel.pincode}
                                    onChange={(e) => onValue(e)}
                                />
                            </div>

                        }
                    </div>
                    <br />
                    <Typography style={{ textAlign: 'left' }} className='headLineLeft'><b>Please Fill the Form I for business model and upload .</b></Typography>
                    <br />
                    <div>
                        <a href={EnglishForm} download style={{ color: "blue", marginLeft: "10%" }}> <b>Download Form in English</b>  </a>
                        <a href={HindiForm} download style={{ color: "blue", marginLeft: "10%" }}> <b>Download Form in Hindi</b>  </a>
                    </div>
                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload Business Model (FORM I&#41;* (In Pdf Only)</Typography>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        inputProps={{ multiple: true, accept: "application/pdf" }}
                        type="file"
                        style={{ width: "100%" }}
                        label={" "}
                        name="form1"
                        required={businessModel.form1Url ? false : true}
                        onChange={(e) => onValueFile(e)}
                    />
                    <ViewFile file={businessModel?.form1Url} />

                    <br />
                    {/* <br /> */}

                    <Typography sx={{ width: "600px", display: "flex", justifyContent: "flex-start", fontWeight: "700", color: "#474747" }}>Team Skill Sets</Typography>
                    <br />
                    <Typography style={{ textAlign: 'left' }} className='headLineLeft'><b>Please Fill the Form II for Team Skill Set and upload.</b></Typography>
                    <br />
                    {/* <div>
                        <a href={download} download style={{ color: "blue", marginLeft: "10%" }}> <b>Please Fill the Form II for business model and upload .</b>  </a>
                        <p></p>
                    </div> */}

                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload  Team Skill Set (Form II&#41;* (In Pdf Only)</Typography>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        inputProps={{ multiple: true, accept: "application/pdf" }}
                        type="file"
                        style={{ width: "100%" }}
                        label={" "}
                        name="form2"
                        required={businessModel.form2Url ? false : true}
                        onChange={(e) => onValueFile(e)}
                        accept="application/pdf"
                    />
                    <ViewFile file={businessModel?.form2Url} />
                    <br />
                    <br />
                    <Button
                        style={{ backgroundColor: "#EB4747" }}
                        variant="contained"
                        fullWidth
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Box>
            {/* <Box className="page_design_develop" style={{ marginTop: "120px", marginLeft: -1250 }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>
                    Designed & developed by Codebucket Solutions Pvt. Ltd.
                </Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022 Department of Industries Government of Bihar
                </Typography>
            </Box> */}
        </Box >
    )
}

export default BusinessModel;