import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  TextareaAutosize,
  Typography,
  TextField,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getApplicationList,
  recommendedSelect,
  getPendingExpert,
  getSsuList,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  getExpertPendingCsv,
  getEvaluatedListCsvSsu,
  getAssignedApplicationDownloadedExcel,
  getAdminProfileDetail
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  AcknowledgementId
} from "../actions";
import {CSVLink, CSVDownload} from 'react-csv';


const inititalValue = {
appid:0,
boolean:"",
reamrks:""
};


const ExpertPending = (props) => {
  const dispatch = useDispatch();
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  const [state,setState]=useState(inititalValue)
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [dateSelector, setDateSelector] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [evaluateDetails, setEvaluateDetails] = useState();
  const [total, setTotal] = useState(0);
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [startDate, handleDateChange] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [modal, setModal] = useState(false);
  const [endDate, handleDateChange2] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [profile, setProfile] = useState("");
  const [isRecognition, setIsRecognition] = useState(false)
  const [filteredResults, setFilteredResults] = useState([]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [datesFil, setDatesFil] = useState(true);

  const [appId, setAppId] = useState();
  const [boolean, setBoolean] = useState('');
  const [remarks, setRemarks] = useState();
  const [categoryID, setCategoryID] = useState();

 


  const { id } = useParams();


  useEffect(() => {
    // getAllApplication();
    ssulist();
    // assigneeList();
    // GetAssignedList();
    getAdminProfile();

    // alert(newDataFlag);

  }, [page, rowsPerPage,isRecognition,flags,newDataFlag]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())

  useEffect(() => {
    try{
      if (search !== "All") {
  
        const result = SsuList
          ? SsuList?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()): 
         
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
          : assigneeTable?.rows?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()):
                 list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            });
            SsuList?
        setFilteredResults(result)
        :
        setFilteredResultsAasinTable(result);
      
    } else {
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
    }
    catch{
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
   
  }, [search]);




  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };

  const Categories = [


    {
        value: '1',
        label: 'A',
    },
    {
        value: '2',
        label: 'B',
    },
    {
      value: '3',
      label: 'C',
  },
  {
    value: '4',
    label: 'D',
},

];


// const headers = [
//   { label: 'RegistrationId', key: 'RegistrationId' },
//   { label: 'NameOfStartup', key: 'NameOfStartup' },
//   { label: 'Name', key: 'Name' },
//   { label: 'Phone', key: 'Phone' },
//   { label: 'AppliedInPolicy2017', key: 'AppliedInPolicy2017' },
//   { label: 'Assignee1', key: 'Assignee1' },
//   { label: 'Assignee2', key: 'Assignee2' },
//   { label: 'Category2', key: 'Category2' },
  
// ];
  
// const dataeXcel = filteredResults?.map(item => ({
//   RegistrationId: item?.account?.acknowledgement?.acknowledgmentNo,
//   NameOfStartup: item?.account?.nameOfStartUp,
//   Name:item?.account?.firstName + "  " + item?.account?.lastName,
//   Phone:item?.account?.mobileNumber,
//   AppliedInPolicy2017: item?.account?.StartUpDocuments ? "Yes" : "No",
//   Assignee1:item?.assignedTo1?.name,
//   Category1:item?.applicationCategory?.name,
//   Assignee2:item?.assignedTo2?.name,
//   Category2:item?.applicationCategory2?.name,


//   // Names: item.suggestedRoles.map(role => role.name),
// }))

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      setProfile(data?.data);
      console.log(data.data)
    }
  };


  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

 
     {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
      width: "150px",
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
   
    {
      name: "Category1",
      selector: (row) => row?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },



    {
      name: "Assignee2.",
      cell: (row) => row?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,
    },

    {
      name: "Category2",
      selector: (row) => row?.applicationCategory2?.name,
      width: "139px",
      sortable: true,
    },



   


    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Select Category",
      cell: (row) =>  
      <TextField
      key = "Category"
            style={{ textAlign: 'left' }}
          id="outlined-basic" type="select"
          select
          label="Category"
          variant="outlined"
          fullWidth
          required
          // value={state?.isDifferentlyAbled}
          name="Category"
          onChange={(e) => {
              setCategoryID(Number(e.target.value))
              
          }
              }
          
      >
              {Categories?.map((option) => (
              <MenuItem className='' key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
          ))}

      </TextField> 

     ,
      width: "160px",
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) =>
        
         
            <Button  variant="outlined" onClick={ () => {
              setAppId(row?.id);
              setBoolean(true)
              // setModal(true)
              recommendedSend(row?.id,true);
            }           }>
             
              Recommend
            </Button>
       
       ,
      width: "180px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Action",
      selector: (row) =>
        
          
            <Button variant="outlined" onClick={ () => {
              
              setAppId(row?.id);
              setBoolean(false)
              setModal(true)
            } }>
              
              Not Recommend
            </Button>
          
       ,
      width: "200px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
  ];
  



  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      // console.log(data.data)
      setAssignee(data);
    }
  };




  const downloadAssignedTableCsv = async () => {
    if(flags == true){

      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
  
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
      
      };

  const downloadAssignedTableCsvSsu = async () => {

    if(flags == true){
      loaderStart();
      let data1 = {
        categories: ["a", "b"],
        isRecognition:true
      };
  
      const data = newDataFlag == true ?  await getExpertPendingCsv({round:2},data1) :
      await getExpertPendingCsv({round:1},data1) 

  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b"],
        isRecognition:false
      };
  
      const data = newDataFlag == true ?  await getExpertPendingCsv({round:2},data1) :
      await getExpertPendingCsv({round:1},data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
   
  };



  const ssulist = async () => {

    if(flags == true){
      let data1 = {
        categories: ["a", "b"],
        isRecognition:true
      };
  
      let ssulist = newDataFlag == true ? await getPendingExpert({
        round:2
      },data1) :
      await getPendingExpert({
        round:1
      },data1)
      // console.log(categoryMenu.categories)
      setSsuList(ssulist?.data);
      setFilteredResults(ssulist?.data);
      setSSuTotal(ssulist?.data);
    }else{
      let data1 = {
        categories: ["a", "b"],
        isRecognition:false
      };
  
      let ssulist = newDataFlag == true ? await getPendingExpert({round:2},data1) :
      await getPendingExpert({round:1},data1)
      console.log(ssulist)
      setSsuList(ssulist?.data);
      setFilteredResults(ssulist?.data);
      setSSuTotal(ssulist?.data);
    }
 
    
  };


  const recommendedSend = async (id,status,remarks)=>{

    if(!categoryID){
      actionNotifier.error('Please Select Category')
    } else if (categoryID == 3 && (status).toString() == 'true'  ){
      actionNotifier.error('Recommend is Not Valid. Please Select Not Recommend')
    }
    else if (categoryID == 4 && (status).toString() == 'true'  ){
      actionNotifier.error('Recommend is Not Valid. Please Select Not Recommend')
    }
    
    
    else{
     
      console.log(categoryID)
      let val = {

        applicationId: appId ? appId : id ,
        recommended:boolean ? (boolean).toString() : (status).toString(),
        remarks: boolean == false ? remarks : '',
        recommendedById:profile?.id,
        categoryId:categoryID
      }
  
      let data = await recommendedSelect(val)
  
      if(data.status){
  
        actionNotifier.success("Application Submitted")
        ssulist();
        setModal(false);
      }else{
        actionNotifier.error("Application Already Submitted")
        ssulist();
        setModal(false);
      }
    }
    
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const GetAssignedList = async () => {
    loaderStart();
    if(flags == true){
      let val = {
       
        isRecognition:true
        }
        
      let data = 
      newDataFlag == true ? await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:2
      },val) :
      await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:1
        
      },val)
    
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        // console.log(data.data);
        
      }
    }else{
      let val = {
       
        isRecognition:false
        }
        let data = 
        newDataFlag == true? await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:2
        },val) :
        await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:1
        },val)
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        console.log(data.data);
        
      }
    }
    
    loaderEnd();
   
  };


  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    
    setSearch(event.target.value);

    console.log(event.target.value);
    
  };



  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
        


        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>

              <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                filteredResults ? filteredResults : ''
              }
              pagination
              subHeader
              subHeaderComponent={
                <>
                     {SsuList && SsuList ? (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          ) : (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          ) }
          
           {/* <CSVLink headers={headers} data={dataeXcel? dataeXcel :''} >Download me</CSVLink> */}
          

<input
                    type="text"
                    placeholder="Search Here"
                    className="w-19 form-Control"
                    onChange={onChangeHandler}
                    style={{marginRight:18}}
                  />
                 


               
                
                  
       

                  <Select
                  sx={{marginLeft:3}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Filter"
                    onChange={handleChangeCategory}
                    displayEmpty
                    style={{ textAlign: "left",height:35 }}
                  >
                    <InputLabel>Filter Categories</InputLabel>

                    {/* <MenuItem value={10}>Start up</MenuItem> */}
                    <MenuItem value={"a"}>A</MenuItem>
                    <MenuItem value={"b"}>B</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>

              

                

           
              

                 
                </>
              }
              subHeaderAlign="center"
              value={search}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
            paginationTotalRows={SsuList ? filteredResults?.length : assigneeTable?.count}
            paginationPerPage={8}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />
          
          
          </TableContainer>
        </Box>
        
      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Submit Your Remarks
            </Typography>
            <br />           
             
            <TextareaAutosize
            style={{width:'100%'}}
					type="text" label="Remarks"
					// value={state.name}
          fullwidth
					required
					onChange={(e) => setRemarks(e.target.value)}
          multiline={true}
				/>
             
            
             
<br/><br/>
              <Button
                onClick={()=>{
                  recommendedSend(appId , boolean,remarks);
                }}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Submit 
              </Button>
            
          </Box>
        </Modal>
      ) : null}

    </>
  );
};

export default ExpertPending;
