import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  Typography,
  Checkbox
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getApplicationList,
  searchApplication,
  getSsuList,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  getEvaluatedListCsv,
  getEvaluatedListCsvSsu,
  getAssignedApplicationDownloadedExcel,
  getSsuScheduledLists,
  lmcSubmit,
  getlms,
  getCategoryMessages
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';

const CategoryB = (props) => {
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  // const flags = useSelector((store) => store.recognition.recognition);
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [dateSelector, setDateSelector] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [evaluateDetails, setEvaluateDetails] = useState();
  const [total, setTotal] = useState(0);
  const [check, setCheck] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [assignee, setAssignee] = useState([{}]);
  const [checkValue, setCheckValue] = useState([]);

 
  const [modal, setModal] = useState(false);



  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [filteredResults, setFilteredResults] = useState([]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [getLmsList, setGetLms] = useState(true);
  const [statusFilter, setStatusFilter] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [categoryMsg, setCategoryMsg] = React.useState([]);
  const [isRecognition, setIsRecognition] = useState(false)




 


  const { url } = useParams();
  

  useEffect(() => {
    getAllApplication();
    ssulist();
    assigneeList();
    GetAssignedList();
    getLms();
    getCategoryMsg();

   

  }, [page, rowsPerPage,flags,newDataFlag]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())
  useEffect(() => {
    try{
      if (search !== "All") {
  
        const result = SsuList
          ? SsuList?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()): 
                  statusFilter == true ?
                  // list.Meetings[list?.Meetings?.length - 1]?.status.substr(-4)
                  // .toLowerCase()
                  // .match(search.substr(-4).toLowerCase())||
                  // list.applicationCategory?.name.substr(1)
                  // .toLowerCase()
                  // .match(search.substr(1).toLowerCase()) ||
                  list.Lms[list?.Lms?.length - 1]?.status.substr(1)
                  .toLowerCase()
                  .match(search.substr(1).toLowerCase()):
         
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
      :""
          
        setFilteredResults(result)
     
      
    } else {
      setFilteredResults(SsuList);
      
    }
    }
    catch{
      setFilteredResults(SsuList);
      
    }
   
  }, [search]);



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };
  // console.log(checkValue)

  

  const sumbitApplication = async (e) => {

    e.preventDefault();

    let data2 = await lmcSubmit({applicationId :checkValue});
    console.log(data2)
    if(data2.status == true){
      actionNotifier.success("Message has been sent successfully");
      setModal(true)
    }
    e.preventDefault();
   
    
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let ids = selectedRows?.map((row) => {
      return {
        applicationId:row?.id
      }
    })
console.log(ids)
setCheckValue(ids)


    
  };
  // console.log('Selected Rows: ', checkValue);


  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Submission Date",
      selector: (row) =>
        changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width: "140px",
    },
    
    {
      name: "Evaluated At",
      selector: (row) =>
        changeTzToNormalDefault(row?.evaluatedAt).date,
      width: "140px",
      sortable: true,
    } ,
 
     {
      name: "Name of Startup",
      selector: (row) => row?.account?.nameOfStartUp,
      width: "150px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
 
    {
      name: "Category Type",
      selector: (row) => row?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },
    // {
    //   name: "Total Marks",
    //   selector: (row) =>
    //     row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
    //   width: "125px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    {
      name: "Total Marks1",
      selector: (row) =>
        // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
        row?.Evaluations?.filter((fname) =>  fname?.evaluatedById ==  row?.assignedTo1Id)
    .reduce((sum, i) => (sum += i.marks + total),0),
      width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Assignee2.",
      cell: (row) => row?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,
    },

    {
      name: "Category2",
      selector: (row) => row?.applicationCategory2?.name,
      width: "139px",
      sortable: true,
    },

    {
      name: "Total Marks2",
      selector: (row) =>
        // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
        row?.Evaluations?.filter((fname) =>  fname?.evaluatedById ==  row?.assignedTo2Id)
    .reduce((sum, i) => (sum += i.marks + total),0),
      width: "135px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },


    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },


    {
      
      name: 'Submission ',
      selector:(row,index) => row?.Lms[row?.Lms?.length - 1]?.status   ?
      row?.Lms[row?.Lms?.length - 1]?.status : 'pending' 
      
      ,
          width:"140px",
          sortable: true,
        
          
         
    },
  ];



 

  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      // console.log(data.data)
      setAssignee(data);
    }
  };

  const downloadAssignedTableCsv = async () => {
    if(flags == true){
    loaderStart();
    let data1 = {
      categories: ["a", "b", "c", "d"],
      isRecognition:true
    };
    const data = await getAssignedApplicationDownloadedExcel(data1);

    if (data.status == false) {
      actionNotifier.error("You are not authorized to perform this action");
    } else {
      let fileName = "Application" + Date.now() + ".csv";
      fileDownload(data, fileName);
    }
    loaderEnd();
  }
  else{
    loaderStart();
    let data1 = {
      categories: [ "c", "d"],
      isRecognition:false
    };
    const data = await getAssignedApplicationDownloadedExcel(data1);

    if (data.status == false) {
      actionNotifier.error("You are not authorized to perform this action");
    } else {
      let fileName = "Application" + Date.now() + ".csv";
      fileDownload(data, fileName);
    }
    loaderEnd();
  }
  };

  const downloadAssignedTableCsvSsu = async () => {
    if(flags == true){
      loaderStart();
      let data1 = {
        categories: [ "c", "d"],
        isRecognition:true
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
  
  };

  const getAllApplication = async () => {
    let val = {
      search: search,
    };
    let data = await getApplicationList(
      { page: page, items: rowsPerPage },
      val
    );
    if (data.status) {
      setAllapplication({ ...data.data });
      // console.log(data.data)
    }
  };

  const getLms = async () => {
   

    let getLmss = await getlms();
    // console.log(categoryMenu.categories)
   setGetLms(getLmss?.data)
    

   
  };


  const getCategoryMsg = async () => {
   

    let getMsg = await getCategoryMessages();
    // console.log(categoryMenu.categories)
    setCategoryMsg(getMsg?.data)
    console.log(getMsg?.data)
    

   
  };

  const ssulist = async () => {
    if(flags == true){
    let data = {
      categories: [ "c","d"],
      isRecognition:true
    };

    let ssulist = newDataFlag == true ? await getSsuList({
      round:2
    },data) :
    await getSsuList({
      round:1
    },data);
    // console.log(categoryMenu.categories)
    setSsuList(ssulist?.data?.data);
    setFilteredResults(ssulist?.data?.data);
    setSSuTotal(ssulist?.data?.data);

  }
  else{
    let data = {
      categories: [ "c","d"],
      isRecognition:true
    };

    let ssulist = newDataFlag == true ? await getSsuList({
      round:2
    },data) :
    await getSsuList({
      round:1
    },data);
    // console.log(categoryMenu.categories)
    setSsuList(ssulist?.data?.data);
    setFilteredResults(ssulist?.data?.data);
    setSSuTotal(ssulist?.data?.data);
  }
    
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const GetAssignedList = async () => {
    loaderStart();
    let data = await getAssignedApplicationListEvaluated({
      page: page,
      items: 50000,
      list: "evaluated",
    });
    if (data.status) {
      setAssigneeTable({ ...data?.data });
      setFilteredResultsAasinTable(data?.data?.rows);

      console.log(data.data);
      
    }
    loaderEnd();
   
  };


  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    setSearch(event.target.value);

    console.log(event.target.value);
  };



  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  const handleChangeStatus = (event) => {
    console.log(event.target.value)
     setStatusFilter(true);
     setSearch(event.target.value);
    
   };

  

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "2px",
          }}
        >
          {/* download excel Start Here*/}

        


   
              


        </Box>


         <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>
          {
            !assigneeTable ? 
            <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                filteredResults ? filteredResults : ''
              }
              pagination
              subHeader
              selectableRows
              onSelectedRowsChange={handleChange}
              
              subHeaderComponent={
                <>
                  {SsuList && SsuList ? (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
             Click here Download excel
            </Button>
          ) : (
            <Button onClick={downloadAssignedTableCsv} variant="text">
            Click here Download excel
            </Button>
          )}
            
                  <input
                  style={{marginLeft:20}}
                    type="text"
                    placeholder="Search Here"
                    className="w-25 form-Control"
                    onChange={onChangeHandler}

                    
                  />



<Select
                  sx={{marginLeft:2}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Age"
                    onChange={handleChangeStatus}
                    displayEmpty
                    style={{ textAlign: "left", height:35 }}
                  >
                    <InputLabel>Filter Status</InputLabel>

                    {/* <MenuItem value={10}>Start up</MenuItem> */}
                    {/* <MenuItem value={"complete"}>complete</MenuItem> */}
                    <MenuItem value={"send"}>send</MenuItem>
                    {/* <MenuItem value={"pending"}>pending</MenuItem> */}
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>

                  
                  <Select
                  sx={{marginLeft:3}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Filter"
                    onChange={handleChangeCategory}
                    displayEmpty
                    style={{ textAlign: "left",height:35 }}
                  >
                    <InputLabel>Filter Categories</InputLabel>

                    {/* <MenuItem value={10}>Start up</MenuItem> */}
                   
                    <MenuItem value={"c"}>C</MenuItem>
                    <MenuItem value={"d"}>D</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>

                  

                  <Button sx={{marginLeft:2}} variant="outlined" onClick={sumbitApplication}>
             Send 
            </Button>

           
          

                  
                </>
              }
              subHeaderAlign="center"
              value={search}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
              paginationTotalRows={SsuList ? filteredResults?.length : SsuList?.length}
            paginationPerPage={8}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />:''

          }
              
          
        
          
          </TableContainer>
        </Box>



        



      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Box sx={style}>
          <h5 style={{  alignContent:"center",justifyContent:"center", color:"blue", fontWeight: "bold", }} >Message Sent : </h5>

            
            <span style={{  color:'green',}}>{categoryMsg?.messageForCategory_C_and_D}</span>:''

           

           
          </Box>

         
           
          </Box>
         
        </Modal>
      ) : null}






      
      
     

    </>
  );
};

export default CategoryB;
