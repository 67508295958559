import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DepartmentHeader from "./DepartmentHeader";
import { getDashboardAnalytics } from "../basicAction";
import { getTotalServices } from "../basicAction";
import { getcurrentStage } from "../basicAction";
import { Card, CardMedia } from "@mui/material";
import { PieChart } from "react-minimal-pie-chart";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";


ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
);

const DepartmentDashboard = () => {
  const [data, SetData] = useState("");
  const [data2, SetData2] = useState([]);
  const [data3, SetData3] = useState([]);

  useEffect(() => {
    getDashboardData();
    getTotal();
    getTotalCur();
  }, []);


 


  var chartData = {
    labels: data2?.map((x) => x.name ),
    datasets: [
      {
        label: data2?.length,
        data: data2?.map((x) => x.id),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,

    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };

  const getDashboardData = async () => {
    let data = await getDashboardAnalytics();
    if (data.status) {
      // console.log(data.data);
      SetData(data.data);
    }
  };



  const getTotal = async () => {
    let services = await getTotalServices();
    if (services) {
      // console.log(services.data.sector[0].industry.name);
      SetData2(services.data.service);
    }
  };

  const getTotalCur = async () => {
    let curstage = await getcurrentStage();
    if (curstage) {
      // console.log(curstage.data);
      SetData3(curstage.data);
    }
  };

  var chartDataStage = {
    labels: data3?.map((x) => x.currentStage),
    datasets: [
      {
        label: data3?.length,
        data: data3?.map((x) => x._count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  var optionStage = {
    maintainAspectRatio: false,

    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 15,
      },
    },
  };

 let  sums = data?.totalRegistration + data?.totalFinalSubmit;
   
 let  Total = (data?.totalFinalSubmit / sums) * 100

  return (
    <>
      <DepartmentHeader />
      

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          margin: "20px",
        
        }}
      >
        {/* <Card style={{ width: '300px', background: '#2A8EA1', height: '200px' }} variant="outlined">
          <div style={{ diplay: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
            <h5>Total No. of Registered Startup</h5>
            <p style={{ fontSize: '20px' }}>{data?.registeredStartup}</p>
          </div>
        </Card> */}
        <Card
          style={{
            width: "400px",
            background: "#2A8EA1",
            height: "200px",
            opacity: "0.8",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px ,10px 10px 80px",
          }}
          variant="outlined"
        >
          <div
            style={{
              diplay: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              opacity: "100",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <h5 style={{ fontSize: "40px", fontWeight: "bold" }}>
              {" "}
              Registration
            </h5>
            <p style={{ fontSize: "50px" }}>{data?.totalRegistration}</p>
          </div>
        </Card>
        <div>
        <PieChart

center={[50, 50]}
data={

  [
    
  {
  color: "#2A8EA1",
  title: "totalRegistration",
  value: data?.totalRegistration,
  
  },
  {
  color: "orange",
  title: "totalFinalSubmit",
  value: data?.totalFinalSubmit,
  },

 
]}

   
labelPosition={5}
lengthAngle={360}
lineWidth={35}
paddingAngle={0}
radius={42}
rounded
startAngle={0}
viewBoxSize={[100, 100]}
   />
   
<center>
 <Card style={{
            width: "180px",
            background: "black",
            height: "60px",
            opacity: "0.8",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px ,10px 10px 80px ",
          }}
          variant="outlined">
              { <h1 style={{ fontSize: "40px", fontWeight: "bold" , color:"white", marginTop:"7px" }}>
   {

    
   Math.floor(Total ) + '%'

   
   
   }
          </h1> }
          </Card>
          </center>
          
        </div>

        <Card
          style={{
            width: "400px",
            background: "orange",
            height: "200px",
            opacity: "0.8",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px ,10px 10px 80px ",
          }}
          variant="outlined"
        >
          <div
            style={{
              diplay: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              opacity: "100",
              color: "white",
            }}
          >
            <h5 style={{ fontSize: "40px", fontWeight: "bold" }}>
              Final Submission
            </h5>
            <p style={{ fontSize: "50px", fontWeight: "bold" }}>
              {data?.totalFinalSubmit}
            </p>
          </div>
        </Card>
      </div>
      <Card
        style={{
          width: "100%",
          background: "white",
          height: "20%",
          opacity: "10",
          borderRadius: "10px",
        }}
      >
          <h5 style={{marginTop:"10px"}}>Total Services</h5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "400px",
          
            justifyContent: "space-around",
            paddingLeft: "25px",
          }}
        >
          <Line height={2000} data={chartData} options={options} />
        </div>
      </Card>

      <br />
      <br />
 <div>
  <center>
      <Card
        style={{
          width: "100%",
          background: "white",
          height: "20%",
          opacity: "10",
          borderRadius: "10px",
          
        }}
      >
        <h5 style={{marginTop:"10px"}}>Total Stages Data</h5>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "400px",
            justifyContent: "space-around",
            paddingLeft: "25px",
          }}
        >
        
          <Bar height={10000} data={chartDataStage} options={optionStage} />
          
        </div>
      </Card>
      </center>
      </div>

      <div style={{ visibility: "hidden" }}>
        <h1>I am Giyan</h1>
        <h1>I am Giyan</h1>
        <h1>I am Giyan</h1>
        <h1>I am Giyan</h1>
        <h1>I am Giyan</h1>
        <h1>I am Giyan</h1>
      </div>
    </>
  );
};

export default DepartmentDashboard;
