import React, { useState } from 'react'
import { Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom"

import "../../../src/Style/Auth.css";
import { FILE_URL } from '../../shared/constant';

const Venture = (props) => {
    return (
        <>
            <br />
            <TextField
                select
                fullWidth
                label="Nature of Entity"
                style={{ textAlign: 'left' }}
                required
                value={props.data.natureOfEntityId}
                onChange={(e) => props.handleChange(e, "natureOfEntityId", null, props.section)}
            >
                {
                    props.natureOfEntity.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
            </TextField>
            <br />
            <br />

            <Typography
                style={{ textAlign: "left" }}
                className='headLineLeft'>Upload proof of entity(In Pdf Only)</Typography>
                {/* upload fund raise  */}
            <TextField
                color="warning"
                label={" "}
                required={props.data.proofOfEntityUploadUrl ? false : true}
                type="file"
                fullWidth
                onChange={(e) => props.handleChange(e, "proofOfEntityUpload", "file", props.section)}
            />
            {
                props?.data?.proofOfEntityUploadUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props?.data?.proofOfEntityUploadUrl?.includes("https") ? props?.data?.proofOfEntityUploadUrl : FILE_URL + props?.data?.proofOfEntityUploadUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }
            <br />
            <br />

            <Typography className='headLineLeft'> Date of Registration* </Typography>
            <TextField

                fullWidth
                label={" "}
                id="fullWidth"
                type="date"
                required
                value={props.data.dateOfRegistration}
                onChange={(e) => props.handleChange(e, "dateOfRegistration", null, props.section)}
            /><br />
            <br />

            <Box sx={{ display: "flex" }}>
                <TextField
                    fullWidth
                    select
                    label="ROC (Bihar Only)"
                    required
                    style={{ textAlign: 'left' }}
                    value={props.data.rocId}
                    onChange={(e) => props.handleChange(e, "rocId", null, props.section)}
                    id="fullWidth"

                >
                    {props.district.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <br />

            <TextField
                fullWidth label="Udyami Aadhar " id="fullWidth"
                value={props.data.udyamiAadharNo}
                onChange={(e) => props.handleChange(e, "udyamiAadharNo", null, props.section)}
            />
            <br />
            <br />
        </>
    )
}

export default Venture;