// Mihir 25
import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
    Checkbox,
    OutlinedInput,
    FormControl,
    ListItemText
} from "@mui/material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "../../../src/Style/Auth.css";
import { getToken } from "../../apiCalls/functions"

import Address from "./basic/address"
import Others from "./basic/others"
import { actionNotifier } from "../notification"
import { loaderStart, loaderEnd } from "../loader"
import { getAllDropdown } from "./basic/actions"
import { REACT_APP_API_URL } from "../../shared/constant";
import UniversityContent from "./universityContent";
import IndustriesContent from "./industriesContent";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const startupIndustries = [
    'Value1',
    'Value2',
    'Value3',
    'Value4'
];
const startupSector = [
    'Value1',
    'Value2',
    'Value3',
    'Value4'
];
const initialValue = {
    incubatorType: ""
};

const IncubatorDetails = () => {
    const navigate = useNavigate()
    const [incubator, setIncubator] = useState(initialValue);
    const [startupSectorSelect, setStartupSectorSelect] = React.useState([]);
    const [startupIndustriesSelect, setStartupIndustriesSelect] = React.useState([]);
    const { incubatorType } = incubator;

    // form onSubmit check
    const onSubmitData = (e) => {
        navigate("/incubator-details")
    };

    const onValue = (e) => {
        setIncubator({ ...incubator, [e.target.name]: e.target.value });
        console.log(incubator)
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStartupSectorSelect(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [formDisplay, setFormDisplay] = useState('')

    const handleDisplay = (e) => {
        const display = e.target.value
        setFormDisplay(display)
    }

    return (
        <Box className='main_box_incubator'>
            <Box className='sub_box_incubator'>
                <Typography className='box_heading_incubator' sx={{ fontWeight: "bold", fontSize: 20 }} >Incubator Details</Typography>
                {/* <br /> */}
                <br />
                <form onSubmit={onSubmitData}>
                    <Grid container spacing={2} sx={{ color: 'text.primary' }}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                id="incubator_type_id"
                                value={incubatorType}
                                label="Type of Incubator"
                                onChange={(e) => { handleDisplay(e); }}
                                name="incubatorType"
                            >
                                <MenuItem value="university">University/College/Institute</MenuItem>
                                <MenuItem value="industry">Industry Association/Private company</MenuItem>
                            </TextField><br /><br />

                            {(() => {
                                switch (formDisplay) {
                                    case "university":
                                        return <UniversityContent />;

                                    case "industry":
                                        return <IndustriesContent />;

                                    default:
                                        return <></>;
                                }
                            })()}



                            <TextField
                                select
                                fullWidth
                                id="incubator_type_id"
                                value={incubatorType}
                                label="Incorporation Structure"
                                onChange={(e) => onValue(e)}
                                name="incubatorType"
                            >
                                <MenuItem value={1}>LLP</MenuItem>
                                <MenuItem value={2}>Pvt. Ltd</MenuItem>
                                <MenuItem value={2}>Partnership Firm</MenuItem>
                                <MenuItem value={2}>Proprietorship Firm</MenuItem>
                                <MenuItem value={2}>Trust</MenuItem>
                                <MenuItem value={2}>Society</MenuItem>
                            </TextField><br /><br />
                            <TextField
                                fullWidth
                                label="Incorporation No"
                                id="fullWidth"
                                value=""
                                type="text"
                                onChange={(e) => { }}
                            />
                            <br /><br />
                            <TextField
                                fullWidth
                                name="someDate"
                                label="Incorporation Date"
                                InputLabelProps={{ shrink: true, required: true }}
                                type="date"
                            /><br /><br />
                            <TextField
                                fullWidth
                                label="Total Years of existence from Incorporation date"
                                id="fullWidth"
                                value=""
                                type="text"
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                type="file"
                                label={" "}
                                inputProps={{ accept: "application/pdf" }}
                                name="casteCertificateUpload"
                                fullWidth
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                fullWidth
                                label="Website URL"
                                id="fullWidth"
                                value=""
                                type="text"
                                onChange={(e) => { }}
                            /><br /><br />
                            <TextField
                                fullWidth
                                label="Linkdin"
                                id="fullWidth"
                                value=""
                                type="text"
                                onChange={(e) => { }}
                            /><br /><br />
                            <div style={{ textAlign: "left" }}>
                                <label>Select stages of startup you are interested in working with</label><br />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="Ideation" />
                                    }
                                    label="Ideation"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="Validation" />
                                    }
                                    label="Validation"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="Early Traction" />
                                    }
                                    label="Early Traction"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="Scaling" />
                                    }
                                    label="Scaling"
                                /><br /><br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Industry of startup you are interested in working with</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={startupIndustriesSelect}
                                        input={<OutlinedInput label="Tag" />}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {startupIndustries.map((val) => (
                                            <MenuItem key={val} value={val}>
                                                <Checkbox checked={startupIndustriesSelect.indexOf(val) > -1} />
                                                <ListItemText primary={val} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Sector of startup you are interested in working with</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={startupIndustriesSelect}
                                        input={<OutlinedInput label="Tag" />}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {startupIndustries.map((val) => (
                                            <MenuItem key={val} value={val}>
                                                <Checkbox checked={startupIndustriesSelect.indexOf(val) > -1} />
                                                <ListItemText primary={val} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl><br /><br />
                                <TextField
                                    fullWidth
                                    label="Contact Person Name"
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <TextField
                                    fullWidth
                                    label="Contact Person Mobile no"
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <TextField
                                    fullWidth
                                    label="Contact person email id"
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <RadioGroup name="use-radio-group" defaultValue="first">
                                    <FormControlLabel value="yes" label="Laboratory /workshop facilities"
                                        control={<Radio />} />
                                </RadioGroup><br />
                                <TextField
                                    fullWidth
                                    label="No. of Startups incubated "
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <TextField
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    label="Brief of Project Incubated"
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <RadioGroup name="use-radio-group" defaultValue="first">
                                    <FormControlLabel value="yes" label="Created Startup/Innovation/Entrepreneurship Development cell"
                                        control={<Radio />} />
                                </RadioGroup><br />
                                <RadioGroup name="use-radio-group" defaultValue="first">
                                    <FormControlLabel value="yes" label="Government funded (Yes/No)"
                                        control={<Radio />} />
                                </RadioGroup><br />
                                <TextField
                                    fullWidth
                                    label="Infrastructure details"
                                    id="fullWidth"
                                    value=""
                                    onChange={(e) => { }}
                                /><br /><br />
                                <label>Upload Undertaking with photo of facility</label>
                                <TextField type="file" label={" "}
                                    inputProps={{ accept: " application/pdf" }} name="casteCertificateUpload" fullWidth
                                    onChange={(e) => { }}
                                /><br /><br />
                                <label>Upload details of each employees/staff dedicated for Startup incubation with experience</label>
                                <TextField type="file" label={" "}
                                    inputProps={{ accept: " application/pdf" }} name="casteCertificateUpload" fullWidth
                                    onChange={(e) => { }}
                                />
                            </div>
                            <br />
                            <div>
                                <Button type="submit" className="btn_class" variant="contained" fullWidth>Submit</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box className="page_design_develop_incu" style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>
                    Designed & developed by Codebucket Solutions Pvt. Ltd.
                </Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022 Department of Industries Government of Bihar
                </Typography>
            </Box>
        </Box >
    );
};

export default IncubatorDetails;
