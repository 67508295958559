import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import './Header.css';
import logo from './logo-white.png';
import { Link } from "react-router-dom";
import policy from '../assets/pdf/Bihar Startup Policy 2022 (1).pdf'
import policy2 from '../assets/pdf/Start Up Policy Hindi 2022.pdf'
import { useEffect } from "react";

const Header = () => {
     
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            // pageLanguage: 'en',
            includedLanguages: 'en,hi', // include this for selected languages
            // layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          'google_translate_element'
        );
      };
  
    useEffect(() => {
        let script = document.createElement('script');
         script  = document.createElement('script');
         script.setAttribute(
          'src',
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(script);
        window.googleTranslateElementInit = googleTranslateElementInit;
        // console.log('i fire once');
      }, []);
   

    return (
        
        <div className="header-start">
          
            <div  className="container-fluid top-header text-center" style={{ backgroundColor: "rgba(255,219,10,0.9)" }}>
                <div  style={{display:"flex",alignItems:"center", justifyContent:"space-around"}}>
                    <div className="col-sm-6" >
                        Helpline: <span className="text-primary font-weight-bold"> 18003456214</span> <span className="ms-2 me-2">|</span> Email : <span className="text-primary">startup-bihar@gov.in</span>
                        
                    </div>
                    
                    
                    <div  className="col-sm-4"  >
                        {/* <span>English</span> <span className="ms-2 me-2">|</span> <span>Hindi</span>  */}
                        
                        <Link to="/department-login" className="ms-12">
                            Department Login
                         
                        </Link>
                      
                        
                    </div>
                    <div className="col-sm-5">
                    <span   id="google_translate_element"  ></span>
                    </div>
              
                    
                    
                    
                  
                    

                         
                    
                </div>
                
            </div>
            
         
            <div style={{ backgroundColor: "rgba(33,158,180,0.8)" }}>
                <div className="row w-100 justify-content-center">
                    <div className="col-sm-10">
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="No Img" style={{ height: "45px", borderRadius: "5px" }} /></Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon">=</span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul className="navbar-nav startupindia">
                                        <li className="nav-item">
                                            <a className="nav-link" aria-current="page" href="/">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/about">
                                                About&nbsp;Us
                                            </a>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                For Startups
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/startup-recognition">Startup Recognition</a></li>
                                                <li><a className="dropdown-item" href="/benefits-supports">Benefits and Support</a></li>
                                                <li><a className="dropdown-item" href="/infrastructure">Infrastructure</a></li>
                                                <li><a className="dropdown-item" target="_blank" href="https://iciitp.com/zerolab/">Zero Lab</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Startup Ecosystem
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/bsft">BSFT</a></li>
                                                <li><a className="dropdown-item" href="/smic">SMIC</a></li>
                                                <li><a className="dropdown-item" href="/psc">PSC</a></li>
                                                <li><a className="dropdown-item" href="/ssu">SSU</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/#notification-section">Notification</a>
                                        </li>

                                    
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Bihar Startup Policy 2022
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" download href={policy2}>Hindi</a></li>
                                                <li><a className="dropdown-item" download href={policy}>English</a></li>
                                               
                                            </ul>
                                        </li>
                                        <div className="bg-light rounded-3 ms-5">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle login_signup" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Login 
                                                </a>
                                                
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item text-dark" href="/login">Login</a></li>
                                                    <li><a className="dropdown-item text-dark" href="/apply-new">Register</a></li>
                                                    {/* <li><a className="dropdown-item text-dark" href="/">Register</a></li> */}
                                                    {/* <li><a className="dropdown-item text-dark" >Register</a></li> */}
                                                    <li><a className="dropdown-item text-dark" href="/login">Sign In with Startup India</a></li>
                                                </ul>
                                            </li>
                                        </div>
                                       
                                       
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Header;