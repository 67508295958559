import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import RadioButtonsGroupTwo from "./Radio-Button-two";
import axios from "axios";
import { getToken } from "../apiCalls/functions"
import { actionNotifier } from "./notification"
import ViewFile from "./teamDetails/files"
import { useNavigate, Link } from "react-router-dom";
import { loaderStart, loaderEnd } from "./loader"
import { REACT_APP_API_URL } from "../shared/constant";
import { SignalCellularNullOutlined } from "@mui/icons-material";

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '30%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#EB4747',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#EB4747',
    },
});

// Inspired by blue printjs
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const initialValue = {
    // year: "2002",
    turnover: "",
    totalEmployees: "",
    alreadyRaisedFund: "yes",
    raisedFundAmount: "",
    balanceSheet: "",
    form3: "",
    form4: "",
}

const TurnoverAndEmployment = () => {
    const navigate = useNavigate()
    const [turnover, setTurnover] = useState(initialValue);
    const [getData, setGetData] = useState();
    const [show, setShow] = useState(false);
    const [token, setToken] = useState(null)
    const [update, setUpdate] = useState(null);

    useEffect(() => {
        let token = getToken()
        setToken(token)
        getTurnover(token);
    }, [])

    // form onSubmit check
    const onSubmitData = (e) => {
        e.preventDefault()
        if (update) {
            updateTurnover()
        }
        else {
            addTurnover()
        }
    }

    const onValue = (e) => {
        console.log(e.target.value);
        setTurnover({ ...turnover, [e.target.name]: e.target.value })
        console.log(turnover);
    }
    const onValueFile = (e) => {
        // let data=e.target.files[0]
        console.log(e.target.files[0]);
        setTurnover({ ...turnover, [e.target.name]: e.target.files[0] })
        console.log(turnover, "helllooo");
    }

    // put api call for business model
    const updateTurnover = async () => {
        loaderStart()
        let _turnover = { ...turnover }
        _turnover.alreadyRaisedFund = show ? "yes" : "no"
        _turnover.raisedFundAmount = _turnover.raisedFundAmount ? _turnover.raisedFundAmount : 0;
        _turnover.totalEmployees = _turnover.totalEmployees ? _turnover.totalEmployees : 0;
        _turnover.turnover = _turnover.turnover ? _turnover.turnover : 0;
        var form_data
        form_data = new FormData();
        for (var key in _turnover) {
            form_data.append(key, _turnover[key]);
        }
        await axios.put(`${REACT_APP_API_URL}turnover/${update}`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate("/main/reviewForm")
            })
            .catch(error => {
                let _err = error.response.data
                actionNotifier.error(_err.message)
            })
        loaderEnd()
    }

    // Post api call for business model
    const addTurnover = async () => {
        loaderStart()
        let _turnover = { ...turnover }
        _turnover.alreadyRaisedFund = show ? "yes" : "no"
        _turnover.raisedFundAmount = _turnover.raisedFundAmount ? _turnover.raisedFundAmount : 0;
        _turnover.totalEmployees = _turnover.totalEmployees ? _turnover.totalEmployees : 0;
        _turnover.turnover = _turnover.turnover ? _turnover.turnover : 0;
        var form_data
        form_data = new FormData();

        for (var key in _turnover) {
            form_data.append(key, _turnover[key]);
        }
        await axios.post(`${REACT_APP_API_URL}turnover`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate("/main/reviewForm")
            })
            .catch(error => {
                let _err = error.response.data
                if (_err.message == "\nInvalid `.create()` invocation in\n/home/startup-bihar/test/startup-bihar-backend/src/services/user/turnover.service.js:110:8\n\n  107   'no',\n  108 ]);\n  109 const data = await prismaModel.turnover\n→ 110   .create(\n  Foreign key constraint failed on the field: `accountId`") {
                    actionNotifier.error("Please Fill Basic Details and all the Previous Forms correctly")
                }
                else if (_err.message == "File size for balanceSheet is too large. Max size is 5 MB") {
                    actionNotifier.error("File size for balanceSheet is too large. Max size is 5 MB")
                }
                else if (_err.message == "File size for form3 is too large. Max size is 5 MB") {
                    actionNotifier.error("File size for form3 is too large. Max size is 5 MB")
                }
                else if (_err.message == "File size for form4 is too large. Max size is 5 MB") {
                    actionNotifier.error("File size for form4 is too large. Max size is 5 MB")
                }
                else {
                    actionNotifier.error("Please Fill all required fields")
                    console.log(_err.message)
                }
            })
        loaderEnd()
    }


    const getTurnover = async (token) => {
        loaderStart()
        await axios.get(`${REACT_APP_API_URL}turnover`,
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(result => {
                if (result.data.status) {
                    let _data = { ...result.data.data[0] }
                    setTurnover({
                        turnover: _data.turnover,
                        totalEmployees: _data.totalEmployees,
                        raisedFundAmount: _data.raisedFundAmount,
                        balanceSheetUrl: _data.balanceSheet,
                        form3Url: _data.form3,
                        form4Url: _data.form4,
                        form5Url: _data.form5,
                    })
                    setShow(_data.alreadyRaisedFund == "yes" ? true : false)
                    setUpdate(_data.id)
                    console.log(_data)
                }

            })
            .catch(error => {
                console.log(error);
            })
        loaderEnd()
    }

    console.log(turnover)

    return (
        <Box>
            <Box style={{ width: "694px", marginLeft: "43px" }}>
                <br />
                {/* <br /> */}
                <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "688px", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Turnover and Employment</Typography>

                <br />
                {/* <br /> */}
                <form onSubmit={onSubmitData}>
                    <TextField
                    type='number'
                        fullWidth
                        id="fullWidth"
                        inputProps={{ inputMode: 'numeric' }}
                        label={"What is your total Turnover in (INR Crore)?"}
                        name="turnover"
                        required
                        value={turnover.turnover}
                        onChange={(e) => onValue(e)}
                    />

                    <br />
                    <br />
                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload  Balance Sheet*(In Pdf Only)</Typography>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        inputProps={{ multiple: true,accept: "application/pdf"  }}
                        label={" "}
                        type="file"
                        required={turnover.balanceSheetUrl ? false : true}
                        style={{ width: "100%" }}
                        name="balanceSheet"
                        onChange={(e) => onValueFile(e)}
                    />
                    <ViewFile file={turnover.balanceSheetUrl} />
                    <br />
                    <br />
                    <TextField
                        fullWidth
                        id="fullWidth"
                        label={"Total Employee strength(In nos.)"}
                        name="totalEmployees"
                        type="number"
                        required
                        value={turnover.totalEmployees}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />
                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload Total Employee strength (Form III)*(In Pdf Only)</Typography>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        inputProps={{ multiple: true,accept: "application/pdf" }}
                        label={" "}
                        type="file"
                        required={turnover.form3Url ? false : true}
                        style={{ width: "100%" }}
                        name="form3"
                        onChange={(e) => onValueFile(e)}
                    />
                    <ViewFile file={turnover.form3Url} />
                    <br />
                    <br />


                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Already raised Fund? &#40;Such as Startup India/Meity/Atal/AI/VC etc&#41;* &#40;INR in Lakh&#41;</Typography>
                    {/* <RadioButtonsGroupTwo /> */}
                    <RadioGroup
                        row
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                    >
                        <FormControlLabel checked={show ? true : false} value="yes" control={<BpRadio />} label="Yes" onClick={() => setShow(true)} />
                        <FormControlLabel checked={!show ? true : false} value="no" control={<BpRadio />} label="No" onClick={() => setShow(false)} />
                    </RadioGroup>
                    {
                        show &&
                        <div>
                            <br />
                            <TextField
                                fullWidth
                                // label="INR Lakhs" 
                                id="fullWidth"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                label={"Raised Amount (INR Lakh)"}
                                name="raisedFundAmount"
                                required
                                value={turnover.raisedFundAmount}
                                onChange={(e) => onValue(e)}
                            />
                            <br />
                            <br />

                            <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload Fund Raised (Form IV)*(In Pdf Only)</Typography>
                            <TextField
                                fullWidth
                                label={" "}
                                type="file"
                                style={{ width: "100%" }}
                                name="form4"
                                onChange={(e) => onValueFile(e)}
                            />
                            <ViewFile file={turnover.form4Url} />
                            {/* <br /> */}

                            {/* <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload Investor (Form V)*</Typography>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                inputProps={{ multiple: true }}
                                label={" "}
                                type="file"
                                style={{ width: "100%" }}
                                name="form5"
                                onChange={(e) => onValueFile(e)}
                            />
                            <ViewFile file={turnover.form5Url} /> */}
                        </div>
                    }

                    <br />
                    {/* <br /> */}
                    {/* <NavLink style={{textDecoration:"none"}} to='/main/reviewForm'> */}
                    <Button style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth type="submit">Submit</Button>
                    {/* </NavLink> */}
                </form>

            </Box>
            {/* <Box style={{ marginLeft: "-1255px", marginTop: "300px", marginBottom: "40px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}


        </Box>
    )
}

export default TurnoverAndEmployment;