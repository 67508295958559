import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Main from './Main'
import EntityVentureDetails from './Entity-Venture-Details'
import Information from './Information'
import Login from './Login'
import QuestionAnswer from './QuestionAnswer'
import QuestionAnswerRecog from './QuestionAnswerRecog'
import Logout from './logout'
import Otp from './Otp'
import PreRegister from './Pre-Register'
import NewForm from './NewForm'
import PreRegisterRecognation from './Pre-Register-Recognation'
import Registration from "./Registration"
import RegistrationRecognation from "./RegistrationRecognation"
import { getDepartmentToken, getToken } from "../apiCalls/functions"

import TableData from './TableData'
import StartupIndia from '../StartupIndia/StartupIndia'
import HowToApply from './HowToApply'
import ApplyNew from './ApplyNew'
import ApplyNewRecog from './ApplyNewRecog'
import HowToApplyRecognation from './HowToApplyRecognation'
import AboutPage from '../pages/about'
import Infrastructure from '../pages/Infrastructure'
import StartupRecognition from '../pages/startup-recognition'
import BenefitsSupport from '../pages/benefits-supports'
import BSFT from '../pages/bsft'
import SSU from '../pages/ssu'
import SMIC from '../pages/smic'
import PSC from '../pages/psc'
import DepartmentLogin from './departmentLogin/DepartmentLogin'
import DepartmentDashboard from './DepartmentDashboard/DepartmentDashboard'
import ViewApplication from './DepartmentDashboard/ViewApplication/ViewApplication'
import RecommendedApplication from './DepartmentDashboard/ViewApplication/RecommendedApplication'
import CoworkingApplication from './DepartmentDashboard/ViewApplication/CoworkingApplication'
import LmcApplication from './DepartmentDashboard/ViewApplication/LmcApplication'
import CategoryMsgA from './DepartmentDashboard/ViewApplication/CategoryMsgA'
import CategoryMsgB from './DepartmentDashboard/ViewApplication/CategoryMsgB'
import EvaluationMain from './DepartmentDashboard/ViewApplication/EvaluationMain'
import MeetingDetails from './DepartmentDashboard/ViewApplication/meetingDetails'
import ScheduleMain from './DepartmentDashboard/ViewApplication/ScheduleMain'
import EvaluationSubmision from './DepartmentDashboard/ViewApplication/EvaluationSubmision'
import ReportApplication from './DepartmentDashboard/ViewApplication/reportApplication'
import Sidebar from './Sidebar/Sidebar'
import ViewSingleApplication from './DepartmentDashboard/ViewApplication/ViewSingleApplication'
import IncubatorRegistration from '../Componets/Incubator/IncubatorRegistration'
import IncuOtp from './Incubator/Otp'
import IncuLogin from './Incubator/Login'
import IncuMain from './Incubator/Main'
import IncubatorDetails from './Incubator/IncubatorsDetails'
import IncuDeclaration from './Incubator/Declaration'
import SearchApplication from './DepartmentDashboard/ViewApplication/SearchApplication'
import LogoutDepartment from './logoutdepartment'
import StartupIndiaLandingPage from './StartupIndia/landingPage'
import ExpertPending from './DepartmentDashboard/ViewApplication/ExpertPending'
import ExpertNotRecom from './DepartmentDashboard/ViewApplication/ExpertNotRecom'
import ExpertRecommended from './DepartmentDashboard/ViewApplication/ExpertRecommended'
import ExpPendingApplication from './DepartmentDashboard/ViewApplication/ExpPendingApplication'
import ExpNotRecomApplication from './DepartmentDashboard/ViewApplication/ExpNotRecomApplication'
import ExpRecomApplication from './DepartmentDashboard/ViewApplication/ExpRecomApplication'
import ExpertEvaluated from './DepartmentDashboard/ViewApplication/ExpertEvaluated'
import ExpertPass from './DepartmentDashboard/ViewApplication/ExpertPass'
import ExpertFail from './DepartmentDashboard/ViewApplication/ExpertFail'
import PersonalInteraction from './DepartmentDashboard/ViewApplication/PersonalInteraction'
import PersonalInteractionRecom from './DepartmentDashboard/ViewApplication/PersonalInteractionRecom'
import PersonalInteractionNot from './DepartmentDashboard/ViewApplication/PersonalInteractionNot'
import SmicPending from './DepartmentDashboard/ViewApplication/SmicPending'
import SmicApproved from './DepartmentDashboard/ViewApplication/SmicApproved'
import SmicRejected from './DepartmentDashboard/ViewApplication/SmicRejected'
// import TeamDetails from './Team-Details'
const Routers = () => {
    const [token, setToken] = useState(null)
    const [dtoken, setDtoken] = useState(null)
    const [startupToken, setStartupToken] = useState(JSON.parse(localStorage.getItem("startup-india")))


    useEffect(() => {
        let token = getToken()
        let dtoken = getDepartmentToken()
        setDtoken(dtoken)
        setToken(token)
        setStartupToken(startupToken)
    }, []);

    // console.log(startupToken)
    // <Sidebar /> 
    return (

        <div>

            
            <BrowserRouter>
            <Routes>
                    <Route path='/how-to-apply' element={<HowToApply />} />
                    <Route path='/apply-new' element={<ApplyNew />} />
                    <Route path='/apply-new-recog' element={<ApplyNewRecog />} />
                    <Route path='/how-to-apply-recognation' element={<HowToApplyRecognation />} />
                    <Route path='/' element={<StartupIndia />} />
                    <Route path='/startup-india/:notification' element={<StartupIndia />} />
                    <Route path='/preregister' element={<PreRegister />} />
                    <Route path='/coworking' element={<NewForm />} />
                    <Route path='/preregisterrecog' element={<PreRegisterRecognation />} />
                    <Route path='/registration' element={<Registration />} />
                    <Route path='/registrationreco' element={<RegistrationRecognation />} />
                    <Route path='/otp' element={<Otp />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/questions' element={<QuestionAnswer />} />
                    <Route path='/questionform' element={<QuestionAnswerRecog />} />
                    <Route path='/department-login' element={<DepartmentLogin />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/infrastructure' element={<Infrastructure />} />
                    <Route path='/startup-recognition' element={<StartupRecognition />} />
                    <Route path='/benefits-supports' element={<BenefitsSupport />} />
                    <Route path='/bsft' element={<BSFT />} />
                    <Route path='/ssu' element={<SSU />} />
                    <Route path='/psc' element={<PSC />} />
                    <Route path='/smic' element={<SMIC />} />
                    <Route path='/incubator-registration' element={<IncubatorRegistration />} />
                    <Route path='/incubator-registration/otp' element={<IncuOtp />} />
                    <Route path='/incubator-login' element={<IncuLogin />} />
                    <Route path='incubator/main/:id' element={<IncuMain />} />
                    <Route path='/incubator-details' element={<IncubatorDetails />} />
                    <Route path='/incubator/declaration' element={<IncuDeclaration />} />
                    <Route path='/department-logout' element={<LogoutDepartment />} />
                    <Route path='/startup-india/landingpage' element={<StartupIndiaLandingPage />} />
                    {
                        token || startupToken
                            ?
                            <>
                                <Route path='/information' element={<Information />} />
                                <Route path='/main/:id' element={<Main />} />
                            </>
                            : null
                    }
                    <Route path='/entityventuredetails' element={<EntityVentureDetails />} />
                    <Route path='/tabledata' element={<TableData />} />
                   
                    {

                        dtoken
                            ?
                            
                            <>
                               
                                <Route path='/department-dashboard' element={<DepartmentDashboard />} />
                                <Route path='/all-application' element={<ViewApplication />} />
                                <Route path='/pendingexp' element={<ExpPendingApplication />} />
                                <Route path='/notrecommended' element={<ExpNotRecomApplication />} />
                                <Route path='/exprecommended' element={<ExpRecomApplication />} />
                                <Route path='/evaluated' element={<RecommendedApplication />} />
                                <Route path='/expevaluated' element={<ExpertEvaluated />} />
                                <Route path='/personal-interaction' element={<PersonalInteraction />} />
                                <Route path='/personal-interaction-recom' element={<PersonalInteractionRecom />} />
                                <Route path='/personal-interaction-not' element={<PersonalInteractionNot />} />
                                <Route path='/pass' element={<ExpertPass />} />
                                <Route path='/fail' element={<ExpertFail/>} />
                                <Route path='/smic-pending' element={<SmicPending/>} />
                                <Route path='/smic-approved' element={<SmicApproved/>} />
                                <Route path='/smic-rejected' element={<SmicRejected />} />
                                <Route path='/coworkinglist' element={<CoworkingApplication />} />
                                <Route path='/lms' element={<LmcApplication />} /> 
                                <Route path='/reportadmin' element={<ReportApplication />} />   
                                <Route path='/catagorymsg' element={<CategoryMsgA />} />
                                <Route path='/catmsg' element={<CategoryMsgB />} />
                                <Route path='/schedule' element={<ScheduleMain />} />
                                <Route path='/evaluation/:id' element={<EvaluationMain />} />
                                <Route path='/evaluation/submit/:id' element={<EvaluationSubmision />} />
                                <Route path='/view-application/:id' element={<ViewSingleApplication />} />
                                <Route path='/search-application' element={<SearchApplication />} />
                                <Route path='/meetinglist/:id' element={<MeetingDetails />} />

                            </>
                            : null
                    }



                    {/* <Route path='/hello' element={<Hello />} /> */}
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Routers