import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import "../../../../src/Style/Auth.css";
import { REACT_APP_API_URL } from "../../../shared/constant";
const Address = (props) => {



    return (
        <>
            <TextField
                color="warning"
                multiline
                fullWidth
                label="Address Line"
                id="fullWidth"                
                value=""
                onChange={(e) => { }}
                style={{ textAlign: 'left' }}
            />
            <br />
            <br />

            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                <TextField
                    select
                    color="warning"
                    value=""
                    fullWidth
                    label="State"
                    style={{ textAlign: 'left' }}                    
                    onChange={(e) => { }}
                >

                </TextField>
                <TextField
                    select
                    color="warning"
                    labelId="district-name"
                    id="district-name"
                    value=""
                    fullWidth                    
                    style={{ textAlign: 'left' }}
                    label="District"
                    onChange={(e) => { }}
                >
                </TextField>

                <TextField
                    select
                    color="warning"
                    labelId="district-name"
                    id="district-name"
                    value=""
                    fullWidth                    
                    label="City"
                    style={{ textAlign: 'left' }}
                    onChange={(e) => { }}
                >

                </TextField>
            </Box>
            <br />
            <TextField
                label="Pin code"
                color="warning"
                fullWidth                
                name="pincode"
                value=""
                onChange={(e) => { }}
            />
            <br />
            <br />
        </>
    )
}

export default Address;