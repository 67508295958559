import React, { useState, useEffect } from 'react'
import { Box, TextField, Grid, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom"
import Saidebar from './Saidebar';
import { checkRegNo, updateStartupData, fetchInfoData } from "./information/actions"
import { actionNotifier } from "./notification"
import { loaderStart, loaderEnd } from "./loader"
import Modal from '@mui/material/Modal';
import ViewFile from "./teamDetails/files"
import { getAccountData, getAllRecordsData,userMeetingDetails,lmsMessage } from "./basicAction"
import { changeTzToNormalDefault } from '../shared/functions';
import { Card, CardMedia } from "@mui/material";
import Linkify from 'react-linkify';

const currencies = [
    {
        value: 'Select',
        label: '',
    },
    {
        value: 'yes',
        label: 'Yes',
    },
    {
        value: 'no',
        label: 'No',
    },

];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Information = () => {
    const [currency, setCurrency] = useState('Select');
    const [preRegister3, setPreRegister3] = useState(false)
    const [modal, setModal] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [disable, setDisable] = useState(false)
    const [Message, setMessage] = useState(false)
    const [state, setState] = useState({
        registrationId: "",
        document: "",
        amount: "",
        nameOfStartUp: "",
        phone: "",
        totalAmount: "",
        siacDoc: "",
        startUpId: ""
    })
    const [allrecord, setAllRecord] = useState()
    const [meetingDetails, setMeetingDetails] = useState()

    useEffect(() => {
        getInData()
        getAllRecords()
        getMeetingDetails()
        getLmsMessage()


    }, [])

    

  
 

    const getInData = async () => {
        let res = await fetchInfoData()
        console.log(res)
        if (res.status) {
            setState({
                ...state,
                nameOfStartUp: res.data.startUp?.nameOfStartUp,
                phone: res.data.startUp?.phone,
                totalAmount: res.data.totalAmount,
                registrationId: res.data.startUp?.registrationId,
                siacDocUrl: res.data.siacDoc,
                startUpId: res.data.startUpId,
            })
            setDisable(true)
            setPreRegister3("yes")
            setSubmit(true)
        }
        
    }

    const getAllRecords = async () => {
        let allrecords = await getAllRecordsData()
        console.log(allrecords)
        setAllRecord(allrecords);
    }

    const getLmsMessage = async () => {
        let message = await lmsMessage()
        console.log(message)
        setMessage(message);
    }
    
    const getMeetingDetails = async () => {
        let meeting = await userMeetingDetails()
        console.log(meeting)
        setMeetingDetails(meeting);
    }

    const handleChange = (e, name) => {
        setState({
            ...state,
            [name]: e.target.value
        })
    }

    const handleChangeFile = (e, name) => {
        setState({
            ...state,
            [name]: e.target.files[0]
        })
    }



    const navigate = useNavigate()

    const handleCheck = async (e) => {
        e.preventDefault()
        loaderStart()
        let res = await checkRegNo(state.registrationId)
        console.log(res)
        if (res.status) {
            setSubmit(true)
            setState({
                ...state,
                nameOfStartUp: res.data.nameOfStartUp,
                phone: res.data.phone,
                startUpId: res.data.id
            })
        }
        else {
            setModal(true)
        }
        loaderEnd()
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        loaderStart()
        let data = {
            totalAmount: state.totalAmount,
            siacDoc: state.siacDoc,
            startUpId: state.startUpId,
        }
        var formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        let res = await updateStartupData(formData)
        console.log(res)
        if (res.status) {
            navigate("/main/basicDetails")
        }
        else {
            actionNotifier(res.message)
        }
        loaderEnd()
    }

    return (
        <Box className='bg'>
            <Box className='form-ui1' sx={{ height: "130vh" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} xl={4}>
                            <Saidebar />
                        </Grid>
                        <Grid item xs={6.5} xl={8}  >
                       
                            <Box xs={{ height: "550vh" }} style={{ marginTop: "1%", marginLeft: 130 }}>
                                <br />
                            

                                {
                                    allrecord?.data.acknowledgement ?  <>
                                    {/* <u><h5 style={{ marginTop: "4%", marginLeft: 150 }} > Your application is Submitted Kindly Review your form </h5></u> <br/> */}
                                      <u><h6 style={{  marginLeft: 300 }}>  Acknowledgement No  : {allrecord?.data.acknowledgement.acknowledgmentNo}</h6></u>
                                    
                                     </> : 
                                     
                                     
                                     <>
                                     

                                     

                                    <TextField
                                           id="outlined-select-currency"
                                           select
                                           style={{ textAlign: 'left' }}
                                           label="Have you received any previous tranche in Startup Bihar Policy 2017"
   
                                           disabled={disable}
                                           sx={{ width: "100%", marginLeft: -20 }}
                                           value={preRegister3}
                                           onChange={(e) => e.target.value === "yes" ? setPreRegister3(e.target.value) : navigate("/main/basicDetails")}
                                       >
                                           {currencies.map((option) => (
                                               <MenuItem className="pre_reg_menu_item" key={option.value} value={option.value}>
                                                   {option.label}
                                               </MenuItem>
                                           ))}
                                       </TextField>

                                       <br/>
                                       {preRegister3 === "yes" &&
                                    <>
                                        <br />
                                        <form onSubmit={handleCheck}>
                                            <TextField
                                                label="Enter your registration no. of Startup Bihar Policy 2017"
                                                sx={{ width: "90%", marginLeft: -20 }}

                                                required
                                                disabled={disable}

                                                value={state.registrationId}
                                                onChange={(e) => handleChange(e, "registrationId")}
                                            >
                                            </TextField>
                                            <br />
                                            <br />
                                            {
                                                !disable
                                                    ?
                                                    <Button
                                                        style={{ backgroundColor: "#EB4747" }} variant="contained"
                                                        fullWidth sx={{ width: "300px", marginLeft: -15 }}
                                                        type="submit">Search</Button>
                                                    : null
                                            }
                                        </form>
                                    </>
                                }
                                <br />
                                <br />
                                {submit &&
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                label="Your registered startup name"
                                                fullWidth
                                                sx={{ width: "90%", marginLeft: -20 }}

                                                disabled={true}
                                                value={state.nameOfStartUp}
                                            >
                                            </TextField>
                                            <br />
                                            <br />
                                            <TextField
                                                label="Your registered Mobile Number"
                                                sx={{ width: "90%", marginLeft: -20 }}

                                                disabled={true}
                                                value={state.phone}
                                            >
                                            </TextField>
                                            <br />
                                            <br />
                                            <Typography className='headLineLeft'>Upload  SIAC Certificate* (In Pdf Only) </Typography>
                                            <TextField
                                                color="warning"
                                                type="file"
                                                label={" "}
                                                disabled={disable}
                                                sx={{ width: "90%", marginLeft: -20 }}

                                                required
                                                onChange={(e) => handleChangeFile(e, "siacDoc")}
                                            />
                                            <ViewFile file={state.siacDocUrl} />
                                            <br />
                                            <br />
                                            <TextField
                                                label="Amount received in Startup Bihar Policy 2017"
                                                type="number"
                                                fullWidth
                                                sx={{ width: "90%", marginLeft: -20 }}

                                                required
                                                disabled={disable}
                                                value={state.totalAmount}
                                                onChange={(e) => handleChange(e, "totalAmount")}
                                            >
                                            </TextField>
                                            <br />
                                            <br />
                                            {
                                                !disable
                                                    ?
                                                    <Button
                                                        style={{ backgroundColor: "#EB4747" }} variant="contained"
                                                        fullWidth sx={{ width: "90%", marginLeft: -15 }}
                                                        type="submit"
                                                    >Submit</Button>
                                                    : null
                                            }

                                        </form>
                                    </>
                                }
                                   </>
                                }
                                    {
                                    meetingDetails?.data.length >=1 ? <>
                        <Card 
          style={{
            marginTop: "6%",
            width: "650px",
            background: "White",
            height: "180px",
            opacity: "0.8",
            borderRadius: "20px",
            boxShadow: "0.5px 0.5px 0.5px ,2px 2px 10px",
          }}
          variant="outlined"
        >
          <div
            style={{
                
              diplay: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop:"12px",
              opacity: "100",
              color: "black",
              fontWeight: "bold",
              textAlign:'justify',
              padding:'5px'
            }}
          >
            
            
            <h5 style={{  marginRight: 400, color:"blue", fontWeight: "bold", }} >Dear Applicant, </h5>
            <span style={{  marginRight: 300 , color:'green',}} > Your meeting has been scheduled with Startup Support Unit on <br/>  { meetingDetails?.data?  new Date(meetingDetails?.data[meetingDetails?.data.length - 1]?.meetingAt).toUTCString().slice(0,-3)  : null} . Kindly follow the below instructions to attend the meeting. </span>
            <br/>
            <span style={{ marginTop: "4%", marginLeft: 0 }}> Message :<br/> <Linkify>{meetingDetails?.data[meetingDetails?.data.length - 1]?.meetingLink}</Linkify>
            
             </span>
          </div>
        </Card>
                                    </>: null
                                }



{
                                    Message?.data?.message ? <>
                        <Card 
          style={{
            marginTop: "6%",
            width: "650px",
            background: "White",
            // height: "180px",
            opacity: "0.8",
            borderRadius: "20px",
            boxShadow: "0.5px 0.5px 0.5px ,2px 2px 10px",
          }}
          variant="outlined"
        >
          <div
            style={{
                
              diplay: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop:"5px",
              opacity: "100",
              color: "black",
              fontWeight: "bold",
              textAlign:'justify',
              padding:'25px'
            }}
          >
            
            
            <h5 style={{  marginRight: 400, color:"blue", fontWeight: "bold", }} >Dear Applicant , </h5>
            <span style={{ marginTop: "4%", marginLeft: 0 ,alignItems: "center", }}> <br/><Linkify>{Message?.data?.message}</Linkify>
            
             </span>
          </div>
        </Card>
                                    </>: null
                                }

                                {/* <>
                                    <TextField
                                           id="outlined-select-currency"
                                           select
                                           style={{ textAlign: 'left' }}
                                           label="Have you received any previous tranche in Startup Bihar Policy 2017"
   
                                           disabled={disable}
                                           sx={{ width: "690px", marginLeft: -20 }}
                                           value={preRegister3}
                                           onChange={(e) => e.target.value === "yes" ? setPreRegister3(e.target.value) : navigate("/main/basicDetails")}
                                       >
                                           {currencies.map((option) => (
                                               <MenuItem className="pre_reg_menu_item" key={option.value} value={option.value}>
                                                   {option.label}
                                               </MenuItem>
                                           ))}
                                       </TextField>
                                   </> */}
                                  
                               

                                <br />

                                
                             
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {
                modal
                    ?
                    <Modal
                        open={modal}
                        onClose={() => setModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                You are not registered with Startup Bihar Policy 2017. Please contact to Department in case of any queries.
                            </Typography>
                        </Box>
                    </Modal>
                    : null

            }
            {/* <Box style={{ marginLeft: "-780px", marginTop: "-70px", marginBottom: "40px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}
        </Box>
    )
}

export default Information