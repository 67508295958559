import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import './style.css';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Chart from 'react-apexcharts'
import cmimg from './nitish-kumar2.png'
import shimg from './Mahaseth.png'

import logo from './logored1.png'
import Header from '../Header/Header'

import registration from './registerimg.png'
import cowork from './cowork.png'
import recognition from './recognition.png'
import incubator_registration from './startup.png'
import pre_incubators from './partner.png'
import mentors from './mentor.png'
import partner from './partner.png'
import angel from './rocket-ship.png'
import { useParams, useLocation,useNavigate } from 'react-router-dom';
import CarouselContainer from "../Container/CarouselContainer/CarouselContainer";
import IconCard from "../Componets/StartupIndia/iconCard";
import CardComponent from "../Componets/StartupIndia/cardComponent";
import StartupIndiaFooter from "../Componets/startupIndiaFooter"
import Pdf from './notice.pdf';
import newGif from './new-gif.gif';
import Modal from "@mui/material/Modal";
import Evaluationtest12Dec from'../assets/pdf/Startup_result_12122022.pdf'
import Evaluationtest from'../assets/pdf/Evaluation_test.pdf'
import EvaluationResult from'../assets/pdf/Evaluation_result.pdf'
import { Button,Box, FormControl, InputLabel, Select, Typography ,Checkbox,Grid, TextField} from "@mui/material";
import {
  getCoworkingInfo,

} from "../Componets/basicAction/index";
import { actionNotifier } from "../Componets/notification";
export function StartupIndia() {
  
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  const url = "https://api-startup.indbih.com/api/v1/data/analytics";
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [registrationId, setRegistrationId] = useState();
  const [details, setDetails] = useState();
  const navigate = useNavigate()
  useEffect(() => {
    fetch(url, {
      method: "get"
    })
      .then((res) => res.json())
      .then((d) => {
        setData(d.data);
      });
  }, []);

  useEffect(() => {
    if (data) {
      const _data = { ...deviceData };
      _data.series[0].data = [data?.registeredStartup, data?.incubators, data?.preIncubators, data?.accelerator, data?.mentors, data?.seedFundedStartup]
      setDeviceData(_data)

    }
   

  }, [data]);

  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height:240,
    bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    borderRadius: 12,
    p: 2,
  };

  const options = [{ text: "Startup Registration", icons: registration, link: `/apply-new` },
  //  const options = [{ text: "Startup Registration", icons: registration,  },



    // { text: "Startup Recognation", icons: registration, link: ''},
    // { text: "Pre-Incubator Registration", icons: pre_incubators, link: "" },
    // { text: "Mentor Registration", icons: mentors, link: "" },
    // { text: "Partner Registration", icons: partner, link: "" },
    // { text: "Accelerator / Angel Investor Registration", icons: angel, link: "" }




  ];

  const [deviceData, setDeviceData] = useState({

    series: [{
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [
          ['No. of Startups Registered'],
          // ['No. of Incubators'],
          // ['No. of Pre Incubators'],
          // ['No. of Accelerator'],
          // ['No. of Mentors'],
          // ['No. of Seed Funded Startup']
        ],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      }
    },


  });
  // console.log(deviceData)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height:340,
    bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    borderRadius: 12,
    p: 2,
  };
 
  const handleClick =  () => {
    setModal(true)
    // navigate("/")
    };

    const handleClick2 =  () => {
      // navigate("/coworking")
      // navigate("/")
      setModalRegister(true)
      };
    const handleSubmit = () => {
      if (checked) {
          navigate("/apply-new-recog")
          // navigate("/")
      }
  }

  const getDetails = async(registeredId) => {
    

    let data1={
        acknowledgmentNo: registeredId 
    }

    const data = await getCoworkingInfo(data1);

    if(data.status){
        setDetails(data?.data)
        console.log(data?.data)
        navigate('/coworking') 
        setModalRegister(false)
        // navigate('/') 
    }
    else{
        actionNotifier.error('Registration Number Not Found')
        navigate('/')
    }

  };

  return (
    <React.Fragment>
      <Header />
      <CarouselContainer />
      <div className="row w-100  justify-content-center">
        <div className="col-sm-12">

          <div className="banner-data" style={{ backgroundColor: "rgba(33,158,180,0.8)",  }}>
           
              <div style={{display:'flex',alignItems:"center",}}>
              {
                options.map((c, i) => (
                  <IconCard text={c.text} icons={c.icons} links={c.link} />
                ))
              }
             
                  <button  onClick={handleClick}   style={{background:"transparent",border:"none"}}>
                  <IconCard text={"Startup Recognition"} icons={recognition} />
                  </button>
                  <button  onClick={handleClick2}  style={{background:"transparent",border:"none"}}>
                  <IconCard text={"Co-working Space"} icons={cowork} />
                  </button>
                  </div>
     
            </div>

          

        </div>

      </div>
      <div className="row ministers_data">
        <div className="col-sm-12">
          <div className="row justify-content-center p-5">
            <div className="col-sm-3 text-center">
              <CardComponent src={cmimg} name={'Sri Nitish Kumar'} positon={`Hon'ble Chief Minister, Bihar`} />
            </div>
            <div className="col-sm-3">
              <img className="w-100" src={logo} style={{ marginTop: "100px" }} />
            </div>
            <div className="col-sm-3 text-center">
              <CardComponent src={shimg} name={'Sri Samir Kumar Mahaseth'} positon={`Hon'ble Minister of Industries, Bihar`} />
            </div>
          </div>

        </div>
      </div>
      {/* Glance Div  Starts */}
      {/* <div className="glance-div" style={{ backgroundColor: "rgba(255,255,255,0.9)" }}>
        <div className="row w-100 p-5">
          <div className="col-sm-5">
            {
              deviceData.series[0].data.length > 0 ? <Chart options={deviceData.options} series={deviceData.series} type="bar" className="mt-5" /> : null
            }

          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-6">
            <div className="glance-data p-5 ps-0">
              <h4 className="text__left" style={{ fontWeight: "700" }}>Startups At a Glance</h4>
              <p className="text__left" style={{ fontSize: "13px" }}>To emerge amongst the top 3 “Startup Hubs” in India through strategic
                partnerships, conducive ecosystem, investment and policy interventions. (<b>Data updation is in progress..</b>)</p>
              <div className="row mt-12">
                <div className="col-sm-12">
                  <div className="glance-icon glance-icon-border">
                    <i className="fa fa-user fa-2x color3"></i><br />
                    <b className="mt-2">{data?.registeredStartup}</b><br />
                    <span className="mt-2">No. of Startups Registered</span>
                  </div>
                </div>
                <div className="col-sm-4 ">
                  <div className="glance-icon">
                    <i className="fa fa-user fa-2x color1"></i><br />
                    <b className="mt-2">{data?.incubators}</b><br />
                    <span className="mt-2">No. of Incubators</span>
                  </div>
                </div>
                <div className="col-sm-4 ">
                  <div className="glance-icon">
                    <i className="fa fa-user fa-2x color1"></i><br />
                    <b className="mt-2">{data?.preIncubators}</b><br />
                    <span className="mt-2">No. of Pre Incubators</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="glance-icon glance-icon-border">
                    <i className="fa fa-user fa-2x color3"></i><br />
                    <b className="mt-2">{data?.accelerator}</b><br />
                    <span className="mt-2">No. of Accelerator</span>
                  </div>
                </div>
                <div className="col-sm-4 ">
                  <div className="glance-icon">
                    <i className="fa fa-user fa-2x color1"></i><br />
                    <b className="mt-2">{data?.mentors}</b><br />
                    <span className="mt-2">No. of Mentors</span>
                  </div>
                </div>
                <div className="col-sm-4 ">
                  <div className="glance-icon">
                    <i className="fa fa-user fa-2x color1"></i><br />
                    <b className="mt-2">{data?.seedFundedStartup}</b><br />
                    <span className="mt-2">No. of Seed Funded Startup</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div> */}
      {/* Glance div ends here*/}
      {/*
      <div className="glance-div" style={{backgroundColor:"#eee"}}>
        <div className="row w-100 p-5">
          <div className="col-sm-6">

          </div>
          <div className="col-sm-6">
            <img src={biharlogo} style={{width:"100%"}}/>
          </div>
        </div>

      </div>
      <div className="team-div bg-dark p-5">
        <div className="container">
          <div className="row justify-content-center" style={{ paddingTop: "100px" }}>
            <div className="col-sm-3">
              <div className="profile-card">
                <div className="profile">
                  <div className="pic"></div>
                  <div className="above-fold">
                    <div className="name">
                      Shri Lorem Ipsum
                    </div>
                    <div className="role">
                      Hon'ble Cabinet Minister
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="profile-card">
                <div className="profile">
                  <div className="pic"></div>
                  <div className="above-fold">
                    <div className="name">
                      Shri Lorem Ipsum
                    </div>
                    <div className="role">
                      Hon'ble Cabinet Minister
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="profile-card">
                <div className="profile">
                  <div className="pic"></div>
                  <div className="above-fold">
                    <div className="name">
                      Shri Lorem Ipsum
                    </div>
                    <div className="role">
                      Hon'ble Cabinet Minister
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="social-media-div p-5" style={{ backgroundColor: "rgba(33,158,180,0.5)" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-4">
              <h6 className="heading text__left"><span></span> Social Media</h6><br />
              <div className="border bg-white" style={{ borderRadius: "10px", height: "410px", overflowX: "hidden", overflowY: "scroll" }}>
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="IndustriesBihar"
                  options={{ height: 400 }}
                />
              </div>
            </div>
            <div className="col-sm-4" id="notification-section">
              <h6 className="heading text__left"><span></span> Notifications</h6><br />
              <div id="notification" className="bg-white" style={{ height: "410px", borderRadius: "10px" }}>
                <ul className="nav nav-pills mb-3 justify-content-center nav_ul" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active w-100" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Notification</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link w-100" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Events</button>
                  </li>
                </ul>
                <hr style={{ marginTop: "-10px" }} />
                <div className="tab-content" id="pills-tabContent">

                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <ul className="notification-event">
                    <li><a href={Evaluationtest12Dec} >Result of Start-Up Evaluation Test held on 12th December 2022
                    <img src={newGif} style={{ height: "20px" }} alt="New" />
                    </a></li>
                    {/* <li><a>The registration for new applications open from 1st December, 2022 to 31st december, 2022<img src={newGif} style={{ height: "20px" }} alt="New" /></a></li> */}
                    <li><a href={EvaluationResult} >Startup Evaluation Test Result Held on 4th November 2022
                    {/* <img src={newGif} style={{ height: "20px" }} alt="New" /> */}
                    </a></li>
                    <li><a href={Evaluationtest} >Startup Evaluation Test Result Held on 22nd October 2022
                    {/* <img src={newGif} style={{ height: "20px" }} alt="New" /> */}
                    </a></li>
                      {/* <li><a >The registration for new applications will be starting from 1st December, 2022<img src={newGif} style={{ height: "20px" }} alt="New" /></a></li> */}
                      <li><a>Notification letter no. 1983. Dated 18.08.2022.
                      </a></li>
                      <li>Portal will be opened for submission of application for a period of one (1) month starting from 29th July, 2022</li>
                    </ul>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <ul className="notification-event">
                      <li>Launch of the Bihar Start Up Policy 2022 and Portal Launch on July 29th, 2022</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/*<div className="highlights-div p-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <h6 className="heading text-center"><span></span> Highlights</h6><br />
              <div id="highlight">
                <ul className="nav nav-pills mb-3 justify-content-center w-100" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="tab1" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="fa fa-rocket"></i> Startups Highlights</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="tab2" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i className="fa fa-rocket"></i> Mentor Network</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="tab3" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i className="fa fa-rocket"></i> Startups Ecosystems</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="tab4" data-bs-toggle="pill" data-bs-target="#tab4" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i className="fa fa-rocket"></i> Funds For Fund</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="highlight-card p-3">
                          <button className="btn btn-sm btn-primary bgcolor4">Home Health Care</button><br /><br />
                          <i className="fa fa-plus fa-2x color2"></i> <b className="color2 ms-2" style={{ fontSize: "18px" }}>OrangeCross</b>
                          <h6 className="mt-2"><b>Orange Healthcare Pvt. Ltd</b></h6>
                          <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                            invidunt ut labore et dolore
                            magna aliquyam erat, sed diam</p>
                          <a href="#" className="text-decoration-none">Visit Website</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>*/}
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
           
            

            <Grid item xs={8}>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red",fontSize:30 }}><b><u>STARTUP RECOGNITION</u></b></h6></div>
                            <br/>
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                                <p >
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                  
                                  
                                  I, hereby declare that I am applying in Startup Bihar only for startup recognition. I don’t want to avail any seed fund as per Startup Bihar Policy / <br/><br/>
                                  मैं, एतद्वारा घोषणा करता हूँ कि मैं स्टार्टअप बिहार में केवल स्टार्टअप मान्यता के लिए आवेदन कर रहा हूँ। मैं स्टार्टअप बिहार नीति के अनुसार कोई सीड फंड नहीं लेना चाहता।  </p>
                               
                            </div>


                        <form style={{marginBottom:20}}>
                            {/* <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>I have read all the instructions carefully.	</span>
                            </div> */}

                            {/* <Box style={{display: "flex", justifyContent:"space-between"}}>
                        <TextField id="outlined-basic" label="Name" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Place" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Date" disabled variant="outlined" />
                    </Box> */}


                            <br />
                            <Button
                                style={checked ? { backgroundColor: "#EB4747", color: "white",marginLeft :330} : {marginLeft :330}}
                                variant="contained"
                                onClick={() => handleSubmit()} 
                                disabled={!checked}
                            >Submit</Button>
                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}

      
{modalRegister ? (
        <Modal
          open={modalRegister}
          onClose={() => setModalRegister(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
           
            

            <Grid item xs={8}>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 35, diplay: "flex", justifyContent: "space-around" }}>
                            <span style={{ textAlign: "center", color: "black",fontSize:18 }}><b>Enter your Startup Bihar Registration number</b></span></div>
                            <br/>
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                        <TextField
        style={{ width: "90%",textAlign: "justify",marginLeft:"5%"  }}
        required
        type="text"
        label="Registration No. "
        name="Registration No. "
        id="outlined-basic"
        variant="outlined"
        // value={'SB000736337'}
        fullwidth
        onChange={(e) => {
           
            setRegistrationId(e.target.value);
        
        }}
         />

                               

                            </div>

                            


                        <form style={{marginBottom:20}}>
                     


                            <br />
                            <Button
                                style={{ backgroundColor: "#EB4747", color: "white",marginLeft :180} }
                                variant="contained"
                                onClick={() => {
                                    
                                  localStorage.setItem('registrationId',registrationId);
                                    getDetails(registrationId);
                                    
                                }} 
                                // disabled={!checked}
                            >Submit</Button>
                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}
      <StartupIndiaFooter />
    </React.Fragment>
      
  );
}

export default StartupIndia;