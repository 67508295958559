

import { apiFunction } from "../../apiCalls/functions";

export const getDropdown = async (url) => {
    return await apiFunction(url, "get", {}, true, null)
}

export const fetchTeam = async () => {
    return await apiFunction("team", "get", {}, true, null)
}
export const updateTeamNo = async (data) => {
    let _data = {
        "noOfTeams": data
    }
    return await apiFunction("basic-details/", "post", _data, true, null)
}

export const addUpdateTeam = async (data, id) => {
    var formData = new FormData();
    for (var key in data) {
        formData.append(key, data[key]);
    }

    if (!data.panUpload) {
        formData.delete("panUpload");
    }
    if (!data.qaulificationUpload) {
        formData.delete("qaulificationUpload");
    }
    if (!data.panUpload) {
        formData.delete("panUpload");
    }

    if (!id) {
        return await apiFunction("team", "post", formData, true, "formData")
    }
    else {
        return await apiFunction("team/" + id, "put", formData, true, "formData")
    }
}

export const deleteTeam = async (id) => {
    return await apiFunction("/team/" + id, "delete", {}, true, null)
}

export const getAllDropdown = async () => {
    let education = await getDropdown("/data/higher-education/")
    let gender = await getDropdown("/data/gender/")
    let category = await getDropdown("/data/category")
    return {
        education: education.data,
        gender: gender.data,
        category: category.data,
    }
}