import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { FaBars, FaFontAwesome, FaThumbsUp, FaThumbsDown, FaRegCalendarCheck } from 'react-icons/fa'
import {
  
  getSsuList,
  getAdminProfileDetail

} from "../basicAction/index";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';


const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true)
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const CoworkingFlags = JSON.parse(localStorage.getItem('CoworkingFlag'));
  const [SsuList, setSsuList] = useState();
  const [active, setActive] = useState('');
  const [profile, setProfile] = useState("");

  


  useEffect(() => {
    
    ssulist();
    getAdminProfile();
     

    const currentURL = window.location.href
    const hash = currentURL.substring(currentURL.indexOf('#') + 1);
    let result = hash.split('/')
    setActive(result[result.length -1])
  console.log(active)

   


  }, [newDataFlag ,CoworkingFlags ]);

  
  const ssulist = async () => {
    let val = {
      categories: [ "b"],
    };

    let ssulist = newDataFlag == true ? await getSsuList({
      round:2
    },val) :
    await getSsuList({
      round:1
    },val)
    // console.log(categoryMenu.categories)
    setSsuList(ssulist?.data);

  };

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      // console.log(data);
      // setPermission(data.data)
      setProfile(data?.data?.username);
    }
  };


   
   
  

  const routes = [
    {
      path: '/all-application',
      name: 'Pending',
      icon: <FaFontAwesome />,
      className: active == 'all-application' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    {
   
      path:   '/evaluated',
      name:  'Evaluated',
      icon: <FaThumbsUp />,
      className: active == 'evaluated' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },

    {
      path: '/schedule',
      name: SsuList? 'Scheduled Meetings':'Schedule Meeting',
      icon: <FaRegCalendarCheck />,
      className: active == 'schedule' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },

    
    {
      path: '/lms',
      name: 'Learning Module',
      icon: <FaRegCalendarCheck />,
      className: active == 'lms' ? 'active_link' : 'link'
      
      // onclickfn: function () { return null }
    },

    {
      path: '/catagorymsg',
      name: ' Message to A',
      icon: <FaRegCalendarCheck />,
      className: active == 'catagorymsg' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },

    {
      path: '/catmsg',
      name: 'Mesage to C & D',
      icon: <FaRegCalendarCheck />,
      className: active == 'catmsg' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
   
  ]






  const routes2 = [
    {
      path: '/all-application',
      name: 'Pending',
      icon: <FaFontAwesome />,
      className: active == 'all-application' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
      
    },
    {
   
      path:   '/evaluated',
      name:  'Evaluated',
      icon: <FaThumbsUp />,
      className: active == 'evaluated' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },

    {
      path: '/schedule',
      name: SsuList? 'Scheduled Meetings':'Schedule Meeting',
      icon: <FaRegCalendarCheck />,
      className: active == 'schedule' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },



    
   
  
  ]

  const route3Coworking = [
    {
      path: '/coworkinglist',
      name: 'Pending',
      icon: <FaFontAwesome />,
      className: active == 'coworkinglist' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    


    
   
  
  ]


  // const routeExpert = [

  //   {
  //     path: '/pendingexp',
  //     name: 'Expert Panel',
  //     icon: <FaFontAwesome />,
  //     className: active == 'pendingexp' ? 'active_link' : 'link',
  //     iconClosed: <RiIcons.RiArrowDownSFill />,
  //     iconOpened: <RiIcons.RiArrowUpSFill />,
      
  //     // onclickfn: function () { return null }
  //   },
    
    
  //   {
  //     path: '/expevaluated',
  //     name: 'Evaluation Test  ',
  //     icon: <FaFontAwesome />,
  //     className: active == 'expevaluated' ? 'active_link' : 'link',
  //     iconClosed: <RiIcons.RiArrowDownSFill />,
  //     iconOpened: <RiIcons.RiArrowUpSFill />,
  //     // onclickfn: function () { return null }
  //   },
    
  //   {
  //     path: '/personal-interaction',
  //     name: 'Personal Interaction',
  //     icon: <FaFontAwesome />,
  //     className: active == 'personal-interaction' ? 'active_link' : 'link',
      
  //     // onclickfn: function () { return null }
  //   },
    
  

  // ]

    const routeExpertEvaluated = [

      
    {
      path: '/pendingexp',
      name: 'Expert Panel',
      icon: <FaFontAwesome />,
      className: active == 'pendingexp' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    {
   
      path:   '/exprecommended',
      name:  'Recommended',
      icon: <FaThumbsUp />,
      className: active == 'exprecommended' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    {
      path: '/notrecommended',
      name: 'Not-Recommended',
      icon:  <FaThumbsDown />,
      className: active == 'notrecommended' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    // {
    //   path: '/expevaluated',
    //   name: 'Evaluation Test',
    //   icon: <FaFontAwesome />,
    //   className: active == 'expevaluated' ? 'active_link' : 'link',
    //   iconClosed: <RiIcons.RiArrowDownSFill />,
    //   iconOpened: <RiIcons.RiArrowUpSFill />,
    //   // onclickfn: function () { return null }
    // },
    // {
    //   path: '/personal-interaction',
    //   name: 'Personal Interaction',
    //   icon: <FaFontAwesome />,
    //   className: active == 'personal-interaction' ? 'active_link' : 'link',
    //   iconClosed: <RiIcons.RiArrowDownSFill />,
    //   iconOpened: <RiIcons.RiArrowUpSFill />,
      
    //   // onclickfn: function () { return null }
    // },

  
    
    
    
  

  ]

  const routeExpertEvaluated5 = [

      
    {
      path: '/pendingexp',
      name: 'Expert Panel',
      icon: <FaFontAwesome />,
      className: active == 'pendingexp' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    {
   
      path:   '/exprecommended',
      name:  'Recommended',
      icon: <FaThumbsUp />,
      className: active == 'exprecommended' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    {
      path: '/notrecommended',
      name: 'Not-Recommended',
      icon:  <FaThumbsDown />,
      className: active == 'notrecommended' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    {
      path: '/expevaluated',
      name: 'Evaluation Test',
      icon: <FaFontAwesome />,
      className: active == 'expevaluated' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    {
      path: '/personal-interaction',
      name: 'Personal Interaction',
      icon: <FaFontAwesome />,
      className: active == 'personal-interaction' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      
      // onclickfn: function () { return null }
    },

  
    
    
    
  

  ]

  const routeExpertEvaluated2 = [

      
    {
      path: '/pendingexp',
      name: 'Expert Panel',
      icon: <FaFontAwesome />,
      className: active == 'pendingexp' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    
    {
      path: '/expevaluated',
      name: 'Evaluation Test',
      icon: <FaFontAwesome />,
      className: active == 'expevaluated' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    {
   
      path:   '/pass',
      name:  'Pass',
      icon: <FaThumbsUp />,
      className: active == 'pass' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    {
      path: '/fail',
      name: 'Fail',
      icon: <FaThumbsDown />,
      className: active == 'fail' ? 'active_link' : 'link'
      // onclickfn: function () { return null }
    },
    
    
    {
      path: '/personal-interaction',
      name: 'Personal Interaction',
      icon: <FaFontAwesome />,
      className: active == 'personal-interaction' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      
      // onclickfn: function () { return null }
    },

  ]

  const routeExpertEvaluated3 = [

      
    {
      path: '/pendingexp',
      name: 'Expert Panel',
      icon: <FaFontAwesome />,
      className: active == 'pendingexp' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    
    {
      path: '/expevaluated',
      name: 'Evaluation Test',
      icon: <FaFontAwesome />,
      className: active == 'expevaluated' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },   
    
    {
      path: '/personal-interaction',
      name: 'Personal Interaction',
      icon: <FaFontAwesome />,
      className: active == 'personal-interaction' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      
      // onclickfn: function () { return null }
    },

    {
      path: '/personal-interaction-recom',
      name: 'Recommended',
      icon: <FaThumbsUp />,
      className: active == 'personal-interaction-recom' ? 'active_link' : 'link',
  
      
      // onclickfn: function () { return null }
    },

    {
      path: '/personal-interaction-not',
      name: 'Not -Recommended',
      icon: <FaThumbsDown />,
      className: active == 'personal-interaction-not' ? 'active_link' : 'link',
  
      
      // onclickfn: function () { return null }
    },

  ]

  const routeSmic = [

      
    {
      path: '/smic-pending',
      name: 'SMIC PENDING',
      icon: <FaFontAwesome />,
      className: active == 'smic-pending' ? 'active_link' : 'link',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },
    
    {
      path: '/smic-approved',
      name: 'SMIC APPROVED',
      icon: <FaFontAwesome />,
      className: active == 'smic-approved' ? 'active_link' : 'link',
      // iconClosed: <RiIcons.RiArrowDownSFill />,
      // iconOpened: <RiIcons.RiArrowUpSFill />,
      // onclickfn: function () { return null }
    },   
    
    {
      path: '/ssmic-rejected',
      name: 'SMIC REJECTED',
      icon: <FaFontAwesome />,
      className: active == 'personal-interaction' ? 'active_link' : 'link',
      // iconClosed: <RiIcons.RiArrowDownSFill />,
      // iconOpened: <RiIcons.RiArrowUpSFill />,
      
      // onclickfn: function () { return null }
    },


  ]


  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };



  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className='main-container'>
        <motion.div animate={{ width: isOpen ? "220px" : "35px" }} className='sidebar'>
          <div className="top_section">
            {
              isOpen
                ?
                <h1
                  className='logo'> Department Dashboard</h1>
                :
                ""
            }

            <div className='bars'>
              <FaBars onClick={toggle} />
            </div>
          </div>
          {
            SsuList && profile !== 'psc' && profile !== 'CIMP' &&  profile !== 'IIT_Patna' && profile !== 'SMIC' && CoworkingFlags !== true    ?

            <section className='routes'>

             
            {
            
          
            
            routes?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
              </NavLink>
            ))   
            
            
            }
          </section>

          :
     ''
          }

          {
            !SsuList && profile !== 'psc' && CoworkingFlags !== true   &&  profile !== 'SMIC' && profile !== 'CIMP' &&  profile !== 'IIT_Patna'  ?
            <section className='routes'>
            {routes2?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
           
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
              </NavLink>
            ))}
          </section> :''
          }

{/*           
{
           profile == 'psc' && CoworkingFlags !== true  ?
            <section className='routes'>
            {routeExpert?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
              </NavLink>
            ))}
          </section> :''
          } */}

{
           active == 'pendingexp' || active == 'exprecommended' || active == 'notrecommended'    ?
            <section className='routes'>
            { profile == 'psc' ? routeExpertEvaluated5?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden "
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
                <div className="">{route.iconClosed}</div>
              </NavLink>
            )):
            routeExpertEvaluated?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden "
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
                <div className="">{route.iconClosed}</div>
              </NavLink>
            ))}
          </section> :     ''
          }

{
           active == 'smic-pending' || active == 'smic-approved' || active == 'smic-rejected'   ?
            <section className='routes'>
            {routeSmic?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
                <div className="">{route.iconClosed}</div>
              </NavLink>
            ))}
          </section> :''
          }


{
           active == 'expevaluated' || active == 'pass' || active == 'fail'  ?
            <section className='routes'>
            {routeExpertEvaluated2?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
               
                <div className="">{route.iconClosed}</div>
              </NavLink>
            ))}
          </section> :''
          }

{
           active == 'personal-interaction' || active == 'personal-interaction-recom' || active == 'personal-interaction-not'  ?
            <section className='routes'>
            {routeExpertEvaluated3?.map((route) => (
              <NavLink to={route.path} key={route.name} className={route.className}>
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {
                    isOpen && <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className='link_text'>
                      {route.name}
                    </motion.div>
                  }
                </AnimatePresence>
               
                <div className="">{route.iconClosed}</div>
              </NavLink>
            ))}
          </section> :''
          }

         
         

          {
               CoworkingFlags == true &&  profile !== 'psc' &&  profile !== 'SMIC'    ?
            
               route3Coworking?.map((route) => (
                 <NavLink to={route.path} key={route.name} className={route.className}>
                   <div className="icon">{route.icon}</div>
                   <AnimatePresence>
                     {
                       isOpen && <motion.div
                         variants={showAnimation}
                         initial="hidden"
                         animate="show"
                         exit="hidden"
                         className='link_text'>
                         {route.name}
                       </motion.div>
                     }
                   </AnimatePresence>
                 </NavLink>
               )) :''
          }

{/* <Menu>
    <SubMenu defaultOpen  label="Charts">
    <MenuItem routerLink={<Link to="/exprecommended" />}> Pie charts </MenuItem>
    
    <MenuItem routerLink={<Link to="/notrecommended" />}>  Line charts </MenuItem>
    </SubMenu>
    <SubMenu defaultOpen  label="Pending">
    <MenuItem routerLink={<Link to="/exprecommended" />}> Pie charts </MenuItem>
    
    <MenuItem routerLink={<Link to="/notrecommended" />}>  Line charts </MenuItem>
    </SubMenu>
  </Menu> */}
        
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  )
}

export default Sidebar