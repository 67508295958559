import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import about1 from '../about/about1.png'
import about2 from '../about/about2.png'
import mission from '../about/mission.png'
import vision from '../about/vision.png'
import './styles.css'
const AboutPage = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5">
          <h2 className="text-start fw-bold">ABOUT US</h2>
          <div className="row aligned-center">
            <div className="col-sm-3 text-end">
              <img src={about1} style={{ width: "250px" }} />
            </div>
            <div className="col-sm-6">
              <div className="img-box1">
                <p style={{ textAlign: "justify" }}>Bihar is progressing fast on the track of development, with
                  unrelenting efforts being made towards strengthening
                  infrastructure and promoting industrial growth. The government
                  of Bihar is committed to improve competitiveness of the state's
                  economy and achieving inclusive growth. Start-ups and
                  Innovation playa key role in economic growth. This can be
                  realized by promoting innovation and creating an ecosystem
                  that nurtures start-ups from concept to commissioning. Apart
                  from creating jobs, start-ups focus on innovation to create next
                  gen solutions which bring economic dynamism.
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex flex-row-reverse aligned-center">
            <div className="col-sm-3">
              <img src={about2} style={{ width: "300px" }} />
            </div>
            <div className="col-sm-6">
              <div className="img-box3">
                <p style={{ textAlign: "justify" }}>
                  Government of Bihar formulated and notified Bihar Start-up
                  Policy, 2016 to create an independent and transparent eco
                  system, where the state shall provide funding, promotion and
                  policy support which was later amended as Bihar Start-up
                  Policy 2017.The State has setup a Trust, with an initial corpus of
                  INR 500 crores, which acts as the nodal agency for
                  implementation of this policy.
                </p>
                <p style={{ textAlign: "justify" }}>
                  In order to further boost the ecosystem and to make it more
                  holistic and beneficial for the youth and entrepreneurs of the
                  state, the Government has approved the Bihar Start-up Policy,
                  2022. This policy also aims to broad base the coverage, with an
                  effective and faster implementation process.
                </p>
              </div>
            </div>

          </div>
          <div className="row aligned-center">
            <div className="col-sm-10">
              <div className="img-box">
                <div className="row">
                  <div className="col-sm-4">
                    <img src={vision} className="w-100" />
                  </div>
                  <div className="col-sm-8">
                    <p style={{ textAlign: "justify" }}>
                      To enable Bihar emerge as the most
                      preferred destination for start-ups
                      and entrepreneurs by leveraging
                      the potential of local youth through
                      a conducive start-up ecosystem for
                      inclusive growth in the State.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10 mt-5">
              <div className="img-box">
                <div className="row">
                  <div className="col-sm-4">
                    <img src={mission} className="w-100" />
                  </div>
                  <div className="col-sm-8">
                    <p style={{ textAlign: "justify", marginTop: "10px" }}>
                      <ul className="ul_style">
                        <li>Support startups by seed funding as well as matching grants to increase their viability</li>
                        <li>Facilitate Entrepreneurship Development Centers and Entrepreneurship Facilitation Centers to increase awareness and promote entrepreneurship amongst the youth.</li>
                        <li>Encourage Entrepreneurship through education by introducing learning modules in the University/ Schools, MOOC (Massive Open Online Courses), internships amongst others.</li>
                        <li>Facilitate development of new and support expansion of existing Incubators/common infrastructure spaces/Coworking spaces.</li>
                        <li>Enable hassle-free and time bound statutory clearances for startups.</li>
                        <li>Provide appropriate institutional support to enable the nurturing of startups including mentoring, hand-holding and training/orientation visits.</li>
                      </ul>
                    </p>
                  </div>
                </div>



              </div>
            </div>

          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default AboutPage;
