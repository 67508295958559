import React, { useState } from 'react'
import { Box, TextField, Select, MenuItem, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom"
import Navbar from './Navbar';
import "../../src/Style/Authentication.css"
import InputLabel from '@mui/material/InputLabel';
import Footer from "./footer"

import FormControl from '@mui/material/FormControl';

const currencies = [


    {
        value: 'yes',
        label: 'YES',
    },
    {
        value: 'no',
        label: 'NO',
    },

];


const PreRegisterRecognation = () => {
    const [currency, setCurrency] = useState('');
    const [currency2, setCurrency2] = useState('');
    const [currency3, setCurrency3] = useState('');


    const [age, setAge] = React.useState('');
    const [preRegister1, setPreRegister1] = useState(null)
    const [preRegister2, setPreRegister2] = useState(null)
    const [preRegister3, setPreRegister3] = useState(null)
    const navigate = useNavigate()

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };

    const handleRedirect = () => {
        console.log(preRegister1, preRegister2)
        let data = {
            isRegisteredEntity: preRegister1 ? preRegister1 : "no",
            "isGreaterThan10Year": preRegister2 ? preRegister2 : "no",
            "isTurnoverGreaterThan100Crores": preRegister3 ? preRegister3 : "no",
        }
        localStorage.setItem("startup-reg", JSON.stringify(data))
        navigate("/registrationreco")
    }

    console.log(preRegister1, preRegister2, preRegister3)

    return (
        <Box className='backround-ui'>
            <Grid item container spacing={1}>
                <Box className='card-ui'>
                    <Grid item xs={4} >
                        <Navbar />
                        <Box sx={{ marginLeft: 2 }}>
                            <Footer />
                        </Box>
                    </Grid>
                    <Grid item xs={8} >
                        <Box className='textFiled-ui' >
                            <br />
                            <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "692px", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Check your eligibility to register</Typography>
                            <>
                                <br />
                                <br />
                                <Box className='' >
                                    <Typography className='formHeadLineLeft' >Is your venture a registered entity in Bihar ?  </Typography>
                                    <br />

                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select"
                                        value={preRegister1}
                                        style={{ textAlign: 'left' }}
                                        onChange={(e) => {
                                            e.target.value === "yes" ? setPreRegister1(e.target.value) : handleRedirect()
                                        }}
                                        fullWidth
                                    >
                                        {currencies.map((option) => (
                                            <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </>
                            <br />
                            <br />
                            <br />
                            {preRegister1 == "yes" &&
                                <>
                                    <Box>
                                        <Typography className='formHeadLineLeft'>Is registration of entity is greater than 10 years ? </Typography>
                                        <br />

                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Select"
                                            value={preRegister2}
                                            style={{ textAlign: 'left' }}
                                            fullWidth
                                            onChange={(e) => {
                                                e.target.value === "yes" ?
                                                    alert("You are not eligible to register as start-up under Bihar Start-up Policy ") :
                                                    setPreRegister2(e.target.value)
                                            }}

                                        >
                                            {currencies.map((option) => (
                                                <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>

                                </>

                            }
                            <br />
                            <br />
                            <br />
                            {preRegister2 == "no" &&
                                <>
                                    <Box>
                                        <Typography className='formHeadLineLeft'>Is turnover of entity is greater than 100 crores ?  </Typography>
                                        <br />
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Select"
                                            value={preRegister3}
                                            style={{ textAlign: 'left' }}
                                            fullWidth
                                            onChange={(e) => {
                                                setCurrency3(e.target.value)
                                                e.target.value === "yes" ?
                                                    alert("You are not eligible to register as start-up under Bihar Start-up Policy ") : handleRedirect()

                                            }}

                                        >
                                            {currencies.map((option) => (
                                                <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>

                                </>

                            }
                        </Box>

                    </Grid>

                </Box>

            </Grid>
        </Box>

    )
}

export default PreRegisterRecognation