import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Box, TextField, Stack, Grid, Button, Typography } from '@mui/material';
import Navbar from './Navbar';
import axios from 'axios';
import { REACT_APP_API_URL } from "../shared/constant"
import Footer from "./footer"
import { actionNotifier } from "./notification"
import { loaderStart, loaderEnd } from "./loader"
import { forgotPassword, resentOTPForgotPassword, updateForgotPassword } from "./information/actions"
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Login = () => {
    const navigate = useNavigate()
    const initialValue = {
        // id: "",
        email: "",
        password: "",
    }
    const [login, setLogin] = useState(initialValue);

    const [modal, setModal] = useState(false)
    const [otpSent, setOtpSent] = useState(false)
    const [state, setState] = useState({
        email: "",
        otp: "",
        password: "",
        rePassword: "",
        mobile: "",
    })

    const { email, password } = login;

    const onLogin = () => {
        let _login = { ...login }
        axios.post(`${REACT_APP_API_URL}auth/login`, _login, {
            // headers: {
            //     // 'authorization': 'your-token',   
            //     'Accept' : 'application/json',
            //     'Content-Type': 'application/json'
            // }
        })
            .then(result => {
                console.log(result.data.data.data)
                localStorage.setItem("startup-india-token", JSON.stringify(result.data.data))

                if (result.data.data?.data?.isRegisteredEntity === "yes") {
                    window.open("/information", "_self")
                }
                else {
                    window.open("/main/basicDetails", "_self")
                }
            })
            .catch(error => {
                console.log(error);
                actionNotifier.error(error.response.data.message)
            })
    }

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.startupindia.gov.in/etc/designs/invest-india/investindialibs/js/siauthlogin.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const onValue = (e) => {
        console.log(e.target.value);
        setLogin({ ...login, [e.target.name]: e.target.value })
        console.log(login);
    }

    const onValueChange = (e, name) => {
        setState({ ...state, [name]: e.target.value })
    }

    const onSubmitData = async (e) => {
        e.preventDefault()

        loaderStart()
        if (otpSent) {
            if (state.password === state.rePassword) {
                let _state = { ...state }
                delete _state.rePassword;
                let res = await updateForgotPassword(_state)
                if (res.status) {
                    actionNotifier.success(res.data.message)
                    setModal(false)
                }
                else {
                    actionNotifier.error(res.message)
                }
                // console.log(res)
            }
            else {
                actionNotifier.error("Both password are not same")
            }
        }
        else {
            let val = {
                email: state.email,
                mobileNumber: state.mobile
            }
            let res = await forgotPassword(val)
            if (res.status) {
                actionNotifier.success(res.data.message)
                setOtpSent(true)
            }
            else {
                actionNotifier.error(res.message)
            }
        }
        loaderEnd()
    }

    const resendOTP = async () => {
        loaderStart()
        let res = await resentOTPForgotPassword(state.email)
        if (res.status) {
            actionNotifier.success(res.data.message)
        }
        else {
            actionNotifier.error(res.message)
        }
        // if(res)
        loaderEnd()
    }

    return (
        <>
            <Box className='bg'>
                <Box className='form-ui1' sx={{ height: "100%" }}>


                    <Grid container spacing={1} >
                        <Grid item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                // borderRadius:"5%",
                                borderRadius: "30px 0px 0px 30px !important"
                            }}
                        >
                            <Navbar />
                            <Box sx={{ marginLeft: 6 , }}>
                            <Footer />
                        </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ marginTop: "-6px", marginLeft: "5% !important", }} >
                            <br />
                            <br />
                            <br />

                            <Typography variant="h4" color="#EB4747" sx={{ fontWeight: "bold", }}>Welcome to Startup Bihar</Typography>
                            <br />
                            <br />
                            <Typography variant="h5" color="#030e12" sx={{ fontWeight: "bold", }}>Sign in to account</Typography>
                            <hr style={{ width: "50px", display: "flex", margin: "auto", color: "#030e12", borderTop: "5px solid #030e12" }} />
                            <br />
                            <br />

                            <TextField fullWidth label="Email" id="fullWidth" type="email"
                                value={email}
                                name="email" onChange={(e) => onValue(e)}



                            />
                            <br />
                            <br />
                            <br />
                            <TextField fullWidth type="password" label="Password" id="fullWidth" value={password}
                                name="password" onChange={(e) => onValue(e)} />
                            <br />
                            <br />
                            <br />

                            <Button variant="contained" fullWidth onClick={() => onLogin()} sx={{ background:/*"#4D35E5"*/"#EB4747" }}>Sign In </Button>
                            <br />
                            <br />

                            {/* () => navigate("/information") */}
                            <Box sx={{ cursor: "pointer", display: "flex", justifyContent: "space-between", }}>
                                <Typography onClick={() => setModal(true)} className='headLineLeft' style={{ color: "#EB4747" }}><u>Forgot Password </u></Typography><br />
                                {/* <Button variant="outlined" className='headLineLeft' onClick={navigate("/registration")}>Sign Up </Button> */}
                                {/* <a href="/registration" style={{ color: "#EB4747" }}>Sign Up</a> */}
                            </Box>

                            <Button variant="contained" sx={{ background: "#EB4747", color: 'white' }} className='oauth-login'>Sign In with Startup India</Button>
                        </Grid>
                       
                    </Grid>
                </Box>
            </Box>
            {
                modal
                    ?
                    <Modal
                        open={modal}
                        onClose={() => setModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Forgot Password
                            </Typography>
                            <br />
                            <form onSubmit={onSubmitData}>
                                <TextField
                                    id="outlined-basic"
                                    label={"Registered Email"}
                                    variant="outlined"
                                    required
                                    type="email"
                                    style={{ width: "100%" }}
                                    value={state.email}
                                    onChange={(e) => onValueChange(e, "email")}
                                />
                                <br />
                                <br />
                                <TextField
                                    id="outlined-basic"
                                    label={"Registered Mobile Number"}
                                    variant="outlined"
                                    required
                                    type="number"
                                    style={{ width: "100%" }}
                                    value={state.mobile}
                                    onChange={(e) => onValueChange(e, "mobile")}
                                />
                                {
                                    otpSent
                                        ?
                                        <>
                                            <br />
                                            <br />
                                            <TextField
                                                id="outlined-basic"
                                                label={"OTP"}
                                                variant="outlined"
                                                required
                                                type="text"
                                                style={{ width: "100%" }}
                                                value={state.newPassword}
                                                onChange={(e) => onValueChange(e, "otp")}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="outlined-basic"
                                                label={"New Password"}
                                                variant="outlined"
                                                required
                                                type="password"
                                                style={{ width: "100%" }}
                                                value={state.newPassword}
                                                onChange={(e) => onValueChange(e, "password")}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="outlined-basic"
                                                label={"Retype Password"}
                                                variant="outlined"
                                                required
                                                type="password"
                                                style={{ width: "100%" }}
                                                value={state.rePassword}
                                                onChange={(e) => onValueChange(e, "rePassword")}
                                            />

                                        </>
                                        : null
                                }
                                <br />
                                <Typography
                                    onClick={() => resendOTP()} sx={{ cursor: "pointer" }}
                                    className='headLineLeft'>
                                    {/* Resend OTP */}
                                </Typography>
                                <br />
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: "#EB4747" }}
                                    variant="contained"
                                    fullWidth
                                >Submit</Button>
                            </form>
                        </Box>
                    </Modal>
                    : null

            }

        </>
    )
}

export default Login