import React,{ useState } from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '30%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#EB4747',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EB4747',
  },
});

// Inspired by blue printjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function CustomizedRadiosTwo() {

  const [show, setShow] = useState(false);

  return (
    <>
      <RadioGroup
        row
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
      >
        <FormControlLabel  value="yes" control={<BpRadio />} label="Yes" onClick={() => setShow(true)} />
        <FormControlLabel value="no" control={<BpRadio />} label="No" onClick={() => setShow(false)} />
      </RadioGroup>
      <br />
      {
      show &&
        <div>
            {/* <br /> */}
            <TextField fullWidth label="INR Lakhs" id="fullWidth" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
            <br />
            <br />
            <Typography style={{textAlign:"left"}} className='headLineLeft'>Upload Fund raised (Form IV)*</Typography>
            <TextField fullWidth label="" type="file"/>
            <br/>
            <br/>
            <Typography style={{textAlign:"left"}} className='headLineLeft'>Upload Investor (Form V)*</Typography>
            <TextField id="outlined-basic" fullWidth inputProps={{ multiple: true }} label="" type="file" style={{width:"100%"}}/>
        </div>
      }
    </>
  );
}