import React, { useState, useEffect } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Box, TextField, Stack, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom"
import "../../../src/Style/Authentication.css"
import { register } from '../../Servies/Api'
import Saidebar from "../Saidebar"
import Navbar from '../Navbar';
import axios from 'axios';
import { REACT_APP_API_URL } from "../../shared/constant"
import Footer from "../footer"
import { actionNotifier } from "../notification"

const initialValue = {
    // id: "",    
    firstName: "",    
    mobileNumber: "",
    email: "",
    adhaarNo: "",
}

export default function IncubatorRegistration() {
    const navigate = useNavigate()

    const [user, setUser] = useState(initialValue);
    const { firstName, mobileNumber, email, adhaarNo } = user;

    const addIncubatorDetails = (e) => {
        e.preventDefault()


        let _user = { ...user }
        if (_user.adhaarNo.length == 12) {
            if (_user.mobileNumber.length == 10) {
                navigate("./otp")
            }
            else {
                actionNotifier.error("Mobile Number invalid")
            }
        }
        else {
            actionNotifier.error("Aadhar invalid")
        }


    }
    const onValue = (e) => {
        console.log(e.target.value);
        setUser({ ...user, [e.target.name]: e.target.value })
        console.log(user);
    }

    useEffect(() => {
        localStorage.setItem("email", JSON.stringify(user.email));
        localStorage.setItem("mobile", JSON.stringify(user.mobileNumber))
        // localStorage.setItem("token", JSON.stringify(user.token));

        // storing input name
    }, [user.email, user.mobileNumber]);

    const inputP = { maxLength: 12 }
    return (
        <Box className='backround-ui'>
            <Box className='form-ui1' >
                <Grid container spacing={1} >
                    <Box className='form-ui1' >
                        <Grid item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <Navbar />
                            <Box sx={{ marginLeft: 2 }}>
                                <Footer />
                            </Box>

                        </Grid>

                        <Grid item xs={8} >
                            <form onSubmit={addIncubatorDetails}>
                                <br />
                                <br />
                                <Box  >
                                    <Typography sx={{ marginLeft: "60px", color: "#EB4747", display: "flex", justifyContent: "flex-start", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Incubator Registration</Typography>
                                    <Stack
                                        // className='form-ui'
                                        sx={{ display: "flex", flexDirection: "column", marginLeft: "50px !important" }}
                                        noValidate
                                        autoComplete="off"

                                    ><br />

                                        <Box  >
                                            <TextField
                                                id="outlined-basic"
                                                label="Name of personnel"
                                                variant="outlined"
                                                sx={{ width: "98%" }}
                                                value={firstName}
                                                name="firstName"
                                                required
                                                type="text"
                                                onChange={(e) => onValue(e)} />
                                            <br /><br />
                                            <TextField
                                                sx={{ width: "98%" }}
                                                type="number"
                                                required
                                                id="outlined-basic"
                                                label="Mobile Phone"
                                                variant="outlined"
                                                value={mobileNumber}
                                                name="mobileNumber"
                                                onChange={(e) => onValue(e)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                            />
                                            <br /><br />
                                            <TextField
                                                sx={{ width: "98%" }}
                                                id="outlined-basic"
                                                required
                                                type="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                name="email"
                                                onChange={(e) => onValue(e)} />
                                            <br /><br />
                                            <TextField
                                                id="outlined-basic" type="number"
                                                label="Aadhar No"
                                                variant="outlined"
                                                sx={{ width: "98%" }}
                                                required
                                                value={adhaarNo}
                                                name="adhaarNo"
                                                onChange={(e) => onValue(e)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                }}
                                            />
                                        </Box>
                                        <br />
                                        <div>
                                            <Button type="submit" style={{ backgroundColor: "#EB4747", width: "98%" }} variant="contained" fullWidth  >Get OTP</Button>
                                            {/* <Button style={{backgroundColor:"#EB4747"}} variant="contained" fullWidth onClick={() => navigate("/otp")} >Get OTP</Button> */}

                                        </div>
                                        <br />
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: 25 }}>
                                            <a href="/login" style={{ color: "#EB4747" }}>Already have account ?Login Here</a>

                                        </div>
                                    </Stack>
                                </Box>
                            </form>
                        </Grid>
                    </Box>
                </Grid>
            </Box>

        </Box>


    );
}