import React, { useState } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom"
import Saidebar from './Saidebar';
import BasicDetails from './Basic-Details';
import TeamDetails from './Team-Details';
// import DocumentUpload from './Document-Upload';
import IncuDeclaration from './Declaration';
import ReviewForm from './ReviewForm';
import IncubatorDetails from './IncubatorsDetails';


const IncuMain = () => {
    const { id } = useParams()

    return (
        <>
            <Box className='bg' xs={{ height: "auto" }}>
                <Box className='form-ui2' sx={{ height: "auto" }}>


                    <Grid container spacing={1}>
                        <Grid item xs={4}
                        >
                            <Saidebar />
                        </Grid>

                        <Grid item xs={8} >
                            {id === "basicDetails" ? <BasicDetails />
                                : id === "incubatorDetails" ? <IncubatorDetails />
                                    : id === "reviewForm" ? <ReviewForm />
                                        : id === "declaration" ? <IncuDeclaration />
                                            : id === "teamDetails" ? <TeamDetails /> : <BasicDetails />
                            }
                        </Grid>
                    </Grid>


                </Box>
            </Box>

        </>
    )
}

export default IncuMain;