
import { apiFunction } from "../../apiCalls/functions";

export const getDropdown = async (url) => {
    return await apiFunction(url, "get", {}, true, false, null)
}

export const getAllDropdown = async () => {
    let education = await getDropdown("/data/higher-education/")
    return {
        education: education.data,
    }
}

export const getRDropdown = async (url) => {
    return await apiFunction(url, "get", {}, true, false, null)
}

export const getRolesDropdown = async () => {
    let roles = await getRDropdown("/admin/data/roles")
    return {
        roles: roles.data,
    }
}