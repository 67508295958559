import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Box, Button, Grid, Typography } from '@mui/material';
import "../../../src/Style/Authentication.css"
import Footer from "../footer"
import { resentOTP, EnterOtp } from "../information/actions"
import { actionNotifier } from "../notification"
import { loaderStart, loaderEnd } from "../loader"
import { REACT_APP_API_URL } from '../../shared/constant';

const IncuOtp = () => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState();

    const handleOnChange = (data) => {
        // console.log('data', data)
        setOtp(data)
    }

    // const RegisterService = async () => {
    //     try {
    //         let email = localStorage.getItem("email")
    //         console.log(email);
    //         let val = {
    //             email: JSON.parse(email),
    //             otp: otp,
    //         }
    //         let res = await axios.post(`${REACT_APP_API_URL}auth/verify-otp`, val);
    //         if (res.status) {
    //             // actionNotifier.success(res.data.message)
    //             navigate("/login")
    //         }
    //         else {
    //             actionNotifier.error(res.message)
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }
    // };

    const RegisterService = async () => {
        // loaderStart()
        navigate("/incubator-login")

    }

    const resendOTP = async () => {
        loaderStart()
        let email = localStorage.getItem("email")
        let mobile = localStorage.getItem("mobile")

        let val = {
            email: JSON.parse(email),
            mobileNumber: JSON.parse(mobile)
        }

        let res = await resentOTP(val)
        if (res.status) {
            actionNotifier.success(res.data.message)
        }
        else {
            actionNotifier.error(res.message)
        }
        loaderEnd()
    }

    return (
        <Box className='backround-ui'>
            <Box className='card-ui'>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Navbar />
                        <Box sx={{ marginLeft: 2 }}>
                            <Footer />
                        </Box>
                    </Grid>

                    <Grid item xs={8} >
                        <div style={{ marginLeft: "30%", marginTop: "30%" }}>
                            <OtpInput
                                value={otp}
                                onChange={handleOnChange}
                                numInputs={4}
                                separator={<span>  </span>}
                                inputStyle={{ height: "50px", margin: 10, fontSize: 30, width: "50px" }}
                            // isInputSecure={true}
                            />
                            <br />
                            <br />
                        </div>
                        <Button
                            style={{ backgroundColor: "#EB4747", width: "696px" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => RegisterService()}
                        // sx={{marginLeft:"-25px"}}
                        >
                            Submit OTP
                        </Button>

                        <br />
                        <br />
                        <Typography onClick={() => resendOTP()} style={{ cursor: "pointer", display: "flex", justifyContent: "flex-start", marginLeft: "45px" }} >Resent OTP</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default IncuOtp