import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  Typography,
  Checkbox,
  TextField
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getReport,
  getReport2,
  getReportExcel,
  getReportExcel2,
  getReportRound2Assignee1,
  getReportRound2Assignee2,
  getReportExcelRound21,
  getReportExcel2Round22
} from "../../basicAction";

import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { Search } from "@mui/icons-material";


const ReportAdmin = (props) => {
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [report, setReport] = useState("");
  const [report2, setReport2] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredResults2, setFilteredResults2] = useState([]);
  const [assigned, setAssigned] = useState("assigne1")
  const [total, setTotal] = useState(0);
  const [A, setA] = useState();
  const [B, setB] = useState();
  const [C, setC] = useState();
  const [D, setD] = useState();
  const [A1, setA1] = useState(); 
  const [B1, setB1] = useState();
  const [C1, setC1] = useState();
  const [D1, setD1] = useState();
  const [AssigneedApplication, setApplicationAssigned] = useState();
  const [EvaluatedApplication, setApplicationEvaluated] = useState();
  const [AssigneedApplication1, setApplicationAssigned1] = useState();
  const [EvaluatedApplication1, setApplicationEvaluated1] = useState();



  const { url } = useParams();
  

  useEffect(() => {
    reportList()
    reportList2()

   

  }, [assigned]);


  useEffect(() => {

    try{
      if (search !== "All" ) {
  
        const result =  report?
        
        report?.filter((list) => {
              return (
                
                categoryFilter == true ? 
                list.A
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.B
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.C
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.D
                  .toLowerCase()
                  .match(search.toLowerCase()):

             
                 
         
                list.application_assigned
                  .toLowerCase()
                  .match(search.toLowerCase()) ||
                  list.application_evaluted.toLowerCase().match(search.toLowerCase())||
                 list.name.toLowerCase().match(search.toLowerCase())
                
              );
            })

            :report2?.filter((list) => {
              return (
                
                categoryFilter == true ? 
                list.A
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.B
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.C
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.D
                  .toLowerCase()
                  .match(search.toLowerCase()):

             
                 
         
                list.application_assigned
                  .toLowerCase()
                  .match(search.toLowerCase()) ||
                  list.application_evaluted.toLowerCase().match(search.toLowerCase())||
                 list.name.toLowerCase().match(search.toLowerCase())
                
              );
            })
         
            
        setFilteredResults(result)
        setFilteredResults2(result)

      
    } else {
      setFilteredResults(report);
      setFilteredResults2(report2);
      
    }
    }catch{
      setFilteredResults(report);
      setFilteredResults2(report2);
    }
  
  }, [search]);



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };
  // console.log(checkValue)

  
  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  const checkassigned = () => {
    if (assigned == "assigne1") {
      setAssigned("assigne2")
    
    }
    else {
      setAssigned("assigne1")
  

    }
  }


  const columnsAssignee2 = [


    {
      id: "Sl. No1",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      cell: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Name",
      cell: (row) => row?.name,
      width: "160px",
      sortable: true,
    },
    {
      name: "Assigned Application " + `(Total - ${EvaluatedApplication1})`,
      cell: (row) => row?.application_assigned,
      width: "260px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Evaluated Application " + `(Total - ${EvaluatedApplication1})`,
      cell: (row) => (row?.application_evaluted),
      width: "260px",
    },
    {
      name: "A  " + `(Total - ${A1})`,
      cell: (row) => row?.A,
      width: "120px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "B  " + `(Total - ${B1})`,
      cell: (row) => row?.B,
      width: "120px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "C  " + `(Total - ${C1})`,
      cell: (row) => row?.C,
      width: "150px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "D" + `(Total - ${D1})`,
      cell: (row) => row?.D,
      width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    // {
    //   name: "Total",
    //   selector: (row) => row?.D,
    //   // width: "160px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    

 
  ];


  const columns = [


    {
      id: "Sl. No1",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      cell: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Name",
      cell: (row) => row?.name,
      width: "160px",
      // sortable: true,
    },
    {
      name: "Assigned Application  " + `(Total - ${AssigneedApplication})`,
      cell: (row) => row?.application_assigned,
      width: "260px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Assigned Evaluated  " + `(Total - ${EvaluatedApplication})`,
      cell: (row) => (row?.application_evaluted),
      width: "250px",
    },
    {
      name: "A  " + `(Total - ${A})`  ,
      cell: (row) => row?.A,
      width: "120px",
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "B  " + `(Total - ${B})` ,
      cell: (row) => row?.B,
      width: "120px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "C  " + `(Total - ${C})` ,
      cell: (row) => row?.C,
      width: "120px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name:"D" + `(Total - ${D})` ,
      cell: (row) => row?.D,
      width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },  
    // {
    //   name: "Total",
    //   selector: (row) => row?.A,
    //   // width: "160px",
    //   sortable: true,
    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    

 
  ];



 

  const reportList = async () => {
    if(newDataFlag == true){

      let data = await getReportRound2Assignee1();
      if (data.status) {
        console.log(data.data)
        setReport(data?.data);
        setFilteredResults(data?.data);
        var A = data?.data?.reduce((sum, i) => (sum += Number(i.A) + total), 0); 
        var B = data?.data?.reduce((sum, i) => (sum += Number(i.B) + total), 0); 
        var C = data?.data?.reduce((sum, i) => (sum += Number(i.C) + total), 0); 
        var D = data?.data?.reduce((sum, i) => (sum += Number(i.D) + total), 0); 
        var applicationAssigned = data?.data?.reduce((sum, i) => (sum += Number(i.application_assigned) + total), 0); 
        var applicationEvaluated = data?.data?.reduce((sum, i) => (sum += Number(i.application_evaluted) + total), 0); 
  
        setA(A);
        setB(B);
        setC(C);
        setD(D);
        setApplicationAssigned(applicationAssigned);
        setApplicationEvaluated(applicationEvaluated);
        
        
      }
    }else{
      let data = await getReport();
      if (data.status) {
        console.log(data.data)
        setReport(data?.data);
        setFilteredResults(data?.data);
        var A = data?.data?.reduce((sum, i) => (sum += Number(i.A) + total), 0); 
        var B = data?.data?.reduce((sum, i) => (sum += Number(i.B) + total), 0); 
        var C = data?.data?.reduce((sum, i) => (sum += Number(i.C) + total), 0); 
        var D = data?.data?.reduce((sum, i) => (sum += Number(i.D) + total), 0); 
        var applicationAssigned = data?.data?.reduce((sum, i) => (sum += Number(i.application_assigned) + total), 0); 
        var applicationEvaluated = data?.data?.reduce((sum, i) => (sum += Number(i.application_evaluted) + total), 0);
  
        setA(A);
        setB(B);
        setC(C);
        setD(D);
        setApplicationAssigned(applicationAssigned);
        setApplicationEvaluated(applicationEvaluated);
        
      }
    }
   
  };



  // const addingNumbers = async (index)=>{

  //   let _report1 = [...report]
  //   _report1[index]['totalA'] = A;
  //   console.log(_report1)
  // }
  

  const reportList2 = async () => {
    if(newDataFlag == true){
      let data = await getReportRound2Assignee2();
      if (data.status) {
        // console.log(data.data)
        setReport2(data?.data);
        setFilteredResults2(data?.data);
        var A1 = data?.data?.reduce((sum, i) => (sum += Number(i.A) + total), 0); 
        var B2 = data?.data?.reduce((sum, i) => (sum += Number(i.B) + total), 0); 
        var C3 = data?.data?.reduce((sum, i) => (sum += Number(i.C) + total), 0); 
        var D4 = data?.data?.reduce((sum, i) => (sum += Number(i.D) + total), 0); 
        var applicationAssigned1 = data?.data?.reduce((sum, i) => (sum += Number(i.application_assigned) + total), 0); 
        var applicationEvaluated1 = data?.data?.reduce((sum, i) => (sum += Number(i.application_evaluted) + total), 0);
  
        setA1(A1)
        setB1(B2)
        setC1(C3)
        setD1(D4)
        setApplicationAssigned1(applicationAssigned1);
        setApplicationEvaluated1(applicationEvaluated1);
      }
    }else{
      let data = await getReport2();
      if (data.status) {
        // console.log(data.data)
        setReport2(data?.data);
        setFilteredResults2(data?.data);
        var A1 = data?.data?.reduce((sum, i) => (sum += Number(i.A) + total), 0); 
        var B2 = data?.data?.reduce((sum, i) => (sum += Number(i.B) + total), 0); 
        var C3 = data?.data?.reduce((sum, i) => (sum += Number(i.C) + total), 0); 
        var D4 = data?.data?.reduce((sum, i) => (sum += Number(i.D) + total), 0); 
        var applicationAssigned1 = data?.data?.reduce((sum, i) => (sum += Number(i.application_assigned) + total), 0); 
        var applicationEvaluated1= data?.data?.reduce((sum, i) => (sum += Number(i.application_evaluted) + total), 0);
  
        setA1(A1)
        setB1(B2)
        setC1(C3)
        setD1(D4)
        setApplicationAssigned1(applicationAssigned1);
        setApplicationEvaluated1(applicationEvaluated1);
      }
    }
   
  };


  const downloadAssignedTableCsv = async () => {
    loaderStart();

    if(newDataFlag == true ){
      const data = await getReportExcelRound21();

      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".xls";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      const data = await getReportExcel();

      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".xls";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
  
   
  };

  const downloadAssignedTableCsv2 = async () => {
    loaderStart();
    if(newDataFlag == true){

      const data = await getReportExcel2Round22();

      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".xls";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      
    const data = await getReportExcel2();

    if (data.status == false) {
      actionNotifier.error("You are not authorized to perform this action");
    } else {
      let fileName = "Application" + Date.now() + ".xls";
      fileDownload(data, fileName);
    }
    loaderEnd();
    }
  
  };

  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    
    setSearch(event.target.value);

    console.log(event.target.value);
  };

 

 


  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };




  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "2px",
          }}
        >
          {/* download excel Start Here*/}

        


   
              


        </Box>


         <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>
           {
            assigned == "assigne1" ? 

           <> <DataTable
            className="dataTables_wrapper"
            component={Paper}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            // title=" Recommended Table"
            columns={columns ? columns : ''}
            data={
              filteredResults ? filteredResults : ''
            }
            pagination
            subHeader
            selectableRows
            // onSelectedRowsChange={handleChange}
            
            subHeaderComponent={
              <>
                {filteredResults && filteredResults ? (
          <Button onClick={downloadAssignedTableCsv} variant="text">
           Click here Download excel
          </Button>
        ) : (
         ''
        )}
                <input
                  type="text"
                  placeholder="Search Here"
                  className="w-25 form-Control"
                  onChange={onChangeHandler}

                  
                />
                <Button sx={{marginLeft:2}} variant="outlined" onClick={checkassigned}>
          {assigned == "assigne1" ? "Assignee1" : "Assignee2"  }
          </Button>


<Select
                sx={{marginLeft:2}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={search}
                  label="Filter"
                  onChange={handleChangeCategory}
                  displayEmpty
                  style={{ textAlign: "left", height:35 }}
                >
                  <InputLabel>Filter Categories</InputLabel>

                  {/* <MenuItem value={10}>Start up</MenuItem> */}
                  <MenuItem value={"a"}>A</MenuItem>
                  <MenuItem value={"c"}>C</MenuItem>
                  <MenuItem value={"d"}>D</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>
             

                
              </>
            }
            subHeaderAlign="center"
            value={search}
            
            
          
            // rowsPerPage={rowsPerPage}
            paginationComponentOptions={paginationComponentOptions}
            // paginationPerPage={
            //   SsuList ? SsuList?.length : assigneeTable?.count
            // }
            
          paginationTotalRows={report ? report?.length : ''}
          paginationPerPage={8}
          // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
          // onChangePage={(val) => setPage(val)}
          /> 
{/*            
                <TextField
                  required
                  value={"Category A Total - " + A}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />
                  <TextField
                  required
                  value={ "Category B Total - " + B}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />

<TextField
                  required
                  value={ "Category C Total - " + C}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />
                <TextField
                  required
                  value={ "Category D Total - " + D}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                /> */}


</>
          
          
          :
<>
          <DataTable
          className="dataTables_wrapper"
          component={Paper}
          fixedHeader
          fixedHeaderScrollHeight="500px"
          // title=" Recommended Table"
          columns={columnsAssignee2 ? columnsAssignee2 : ''}
          data={
            filteredResults2 ? filteredResults2 : ''
          }
          pagination
          subHeader
          selectableRows
          // onSelectedRowsChange={handleChange}
          
          subHeaderComponent={
            <>
              {filteredResults2 && filteredResults2 ? (
        <Button onClick={downloadAssignedTableCsv2} variant="text">
         Click here Download excel
        </Button>
      ) : (
       ''
      )}
              <input
                type="text"
                placeholder="Search Here"
                className="w-25 form-Control"
                onChange={onChangeHandler}

                
              />
            <Button sx={{marginLeft:2}} variant="outlined" onClick={checkassigned}>
          {assigned == "assigne1" ? "Assignee1" : "Assignee2"  }
          </Button>

<Select
              sx={{marginLeft:2}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={search}
                label="Filter"
                onChange={handleChangeCategory}
                displayEmpty
                style={{ textAlign: "left", height:35 }}
              >
                <InputLabel>Filter Categories</InputLabel>

                {/* <MenuItem value={10}>Start up</MenuItem> */}
                <MenuItem value={"a"}>A</MenuItem>
                <MenuItem value={"c"}>C</MenuItem>
                <MenuItem value={"d"}>D</MenuItem>
                <MenuItem value={"All"}>All</MenuItem>
              </Select>
           

              
            </>
          }
          subHeaderAlign="center"
          value={search}
          
          
        
          // rowsPerPage={rowsPerPage}
          paginationComponentOptions={paginationComponentOptions}
          // paginationPerPage={
          //   SsuList ? SsuList?.length : assigneeTable?.count
          // }
          
        paginationTotalRows={report2 ? report2?.length : ''}
        paginationPerPage={8}
        // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
        // onChangePage={(val) => setPage(val)}
        />
        
        {/* <TextField
                  required
                  value={"Category A Total - " + A1}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />
                  <TextField
                  required
                  value={ "Category B Total - " + B1}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />

                 <TextField
                  required
                  value={ "Category C Total - " + C1}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                />
                <TextField
                  required
                  value={ "Category D Total - " + D1}
                  disabled={true}
                  type="text"
                  fullWidth
                  // label="Category"
                /> */}
        </>
           }
           

        
          
        
          
          </TableContainer>
        </Box>



        



      </div>

      
      
     

    </>
  );
};

export default ReportAdmin;
