import React, { useState } from 'react';
import NumberOFFounders from './teamDetails/Number-of-Founders';
import Data from "./Data.json"
import { Dialog, DialogContentText, DialogTitle, DialogActions, DialogContent, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import "../../src/Style/Auth.css"

function TableData() {
	const [FounderData, setFounderData] = useState(Data);
	const [open, setOpen] = useState(false);

	const handleClickToOpen = () => {
		setOpen(true);
	};

	const handleToClose = () => {
		setOpen(false);
	};

	const tableRows = FounderData.map((info) => {
		return (
			<tr>
				<td>{info.id}</td>
				{/* <td>{info.numberOfFounders}</td> */}
				<td>{info.name}</td>
				<td>{info.aadharNo}</td>
				<td>{info.penNo}</td>
				<td>{info.dob}</td>
				<td>{info.qaulification}</td>
				<td>{info.industry}</td>
				<td>{info.gender}</td>
				<td>{info.category}</td>
			

			</tr>
		);
	});

	const addRows = (data) => {
		const totalStudents = FounderData.length;
		data.id = totalStudents + 1;
		const updatedFounderData = [...FounderData];
		updatedFounderData.push(data);
		setFounderData(updatedFounderData);
	};

	return (
		<div >
			
			 <div style={{ display: "flex", flexDirection: "row",justifyContent:"space-around" }}>
				<table>
					<thead>
						<tr style={{fontSize:"14px"}}>
							{/* <th>Id *</th> */}
							<th>Name</th>
							<th>Aadhar No</th>
							<th>Pen No*</th>
							<th>Date of Birth</th>
							<th>Qaulification</th>
							<th>Industry </th>
							<th>Gender</th>
							<th>Category </th>
							
							
						</tr>
					</thead>
					<tbody>{tableRows}</tbody>
				</table>
			</div> 




		</div>
	);
}

export default TableData;
