import React, { useState, useEffect } from 'react';
import { Box, TextField, Stack, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom"
import "../../src/Style/Authentication.css"
import Navbar from './Navbar';
import axios from 'axios';
import { REACT_APP_API_URL } from "../shared/constant"
import Footer from "./footer"
import { actionNotifier } from "./notification"



export default function Registration() {
    const navigate = useNavigate()
    const [user, setUser] = useState({});
    const { nameOfStartUp, firstName, middleName, lastName, mobileNumber, email, adhaarNo, isRegisteredEntity, isGreaterThan10Year, isTurnoverGreaterThan100Crores } = user;



    const onStartupIndia = () => {
        let token = localStorage.getItem("startup-india")
        token = JSON.parse(token)
        if (token) {
            const config = {
                headers: {
                    'x-auth-token': token,
                }
            };
            axios.get(`https://api.startupindia.gov.in/sih/api/auth/sso/services/get/userDetails`, config)
                .then(result => {
                    let startupIndia = { ...result.data }
                    const initialValue = {
                        // id: "",
                        nameOfStartUp: startupIndia ? startupIndia?.legalName : '',
                        firstName: startupIndia ? startupIndia?.name.substring(0, 10) : "",
                        middleName: "",
                        lastName: "",
                        mobileNumber: startupIndia ? startupIndia?.phone : "",
                        email: startupIndia ? startupIndia?.email : "",
                        adhaarNo: startupIndia ? startupIndia?.udyogAadhar : "",
                        authToken: token
                    }
                    setUser(initialValue)
                })
                .catch(error => {
                    // console.log(error.response.data);
                    actionNotifier.error(error.response.data.message)
                })
        }
    }

    useEffect(() => {
        onStartupIndia()
    }, [])

    const addUserDetails = (e) => {
        e.preventDefault()
        let _user = { ...user }
        console.log(_user)
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
            if (_user.adhaarNo.length == 12) {
                if (_user.mobileNumber.length == 10) {
                    localStorage.getItem("email", JSON.stringify(user.email));
                    let basicReg = localStorage.getItem("startup-reg");
                 
                    basicReg = JSON.parse(basicReg)
                    // console.log(basicReg)
                    _user = {
                        ...user,
                        ...basicReg,
                       
                    }
                    // console.log(_user)
                    axios.post(`${REACT_APP_API_URL}auth/register`, _user)
                        .then(result => {
                            console.log(result)
                            navigate("/otp")
                            // localStorage.removeItem('startup-india')
                        })
                        .catch(error => {
                            console.log(error.response.data);
                            actionNotifier.error(error.response.data.message)
                        })
                }
                else {
                    actionNotifier.error("Mobile Number invalid")
                }
            }
            else {
                actionNotifier.error("Adhar invalid")
            }
        } else {
            actionNotifier.error("Email Invalid")
        }
    }

    const onValue = (e) => {
        console.log(e.target.value);
        setUser({ ...user, [e.target.name]: e.target.value })
        console.log(user);
    }

    useEffect(() => {
        localStorage.setItem("email", JSON.stringify(user.email));
        localStorage.setItem("mobile", JSON.stringify(user.mobileNumber))

        // storing input name
    }, [user.email, user.mobileNumber]);

    return (
        <Box className='backround-ui'>
            <Box className='form-ui1' >
                <Grid container spacing={8} >
                   
                        <Grid item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <Navbar />
                            <Box sx={{ marginLeft: 2 }}>
                                <Footer />
                            </Box>

                        </Grid>

                        <Grid item xs={8} >
                            <form onSubmit={addUserDetails}>
                                <br />
                                <br />
                                <Box  >
                                    <Typography sx={{ marginLeft: "60px", color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "80%", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }}>Register Your Startup</Typography>
                                    <Stack
                                        // className='form-ui'
                                        sx={{ '& > :not(style)': { m: 1, width: '80ch' }, display: "flex", flexDirection: "column", marginLeft: "50px !important" }}
                                        noValidate
                                        autoComplete="off"

                                    ><br />

                                        <Box style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }} >
                                            <TextField
                                                // style={{ width: "40%" }}
                                                required
                                                type="text"
                                                label="First Name"
                                                name="firstName"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={firstName || ''}
                                                onChange={(e) => onValue(e)} />

                                            <TextField
                                                // style={{ width: "40%" }}
                                                id="outlined-basic"
                                                label="Middle Name"
                                                variant="outlined"
                                                type="text"
                                                value={middleName || ''}
                                                name="middleName"
                                                onChange={(e) => onValue(e)}
                                            />
                                            <TextField
                                                // style={{ width: "40%" }}
                                                type="text"
                                                id="outlined-basic"
                                                label="Last Name"
                                                variant="outlined"
                                                value={lastName || ''}
                                                name="lastName"
                                                onChange={(e) => onValue(e)} />
                                        </Box>
                                        <br />
                                        <Box style={{
                                            // display: "flex",
                                            // justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }}>
                                            <TextField
                                            
                                                 id="outlined-basic"
                                                label="Name of startup"
                                                variant="outlined"
                                                value={nameOfStartUp || ''}
                                                name="nameOfStartUp"
                                                onChange={(e) => onValue(e)}
                                                type="text"
                                                fullWidth
                                            />
                                            <br />
                                            <br />

                                            <TextField  type="number"
                                                required
                                                id="outlined-basic"
                                                label="Mobile Phone"
                                                variant="outlined"
                                                fullWidth
                                                value={mobileNumber || ''}
                                                name="mobileNumber"
                                                onChange={(e) => onValue(e)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                            />
                                            <br />
                                            <br />

                                            <TextField  id="outlined-basic"
                                                required
                                                type="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={email || ''}
                                                name="email"
                                                onChange={(e) => onValue(e)}
                                            />
                                            <br />
                                            <br />

                                            <TextField
                                                
                                                id="outlined-basic" type="number"
                                                label="Aadhar No"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={adhaarNo || ''}
                                                name="adhaarNo"
                                                onChange={(e) => onValue(e)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                }}
                                            />
                                        </Box>
                                        <br />
                                        <br />

                                        <div style={{ margin: "auto", marginTop: 20,  marginLeft: "-26px",width: "100%"  }}>
                                            <Button type="submit" style={{ backgroundColor: "#EB4747", width: "80%" }} variant="contained" fullWidth>Get OTP</Button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: 25 ,width: "100%"}}>
                                            <a href="/login" style={{ color: "#EB4747",width: "90%" }}>Already have account ?Login Here</a>
                                        </div>
                                    </Stack>
                                </Box>
                            </form>
                        </Grid>
                
                </Grid>
            </Box>
        </Box>
    );
}