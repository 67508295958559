import React, { useState, useEffect } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";

import { useNavigate, Link, NavLink } from "react-router-dom"
import EntityVentureDetails from "./Entity-Venture-Details"
import logo from "../Image/logo.png"
import "../../src/Style/Auth.css"
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getAccountData, getAllRecordsData,userMeetingDetails } from "./basicAction"
import Modal from '@mui/material/Modal';
import { actionNotifier } from "./notification"
import { loaderStart, loaderEnd } from "./loader"
import { changePassword } from "./information/actions"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { changeTzToNormalDefault } from "../../src/shared/functions";
import Linkify from 'react-linkify';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height:600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};
// const sideMenu= {
//         BasicDetails: true,
//         EntityVenture: false,
//         FounderDetail: false,
//         ConceptandIdea: false,
//         BusinessModel: false,
//         TurnoverEmployement: false,
//         ReviewForm: false,
//         Declaration: false
// }

const Saidebar = () => {
    const navigate = useNavigate()
    const [sideMenu, setSideMenu] = useState({
        BasicDetails: true,
        EntityVenture: false,
        FounderDetail: false,
        ConceptandIdea: false,
        BusinessModel: false,
        TurnoverEmployement: false,
        ReviewForm: false,
        Declaration: false
    })
    const [meetingDetails, setMeetingDetails] = useState()
    const [currency, setCurrency] = useState();
    const [category, setCategory] = useState();
    // const [value, setValue] = React.useState(0);
    const [isRegEntity, setIsRegEntity] = useState(true)
    const [data, setData] = useState({})
    // const [active, setActive] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [allrecord, setAllRecord] = useState()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        rePassword: ""
    })



    const handleChange = (event) => {
        setCategory(event.target.value);
        setCurrency(event.target.value);
        //  setValue(newValue);

    }
    useEffect(() => {
        getInitialData()
        getAllRecords()
        getMeetingDetails()

    }, [])


    const getMeetingDetails = async () => {
        let meeting = await userMeetingDetails()
        // console.log(meeting.data)
        setMeetingDetails(meeting);
   
    }

   
    let time = new Date (meetingDetails?.data?.meetingAt).toUTCString()
    let dateTime = time;

    const getInitialData = async () => {
        let initial = await getAccountData()
        console.log(initial)
        if (initial.status) {
            setIsRegEntity(initial.data.isRegisteredEntity == "yes" ? true : false)
        }
    }

    const getAllRecords = async () => {
        let allrecords = await getAllRecordsData()
       
        setAllRecord(allrecords);
        console.log(allrecords)
    }
    const [age, setAge] = React.useState('');

    useEffect(() => {
        let data = localStorage.getItem("startup-india-token")
        data = JSON.parse(data)
        if (data) {
            console.log(data?.data)
            setData(data?.data)
        }
    }, [])
  


    const handleSet = (event) => {
        setAge(event.target.value);
    };

 

   

    const onValue = (e, name) => {
        setState({ ...state, [name]: e.target.value })
    }

    const onSubmitData = async (e) => {
        e.preventDefault()
   

        if(state.currentPassword === state.newPassword){
            actionNotifier.error("New Password and Current Password cannot be same")

        }
        else{
            if (state.newPassword === state.rePassword) {
                let _state = { ...state }
                delete _state.rePassword;
                let res = await changePassword(_state)
                if (res.status) {
                    actionNotifier.success("Success")
                    loaderStart()
                    setModal(false)
                }
                else {
                    actionNotifier.error(res.message)
                }
                console.log(res)
            }
            else {
                actionNotifier.error("Both password are not same")
            }
            loaderEnd()
        }
     
    }

    return (
        <>

            <Box className=''>
                <Box className='' >
                    <Box sx={{}}>
                        <div style={{ position: "relative" }}>
                            <a href="/"><img src={logo} alt="Paris"  className="logo" ></img> </a>
                            <div className='profile'>
                                <div>
                                    <h4 style={{ zIndex: "8888", top: "29%", fontSize: "16px", marginTop: "29%",  }} >Hi {data.firstName}</h4>
                                </div>
                                <div className="avatar-style">
                                    <Box sx={{ minWidth: 20, border: "none", outline: "none" }}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                            <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}>
                                                    {data.firstName ? data.firstName[0] : ""}
                                                </Avatar>
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                id="account-menu"
                                                open={open}
                                                onClose={handleClose}
                                                onClick={handleClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem onClick={() => setModal(true)}>
                                                    Change Password
                                                </MenuItem>
                                                <MenuItem onClick={() => navigate("/logout")}>
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </FormControl>
                                    </Box>
                                </div>

                            </div>

                            <br />
                            <br />
                                
                            {
           allrecord?.data.acknowledgement  ? <>  <ul style={{ color: "#fff" }}> 
            <li><NavLink className='disable' style={{ color: "black" , margin:"1px" , marginTop:"70px"    }} to='/information'>Dashboard</NavLink></li>
           
            <li><NavLink className='disable' style={{ color: "black" , margin:"10px"  }} to="/main/reviewForm">Review Form</NavLink> </li> 
             
            <li><NavLink className='disable' style={{ color: "black" , margin:"1px"  }} to='/main/declaration'>Acknowledgement Print</NavLink></li>
           {
             meetingDetails?.data.length >=1  ?
            <li> 
          <button  onClick={() => setModal1(true)} style={{ color: "black" ,  margin:"11px", backgroundColor:'rgba(0, 0, 0, 0)', border:'none', fontSize:'19px' }}>
            Meeting Details</button>
        </li> : null
           }
           


            <br/>

           
            </ul> 
           
             </>  : 
            
            
            <ul style={{ color: "#fff" }}>


            <li><NavLink style={{ color: "black", marginTop: "100px" }} to="/main/basicDetails"><span>Basic Details</span></NavLink></li>
            <hr />
            {/* {
                allrecord ?
                    <><li disabled="true"><NavLink style={{ color: "black", marginTop: "100px" }} to="/main/basicDetails">Basic Details</NavLink></li>
                        <hr /></> : "Basic detail Gayab"
            } */}
            <li><NavLink style={{ color: "black" }} to="/main/entityVentureDetails">Entity/Venture Details</NavLink></li>
            <hr />
            {/* {
                allrecord ?
                    <> <li><NavLink style={{ color: "black" }} to="/main/entityVentureDetails">Entity/Venture Details</NavLink></li>
                        <hr /></> : "entity venture gayab"
            } */}
            <li><NavLink className='disable' style={{ color: "black" }} to="/main/teamDetails">Founder Details</NavLink></li>
            <hr />
            <li><NavLink className='disable' style={{ color: "black" }} to="/main/conceptIdeasOfStartup">Concept & Idea of Startup</NavLink></li>
            <hr />
            {
                isRegEntity
                    ?
                    <>
                        <li><NavLink className='disable' style={{ color: "black" }} to="/main/businessModels">Business Models</NavLink></li>
                        <hr />

                        <li><NavLink className='disable' style={{ color: "black" }} to="/main/turnoverAndEmployment">Turnover and Employment</NavLink></li>
                        <hr />
                    </>
                    : null

            }
            <li><NavLink className='disable' style={{ color: "black" }} to="/main/reviewForm">Review Form</NavLink></li>
            <hr />
            <li><NavLink className='disable' style={{ color: "black" }} to="/main/declaration">Declaration</NavLink></li>
        </ul>

                             }
                           
                        </div>

                    </Box>
                </Box>
                {
                    modal
                        ?
                        <Modal
                            open={modal}
                            onClose={() => setModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Change Password
                                </Typography>
                                <br />
                                <form onSubmit={onSubmitData}>
                                    <TextField
                                        id="outlined-basic"
                                        label={"Current Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.currentPassword}
                                        onChange={(e) => onValue(e, "currentPassword")}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        id="outlined-basic"
                                        label={"New Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.newPassword}
                                        onChange={(e) => onValue(e, "newPassword")}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        id="outlined-basic"
                                        label={"Retype Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.rePassword}
                                        onChange={(e) => onValue(e, "rePassword")}
                                    />
                                    <br />
                                    <br />
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: "#EB4747" }}
                                        variant="contained"
                                        fullWidth
                                    >Submit</Button>
                                </form>
                            </Box>
                        </Modal>
                        : null

                }

{
                    modal1
                        ?
                        <Modal
                            open={modal1}
                            onClose={() => setModal1(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style2}>
                            <Typography style={{  marginLeft: 370, color:"Purple", }} id="modal-modal-title" variant="h4" component="h2">
                                 <u><b>  Meeting Details</b></u> 
                                </Typography>
                            <VerticalTimeline>
                            {meetingDetails?.data &&
                  meetingDetails?.data.map((row, index) => (
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'yellow', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date={row ? row?.meetingAt ? new Date(row?.meetingAt).toUTCString().slice(0,-3) : null :"2022"  }
                    // date="2010 - 2011"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    
                  >
                    <u><h3 className="vertical-timeline-element-title">Message</h3></u>
                    <p>{ row  ?<Linkify>{row?.meetingLink}</Linkify>  : null}</p>
                
                  </VerticalTimelineElement>
                    
                  ))}
 

  {/* <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'yellow', color: 'black' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date={meetingDetails ? dateTime ? dateTime : null :"2022"  }
    // date="2010 - 2011"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    
  >
    <u><h3 className="vertical-timeline-element-title">Meeting Date & Time</h3></u>
    <p>Mesage - {meetingDetails  ? meetingDetails?.data?.meetingLink : null}</p>

  </VerticalTimelineElement> */}
  
  
  {/* <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2010 - 2011"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Art Director</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      Creative Direction, User Experience, Visual Design, SEO, Online Marketing
    </p>
  </VerticalTimelineElement> */}
 
  

</VerticalTimeline>
                            </Box>
                        </Modal>
                        : null

                }
            </Box>


        </>
    )
}

export default Saidebar;
