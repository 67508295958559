import React, { useEffect, useState } from "react";
import {
    Grid,
    InputLabel,
    Select,
    Box,
    TextField,
    Stack,
    MenuItem,
    Button,
    Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FILE_URL, REACT_APP_API_URL } from "../../shared/constant"

import "../../../src/Style/Auth.css";
const Address = (props) => {
    //get api for categoryes
    const [categoryes, setcategoryes] = useState({});

    //get api for genders
    const [genders, setGenders] = useState({});

    //get api for religiones
    const [religiones, setReligion] = useState({});

    useEffect(() => {
        if (props.token) {
            GenderApi();
            ReligionApi();
            CategoryApi();
        }
    }, [props.token]);

    //get api for  Gender name
    const GenderApi = () => {
        axios
            .get(`${REACT_APP_API_URL}data/gender/`, {
                headers: { Authorization: `Bearer ${props.token}` },
            })
            .then((response) => response.data)
            .then((data) => {
                setGenders(data);
                console.log(data, "Gender Name");
                // console.log(props.basicDetails.genderId)
            });
    };

    //get api for  ReligionApi name
    const ReligionApi = () => {
        axios
            .get(`${REACT_APP_API_URL}data/religion/`, {
                headers: { Authorization: `Bearer ${props.token}` },
            })
            .then((response) => response.data)
            .then((data) => {
                setReligion(data);
                console.log(data, "religion");
                // console.log(props.basicDetails.religionId)
            });
    };
    //get api for  Category name
    const CategoryApi = () => {
        axios
            .get(`${REACT_APP_API_URL}data/category/`, {
                headers: { Authorization: `Bearer ${props.token}` },
            })
            .then((response) => response.data)
            .then((data) => {
                setcategoryes(data);
                console.log(data, "Category name");
            });
    };

    return (
        <>
            <TextField
                select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={props.basicDetails.genderId}
                fullWidth
                required
                label="Gender"
                style={{ textAlign: 'left' }}
                onChange={(e) => {
                    props.setBasicDetails({ ...props.basicDetails, genderId: e.target.value })
                }}
            >
                {
                    genders?.data?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))
                }
            </TextField>
            <br />
            <br />

            {/* <TextField
                select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={props.basicDetails.religionId}
                fullWidth
                required
                label="Religion"
                style={{ textAlign: 'left' }}
                onChange={(e) => {
                    props.setBasicDetails({ ...props.basicDetails, religionId: e.target.value })
                }}
            >
                {
                    religiones?.data?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))
                }
            </TextField> */}
           
            <TextField
                select
                value={props.basicDetails.categoryId}
                fullWidth
                label="Category"
                style={{ textAlign: 'left' }}
                required
                onChange={(e) => {
                    props.setBasicDetails({ ...props.basicDetails, categoryId: e.target.value })
                }}
            >
                {
                    categoryes?.data?.map((option) => (
                        <MenuItem key={option.name} value={option.id}>
                            {option.name}

                        </MenuItem>
                    ))
                }
            </TextField>

            <br />
            <br />

            <Typography className="headLineLeft">
                Upload highest Qualification certificate* (in Pdf Only)
            </Typography>
            <TextField
                color="warning"
                type="file"
                label={" "}
                inputProps={{ accept: "application/pdf" }}
                fullWidth
                required={props.basicDetails.qualificationUploadUrl ? false : true}
                onChange={(e) => props.onValueFile(e)}
                name="qualificationUpload"
            />
            {
                props.basicDetails.qualificationUploadUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props.basicDetails.qualificationUploadUrl?.includes("https") ? props.basicDetails.qualificationUploadUrl : FILE_URL + props.basicDetails.qualificationUploadUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }
            <br />
            
            {/* <br /> */}
            <Typography className="headLineLeft">
                Upload Caste Certificate (Not Required For General Category) (in Pdf Only)
            </Typography>
            <TextField
                color="warning"
                type="file"
                label={" "}
                inputProps={{ accept: "application/pdf" }}
                name="casteCertificateUpload"
                fullWidth
                onChange={(e) => props.onValueFile(e)}
            />
              <br />
            {
                props.basicDetails.casteCertificateUploadUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props.basicDetails.casteCertificateUploadUrl?.includes("https") ? props.basicDetails.casteCertificateUploadUrl : FILE_URL + props.basicDetails.casteCertificateUploadUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }
            <br />
            {/* <br /> */}
            <Typography className="headLineLeft">Upload PAN Card (Pdf Only)</Typography>
            <TextField
                label={" "}
                color="warning"
                name="panCardUpload"
                type="file"
                inputProps={{ accept: "application/pdf, image/*" }}
                fullWidth
                onChange={(e) => props.onValueFile(e)}
            />
            
            {
                props.basicDetails.panCardUploadUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props.basicDetails.panCardUploadUrl?.includes("https") ? props.basicDetails.panCardUploadUrl : FILE_URL + props.basicDetails.panCardUploadUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }
            <br />
            <br />
            {/* <br /> */}
            <Typography className="headLineLeft">
                Upload Passport Photo*(in Jpg Only)
            </Typography>
            <TextField
                color="warning"
                type="file"
                label={" "}
                fullWidth
                inputProps={{ accept: "image/*" }}
                name="photoUpload"
                onChange={(e) => props.onValueFile(e)}
                required={props.basicDetails.photoUploadUrl ? false : true}
            />
            {
                props.basicDetails.photoUploadUrl
                    ?
                    <div className="view-file" >
                        <a target="_blank" rel="noreferrer" href={props.basicDetails.photoUploadUrl?.includes("https") ? props.basicDetails.photoUploadUrl : FILE_URL + props.basicDetails.photoUploadUrl}>
                            View File
                        </a>
                    </div>
                    : null

            }

        </>
    )
}

export default Address;