import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getApplicationList,
  searchApplication,
  getSsuList,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  getEvaluatedListCsv,
  getEvaluatedListCsvSsu,
  getAssignedApplicationDownloadedExcel,
  getAdminProfileDetail
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  AcknowledgementId
} from "../actions";


const RecommendedPage = (props) => {
  const dispatch = useDispatch();
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [dateSelector, setDateSelector] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [evaluateDetails, setEvaluateDetails] = useState();
  const [total, setTotal] = useState(0);
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [startDate, handleDateChange] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [modal, setModal] = useState(false);
  const [endDate, handleDateChange2] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [profile, setProfile] = useState("");
  const [isRecognition, setIsRecognition] = useState(false)
  const [filteredResults, setFilteredResults] = useState([]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [datesFil, setDatesFil] = useState(true);

 


  const { id } = useParams();


  useEffect(() => {
    getAllApplication();
    ssulist();
    assigneeList();
    GetAssignedList();
    getAdminProfile();
    if(dateSelector == false){
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
    // alert(newDataFlag);

  }, [page, rowsPerPage,dateSelector,isRecognition,flags,newDataFlag]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())

  useEffect(() => {
    try{
      if (search !== "All") {
  
        const result = SsuList
          ? SsuList?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()): 
         
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
          : assigneeTable?.rows?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()):
                 list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            });
            SsuList?
        setFilteredResults(result)
        :
        setFilteredResultsAasinTable(result);
      
    } else {
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
    }
    catch{
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
   
  }, [search]);




  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };



  
  const DateRange = ()=>{
    loaderStart()
   setModal(false) 
   var startDates = changeTzToNormalDefault(startDate).date;
var endDates = changeTzToNormalDefault(endDate).date;
   if (datesFil == true) {
  
    const result = SsuList ?
    SsuList?.filter(a => {
      var dateApi = changeTzToNormalDefault(a?.evaluatedAt).date;
      return (
        dateApi >= startDates && dateApi <= endDates
      
        );
     
      
    }) :
    assigneeTable?.rows?.filter(a => {
      var dateApi = changeTzToNormalDefault(a?.evaluatedAt).date;
      return (
        dateApi >= startDates && dateApi <= endDates
        
      
        );
     
      
    });

   
    setFilteredResults(result);
    setFilteredResultsAasinTable(result);
  console.log(result)
  
} else {
  setFilteredResults(SsuList);
  setFilteredResultsAasinTable(assigneeTable?.rows);
  alert('yes')

}
loaderEnd()
  }

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      setProfile(data?.data);
      console.log(data.data)
    }
  };


  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Submission Date",
      selector: (row) =>
        changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width: "140px",
    },
    
    {
      name: "Evaluated At",
      selector: (row) =>
        changeTzToNormalDefault(row?.evaluatedAt).date,
      width: "140px",
      sortable: true,
    } ,
 
     {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
      width: "150px",
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
   
    {
      name: "Category1",
      selector: (row) => row?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },


    // {
    //   name: "Total Marks",
    //   selector: (row) =>
    //     row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
    //   width: "125px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    {
      name: "Total Marks1",
      selector: (row) =>
        // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
        row?.Evaluations?.filter((fname) =>  fname?.evaluatedById ==  row?.assignedTo1Id)
    .reduce((sum, i) => (sum += i.marks + total),0),
      width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Assignee2.",
      cell: (row) => row?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,
    },

    {
      name: "Category2",
      selector: (row) => row?.applicationCategory2?.name,
      width: "139px",
      sortable: true,
    },

    {
      name: "Total Marks2",
      selector: (row) =>
        // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
        row?.Evaluations?.filter((fname) =>  fname?.evaluatedById ==  row?.assignedTo2Id)
    .reduce((sum, i) => (sum += i.marks + total),0),
      width: "135px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    // {
    //   name: "Final Marks",
    //   selector: (row) =>
    //     // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
    //     row?.Evaluations?.reduce((sum, i) => (sum += i.marks / 2 + total ),0),
    //   width: "135px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },

    // {
    //   name: "Final Category",
    //   selector: (row) =>
    //     row?.Evaluations?.reduce((sum, i) => (sum += i.marks / 2 + total ),0) <= 17 ?
    //     'D' :  row?.Evaluations?.reduce((sum, i) => (sum += i.marks / 2 + total ),0) <= 19 ?
    //     'C':'A'
        
    //     ,
    //   width: "155px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },


    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    // {
    //   name: "Action",
    //   selector: (row) =>
    //     assigneeTable ? (
    //       <NavLink target={"_blank"} to={`/evaluation/${row?.id}`}>
    //         <Button onClick={ dispatch(AcknowledgementId(row?.account?.acknowledgement?.id)) }>
    //           <FaEye />
    //           Update
    //         </Button>
    //       </NavLink>
    //     ) : '',
    //   width: "130px",
    //   sortable: true,

    //   // format: (value) => value.toLocaleString('en-US'),
    // },
  ];



  const columnSsuMember = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      cell: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Submission Date",
      cell: (row) =>
        changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width: "140px",
    },
    {
      name: "Evaluated At",
      selector: (row) =>
        changeTzToNormalDefault(row?.evaluatedAt).date,
      width: "140px",
      sortable: true,
    } ,

  
      {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
      width: "150px",
    },
    {
      name: "Name",
      selector: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "160px",
      sortable: true,
    },
    {
      name: "Phone No.",
      cell: (row) => row?.account?.mobileNumber,
      width: "130px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: <center>Applied in Policy 2017.</center>,
      cell: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "180px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.assignedTo1?.name,
      width: "130px",

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Assignee 2.",
      cell: (row) => row?.assignedTo2?.name,
    },
    {
      name: "Category Type",
      selector: (row) => row?.assignedTo2Id == profile?.id && row?.isEvaluated2 == true ? 
      
      row?.applicationCategory2?.name :row?.applicationCategory?.name ? row?.applicationCategory?.name :'' ,
      width: "145px",
      sortable: true,
    },
    {
      name: "Total Marks",
      selector: (row) =>
        row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
      width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Action",
      cell: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    // {
    //   name: "Action",
    //   cell: (row) =>
    //     assigneeTable ? (
    //       <NavLink target={"_blank"} to={`/evaluation/${row?.id}`}>
    //         <Button onClick={dispatch(AcknowledgementId(row?.account?.acknowledgement?.id))}>
    //           <FaEye />
    //           Update
    //         </Button>
    //       </NavLink>
    //     ) : '',
    //   width: "130px",

    //   // format: (value) => value.toLocaleString('en-US'),
    // },
  ];
  



  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      // console.log(data.data)
      setAssignee(data);
    }
  };



  const downloadAssignedTableCsv = async () => {
    if(flags == true){

      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
  
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
      
      };

  const downloadAssignedTableCsvSsu = async () => {

    if(flags == true){
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 

  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
  
      const data = newDataFlag == true ?  await getEvaluatedListCsvSsu({round:2},data1) :
      await getEvaluatedListCsvSsu({round:1},data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
   
  };

  const getAllApplication = async () => {

    if(flags == true){
      let val = {
        search: search,
        isRecognition:true
      };
      let data = await getApplicationList(
        { page: page, items: 50000 },
        val
      );
      if (data.status) {
        setAllapplication({ ...data?.data });
        // console.log(data.data)
      }
    }
    else{
      let val = {
        search: search,
        isRecognition:false
      };
      let data = await getApplicationList(
        { page: page, items: 50000 },
        val
      );
      if (data.status) {
        setAllapplication({ ...data?.data });
        // console.log(data.data)
      }
    }
   
  };

  const ssulist = async () => {

    if(flags == true){
      let val = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
  
      let ssulist = newDataFlag == true ? await getSsuList({
        round:2
      },val) :
      await getSsuList({
        round:1
      },val)
      // console.log(categoryMenu.categories)
      setSsuList(ssulist?.data?.data);
      setFilteredResults(ssulist?.data?.data);
      setSSuTotal(ssulist?.data?.data);
    }else{
      let data = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
  
      let ssulist = newDataFlag == true ? await getSsuList({
        round:2
      },data) :
      await getSsuList({
        round:1
      },data)
      // console.log(categoryMenu.categories)
      setSsuList(ssulist?.data?.data);
      setFilteredResults(ssulist?.data?.data);
      setSSuTotal(ssulist?.data?.data);
    }
 
    
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const GetAssignedList = async () => {
    loaderStart();
    if(flags == true){
      let val = {
       
        isRecognition:true
        }
        
      let data = 
      newDataFlag == true ? await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:2
      },val) :
      await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:1
        
      },val)
    
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        // console.log(data.data);
        
      }
    }else{
      let val = {
       
        isRecognition:false
        }
        let data = 
        newDataFlag == true? await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:2
        },val) :
        await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:1
        },val)
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        console.log(data.data);
        
      }
    }
    
    loaderEnd();
   
  };


  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    
    setSearch(event.target.value);

    console.log(event.target.value);
    
  };



  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "80%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
        


        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>

            {
              filteredResults ?
              <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                filteredResults ? filteredResults : ''
              }
              pagination
              subHeader
              subHeaderComponent={
                <>
                     {SsuList && SsuList ? (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          ) : (
            <Button onClick={downloadAssignedTableCsv} variant="text">
              Click here to download excel
            </Button>
          )}
          
          

<input
                    type="text"
                    placeholder="Search Here"
                    className="w-19 form-Control"
                    onChange={onChangeHandler}
                    style={{marginRight:18}}
                  />
                 


          <Button  variant="outlined"
              style={{backgroundColor:'blue',color:'white', marginLeft:10}}
               onClick={() => {
                setModal(true);
                setDateSelector(true);
              
              }}
              
              >
                Select Date Range
              </Button>
              <Button  style={{backgroundColor:'blue',color:'white',marginLeft:10}}
              variant="outlined"
              
                 onClick={() => {
                  setDateSelector(false);
            
                }}>
                Reset
              </Button>
               
                
                  
       

                  <Select
                  sx={{marginLeft:3}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={search}
                    label="Filter"
                    onChange={handleChangeCategory}
                    displayEmpty
                    style={{ textAlign: "left",height:35 }}
                  >
                    <InputLabel>Filter Categories</InputLabel>

                    {/* <MenuItem value={10}>Start up</MenuItem> */}
                    <MenuItem value={"a"}>A</MenuItem>
                    <MenuItem value={"b"}>B</MenuItem>
                    <MenuItem value={"c"}>C</MenuItem>
                    <MenuItem value={"d"}>D</MenuItem>
                    <MenuItem value={"All"}>All</MenuItem>
                  </Select>

              

                

           
              

                 
                </>
              }
              subHeaderAlign="center"
              value={search}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
            paginationTotalRows={SsuList ? filteredResults?.length : assigneeTable?.count}
            paginationPerPage={8}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />
            :
            <DataTable
            className="dataTables_wrapper"
            component={Paper}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            // title=" Recommended Table"
            columns={columnSsuMember ? columnSsuMember : ''}
            data={
              filteredResultsAasinTable ? filteredResultsAasinTable : ''
            }
           
            pagination
            subHeader
            subHeaderComponent={
              <>
                   {SsuList && SsuList ? (
          <Button onClick={downloadAssignedTableCsvSsu} variant="text">
            Click here to download excel
          </Button>
        ) : (
          <Button onClick={downloadAssignedTableCsv} variant="text">
            Click here to download excel
          </Button>
        )}
        
        

<input
                  type="text"
                  placeholder="Search Here"
                  className="w-19 form-Control"
                  onChange={onChangeHandler}
                  style={{marginRight:18}}
                />
               


        <Button  variant="outlined"
            style={{backgroundColor:'blue',color:'white', marginLeft:10}}
             onClick={() => {
              setModal(true);
              setDateSelector(true);
            
            }}
            
            >
              Select Date Range
            </Button>
            <Button  style={{backgroundColor:'blue',color:'white',marginLeft:10}}
            variant="outlined"
            
               onClick={() => {
                setDateSelector(false);
          
              }}>
              Reset
            </Button>
             
              
                
     

                <Select
                sx={{marginLeft:3}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={search}
                  label="Filter"
                  onChange={handleChangeCategory}
                  displayEmpty
                  style={{ textAlign: "left",height:35 }}
                >
                  <InputLabel>Filter Categories</InputLabel>

                  {/* <MenuItem value={10}>Start up</MenuItem> */}
                  <MenuItem value={"a"}>A</MenuItem>
                  <MenuItem value={"b"}>B</MenuItem>
                  <MenuItem value={"c"}>C</MenuItem>
                  <MenuItem value={"d"}>D</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>

                

            

              

         
            

               
              </>
            }
            subHeaderAlign="center"
            value={search}
            defaultSortFieldId={1} 
            
            
            // rowsPerPage={rowsPerPage}
            paginationComponentOptions={paginationComponentOptions}
            // paginationRowsPerPageOptions={[5, 10, 50, 100, 200, 300, 500]}
            // paginationPerPage={
            //   SsuList ? SsuList?.length : assigneeTable?.count
            // }
            paginationTotalRows={assigneeTable ? filteredResultsAasinTable?.length : assigneeTable?.count}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
                    paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
                    // onChangePage={(val) => setPage(val)}
                    paginationDefaultPage={page}
                    // paginationServer
          />
            }
          
          </TableContainer>
        </Box>
        
      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Date Range
            </Typography>
            <br />           
             

              <Typography
                // onClick={() => resendOTP()} sx={{ cursor: "pointer" }}
                className="headLineLeft"
              >
                {/* Resend OTP */}
                <b>Select From Date </b>
              </Typography>
             
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <DatePicker
                // type="date"
                  value={changeTzToNormalDefault(startDate).date}
                  onChange={handleDateChange}
                  // placeholder="Select Date&Time"
                  // format='yyyy-MM-dd'
                />
              </MuiPickersUtilsProvider>
          
              <br />
              <br />
              <Typography
                // onClick={() => resendOTP()} sx={{ cursor: "pointer" }}
                className="headLineLeft"
              >
                {/* Resend OTP */}
                <b>Select To  Date </b>
              </Typography>
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <DatePicker
                // type="date"
                  value={changeTzToNormalDefault(endDate).date}
                  onChange={handleDateChange2}
                  // placeholder="Select Date&Time"
                  // format='yyyy-MM-dd'
                  
                  
                />
              </MuiPickersUtilsProvider>
            
             
<br/><br/>
              <Button
                onClick={DateRange}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Select 
              </Button>
            
          </Box>
        </Modal>
      ) : null}

    </>
  );
};

export default RecommendedPage;
