// Mihir 25
import React, { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Box,
  TextField,
  Stack,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";

import { coworkingDetails } from "../Componets/basicAction/index";
// import isURL from 'validator/lib/isURL';

import { useNavigate, Link } from "react-router-dom";
import EntityVentureDetails from "./Entity-Venture-Details";
import axios from "axios";
import "../../src/Style/Auth.css";
import { getToken } from "../apiCalls/functions";

import Address from "./basic/address";
import Others from "./basic/others";
import { actionNotifier } from "./notification";
import { loaderStart, loaderEnd } from "./loader";
import { getAllDropdown } from "./basic/actions";
import { REACT_APP_API_URL } from "../shared/constant";
import Footer from "./footer";
import Select, { components } from "react-select";

// const Educations = [
//     {
//         value: "0",
//         label: "Matric",
//     },
//     {
//         value: "1",
//         label: "Inter",
//     },
//     {
//         value: "2",
//         label: "Diploma",
//     },
//     {
//         value: "3",
//         label: "Graduation",
//     },
//     {
//         value: "4",
//         label: "Master",
//     },
//     {
//         value: "5",
//         label: "Phd",
//     },
//     {
//         value: "6",
//         label: "Others",
//     },
// ];

const initialValue1 = {
  panCardNo: "",
  website: "",
  fatherOrGuardianName: "",
  dateOfBirth: "",
  higherEducationId: 0,
  genderId: 0,
  // religionId: 0,
  categoryId: 0,
  isTechnical: "yes",
  startupInnovation: "technical",
  photoUpload: "",
  casteCertificateUpload: "",
  qualificationUpload: "",
  panCardUpload: "",
  otherInstitute:"",
  isntituteId:0,
  instituesName:""
};

const initialValue2 = {
  addressLine1: "",
  addressLine2: "",
  cityId: "",
  stateId: "",
  districtId: "",
  pincode: "",
  addressType: "permanent",
};

const initialValue3 = {
  firstName: "",
  adhaarNo: "",
  email: "",
  firstName: "",
  lastName: "",
  middleName: "",
  mobileNumber: "",
  nameOfStartUp: "",
};

const BasicDetails = () => {
  const navigate = useNavigate();
  //post api for add details
  const [basicDetails, setBasicDetails] = useState(initialValue1);
  const [addressDetails, setAddressDetails] = useState(initialValue2);
  //get api for auto fill details
  const [autoFillDeatils, setAutoFillDeatils] = useState(initialValue3);
  //get api for show data
  const [update, setUpdate] = useState(null);
  const [token, setToken] = useState(null);
  const [dropdown, setDropdown] = useState({});
  const [Institutes, setInstitutes] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  // startup india code
  // const onStartupIndia = () => {
  //     let token = localStorage.getItem("startup-india")
  //     token = JSON.parse(token)
  //     if (token) {
  //         // localStorage.removeItem('startup-india')
  //         const config = {
  //             headers: {
  //                 'x-auth-token': token,
  //             }
  //         };
  //         axios.get(`https://api.startupindia.gov.in/sih/api/auth/sso/services/get/userDetails`, config)
  //             .then(result => {
  //                 let startupIndia = { ...result.data }
  //                 const initialValue = {
  //                     // id: "",
  //                     panCardNo: startupIndia ? startupIndia?.pan : '',
  //                     website: startupIndia ? startupIndia?.website : '',
  //                 }
  //                 setBasicDetails(initialValue)
  //             })
  //             .catch(error => {
  //                 // console.log(error.response.data);
  //                 actionNotifier.error(error.response.data.message)
  //             })
  //     }
  // }

  // useEffect(() => {
  //     onStartupIndia()
  // }, [])

  // startup india code

  // form onSubmit check
  const onSubmitData = (e) => {
    e.preventDefault();

    if (update) {
      basicDetailsUpdate();
    } else {
      addBasicDetails();
    }
  };

  const getAllDetails = async () => {
    let val = {
      dropdown_type: "institute",
      primarykey: ["%"],
    };

   

    const dataInstitutes = await coworkingDetails(val);
    if (dataInstitutes.status) {
    //   setInstitutes(dataInstitutes?.data);

      console.log(dataInstitutes?.data);

      const options = dataInstitutes?.data.map(d => ({
        "value" : d.id,
        "label" : d.name,
        "key":d.id
      }))
      setInstitutes(options)
    }
  };



  const onValue = (e) => {
    console.log(e.target.value);
    setBasicDetails({ ...basicDetails, [e.target.name]: e.target.value });
    console.log(basicDetails);
  };
  const onValueFile = (e) => {
    // let data=e.target.files[0]
    console.log(e.target.files[0]);
    setBasicDetails({ ...basicDetails, [e.target.name]: e.target.files[0] });
    // console.log(basicDetails, "helllooo");
  };

  const handleChangeInstitute= (e) =>{
    setBasicDetails({ ...basicDetails, ["instituteId"]: e.value });
   console.log(basicDetails);
   }

  // post addBasicDetails call here
  const addBasicDetails = async (e) => {
    loaderStart();
    // e.preventDefault()
    let _basicDetails = { ...basicDetails };

    var form_data;
    form_data = new FormData();
    for (var key in _basicDetails) {
      form_data.append(key, _basicDetails[key]);
    }
    await axios
      .post(`${REACT_APP_API_URL}basic-details/`, form_data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        // console.log(result);
        // actionNotifier.success("Success")
        addAddress();
      })
      .catch((error) => {
        // let _err = err.response.data
        // console.log(error);
        let _err = error.response.data;
        if (
          _err.message ==
          "Foreign key constraint failed on the field: `categoryId`"
        ) {
          actionNotifier.error("Please Select Category");
        } else if (
          _err.message ==
          "Foreign key constraint failed on the field: `genderId`"
        ) {
          actionNotifier.error("Please Select Gender");
        } else if (
          _err.message ==
          "Foreign key constraint failed on the field: `districtId`"
        ) {
          actionNotifier.error("Please Select Disctrict");
        } else if (
          _err.message ==
          "Foreign key constraint failed on the field: `dateOfBirth`"
        ) {
          actionNotifier.error("Please Select dateofBirth");
        } else if (
          _err.message == "Foreign key constraint failed on the field: `cityId`"
        ) {
          actionNotifier.error("Please Select City");
        } else if (
          _err.message ==
          "Foreign key constraint failed on the field: `pincode`"
        ) {
          actionNotifier.error("Please type pincode");
        }

        // else if (_err.message == "Foreign key constraint failed on the field: `religionId`") {
        //     actionNotifier.error("Please Select Religion")
        // }
        else if (_err.message.includes("is not a valid PAN number")) {
          actionNotifier.error("Please Fill Pan Card Details Properly");
        } else if (
          _err.message ==
          "Foreign key constraint failed on the field: `higherEducationId`"
        ) {
          actionNotifier.error("Please Select Education");
        } else if (
          _err.message ==
          "File size for casteCertificateUpload is too large. Max size is 5 MB"
        ) {
          actionNotifier.error(
            "File size for Caste Certificate is too large. Max size is 5 MB"
          );
        } else if (
          _err.message ==
          "File size for qualificationUpload is too large. Max size is 5 MB"
        ) {
          actionNotifier.error(
            "File size for Education Certificate is too large. Max size is 5 MB"
          );
        } else if (
          _err.message ==
          "File size for panCardUpload is too large. Max size is 5 MB"
        ) {
          actionNotifier.error(
            "File size for PAN Card is too large. Max size is 5 MB"
          );
        } else if (
          _err.message ==
          "File size for photoUpload is too large. Max size is 5 MB"
        ) {
          actionNotifier.error(
            "File size for Photo Upload is too large. Max size is 5 MB"
          );
        } else if (
          _err.message ==
          "Invalid file type for photoUpload. Allowed types are .jpg, .jpeg, .png"
        ) {
          actionNotifier.error(
            "Invalid file type for Photo Upload. Allowed types are .jpg, .jpeg, .png"
          );
        } else if (
          _err.message ==
          "File size for photoUpload is too large. Max size is 0.5 MB"
        ) {
          actionNotifier.error(
            "File size for Photo Upload is too large. Max size is 0.5 MB"
          );
        }
        // else if (basicDetails?.panCardNo == "") {
        //     actionNotifier.error("Please fill Pan Card Details")
        // }
        else {
          actionNotifier.error("Please Fill all the required fields");
          // actionNotifier.error(_err.message)
        }
      });
    // await addAddress();
    loaderEnd();
  };

  //auto fill get api
  const autoFillDetails = async (token) => {
    axios
      .get(`${REACT_APP_API_URL}account/details`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status) {
          setAutoFillDeatils({
            ...response.data.data,
          });
        }
      });
    let dropdown = await getAllDropdown();
    setDropdown(dropdown);
  };

  const getBasicDetails = async (token) => {
    loaderStart();
    await axios
      .get(`${REACT_APP_API_URL}/basic-details/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data)
        if (result.data.status) {
          setBasicDetails({
            panCardNo: result.data.data?.panCardNo,
            website: result.data.data?.website,
            fatherOrGuardianName: result.data.data?.fatherOrGuardianName,
            dateOfBirth: result.data.data?.dateOfBirth.split("T")[0],
            higherEducationId: result.data.data?.higherEducationId,
            genderId: result.data.data?.genderId,
            categoryId: result.data.data?.categoryId,
            photoUploadUrl: result.data.data?.photoUpload,
            casteCertificateUploadUrl: result.data.data?.casteCertificateUpload,
            qualificationUploadUrl: result.data.data?.qualificationUpload,
            panCardUploadUrl: result.data.data?.panCardUpload,
            // otherInstitute : result?.data?.data?.otherInstitute == null ?  result?.data?.data?.Institute?.name : result?.data?.data?.otherInstitute
            instituesName: result?.data?.data?.otherInstitute == '' ?  result?.data?.data?.Institute?.name : result?.data?.data?.otherInstitute
          });

          if (
            result.data.data?.addresses &&
            result.data.data?.addresses.length > 0
          )
            setAddressDetails({
              id: result.data.data?.addresses[0]?.id,
              addressLine1: result.data.data?.addresses[0]?.addressLine1,
              addressLine2: result.data.data?.addresses[0]?.addressLine2,
              cityId: result.data.data?.addresses[0]?.cityId,
              stateId: result.data.data?.addresses[0]?.district.stateId,
              districtId: result.data.data?.addresses[0]?.districtId,
              pincode: result.data.data?.addresses[0]?.pincode,
              addressType: result.data.data?.addresses[0]?.addressType,
            });

          setUpdate(true);
        }
      })
      .catch((error) => {
        let _err = error.response.data;
        // actionNotifier.error(_err.message)
        // console.log(error);
      });
    loaderEnd();
  };

  const basicDetailsUpdate = async () => {
    loaderStart();
    let _updateDeatils = { ...basicDetails };
    console.log(_updateDeatils);
    var form_data;
    form_data = new FormData();
    for (var key in _updateDeatils) {
      form_data.append(key, _updateDeatils[key]);
    }
    await axios
      .put(`${REACT_APP_API_URL}basic-details/`, form_data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        console.log(result);
        addAddress();
        // actionNotifier.success("Success")
      })
      .catch((error) => {
        let _err = error.response.data;
        if (
          _err.message ==
          "Foreign key constraint failed on the field: `categoryId`"
        ) {
          actionNotifier.error("Please Enter Category");
        } else if (_err.message.includes("is not a valid PAN number"))
          actionNotifier.error("Please Enter Correct Pan Number");
      });
    loaderEnd();
  };

  const addAddress = async () => {
    console.log(addressDetails);
    let _updateDeatils = { ...addressDetails };
    if (_updateDeatils.id) {
      delete _updateDeatils.id;
      await axios
        .put(
          `${REACT_APP_API_URL}address/` + addressDetails.id,
          _updateDeatils,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((result) => {
          actionNotifier.success("Success");
          navigate("/main/entityVentureDetails");
        })
        .catch((error) => {
          let _err = error.response.data;
          actionNotifier.error(_err.message);
        });
    } else {
      await axios
        .post(`${REACT_APP_API_URL}address`, _updateDeatils, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          actionNotifier.success("Success");
          navigate("/main/entityVentureDetails");
        })
        .catch((error) => {
          let _err = error.response.data;
          actionNotifier.error(_err.message);
        });
    }
  };

  useEffect(() => {
    let token = getToken();
    setToken(token);
    autoFillDetails(token);
    getBasicDetails(token);
    getAllDetails();

    // panValidation()
  }, []);

  return (
    <Box className="main_box">
      <Box className="sub_box2">
        <Typography
          className="box_heading"
          sx={{ fontWeight: "bold", fontSize: 20 }}
        >
          Basic Details
        </Typography>
        {/* <br /> */}
        <br />
        <form onSubmit={onSubmitData}>
          <Grid container spacing={2} sx={{ color: "text.primary" }}>
            <Grid item xs={4}>
              <TextField
                required
                type="text"
                fullWidth
                label="First Name"
                name="firstName"
                value={autoFillDeatils.firstName}
                disabled
                // onChange={(e) => onValue(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Middle Name"
                variant="outlined"
                type="text"
                value={autoFillDeatils.middleName}
                name="middleName"
                onChange={(e) => onValue(e)}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                type="text"
                value={autoFillDeatils.lastName}
                name="lastName"
                onChange={(e) => onValue(e)}
                disabled
              />
            </Grid>
          </Grid>

          <Grid item xs={12} xl={12}>
            <div className="">
              <br />
              <TextField
                disabled
                fullWidth
                type="text"
                label="Name of startup"
                id="fullWidth"
                value={autoFillDeatils.nameOfStartUp}
                name="nameOfStartUp"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />
              <TextField
                fullWidth
                disabled
                label="Mobile No*"
                id="fullWidth"
                variant="outlined"
                value={autoFillDeatils.mobileNumber}
                name="mobileNumber"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />
              <TextField
                fullWidth
                disabled
                label="Email*"
                id="fullWidth"
                variant="outlined"
                value={autoFillDeatils.email}
                name="email"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label="Aadhar No.*"
                id="fullWidth"
                variant="outlined"
                type="number"
                disabled
                value={autoFillDeatils.adhaarNo}
                name="adhaarNo"
                onChange={(e) => onValue(e)}
              />
              <br />

              {/* <br />
                        <hr /> */}
              <br />
              <TextField
                fullWidth
                label="PAN No"
                id="fullWidth"
                variant="outlined"
                type="text"
                // inputProps={{ pattern: "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/" }}

                //  required

                value={basicDetails.panCardNo}
                name="panCardNo"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />

              <TextField
                fullWidth
                label="Website Url"
                id="fullWidth"
                variant="outlined"
                type="url"
                value={basicDetails.website}
                name="website"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />

              <TextField
                fullWidth
                label="Father's/guardian Name"
                id="fullWidth"
                variant="outlined"
                type="text"
                // inputProps={{ maxLength: 12 }}
                pattern="[A-Za-z]"
                value={basicDetails.fatherOrGuardianName}
                name="fatherOrGuardianName"
                onChange={(e) => onValue(e)}
                required
                color="warning"
              />
              <br />
              <br />

              <TextField
                fullWidth
                id="fullWidth"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true, required: true }}
                value={basicDetails.dateOfBirth}
                name="dateOfBirth"
                label="Date Of Birth"
                placeholder="none"
                onChange={(e) => onValue(e)}
              />
              <br />
              <br />

              <TextField
                select
                label="Education"
                value={basicDetails.higherEducationId}
                name="higherEducationId"
                onChange={(e) => onValue(e)}
                id="fullWidth"
                fullWidth
                style={{ textAlign: "left" }}
                required
                color="warning"
              >
                {dropdown.education?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <br />
            

                   { !basicDetails?.instituesName ? 
                           <>
                            <Select
                options={Institutes}
                placeholder="Select Your Institute"
                name="otherInstitute"
                defaultValue={basicDetails?.otherInstitute}
                onChange={(e)=>{
                    handleChangeInstitute(e)
                    setSelectedOption(e.label)
                }
                   
                }
                isSearchable={true}
                className="basic-single"
                required
              >
              
          

              </Select>
              <br/>
                           </>:''
                   }
          

              {
             selectedOption == 'Others' || basicDetails?.instituesName?
<>
             <TextField
             fullWidth
             label="Enter Your Institution Name"
             id="fullWidth"
             variant="outlined"
             type="text"
             value={basicDetails?.instituesName ? basicDetails?.instituesName :basicDetails?.otherInstitute}
             name="otherInstitute"
             onChange={(e)=>{
                onValue(e)
            }
               
            }
             required
             color="warning"
           />
           
           <br/>
           <br/>
           </>
            :
            ''
}

           
              
              
             
              
          

              <Address
                basicDetails={addressDetails}
                setBasicDetails={setAddressDetails}
                token={token}
                onValue={onValue}
              />

              <Others
                basicDetails={basicDetails}
                setBasicDetails={setBasicDetails}
                token={token}
                onValueFile={onValueFile}
              />
              <br />
              <br />

              <Button
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
                type={"submit"}
              >
                Submit
              </Button>
            </div>
          </Grid>
        </form>
      </Box>

      {/* <Box className="page_design_develop" style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>
                    Designed & developed by Codebucket Solutions Pvt. Ltd.
                </Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022 Department of Industries Government of Bihar
                </Typography>
            </Box> */}
    </Box>
  );
};

export default BasicDetails;
