import React, { useState } from "react";
import './style.css'
const CardComponent = (props) => {
    return (
        <div className="minister_card">
            <img className="w-100" src={props.src} alt="..." />
            <hr />
            <b>{props.name}</b><br />
            <span>{props.positon}</span>
        </div>
    )
}

export default CardComponent;