import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  coworkingList,coworkingExcel
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  AcknowledgementId
} from "../actions";
import { changeTzToNormalDefaultS } from '../../../shared/functions';


const CoworkingList = (props) => {
  const dispatch = useDispatch();
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState();
  const [list, setList] = React.useState();

 


  const { id } = useParams();


  useEffect(() => {

    getCoworkngList();

    // alert(newDataFlag);

  }, [flags,newDataFlag , search]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())






  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };



  


  const getCoworkngList = async () => {
    let val = {
      search: search,
    };

    
    let data = await coworkingList(val);
    if (data.status) {
      setList(data?.data);
      console.log(data.data)
    }
  };

  const downlaodExcelCoworking = async () => {

      loaderStart();
    
  
      const data = await coworkingExcel();
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
   





  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      // width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      id: "Registration Id",
      name:<center> Registration Id</center>,
      // selector: (row,index)=>index+1,
      cell: (row, index) => row?.oldstartup == null ? row?.account?.acknowledgement?.acknowledgmentNo : row?.oldstartup?.registrationId,
      // width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

 
     {
      name: <center> Name of Startup</center>,
      cell: (row) => row?.oldstartup == null ? row?.account?.nameOfStartUp : row?.oldstartup?.nameOfStartUp,
      // width: "150px",
      // sortable: true,
    },
    {
      name: <center>Name</center>,
      cell: (row) => row?.oldstartup == null ? row?.account?.firstName + "  " + row?.account?.lastName : row?.oldstartup?.nameOfApplicant ,
      // width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: <center>Phone No</center>,
      cell: (row) => row?.oldstartup == null ?  row?.account?.mobileNumber : row?.oldstartup?.phone ,
      // width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Email Id",
      cell: (row) =>  row?.oldstartup == null ? (row?.account?.email) :(row?.oldstartup?.email) ,
      // width: "190px",
      // sortable: true,
    },
    {
      name: <center>Office Start Date</center>,
      cell: (row) => changeTzToNormalDefaultS(row?.officeStartDate).date,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
   
    {
      name: <center>office End Date</center>,
      cell: (row) => changeTzToNormalDefaultS(row?.officeEndDate).date,
      // width: "139px",
      sortable: true,
    },


    {
      name: <centter>Differently Abled</centter>,
      selector: (row) =>
        // row?.Evaluations?.reduce((sum, i) => (sum += i.marks + total), 0),
      row?.isDifferentlyAbled,
      // width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Cost",
      cell: (row) =>  row?.cost,
      // width: "160px",
      // sortable: true,
    },

    {
      name: <center>No Of Seat Required</center>,
      selector: (row) => row?.noOfSeatRequired,
      // width: "139px",
      sortable: true,
    },

    {
      name: <center>startup Bihar Certificate </center>,
      selector: (row) =>
        
        row?.startupBiharCertificateReceived,
      // width: "135px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: <center>coWorking Space Category</center>,
      selector: (row) =>
        
        row?.coWorkingSpaceCategory?.name,
      // width: "135px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Duration",
      selector: (row) =>row?.duration?.name,
      
      // width: "155px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Plan",
      selector: (row) =>row?.plan?.name,
      
      // width: "155px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: <center>Location</center>,
      cell: (row) =>
      row?.preferredLocation?.name,
      
      // width: "155px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

  ];





 


 




  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };




  const onChangeHandler = (event) => {
  
    
    setSearch(event.target.value);

    console.log(event.target.value);
    
  };



  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
        


        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>

            {
             
              <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                list ? list?.rows : ''
              }
              pagination
              subHeader
              subHeaderComponent={
                <>
                     {list && list ? (
            <Button onClick={downlaodExcelCoworking} variant="text">
              Click here to download excel
            </Button>
          ) : (
            <Button onClick={downlaodExcelCoworking} variant="text">
              Click here to download excel
            </Button>
          )}
          
          

<input
                    type="text"
                    placeholder="Search Here"
                    className="w-19 form-Control"
                    onChange={onChangeHandler}
                    style={{marginRight:18}}
                  />
                 


               
                
                  
       

                
              

                

           
              

                 
                </>
              }
              subHeaderAlign="center"
              value={search}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
            paginationTotalRows={list ? list?.count : list?.length}
            paginationPerPage={10}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />
          
            }
          
          </TableContainer>
        </Box>
        
      </div>


    </>
  );
};

export default CoworkingList;
