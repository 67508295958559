import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, Button, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { actionNotifier } from '../notification';
import axios from 'axios'
import { REACT_APP_API_URL } from '../../shared/constant';
import { getRolesDropdown } from '../basic/actions';


const DepartmentLogin = () => {

  useEffect(() => {
    getRoleDropdown()
  }, [])


  const navigate = useNavigate()
  const initialValue = {
    username: "",
    password: "",
    roleId: "",
  }

  const [login, setLogin] = useState(initialValue);
  const [dropdown, setDropdown] = useState({})
  const { username, password, roleId } = login;

  const onValue = (e) => {
    console.log(e.target.value);
    setLogin({ ...login, [e.target.name]: e.target.value })
    console.log(login);
  }

  const onLogin = () => {
    let _login = { ...login }
    axios.post(`${REACT_APP_API_URL}admin/auth/login`, _login, {

    })
      .then(result => {
        localStorage.setItem("department-startup-india-token", JSON.stringify(result.data.data))
        // localStorage.setItem("department-roles", JSON.stringify(result.data.data.Role.name))
        // actionNotifier.success("Succesfully Logged in")
        navigate('/department-dashboard')
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        actionNotifier.error(error.response.data.message)
      })
  }

  const getRoleDropdown = async () => {
    let rolesDropdown = await getRolesDropdown()
    setDropdown(rolesDropdown)
  }

  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" }
  ];


  return (
    <>
      <Box className='bg'>
        <Box className='form-ui_department_login' sx={{ height: "110vh" }}>
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="h4" color="#EB4747" sx={{ fontWeight: "bold", }}>Welcome to Startup Bihar</Typography>
            <br />
            <Typography variant="h6" color="#EB4747">Department Login</Typography>
            <br />
            <Typography variant="h5" color="#030e12" sx={{ fontWeight: "bold", }}>Sign in to account</Typography>
            <hr style={{ width: "50px", display: "flex", margin: "auto", color: "#030e12", borderTop: "5px solid #030e12" }} />
            <br />
            <TextField fullWidth label="Username" id="fullWidth" type="text"
              name="username"
              value={username}
              onChange={(e) => onValue(e)}
            />
            <br />
            <br />
            <TextField fullWidth type="password" label="Password" id="fullWidth"
              name="password"
              value={password}
              onChange={(e) => onValue(e)} />
            <br />
            <br />
            <TextField
              select
              label="Roles"
              value={roleId}
              name="roleId"
              onChange={(e) => onValue(e)}
              id="fullWidth"
              fullWidth
              style={{ textAlign: 'left' }}
              required
              color="warning"
         
            >
              {
                dropdown.roles?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))
              }
            </TextField>
            <br />
            <br />

  
      
            <Button onClick={() => onLogin()} variant="contained" fullWidth sx={{ background: "#EB4747" }} > Sign In </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default DepartmentLogin