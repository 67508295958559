import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import CoworkingList from './CowrkingList'


// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const CoworkingApplication = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <CoworkingList/>
        
      
      </div>

    </>
  )
}

export default CoworkingApplication










