import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import infrastructure from './infrastructure.png'
import './styles.css'
const Infrastructure = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5">
          <h2 className="text-start fw-bold">Infrastructure</h2>
          <div className="row aligned-center img-box-rec">
          <div className="col-sm-8">
              <div className="">
                <p style={{ textAlign: "justify" }}>
                  <ul className="ul_style">
                    <li>Government shall facilitate creation of common infrastructure for Start-ups.
                      <br />The shared infrastructure shall include:
                      <ul className="ul_style">
                        <li>Co-working spaces.</li>
                        <li>Provide shared facilities like R&D labs, cubicles, conference rooms etc.</li>
                        <li>Shared software and hardware like high-end printer, computers etc.</li>
                        <li>Common testing labs and tool rooms.</li>
                        <li>Shared services like legal, accounting, technology, patents, investment banking.</li>
                        <li>Individual accommodation and hostel rooms.</li>
                        <li>Community events and promotional support for incubation and start-ups.</li>
                        <li>Common facilities Centers (warehouse, Storage, Quality Assurance labs).</li>
                      </ul>
                    </li>
                    <li>Common infrastructure spaces shall be established either by Government or through PPP mode. This support shall be available to Start-ups Free of Cost and on chargeable basis for specified periods as per norms decided by Bihar Start-up Fund Trust.</li>
                    <li>Apart from the incubation centres, a "Bihar Innovation Hub" will be set up, which shall provide all services of an incubator including shared workspaces, professional business advisory/consultancy; access to funding and also provide support at commercialization stage including networking and merger & acquisition, valuation advisory amongst others. This innovation hub shall be further expanded into virtual incubator to widespread its reach.</li>                    
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-sm-4 text-end">
              <img src={infrastructure} className="w-100" />
            </div>
            
          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default Infrastructure;
