import React, { useState, useEffect } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import { useNavigate, Link, NavLink } from "react-router-dom"
import logo from "../../Image/logo.png"
import "../../../src/Style/Auth.css"
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getAccountData, getAllRecordsData } from "./basicAction"
import Modal from '@mui/material/Modal';
import { actionNotifier } from "../notification"
import { loaderStart, loaderEnd } from "../loader"
import { changePassword } from "./information/actions"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
// const sideMenu= {
//         BasicDetails: true,
//         EntityVenture: false,
//         FounderDetail: false,
//         ConceptandIdea: false,
//         BusinessModel: false,
//         TurnoverEmployement: false,
//         ReviewForm: false,
//         Declaration: false
// }

const Saidebar = () => {
    const navigate = useNavigate()
    const [sideMenu, setSideMenu] = useState({
        BasicDetails: true,
        EntityVenture: false,
        FounderDetail: false,
        ConceptandIdea: false,
        BusinessModel: false,
        TurnoverEmployement: false,
        ReviewForm: false,
        Declaration: false
    })
    const [currency, setCurrency] = useState();
    const [category, setCategory] = useState();
    // const [value, setValue] = React.useState(0);
    const [isRegEntity, setIsRegEntity] = useState(true)
    const [data, setData] = useState({})
    // const [active, setActive] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [allrecord, setAllRecord] = useState()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modal, setModal] = useState(false)
    const [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        rePassword: ""
    })



    const handleChange = (event) => {
        setCategory(event.target.value);
        setCurrency(event.target.value);
        //  setValue(newValue);

    }
    useEffect(() => {
        getInitialData()
        getAllRecords()
    }, [])

    const getInitialData = async () => {
        let initial = await getAccountData()
        // console.log(initial)
        if (initial.status) {
            setIsRegEntity(initial.data.isRegisteredEntity == "yes" ? true : false)
        }
    }

    const getAllRecords = async () => {
        let allrecords = await getAllRecordsData()
        console.log(allrecords)
    }
    const [age, setAge] = React.useState('');

    useEffect(() => {
        let data = localStorage.getItem("startup-india-token")
        data = JSON.parse(data)
        if (data) {
            console.log(data?.data)
            setData(data?.data)
        }
    }, [])



    const handleSet = (event) => {
        setAge(event.target.value);
    };

    const onValue = (e, name) => {
        setState({ ...state, [name]: e.target.value })
    }

    const onSubmitData = async (e) => {
        e.preventDefault()
        loaderStart()
        if (state.newPassword === state.rePassword) {
            let _state = { ...state }
            delete _state.rePassword;
            let res = await changePassword(_state)
            if (res.status) {
                actionNotifier.success("Success")
                setModal(false)
            }
            else {
                actionNotifier.error(res.message)
            }
            console.log(res)
        }
        else {
            actionNotifier.error("Both password are not same")
        }
        loaderEnd()
    }

    return (
        <div>
            <Box className='' >
                <Box className=''>
                    <Box sx={{}} >
                        <div style={{ position: "relative" }} >
                            <img src={logo} alt="Paris" className="logo" ></img>
                            <div className='profile_incubator'>
                                <div>
                                    <h4 style={{ zIndex: "8888", top: "20px", fontSize: "16px", marginTop: "19px" }} >Hi {data.firstName}</h4>
                                </div>
                                <div className="avatar-style">
                                    <Box sx={{ minWidth: 20, border: "none", outline: "none" }}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                            <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}>
                                                    {data.firstName ? data.firstName[0] : ""}
                                                </Avatar>
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                id="account-menu"
                                                open={open}
                                                onClose={handleClose}
                                                onClick={handleClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem onClick={() => setModal(true)}>
                                                    Change Password
                                                </MenuItem>
                                                <MenuItem onClick={() => navigate("/logout")}>
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </FormControl>
                                    </Box>
                                </div>

                            </div>

                            <br />
                            <br />

                            <ul id="incubator" style={{ color: "#fff" }}>
                                <li><NavLink style={{ color: "black", marginTop: "100px" }} to="/incubator/main/basicDetails"><span>Basic Details</span></NavLink></li>
                                <hr />
                                <li><NavLink style={{ color: "black" }} to="/incubator/main/incubatorDetails">Incubator Details</NavLink></li>
                                <hr />
                                <li><NavLink className='disable' style={{ color: "black" }} to="/incubator/main/reviewForm">Review Form</NavLink></li>
                                <hr />
                                <li><NavLink className='disable' style={{ color: "black" }} to="/incubator/main/declaration">Declaration</NavLink></li>
                            </ul>
                        </div>

                    </Box>
                </Box>
                {
                    modal
                        ?
                        <Modal
                            open={modal}
                            onClose={() => setModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Change Password
                                </Typography>
                                <br />
                                <form onSubmit={onSubmitData}>
                                    <TextField
                                        id="outlined-basic"
                                        label={"Current Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.currentPassword}
                                        onChange={(e) => onValue(e, "currentPassword")}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        id="outlined-basic"
                                        label={"New Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.newPassword}
                                        onChange={(e) => onValue(e, "newPassword")}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        id="outlined-basic"
                                        label={"Retype Password"}
                                        variant="outlined"
                                        required
                                        type="password"
                                        style={{ width: "100%" }}
                                        value={state.rePassword}
                                        onChange={(e) => onValue(e, "rePassword")}
                                    />
                                    <br />
                                    <br />
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: "#EB4747" }}
                                        variant="contained"
                                        fullWidth
                                    >Submit</Button>
                                </form>
                            </Box>
                        </Modal>
                        : null

                }
            </Box>


        </div>
    )
}

export default Saidebar;
