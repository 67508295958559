import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getApplicationList,
  searchApplication,
  getSsuList,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  getEvaluatedListCsv,
  scheduleMeeting,
  meetingList,
  addingFinalRemarks,
  getSsuScheduledLists,
  getAdminProfileDetail
} from "../../basicAction";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Modal from "@mui/material/Modal";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { useSelector, useDispatch } from 'react-redux';

const Schedule = (props) => {
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  // const flags = useSelector((store) => store.recognition.recognition);
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [SsuScheduled, setSsuScheduled] = useState();
  const [selectedDate, handleDateChange] = useState(
    new Date("2022-09-21T00:00:00.000Z")
  );
  const [profile, setProfile] = useState("");
  const [state, setState] = useState({
    meetingMode: "",
    meetingAt: "",
    meetingLink: "",
    status: "scheduled",
    finalRemarks: "",
   
  });
  const [modal, setModal] = useState(false);
  const [applicationId, setApplicationid] = useState();
  const [scheduleList, setScheduleList] = useState();
  const [status, setStatus] = useState("");
  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [filteredSsu, setFilteredSsu] = useState(
    []
  );
  const [filteredResults, setFilteredResults] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [isRecognition, setIsRecognition] = useState(false)

  const { id } = useParams();

  useEffect(() => {
    getAllApplication();
    ssulist();
    assigneeList();
    GetAssignedList();
    scheduleLists();
    searchData();
    GetSsuScheduled();
    getAdminProfile();

  }, [page, rowsPerPage,flags,newDataFlag]);


  useEffect(() => {

    try{
      if (search !== "All") {
  
        const result = 
        assigneeTable ?
        assigneeTable?.rows.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.Meetings[list?.Meetings?.length - 1]?.status.substr(-4)
                  .toLowerCase()
                  .match(search.substr(-4).toLowerCase()): 

                  statusFilter == true ?
                  list.Meetings[list?.Meetings?.length - 1]?.status.substr(-4)
                  .toLowerCase()
                  .match(search.substr(-4).toLowerCase())||
                  list.applicationCategory?.name.substr(1)
                  .toLowerCase()
                  .match(search.substr(1).toLowerCase())
                  
                  :


                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                  list.account?.mobileNumber
                    .toLowerCase()
                    .match(search.toLowerCase())||
                   list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                   list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                   list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
              
            })
            
            :
            SsuScheduled?.rows.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.Meetings[list?.Meetings?.length - 1]?.status.substr(-4)
                  .toLowerCase()
                  .match(search.substr(-4).toLowerCase()): 

                  statusFilter == true ?
                  list.Meetings[list?.Meetings?.length - 1]?.status.substr(-4)
                  .toLowerCase()
                  .match(search.substr(-4).toLowerCase())||
                  list.applicationCategory?.name.substr(1)
                  .toLowerCase()
                  .match(search.substr(1).toLowerCase())
                  
                  :


                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                  list.account?.mobileNumber
                    .toLowerCase()
                    .match(search.toLowerCase())||
                   list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                   list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                   list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
         
        
            setFilteredSsu(result);
            setFilteredResultsAasinTable(result);

        
            
      
    } else {

      categoryFilter == true && statusFilter == true && search == 'All'?

      window.location.reload(): 

      setFilteredSsu(SsuScheduled?.rows);
      setFilteredResultsAasinTable(assigneeTable?.rows);
      
     
    }
    }
    catch{
      setFilteredSsu(SsuScheduled?.rows);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
   
  }, [search]);

  const options = [
    {
      value: "offline",
      id: 1,
    },
    {
      value: "online",
      id: 2,
    },
  ];

  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      setAssignee(data);
    }
  };

  const scheduleLists = async () => {
   
      let data = await meetingList();
      setScheduleList(data);
      console.log(data);
      setFilteredResults(data?.rows)

   
  };

  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      setProfile(data?.data);
      console.log(data.data)
    }
  };

  const searchData = async () => {
    let val = {
      search: search,
    };
    let data = await searchApplication(val);
    if (data.status) {
      setFilData(data.data);
    }
    // setSearch("")
  };

 

  const getAllApplication = async () => {
    let val = {
      search: search,
    };
    let data = await getApplicationList(
      { page: page, items: rowsPerPage },
      val
    );
    if (data.status) {
      setAllapplication({ ...data.data });
      // console.log(data.data)
    }
  };

  const ssulist = async () => {
    let data = {
      categories: ["a", "b", "c","d"],
    };
    let ssulist = newDataFlag == true ? await getSsuList({
      round:2
    },data) :
    await getSsuList({
      round:1
    },data);
    setSsuList(ssulist.data);
    // console.log(ssulist.data);
    setSSuTotal(ssulist.data);
  };

  const handleChangeRowsPerPage = (event) => {
    loaderStart();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(page);
    loaderEnd();
  };

  const GetAssignedList = async () => {
    // loaderStart();
    if(flags == true){
      let val = {
      isRecognition:true
      }
    
      let data = await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
      },val);
      if (data.status) {
        setAssigneeTable({ ...data.data });
        setFilteredResultsAasinTable(data?.data?.rows);
        console.log(data.data);
      }
      loaderEnd();
    
    }else{
      let val = {
        isRecognition:false
        }
      
        let data = await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
        },val);
        if (data.status) {
          setAssigneeTable({ ...data.data });
          setFilteredResultsAasinTable(data?.data?.rows);
          console.log(data.data);
        }
        loaderEnd();
    }
 
  };


  const GetSsuScheduled = async () => {
    // loaderStart();
    if(flags == true){
    let data1 = {
      categories: ["a","c","d","b"],
      isRecognition:true
    };
    let data = await getSsuScheduledLists(data1);
    if (data.status) {
      setSsuScheduled({ ...data.data });
      setFilteredSsu(data?.data?.rows);
      console.log(data.data);
    }
    loaderEnd();
  }else{
    let data1 = {
      categories: ["a","c","d","b"],
      isRecognition:false
    };
    let data = await getSsuScheduledLists(data1);
    if (data.status) {
      setSsuScheduled({ ...data.data });
      setFilteredSsu(data?.data?.rows);
      console.log(data.data);
    }
    loaderEnd();
  }
  };

  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    setSearch(event.target.value);

    console.log(event.target.value);
  };


  const sumbitApplication = async (e) => {

    e.preventDefault();

    let date = changeTzToNormalDefault(selectedDate).date;
    let time = changeTzToNormalDefault(selectedDate).time;
    let combineDate = date + " " + time;


    let data = {
      meetingMode: state.meetingMode,
      meetingAt: combineDate,
      // meetingEnd:changeTzToNormalDefault(selectedDate).date ,
      meetingLink: state.meetingLink,
      status: state.status,
      applicationId: applicationId,
      
    };
    let data2 = await scheduleMeeting(data);
   
     
    if (data2.status == true) {
      e.preventDefault();
      setModal(false);
      
      actionNotifier.success("Meeting is Scheduled");
      // window.location.reload()
      e.preventDefault();
    } else {
      // alert('fail')
    }
  };


  const handleChanges = (e, name) => {
    setState({ ...state, [name]: e.target.value });
  };

  // const handleChangePage = (event, page) => {
  //   setPage(page);
  // };


  
  const paginationComponentOptions = {
   
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
};



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const columns = [

   

    {
      id:'Sl. No',
      name: 'Sl. No',
      // selector: (row,index)=>index+1,
      cell: (row,index) =>  index+1,
      width:"70px",
     
       
      
     
      // format: (value) => value.toLocaleString('en-US'),
      
    },
  
    {
     
      name: 'Registration Id',
      selector: row => row?.account?.acknowledgement?.acknowledgmentNo,
      width:"140px",
      sortable: true,

     
  
     
      // format: (value) => value.toLocaleString('en-US'),
    }, 
    {
     
      name: 'Submission Date',
      selector: row => changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width:"160px",
      sortable: true,
  
     
    }, 
    {
     
      name: 'Name of Startup',
      cell: row =>row?.account?.nameOfStartUp,
      width:"150px",
     
     

    }, 
    {
     
      name: 'Name',
      cell: row =>row?.account?.firstName + '  '+row?.account?.lastName,
      
    }, 
    {
      
      name: 'Phone No.',
      cell: row =>row?.account?.mobileNumber,
      width:'120px',
      
     
      // format: (value) => value.toLocaleString('en-US'),
    }, 
    {
      
      name: <center>  Applied in Policy 2017.</center>,
      selector: row =>row?.account?.StartUpDocuments ? "Yes" : "No",
      width:'140px',
      sortable: true,

      
     
   
    }, 

    {
      
      name: 'Category Type',
      cell: row =>row?.assignedTo2Id == profile?.id && row?.isEvaluated2 == true ? 
      
      row?.applicationCategory2?.name :row?.applicationCategory?.name ? row?.applicationCategory?.name :'' ,
      width:'140px',
      sortable: true,
      
   
    }, 
 
    {
      
      name: 'Assignee1.',
      cell: row =>row?.assignedTo1?.name,
      
   
    }, 
    {
      name: "Assignee 2.",
      cell: (row) => row?.assignedTo2?.name,
      width:"110px"
    },

    {
      
      name: <center>Meeting Date & Time</center>,
      cell:row =>  row?.Meetings[row?.Meetings?.length - 1]?.meetingAt ?
      new Date(row?.Meetings[row?.Meetings?.length - 1]?.meetingAt).toUTCString().slice(0, -3):'',
     
     
   
    }, 
    {
      
      name: 'Action',
      cell: row =>  <NavLink
      target={"_blank"}
      to={`/view-application/${row.account.acknowledgement.id}`}
    >
      <Button>
        <FaEye />
        View
      </Button>
    </NavLink>,
     width:'120px',
     
      
   
    }, 
    {
      
      name: 'Action',
      cell: row =>  <Button
      onClick={() => {
        setModal(true);
        setApplicationid(row?.id);
      }}
    >
      <FaEye />
      {row?.meetingApprovedStatus == "pending"
        ? "Schedule"
        : ''}
        {row?.meetingApprovedStatus == "Scheduled"
        ? "ReSchedule"
        : ''}
    </Button>,
    width:'150px'
      
   
    }, 

    {
      
      name: <center>Meeting Status</center>,
      cell:row =>     
 
      <>
   {      row?.Meetings[row?.Meetings?.length - 1]?.status == "done"
                          ? "Complete"
                          : ''}
                           {row?.Meetings[row?.Meetings?.length - 1]?.status == "scheduled"
                          ? "Scheduled"
                          : ''}
                           {row?.Meetings[row?.Meetings?.length - 1]?.status == "rescheduled"
                          ? "Rescheduled"
                          : ''}

{!row?.Meetings[row?.Meetings?.length - 1]?.status 
                          ? "Pending"
                          : ''}
      </>,
       width:'150px',
       
         
        
          
         
}
 
  
  ]




  const columnSsu = [

   

    {
      id:'Sl. No',
      name: 'Sl. No',
      // selector: (row,index)=>index+1,
      cell: (row,index) =>  index+1,
      width:'70px'
     
     
       
      
     
      // format: (value) => value.toLocaleString('en-US'),
      
    },
  
    {
     
      name: 'Registration Id',
      selector: row => row?.account?.acknowledgement?.acknowledgmentNo,
      width:'140px',
      sortable: true,

     
  
     
      // format: (value) => value.toLocaleString('en-US'),
    }, 
    {
     
      name: 'Submission Date',
      selector: row => changeTzToNormalDefault(row?.account?.acknowledgement?.createdAt).date,
      width:"150px",
      
     
    }, 
    {
     
      name: 'Name of Startup',
      cell: row =>row?.account?.nameOfStartUp,
      width:"150px",
      // sortable: true,
     

    }, 
    {
     
      name: 'Name',
      selector: row =>row?.account?.firstName + '  '+row?.account?.lastName,
      width:"130px",
      sortable: true,
    }, 
    {
      
      name: 'Phone No.',
      selector: row =>row?.account?.mobileNumber,
      width:"130px",
      sortable: true,
     
      // format: (value) => value.toLocaleString('en-US'),
    }, 
    {
      
      name: <center>Applied in Policy 2017.</center>,
      selector: row =>row?.account?.StartUpDocuments ? "Yes" : "No",
      width:"180px",
      sortable: true,
   
    }, 

    {
      
      name: 'Category Type',
      selector: row =>row?.applicationCategory?.name,
      width:"150px",
      sortable: true,
   
    }, 
 
    {
      
      name: 'Assignee1.',
      selector: row =>row?.assignedTo1?.name,
      width:"180px",
      sortable: true,
   
    }, 

    {
      
      name: 'Meeting Date & Time',
      cell:row =>  row?.Meetings[row?.Meetings?.length - 1]?.meetingAt ?
      new Date(row?.Meetings[row?.Meetings?.length - 1]?.meetingAt).toUTCString().slice(0,-3):'',
      // width:"180px"
      sortable: true,
   
    }, 
    

    {
      
      name: 'Meeting Status',
      cell:row => row?.Meetings[row?.Meetings?.length - 1]?.status == "done"
      ? "Complete"
      : row?.Meetings[row?.Meetings?.length - 1]?.status ,
          width:"150px",
          
          
        
          
         
},
{
      
  name: 'Action',
  cell: row =>  <NavLink
  target={"_blank"}
  to={`/view-application/${row.account.acknowledgement.id}`}
>
  <Button>
    <FaEye />
    View
  </Button>
</NavLink>,
width:"110px"
  

},

  
  ]

  
  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  const handleChangeStatus = (event) => {
   console.log(event.target.value)
    setStatusFilter(true);
    setSearch(event.target.value);
   
  };



  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: 25,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          {/* download excel Start Here*/}

        </Box>
        
      
     {
assigneeTable?.rows && assigneeTable?.rows ?
<Box sx={{ display: 'flex', flexDirection: "column", width: '85%',  height:'100vh', marginRight:5 }}>
            <TableContainer 
            
            component={Paper}>
             
                <DataTable
             
                className="dataTables_wrapper"
                 component={Paper}
                 fixedHeader
  fixedHeaderScrollHeight="499px"
 
                 // title=" Recommended Table"
                 columns={ columns } data={filteredResultsAasinTable ? filteredResultsAasinTable: '' } pagination 
                 
                 subHeader
                 subHeaderComponent={
                  <>
                  
              {/* <Button onClick={downloadAssignedTableCsv} variant="text">
                Download excel
              </Button> */}
        
        <Select
                       sx={{marginLeft:5}}
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         // value={search}
                         label="Age"
                         onChange={handleChangeCategory}
                         displayEmpty
                         style={{ textAlign: "left", height:35 }}
                       >
                         <InputLabel>Filter Categories</InputLabel>
     
                         {/* <MenuItem value={10}>Start up</MenuItem> */}
                         <MenuItem value={"a"}>A</MenuItem>
                         <MenuItem value={"b"}>B</MenuItem>
                         <MenuItem value={"c"}>C</MenuItem>
                         <MenuItem value={"d"}>D</MenuItem>
                         <MenuItem value={"All"}>All</MenuItem>
                       </Select>
     
                       <Select
                       sx={{marginLeft:2}}
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         // value={search}
                         label="Age"
                         onChange={handleChangeStatus}
                         displayEmpty
                         style={{ textAlign: "left", height:35 }}
                       >
                         <InputLabel>Filter Status</InputLabel>
     
                         {/* <MenuItem value={10}>Start up</MenuItem> */}
                         <MenuItem value={"done"}>complete</MenuItem>
                         <MenuItem value={"scheduled"}>scheduled</MenuItem>
                         <MenuItem value={"rescheduled"}>rescheduled</MenuItem>
                         <MenuItem value={"All"}>All</MenuItem>
                       </Select>
                
     
                       <input
                       style={{marginLeft:20}}
                             type="text"
                             placeholder="Search Here"
                             className="w-25 form-Control"
                             onChange={onChangeHandler}
                           />

                   </>}
                     subHeaderAlign="center"
                 
                 value={search}
                 onChange={onChangeHandler}
                
                //  rowsPerPage={rowsPerPage}
                paginationTotalRows={assigneeTable?  filteredResultsAasinTable?.length : assigneeTable?.count}
                 paginationComponentOptions={paginationComponentOptions}
                //  paginationPerPage={SsuScheduled?.count}
                paginationPerPage={8}
                // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
                paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
                // onChangePage={(val) => setPage(val)}
                paginationDefaultPage={page}
                //  paginationServer
              
                 />

            
             
           
        </TableContainer>
        </Box>
:




<Box sx={{ display: 'flex', flexDirection: "column", width: '85%',  height:'100vh', marginRight:5 }}>
            <TableContainer 
            
            component={Paper}>
             
                <DataTable
             
                className="dataTables_wrapper"
                 component={Paper}
                 fixedHeader
  fixedHeaderScrollHeight="499px"
 
                 // title=" Recommended Table"
                 columns={ columnSsu } data={filteredSsu ? filteredSsu: '' } pagination 
                 subHeader
                 subHeaderComponent={
                   <>
                   
               {/* <Button onClick={downloadAssignedTableCsv} variant="text">
                 Download excel
               </Button> */}
         
                 <Select
                       sx={{marginLeft:5}}
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         // value={search}
                         label="Age"
                         onChange={handleChangeCategory}
                         displayEmpty
                         style={{ textAlign: "left", height:35 }}
                       >
                         <InputLabel>Filter Categories</InputLabel>
     
                         {/* <MenuItem value={10}>Start up</MenuItem> */}
                         <MenuItem value={"a"}>A</MenuItem>
                         <MenuItem value={"b"}>B</MenuItem>
                         <MenuItem value={"c"}>C</MenuItem>
                         <MenuItem value={"d"}>D</MenuItem>
                         <MenuItem value={"All"}>All</MenuItem>
                       </Select>
     
                       <Select
                       sx={{marginLeft:2}}
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         // value={search}
                         label="Age"
                         onChange={handleChangeStatus}
                         displayEmpty
                         style={{ textAlign: "left", height:35 }}
                       >
                         <InputLabel>Filter Status</InputLabel>
     
                         {/* <MenuItem value={10}>Start up</MenuItem> */}
                         <MenuItem value={"done"}>complete</MenuItem>
                         <MenuItem value={"scheduled"}>scheduled</MenuItem>
                         <MenuItem value={"rescheduled"}>rescheduled</MenuItem>
                         <MenuItem value={"All"}>All</MenuItem>
                       </Select>

               
                       <input
                       style={{marginLeft:20}}
                             type="text"
                             placeholder="Search Here"
                             className="w-25 form-Control"
                             onChange={onChangeHandler}
                             
                           />
             
                   </>}
                     subHeaderAlign="center"
                 
                 value={search}
                 onChange={onChangeHandler}
                
                //  rowsPerPage={rowsPerPage}
                paginationTotalRows={SsuScheduled ? filteredSsu?.length : SsuScheduled?.count }
                 paginationComponentOptions={paginationComponentOptions}
                //  paginationPerPage={SsuScheduled?.count}
                paginationPerPage={8}
                // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
                paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
                // onChangePage={(val) => setPage(val)}
                 
              
                 />

            
             
           
        </TableContainer>
        </Box>

     }
    
            



        
      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Schedule Meeting
            </Typography>
            <br />

            <form>
              
              <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">
                  Meeting Mode
                </InputLabel>
                <Select
                style={{height:"60px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Schedule Meeting"
                  required
                  value={state.meetingMode}
                  onChange={(e) => handleChanges(e, "meetingMode", null)}
                >
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
             

              <Typography
                // onClick={() => resendOTP()} sx={{ cursor: "pointer" }}
                className="headLineLeft"
              >
                {/* Resend OTP */}
                <b>Select Meeting Date & Time</b>
              </Typography>
             
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <DateTimePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  placeholder="Select Date&Time"
                />
              </MuiPickersUtilsProvider>
          
              <br />
              <br />
              <Typography></Typography>
              <TextareaAutosize
                
                style={{height:"50px" , width:"100%", padding:"10px"}}
                id="outlined-basic"
                label={"Message"}
                variant="outlined"
                required
                value={state.meetingLink}
                type="text"
                placeholder="Message"
              
                onChange={(e) => handleChanges(e, "meetingLink", null)}
                multiline={true}
              />
              <br />
              <br />

          
              
             

              <Button
                onClick={sumbitApplication}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Schedule
              </Button>
            </form>
          </Box>
        </Modal>
      ) : null}


    

       
    </>
  );
};

export default Schedule;
