import React, { useState, useEffect } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, Link } from "react-router-dom"
import TableData from './teamDetails/table';
import Dailogs from './teamDetails/dialogue';
import { actionNotifier } from "./notification"
import { loaderStart, loaderEnd } from "./loader"

import { getAllDropdown, fetchTeam, addUpdateTeam, deleteTeam, updateTeamNo } from './teamDetails/action';


const TeamDetails = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [data, setData] = useState(null)
    const [allTeam, setAllTeam] = useState([])
    const [dropdown, setDropdown] = useState({})

    const dailogBox = () => {
        setShow(true)
    }

    useEffect(() => {
        getPageDropdown()
        getInitialData()

    }, [])

    const getPageDropdown = async () => {
        let dropdown = await getAllDropdown()
        setDropdown(dropdown)

    }
    const getInitialData = async () => {
        loaderStart()
        let initial = await fetchTeam()
        console.log(initial)
        if (initial.status) {
            setAllTeam(initial.data)
        }
        loaderEnd()
    }

    const handleModalClose = (update) => {
        setShow(false)
        if (update) {
            getInitialData()
        }
    }

    const handleDelete = async (info) => {
        console.log(info)
        loaderStart()
        let data = await deleteTeam(info.id)
        if (data.status) {
            getInitialData()
        }
        else {
            actionNotifier.error(data.message)
        }
        loaderEnd()
    }

    const handleEdit = (info) => {
        setData({
            id: info.id,
            data: info
        })
        setShow(true)
    }

    const handleSave = () => {
        if (allTeam.length > 0) {
            navigate("/main/conceptIdeasOfStartup")
        }
        else {
            actionNotifier.error("Please add founder to proceed.")
        }
    }

    return (
        <Box>
            <Box className="sub_box2">
                <br />
                <br />
                <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "692px", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Founder Details</Typography>
                <br />
                {/* <br /> */}
                <TextField
                    fullWidth
                    label="Number of Founders*"
                    id="fullWidth"
                    disabled={true}
                    value={allTeam?.length}
                />

                <br />
                <br />

                <TableData
                    data={allTeam}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                />
                <br />
                <br />
                <Button style={{ backgroundColor: "#EB4747" }} variant="contained" onClick={() => {
                    setData(null)
                    dailogBox()
                }} >Add Founders </Button>
                <br />
                <br />
                {
                    show &&
                    <Dailogs
                        handleClose={handleModalClose}
                        data={data?.data}
                        selectedId={data?.id}
                        dropdown={dropdown}

                    />
                }
                {/* <br />
                <br /> */}
                <Button onClick={() => handleSave()} style={{ backgroundColor: "#EB4747" }} fullWidth variant="contained" >Submit</Button>
            </Box>
            {/* <Box style={{ marginLeft: "-1260px", marginTop: "340px", marginBottom: "40px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>
                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}

        </Box>
    )
}

export default TeamDetails;