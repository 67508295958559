import React, { useState } from 'react'
import { Box, TextField, Select, MenuItem, Grid, Typography, Stack,Button, Modal } from '@mui/material';
import { useNavigate } from "react-router-dom"
import Navbar from './Navbar';
import "../../src/Style/Authentication.css"
import InputLabel from '@mui/material/InputLabel';
import Footer from "./footer"
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
// import { Button } from 'bootstrap';
import {
    getCoworkingInfo,
    addCoworking,
    coworkingDetails,
    getCostCoworking
  } from "../Componets/basicAction/index";
import { actionNotifier } from './notification';
import { changeTzToNormalDefaultS , changeTzToNormalDefaultdate} from '../shared/functions';



const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height:300,
    bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    borderRadius: 12,
    p: 2,
  };

const Abled = [


    {
        value: 'yes',
        label: 'Yes',
    },
    {
        value: 'no',
        label: 'No',
    },

];

const CertificateReceived = [


    {
        value: 'yes',
        label: 'Yes',
    },
    {
        value: 'no',
        label: 'No',
    },

];

const location = [


    {
        value: '1',
        label: 'Maurya Lok Complex',
    },
    {
        value: '2',
        label: 'BSFC Building Fraser Road',
    },

];

const initialState = {
    coWorkingSpaceCategory: "",
    typeOfSeat: "",
    noOfSeatAllocated: "",
    plans: "",
    categoryId: "",
    officeStartDate: "",
    officeEndDate: "",
    genderId: "",
    isDifferentlyAbled: "",
    duration: "",
    Certificate:"",
    location:"",
    addCost:""
  };
export default function NewForm() {
    const {addMinutes,addHours,addDays,addMonths,addYears,format} = require('date-fns');
    const [category, setCategory] = useState();
    const [endDate, setEndDate] = useState();
    const [plans, setPlans] = useState();
    const [seats, setSeats] = useState();
    const [durations, setDuration] = useState();
    const [modalData, setModalData] = useState(false);
    const [details, setDetails] = useState();
    const [cost, setCost] = useState();
    const [state, setState] = useState({
    ...initialState,
    
  });

    const navigate = useNavigate()

    
    useEffect(() => {
        getDetails();
        getAllDetails();
        getPlans();
        getDuration();
        getSeats();
    let check = localStorage.getItem('registrationId');
        if(!check){
            navigate("/")
        }
       
       
        
    }, []);

    const handleChange = (name, value, i) => {
        // console.log()
        let _state = { ...state };
        _state[name] = value;
        setState(_state);
        console.log(_state)
      };
      

      const getAllDetails = async() => {

        let val={
            dropdown_type : "coworking_space_category",
            // primarykey:[2]
        }



        const dataSpaceCategory = await coworkingDetails(val);
        if(dataSpaceCategory.status){

            setCategory(dataSpaceCategory?.data);
            
            // console.log(dataSpaceCategory?.data)
        }
       
      };

      const getPlans = async(categories) => {

        let val={
            dropdown_type : "plan",
            primarykey:[categories]
        }

        const dataPlan = await coworkingDetails(val);
        if(dataPlan.status){

            setPlans(dataPlan?.data);
            
            console.log(dataPlan?.data)
        }
       
      };

      const getSeats = async() => {

        let val={
            dropdown_type : "no_of_seat_required",
          
        }

        const dataSeats = await coworkingDetails(val);
        if(dataSeats.status){

            setSeats(dataSeats?.data[0]);
            
            
        }
       
      };

      const getDuration = async(planID) => {

        let val={
            dropdown_type : 'duration',
            primarykey:[state?.coWorkingSpaceCategory,planID]
        }

        const dataDuration = await coworkingDetails(val);
        if(dataDuration.status){

            setDuration(dataDuration?.data);
            
            console.log(dataDuration?.data)
        }
       
      };

    
      const getDetails = async(e) => {
        
        var getRegisteredId = localStorage.getItem('registrationId');

        

        let data1={
            acknowledgmentNo: getRegisteredId 
        }

        const data = await getCoworkingInfo(data1);

        if(data.status){
            setDetails(data?.data)
            console.log(data?.data)
        }
        else{
            // actionNotifier.error("Some Error Occured")
        }

      };

      const addForm = async(e) => {

        if(details?.id){
            let addData = {
                coWorkingSpaceCategoryId:state?.coWorkingSpaceCategory,
                planId:state?.plans,
                noOfSeatRequired:state?.noOfSeatAllocated,
                officeStartDate:changeTzToNormalDefaultS(state?.officeStartDate).date,
                officeEndDate:endDate,
                isDifferentlyAbled:state?.isDifferentlyAbled,
                // accountId:details?.direct_account?.id,
                durationId: state?.duration,
                startupBiharCertificateReceived:state?.Certificate,
                preferredLocationId:state?.location,
                cost : cost,
                oldStartUpId : details?.id
               }
               
     

        const data = await addCoworking(addData);

        if(data.status){
            actionNotifier.success("Application Submitted")
            setModalData(true);
        }
        else if ((data?.message).includes("already exists")) {
            actionNotifier.error("Application Already Submitted")
            navigate("/")
		}
        
        else{
            actionNotifier.error("Kindly Fill Form Properly")
        }
        } else{
            let addData = {
                coWorkingSpaceCategoryId:state?.coWorkingSpaceCategory,
                planId:state?.plans,
                noOfSeatRequired:state?.noOfSeatAllocated,
                officeStartDate:changeTzToNormalDefaultS(state?.officeStartDate).date,
                officeEndDate:endDate,
                isDifferentlyAbled:state?.isDifferentlyAbled,
                accountId:details?.direct_account?.id,
                durationId: state?.duration,
                startupBiharCertificateReceived:state?.Certificate,
                preferredLocationId:state?.location,
                cost : cost
               }
               
     

        const data = await addCoworking(addData);

        if(data.status){
            actionNotifier.success("Application Submitted")
            setModalData(true);
        }
        else if ((data?.message).includes("already exists")) {
            actionNotifier.error("Application Already Submitted")
            navigate("/")
		}
        
        else{
            actionNotifier.error("Kindly Fill Form Properly")
        }
        }


      };


      const getCost = async(e) => {

        let addData = {
         coWorkingSpaceCategoryId:state?.coWorkingSpaceCategory,
         categoryId:details?.id ? details?.categoryId : details?.direct_account?.basicDetails?.categoryId,
         planId:state?.plans,
         noOfSeatRequired:state?.noOfSeatAllocated,
         genderId:details?.id ? details?.genderId : details?.direct_account?.basicDetails?.genderId,
         isDifferentlyAbled:state?.isDifferentlyAbled,
         durationId: state?.duration,
        }

        console.log(addData)
 
         const data = await getCostCoworking(addData);
 
         if(data.status){
            //  actionNotifier.success("Application Submitted")
             setCost(data?.data)
             console.log(data?.data)
            
         }else{
             actionNotifier.error("Error in calculating cost")
         }
 
       };

   

const getDates = async(startDate) => {

    var datesSelect = new Date(startDate);
    

  if(state?.duration == 1){

 const year1 = addYears(datesSelect, 1);

  setEndDate(changeTzToNormalDefaultS(year1).date);
  


  } else if(state?.duration == 2){
    const year2 = addYears(datesSelect, 2);
   
    setEndDate(changeTzToNormalDefaultS(year2).date);
    
  
  }

  else if(state?.duration == 3){

    console.log(state?.duration)
    const month1 = addMonths(datesSelect, 1);
    
    setEndDate(changeTzToNormalDefaultS(month1).date);
  

  }

  else if(state?.duration == 4){
    const month2 = addMonths(datesSelect, 2);
    
    setEndDate(changeTzToNormalDefaultS(month2).date);
   
  }

  else if(state?.duration == 5){
    const month3 = addMonths(datesSelect, 3);
    
    setEndDate(changeTzToNormalDefaultS(month3).date)
     
  }

  else if(state?.duration == 6){
    const day1 = addDays(datesSelect, 1);
    
    setEndDate(changeTzToNormalDefaultS(day1).date);
       }
  else if(state?.duration == 7){
    const day2 = addDays(datesSelect, 2);
    
    setEndDate(changeTzToNormalDefaultS(day2).date);
       }
  else if(state?.duration == 8){
    const day3 = addDays(datesSelect, 3);
    
    setEndDate(changeTzToNormalDefaultS(day3).date);
       }

  else if(state?.duration == 9){
    const day4 = addDays(datesSelect, 4);
    
    setEndDate(changeTzToNormalDefaultS(day4).date);
       }

  else if(state?.duration == 10){
    const day5 = addDays(datesSelect, 5);
    
    setEndDate(changeTzToNormalDefaultS(day5).date);
       }

  else if(state?.duration == 11){
    const day6 = addDays(datesSelect, 6);
    
    setEndDate(changeTzToNormalDefaultS(day6).date);
       }
  else if(state?.duration == 12){
    const day7 = addDays(datesSelect, 7);
    
    setEndDate(changeTzToNormalDefaultS(day7).date);
       }
  else if(state?.duration == 13){
    const day8 = addDays(datesSelect, 8);
    
    setEndDate(changeTzToNormalDefaultS(day8).date);
       }
  else if(state?.duration == 14){
    const day9 = addDays(datesSelect, 9);
    
    setEndDate(changeTzToNormalDefaultS(day9).date);
       }
  else if(state?.duration == 15){
    const day10 = addDays(datesSelect, 10);
    
    setEndDate(changeTzToNormalDefaultS(day10).date)
     
  } else{
    // setEndDate('Please select Duration')
    actionNotifier.error('Please select Duration')
  }

  


   
  };





    return (
        <Box className='backround-ui'>
            <Box className='form-ui1' >
                <Grid container spacing={8} >
                   
                        <Grid item xs={4}
                            sx={{
                                // background: "#F3F7FE",
                                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <Navbar />
                            <Box sx={{ marginLeft: 2,marginTop:72  }}>
                                <Footer  />
                            </Box>

                        </Grid>

                        <Grid item xs={8} >
                        <Box  >
                            <br/>
                            
                                    <Typography sx={{ marginLeft: "60px", color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "90%", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }}>Co-working Space</Typography>
                                    <Stack
                                        // className='form-ui'
                                        sx={{ '& > :not(style)': { m: 1, width: '80ch' }, display: "flex", flexDirection: "column", marginLeft: "50px !important" }}
                                        noValidate
                                        autoComplete="off"

                                    ><br />
                                   
                                            <>
                                            <Box style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }} >
                                            
                                         
                                            <TextField
                                                // style={{ width: "40%" }}
                                                required
                                                type="text"
                                                label="Registration No. "
                                                name="Registration No. "
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={localStorage.getItem('registrationId')}
                                                onChange={(e) => getDetails(e.target.value)}
                                                 />

                                                <TextField
                                                // style={{ width: "40%" }}
                                                key={details?.id ? details?.nameOfApplicant : details?.direct_account?.firstName}
                                                id="outlined-basic"
                                                label="Full Name "
                                                variant="outlined"
                                                type="text"
                                                value={ details?.id ? details?.nameOfApplicant : details?.direct_account?.firstName + ' ' + details?.direct_account?.lastName ? details?.direct_account?.firstName + ' ' + details?.direct_account?.lastName : details?.direct_account?.firstName}
                                                name="Full Name "
                                                // onChange={(e) => onValue(e)}
                                            />
                                            {/* <TextField
                                                // style={{ width: "40%" }}
                                                type="text"
                                                id="outlined-basic"
                                                placeholder="Startup Name "
                                                label="Startup Name "
                                                variant="outlined"
                                                value={details?.direct_account?.nameOfStartUp}
                                                name="Startup Name "
                                                // onChange={(e) => onValue(e)}
                                                 /> */}
                                                      <TextField
                                                // style={{ width: "40%" }}
                                                key={details?.id ? details?.nameOfStartUp :details?.direct_account?.nameOfStartUp}
                                                required
                                                type="text"
                                                label="Startup Name "
                                                name="Startup Name "
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={ details?.id ? details?.nameOfStartUp : details?.direct_account?.nameOfStartUp}
                                                // onChange={(e) => e.target.value}
                                                 />
                                        </Box>
                                        
                                        <Box style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }}>
                                            

                                            <TextField
                                            key={details?.id ? details?.phone : details?.direct_account?.mobileNumber}
                                            style={{ width: "46%" }}
                                          
                                            id="outlined-basic"
                                            label="Mobile No "
                                            variant="outlined"
                                            type="number"
                                            value={details?.id ? details?.phone : details?.direct_account?.mobileNumber}
                                            name="Mobile No"
                                           
                                       />
                                     
                                       

                                       <TextField  type="email"
                                        style={{ width: "50%" }}
                                        key={details?.id ? details?.email : details?.direct_account?.email}
                                           required
                                           id="outlined-basic"
                                           label="Email Id"
                                           variant="outlined"
                                           
                                           value={details?.id ? details?.email : details?.direct_account?.email}
                                           name="Email Id"
                                           onChange={(e) => e.target.value}
                                       
                                           // onChange={(e) => onValue(e)}
                                           // onInput={(e) => {
                                           //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                           // }}
                                       />
                                      

                                       

                                      
                                       


                                            </Box>

                                            <Box style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }}>

                                         
                                       <TextField  type="categoryAuto"
                                        style={{ width: "46%" }}
                                        key={details?.id ? details?.category?.name : details?.direct_account?.basicDetails?.category?.name}
                                           required
                                           id="outlined-basic"
                                           label="Category"
                                           variant="outlined"
                                           
                                           value={details?.id ? details?.category?.name : details?.direct_account?.basicDetails?.category?.name}
                                           name="categoryAuto"
                                       
                                           // onChange={(e) => onValue(e)}
                                           // onInput={(e) => {
                                           //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                           // }}
                                       />
                                     

                                       <TextField  type="genderAuto"
                                        style={{ width: "50%" }}
                                        key= { details?.id ? details?.gender?.name : details?.direct_account?.basicDetails?.gender?.name}
                                           required
                                           id="outlined-basic"
                                           label="gender"
                                           variant="outlined"
                                           
                                           value={details?.id ? details?.gender?.name : details?.direct_account?.basicDetails?.gender?.name}
                                           name="genderAuto"
                                       
                                           // onChange={(e) => onValue(e)}
                                           // onInput={(e) => {
                                           //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                           // }}
                                       />
                                      

                                       


                                            </Box>
                                        <Box style={{
                                            // display: "flex",
                                            // justifyContent: "space-between",
                                            width: "90%", marginLeft: 20
                                        }}>
                                                   
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="Differently Abled"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="Differently Abled"
                                                onChange={(e) => {
                                                    handleChange("isDifferentlyAbled", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {Abled?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>  
                                            <br/>
                                            <br/>

                                           

                                            <TextField  id="outlined-basic"
                                             style={{ textAlign: 'left' }}
                                                required
                                                type="select"
                                                select
                                                label="Co-working Space Category "
                                                variant="outlined"
                                                fullWidth
                                                value={state?.coWorkingSpaceCategory}
                                                name="Co-working Space Category "
                                                
                                                onChange={(e) => {
                                                    handleChange("coWorkingSpaceCategory", e.target.value);
                                                    getSeats();
                                                    getPlans(e.target.value);
                                                    if(state?.coWorkingSpaceCategory){
                                                        setState()
                                                        setEndDate()
                                                        setCost()
                                                    }

                                                    // setCategory(e.target.value);
                                                    
                                                }
                                                    }
                                               
                                            >
                                                {
                                                    category?.length >= 1? 

                                                    category?.map((option) => (
                                                        <MenuItem className='pre_reg_menu_item' key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    )) :
                                                          null
                                                }
                                                
                                            </TextField>
                                            <br />
                                            <br />

                                            {/* <TextField
                                                 style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="Type of seat "
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="Type of seat "
                                                // value={state?.typeOfSeat}
                                                onChange={(e) => {
                                                    handleChange("typeOfSeat", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >
                                                  {

                                                category  == "Fixed Seat" ? currencies2?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label }
                                                    </MenuItem> 

                                                    

                                                )) :
                                                currencies8?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label }
                                                    </MenuItem> 

                                                    

                                                ))
                                                
                                                }

                                            </TextField>
                                            <br />
                                            <br /> */}
                                            

                                            
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="No. of Seat required "
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={state?.noOfSeatAllocated}
                                                name="No. of Seat requred"
                                                onChange={(e) => {
                                                    handleChange("noOfSeatAllocated", Number(e.target.value));
                                                    if(state?.noOfSeatAllocated){
                                                        setState()
                                                        setEndDate()
                                                        setCost()
                                                    }
                                                   
                                                    
                                                }
                                                    }
                                                
                                            >
                                                   {[...Array(seats? seats?.maxValue : null + 1)].map(
                              (elementInArray, index) => (
                                <MenuItem value={index + 1}>{index + 1}</MenuItem>
                              )
                            )}

                                            </TextField>
                                            <br />
                                            <br />

                                            
                                     
                                          

                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                label="Plan"
                                                variant="outlined"
                                                fullWidth
                                                select
                                                required
                                                value={state?.plans}
                                                name="Plan"
                                                onChange={(e) => {
                                                    handleChange("plans", e.target.value);
                                                    getDuration(e.target.value);
                                                    
                                                }
                                                    }
                                                
                                                
                                            >
                                                      {
                                                    plans?.length >= 1? 

                                                    plans?.map((option) => (
                                                        <MenuItem className='pre_reg_menu_item' key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    )) :
                                                          null
                                                }


                                            </TextField>
                                            
                                            <br/>
                                            <br/>

                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                label="Duration"
                                                variant="outlined"
                                                fullWidth
                                                select
                                                required
                                                value={state?.duration}
                                                name="Duration"
                                                onChange={(e) => {
                                                    handleChange("duration", e.target.value);
                                                   
                                                   
                                                    
                                                }
                                                    }
                                                
                                                
                                            >
                                                    {
                                                    durations?.length >= 1? 

                                                    durations?.map((option) => (
                                                        <MenuItem className='pre_reg_menu_item' key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    )) :
                                                          null
                                                }

                                                 


                                            </TextField>
                                            
                                            <br/>
                                            <br/>

                                            {/* <TextField
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="Caste"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.categoryId}
                                                name="Castes"
                                                onChange={(e) => {
                                                    handleChange("categoryId", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >
                                                  {castType?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <br/>
                                            <br/> */}

                                           
                                            <Typography className='formHeadLineLeft'>Office Start Date </Typography>
                                            
                                            
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                  key={state?.officeStartDate}
                                                id="outlined-basic" type="date"
                                                // label="dd-mm-yyy"
                                                // placeholder="Office Start Date"
                                                // format={"MM DD YYYY"}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={state?.officeStartDate}
                                                name="Office Start Date"
                                                onChange={(e) => {
                                                    handleChange("officeStartDate", e.target.value);
                                                    getCost();
                                                    getDates(e.target.value);
                                                    
                                                    
                                                    
                                                }
                                                    }
                                                
                                            >
                                                 

                                            </TextField>
                                            <br />
                                            <br />



                                            <Typography className='formHeadLineLeft'>Office End Date </Typography>
                                            
                                            <TextField
                                                  style={{ textAlign: 'left' }}
                                                  key = {endDate}
                                                id="outlined-basic" type="text"
                                                // disabled={true}
                                                // label="Office End Date"
                                                // placeholder="Office End Date"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                format={'DD/MM/YYYY'}
                                                value={changeTzToNormalDefaultdate(endDate).date}
                                                name="Office End Date"
                                                onChange={(e) => {
                                                    handleChange("officeEndDate", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >


                                                 

                                            </TextField>
                                            
                                            <br />
                                            <br />
                                            
                                            <TextField  type="number"
                                            key = {cost }
                                                required
                                                id="outlined-basic"
                                                label="cost"
                                                variant="outlined"
                                                fullWidth
                                                value={cost ? cost : 'Calculating'}
                                                name="cost"
                                            
                                                onChange={(e) =>   handleChange("addCost", e.target.value)}
                                                // onInput={(e) => {
                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                // }}
                                            />
                                           
                                            <br />
                                            <br />

                                            
                                            <TextField
                                             key = "Certificate"
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="Startup Bihar Certificate received"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="Startup Bihar Certificate received"
                                                onChange={(e) => {
                                                    handleChange("Certificate", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {CertificateReceived?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>  

                                            <br/>
                                            <br/>

                                            <TextField
                                            key = "location"
                                                  style={{ textAlign: 'left' }}
                                                id="outlined-basic" type="select"
                                                select
                                                label="Preferred Location"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.isDifferentlyAbled}
                                                name="Preferred Locations"
                                                onChange={(e) => {
                                                    handleChange("location", Number(e.target.value));
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {location?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField> 

                                            

                                            {/* <TextField
                                              style={{ textAlign: 'left' }}
                                                
                                                id="outlined-basic" type="select"
                                                label="Gender"
                                                select
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.genderId}
                                                name="Gender"
                                                onChange={(e) => {
                                                    handleChange("genderId", e.target.value);
                                                    
                                                }
                                                    }
                                                
                                            >
                                                    {gender?.map((option) => (
                                                    <MenuItem className='pre_reg_menu_item' key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>    
                                            <br />
                                            <br /> */}

                                       
                                        </Box>
                                        <br />
                                        <br />
                                        <div style={{ margin: "auto", marginTop: 20,  marginLeft: "-26px",width: "100%"  }}>
                                            <Button onClick={()=>{
                                                addForm()
                                            }}    type="submit" style={{ backgroundColor: "#EB4747", width: "80%" }} variant="contained" fullWidth>Submit </Button>
                                        </div>
                                        <br />
                                        <br />
                                            </>


                                        

                                        

                                       
                                       
                                    </Stack>
                                </Box>
                        </Grid>
                 
                </Grid>
            </Box>
            
            {modalData ? (
        <Modal
          open={modalData}
          onClose={() => setModalData(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
           
            

          <Grid item xs={8}>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red",fontSize:30 }}><b><u>Application Submitted</u></b></h6></div>
                            <br/>
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
   <p >
                                    
Dear {details?.id ? details?.nameOfApplicant :details?.direct_account?.firstName + ' '+ details?.direct_account?.lastName},<br/> Your application for co-working space has been successfully submitted and sent for approval.


                                  
                                  
                                    </p>
                               
                            </div>


                        <form style={{marginBottom:20}}>
                            {/* <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>I have read all the instructions carefully.	</span>
                            </div> */}

                            {/* <Box style={{display: "flex", justifyContent:"space-between"}}>
                        <TextField id="outlined-basic" label="Name" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Place" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Date" disabled variant="outlined" />
                    </Box> */}


                            <br />
                            <Button
                                style={details ? { backgroundColor: "#EB4747", color: "white",marginLeft :330} : {marginLeft :330}}
                                variant="contained"
                                onClick={() => navigate('/')} 
                                // disabled={!checked}
                            >OK</Button>
                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}

        </Box>
        
        
    );
    
}