import { apiFunction } from "../../apiCalls/functions";

export const coworkingDetails = async (val) => {
    return await apiFunction("data/all", "post", val, false, true, null)
}

export const addMarksExpert = async (data,val) => {
    return await apiFunction(`admin/expert-panel/marks/${data.applicationId}`, "put", val, false, true, null)
}

export const approveSmic = async (data,val) => {
    return await apiFunction(`admin/smic/approve?round=${data.round}`, "post", val, false, true, null)
}

export const rejectSmic = async (data,val) => {
    return await apiFunction(`admin/smic/reject?round=${data.round}`, "post", val, false, true, null)
}


export const getPendingExpert = async (data,val) => {
    return await apiFunction(`admin/expert-panel/pending?round=${data.round}`, "post", val, false, true, null)
}

export const getSmicPending = async (data,val) => {
    return await apiFunction(`admin/smic/smic-list?items=50000&round=${data.round}`, "get", val, false, true, null)
}

export const getRecommendedCsv = async (data,val) => {
    return await apiFunction(`admin/expert-panel/export-expert-panel-list-to-csv/recommended?round=${data.round}`, "post", val, false, true, 'blob')
}

export const getNonRecommendedCsv = async (data,val) => {
    return await apiFunction(`admin/expert-panel/export-expert-panel-list-to-csv/nonrecommended?round=${data.round}`, "post", val, false, true, 'blob')
}

export const recommendedSelect = async (val) => {
    return await apiFunction("admin/expert-panel/add", "post", val, false, true, null)
}

export const personalInteractionSend = async (val) => {
    return await apiFunction("admin/expert-panel/add-personal-interaction", "post", val, false, true, null)
}

export const personalInteractionGet = async (data,val) => {
    return await apiFunction(`admin/expert-panel/personal-interaction?round=${data.round}`, "post", val, false, true, null)
}

export const getRecommendedLists = async (data,val) => {
    return await apiFunction(`admin/expert-panel/expertpannel-list/${data.choose}?items=50000&round=${data.round}`, "post", val, false, true, null)
}

export const getApprovedList = async (data,val) => {
    return await apiFunction(`admin/smic/smic/approved?items=50000&round=${data.round}`, "post", val, false, true, null)
}

export const getInteractionRecom = async (data,val) => {
    return await apiFunction(`admin/expert-panel/personal-interaction/${data.choose}?items=50000&round=${data.round}`, "post", val, false, true, null)
}

export const getListsEvaluatedExp = async (data,val) => {
    return await apiFunction(`admin/expert-panel/pending-b?items=50000&round=${data.round}`, "post", val, false, true, null)
}

export const getPassList = async (data,val) => {
    return await apiFunction(`admin/expert-panel/result/${data.result}?items=50000&round=${data.round}`, "post", val, false, true, null)
}

export const coworkingList = async (val,data) => {
    return await apiFunction(`admin/coworking/list?items=50000`, "post", val, false, true, null)
}

export const addCoworking = async (val) => {
    return await apiFunction("coworking/add", "post", val, true, false, null)
}

export const getCostCoworking = async (val) => {
    return await apiFunction("coworking/cost", "post", val, true, false, null)
}

export const getCoworkingInfo = async (val) => {
    return await apiFunction("coworking", "post", val, true, false, null)
}

export const getReport = async () => {
    return await apiFunction("admin/application/get-report", "get", {}, false, true, null)
}

export const getReportRound2Assignee1 = async () => {
    return await apiFunction("admin/report-for-round2/get-report", "get", {}, false, true, null)
}

export const getReportRound2Assignee2 = async () => {
    return await apiFunction("admin/report-for-round2/get-report2", "get", {}, false, true, null)
}

export const getReport2 = async () => {
    return await apiFunction("admin/application/get-report2", "get", {}, false, true, null)
}

export const searchAssignees = async (val) => {
    return await apiFunction("admin/timeline/list/all", "post", val, false, true, null)
}


export const getTimelineDetails = async (id) => {
    return await apiFunction(`admin/timeline/get-timeline/${id}`, "get", {}, false, true, null)
}



export const getCategoryMessages = async () => {
    return await apiFunction("admin/data/msg", "get", {}, false, true, null)
}


export const getReportExcel = async () => {
    return await apiFunction("admin/application/get-report?format=xlsx", "get", {}, false, true, 'blob')
}

export const getReportExcel2 = async () => {
    return await apiFunction("admin/application/get-report2?format=xlsx", "get", {}, false, true, 'blob')
}

export const getReportExcelRound21 = async () => {
    return await apiFunction("admin/report-for-round2/get-report?format=xlsx", "get", {}, false, true, 'blob')
}

export const getReportExcel2Round22 = async () => {
    return await apiFunction("admin/report-for-round2/get-report2?format=xlsx", "get", {}, false, true, 'blob')
}

export const lmcSubmit = async (data) => {
    return await apiFunction("admin/lms/lms-send", "post", data, false, true, null)
}

export const lmsMessage = async () => {
    return await apiFunction("account/get-message-for-lms", "get", {}, true, false, null)
}

export const getlms = async () => {
    return await apiFunction("admin/lms/lms-send/1", "get", {}, false, true, null)
}

export const scheduleMeeting = async (data) => {
    return await apiFunction("admin/meeting", "post", data, false, true, null)
}

export const addingFinalRemarks = async (data,id) => {
    return await apiFunction(`admin/meeting/remarks/${id}`, "put", data, false, true, null)
}

export const updateMeeting = async (data,id) => {
    return await apiFunction(`admin/meeting/${id}`, "put", data, false, true, null)
}

export const userMeetingDetails = async () => {
    return await apiFunction("account/get-meeting-link", "get", {}, true, false, null)
}

export const meetingList = async (id,val) => {
    return await apiFunction(`admin/meeting/${id}/list`, "get", val, false, true, null)
}

export const getEvaluationCriteria = async (id) => {
    return await apiFunction(`admin/evaluation/get-evaluation-criteria/${id}`, "get", {}, false, true, null)
}

export const getEvaluatedDetails = async (id) => {
    return await apiFunction(`admin/ssu/application-detail-with-evaluations/${id}`, "get", {}, false, true, null)
}


export const getSsuList = async (data,val) => {
    return await apiFunction(`admin/ssu/ssu-view-list?round=${data.round}`, "post", val, false, true, null)
}

export const addUpdateEvaluation = async (id, data) => {
    return await apiFunction(`admin/evaluation/add-or-update-evaluation/${id}`, "post", data, false, true, null)
}


export const addApplicationCategory = async (id, data) => {
    return await apiFunction(`admin/evaluation/add-or-update-application-category/${id}`, "post", data, false, true, null)
}


export const submitEvaluate = async (id, data) => {
    return await apiFunction(`admin/evaluation/submit-evaluation/${id}`, "post", data, false, true, null)
}




export const getAccountData = async (url) => {
    return await apiFunction("account/details", "get", {}, true, false, null)
}


export const getAllRecords = async (url) => {
    return await apiFunction("/account/all-records", "get", {}, true, false, null)
}

export const getTotalServices = async () => {
    return await apiFunction("/admin/data/total-services", "get", {}, false, true, null)
}


export const getcurrentStage = async (url) => {
    return await apiFunction("/admin/data/current-stage", "get", {}, false, true, null)
}


export const getAllRecordsData = async (url) => {
    return await apiFunction("/account/all-records", "get", {}, true, false, null)
}
export const finalSubmit = async (data) => {
    return await apiFunction("/acknowledgment/final-submit-and-generating-acknowledgementNo", "post", data, true, false, null)
}

export const conceptoridea = async () => {
    return await apiFunction("/conceptoridea", "get", {}, true, false, null)
}
export const getApplicationList = async (data, val) => {
    return await apiFunction(`admin/application/list/${data.assigned}?page=${data.page}&items=${data.items}&round=${data.round}`, "post", val, false, true, null)
}

export const searchGlobal = async (val) => {
    return await apiFunction(`admin/application/list/all`, "post", val, false, true, null)
}

export const checkUser = async (data) => {
    console.log(data)
    return await apiFunction(`auth/check-exist?email=${data.email}`, "get", {}, false, false, null)
}

export const getAssignedApplicationList = async (data, val) => {
    return await apiFunction(`admin/assignee/list/all/?page=${data.page}&items=${data.items}&list=${data.evaluated}&round=${data.round}`, "post", val, false, true, null)
}


export const getAssignedApplicationListEvaluated = async (data,val) => {
    return await apiFunction(`admin/assignee/list/all/?page=${data.page}&items=${data.items}&list=evaluated&round=${data.round}`, "post", val, false, true, null)
}

export const getSsuScheduledLists = async (data) => {
    return await apiFunction(`admin/meeting/list/scheduled`, "post", data, false, true, null)
}


export const addAssignee1and2 = async (data, val) => {
    return await apiFunction(`admin/assign/add/${data.id}`, "post", val, false, true, null)
}

export const updateAssignee1and2 = async (data, val) => {
    return await apiFunction(`admin/assign/update/${data.id}`, "put", val, false, true, null)
}
export const getDownloadedExcel = async (data,val) => {
    return await apiFunction(`admin/application/export-application-list-to-csv/${data.assigned}?round=${data.round}`, "post", val, false, true, 'blob')
}

export const getAssignedApplicationDownloadedExcel = async (data) => {
    return await apiFunction('admin/assignee/export-to-csv/all', "post", data, false, true, 'blob')
}

export const getPendingCsv = async (val,data) => {
    return await apiFunction(`admin/assignee/pending-export-to-csv/assignee1?round=${data.round}`, "post", val, false, true, 'blob')
}

export const getExpertPendingCsv = async (data,val) => {
    return await apiFunction(`admin/expert-panel/export-expert-panel-pending-list-to-csv?round=${data.round}`, "post", val, false, true, 'blob')
}

export const getEvaluatedPendingCsv = async (data,val) => {
    return await apiFunction(`admin/expert-panel/export-expert-panel-pending-list-for-B-to-csv?round=${data.round}`, "post", val, false, true, 'blob')
}

export const getEvaluatedListCsvSsu = async (data,val) => {
    return await apiFunction(`admin/ssu/evaluated-export-to-csv?round=${data.round}`, "post", val, false, true, 'blob')
}

export const coworkingExcel = async () => {
    return await apiFunction('/admin/coworking/coworking-export-to-csv', "post", {}, false, true, 'blob')
}




export const getAllPermissionList = async () => {
    return await apiFunction('admin/data/permissions', "get", {}, false, true, null)
}

export const getAdminProfileDetail = async () => {
    return await apiFunction('admin/data/profile', "get", {}, false, true, null)
}

export const changePasswordDepartment = async (data) => {
    return await apiFunction("admin/application/change-password", "post", data, false, true, null)
}

export const searchApplication = async (data) => {
    return await apiFunction("admin/application/list", "post", data, false, true, null)
}

export const viewApplicationList = async (id) => {
    return await apiFunction(`admin/application/detail/${id}`, "get", {}, false, true, null)
}

export const getAssigneeList = async () => {
    return await apiFunction('admin/assign/assignee-list', "get", {}, false, true, null)
}

export const getDashboardAnalytics = async () => {
    return await apiFunction('/data/analytics', "get", {}, false, true, null)
}
