
import './App.css';
import Routers from "./Componets/Routers"
// import Routers from "./Componets/RoutersAdmin"
import Toast from "./Componets/notification";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // console.log(process.env.REACT_APP_API_URL)
  return (
    <div className="App">
      <Routers />
      {/* <RoutersAdmin/> */}
      <Toast />
    </div>
  );
}

export default App;


// "aadharNo":"1212 1212 1212 1212",
// "penNo":"EDH5555254",
// "dob":"21/02/2022",
// "qaulification":"pg",
// "industry":"kavita pvt limited",
