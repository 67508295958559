import React from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';

const Footer = () => {
  return (
    <div>
      <Box className='navbarFooter-ui' sx={{ marginTop: 20, marginBottom:5, justifyContent: "center"  }} >
        <Typography sx={{ fontSize: "12px", color: "red", }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} >
          Copyright © 2022
          Department of Industries
          Government of Bihar
        </Typography>
      </Box>
    </div>
  )
}

export default Footer