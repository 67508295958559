import React, { useState, useEffect } from 'react'
import { Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom"

import RegEntity from "./entity/regEntity";

import Venture from "./entity/venture";
import "../../src/Style/Auth.css";
// import DocUploader from "./Doc-Uploader";
import { loaderStart, loaderEnd } from "./loader"
import { actionNotifier } from "./notification"
import { getAllDropdown, getDropdown, fetchVenture, addUpdateVenture, fetchRegEntity, addUpdateRegEntity } from "./entity/action"
import { getAccountData } from "./basicAction"

const EntityVentureDetails = () => {
    const navigate = useNavigate();
    const [allSector, setAllSector] = useState([])
    const [isRegEntity, setIsRegEntity] = useState(true)
    const [updateId, setUpdateId] = useState(null)
    const [dropdown, setDropdown] = useState({})
    const [regEntity, setRegEntity] = useState({
        dateOfRegistration: "",
        rocId: "",
        proofOfEntityUpload: "",
        natureOfEntityId: "",
        proofOfEntityType: "pvtLtd",
        udyamiAadharNo: "",
        document: ""
    })

    const [venture, setVenture] = useState({
        dpiitUpload: "",
        isDeclare: false,
        dpiitRegisterNo: "",
        currentStage: "",
        sectorId: "",
        serviceId: "",
        industryId: "",
        startupInnovation: "",
        dpiitCertificate: "",
    })

    const handleChange = (e, name, type, section) => {
        let checkbox = false;
        let file = "";
        if (type == "checkbox") {
            if (e.target.checked) {
                checkbox = true;
            }
        }
        if (type == "file") {
            file = e.target.files[0]
        }

        // console.log(type, checkbox, name, section)

        if (section == "venture") {
            let _venture = { ...venture }
            if (type == "checkbox") {
                // console.log(_venture[name], checkbox)
                _venture[name] = checkbox
                // console.log(_venture[name], checkbox)
            }
            else if (type == "file")
                _venture[name] = file
            else
                _venture[name] = e.target.value

            if (name == "industryId") {
                fetchSector(e.target.value)
            }

            // console.log(_venture)
            setVenture(_venture)
        }
        else {
            let _regEntity = { ...regEntity }
            if (type === "checkbox")
                _regEntity[name] = checkbox
            else if (type === "file")
                _regEntity[name] = file
            else
                _regEntity[name] = e.target.value

            setRegEntity(_regEntity)
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        getPageDropdown()
        getInitialData()

    }, [])

    const getInitialData = async () => {
        loaderStart()
        let initial = await getAccountData()
        console.log(initial)
        if (initial.status) {
            setIsRegEntity(initial.data.isRegisteredEntity == "yes" ? true : false)
        }
        let ven = await fetchVenture()
        let reg = await fetchRegEntity()
        console.log(ven)
        loaderEnd()
        if (ven.status) {
            setVenture({
                isDeclare: ven.data.isDeclare,
                dpiitRegisterNo: ven.data.dpiitRegisterNo,
                currentStage: ven.data.currentStage,
                sectorId: ven.data.sectorId,
                industryId: ven.data.sector.industryId,
                serviceId: ven.data.serviceId,
                startupInnovation: ven.data.startupInnovation,
                dpiitCertificateUrl: ven.data.dpiitCertificate,
            })
            fetchSector(ven.data.sector.industryId)
            setUpdateId(true)
        }

        if (reg.status) {
            setRegEntity({
                dateOfRegistration: reg.data.dateOfRegistration.split("T")[0],
                rocId: reg.data.rocId,
                proofOfEntityUploadUrl: reg.data.proofOfEntityUpload,
                natureOfEntityId: reg.data.natureOfEntityId,
                proofOfEntityType: reg.data.proofOfEntityType,
                udyamiAadharNo: reg.data.udyamiAadharNo,
                document: reg.data.dateOfRegistration,
            })
            setUpdateId(true)
        }
    }

    const fetchSector = async (id) => {
        let res = await getDropdown(`/data/industry/${id}/sector`)
        setAllSector(res.data)
    }

    const getPageDropdown = async () => {
        let dropdown = await getAllDropdown()
        setDropdown(dropdown)
        // console.log(dropdown)
    }


    console.log(venture)


    const onSubmit = async (e) => {
        // loaderStart()
        e.preventDefault()
        let res1 = await addUpdateVenture(venture, updateId)
        if (res1.status && isRegEntity) {
            let res2 = await addUpdateRegEntity(regEntity, updateId)
            if (res2.status) {
                navigate("/main/teamDetails")
            }
            else {
                actionNotifier.error(res2.message)
            }
        }
        else if (res1.status && !isRegEntity) {
            navigate("/main/teamDetails")
        }
        else if (res1.message == "\nInvalid `.create()` invocation in\n/home/startup-bihar/test/startup-bihar-backend/src/services/user/venture.service.js:109:8\n\n  106 );\n  107 \n  108 const data = await prismaModel.venture\n→ 109   .create(\n  Foreign key constraint failed on the field: `accountId`") {
            actionNotifier.error("Please Fill Basic Details and all the Previous Forms correctly")
        }
        else if (res1.message == "File size for dpiitCertificate is too large. Max size is 5 MB") {
            actionNotifier.error("File size for DPIIT Certificate is too large. Max size is 5 MB")
        }
        else {
            console.log(res1.message)
            actionNotifier.error("Please Fill all required fields")
        }

        loaderEnd()
    }

    return (
        <Box className='main_box'>
            <Box className='sub_box_entity'>
                <Typography className='box_heading' sx={{ fontWeight: "bold", fontSize: 20 }} >Entity Venture</Typography>
                <br />
                <form onSubmit={onSubmit}>
                    {
                        isRegEntity
                            ?
                            <RegEntity
                                data={regEntity}
                                handleChange={handleChange}
                                natureOfEntity={dropdown.natureOfEntity ? dropdown.natureOfEntity : []}
                                district={dropdown.district ? dropdown.district : []}
                                section={"regEntity"}
                            />
                            : null
                    }
                    <Venture
                        data={venture}
                        handleChange={handleChange}
                        services={dropdown.services ? dropdown.services : []}
                        industryList={dropdown.industryList ? dropdown.industryList : []}
                        allSector={allSector ? allSector : []}
                        section={"venture"}
                    />
                    <br />
                    <br />
                    <Button
                        type="submit"
                        style={{ backgroundColor: "#EB4747" }} variant="contained" fullWidth  >Submit</Button>
                </form>
            </Box>
            {/* <Box className='page_design_develop' style={{ marginTop: "-90px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>
                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}
        </Box>
    )
}

export default EntityVentureDetails