import axios from "axios";
import { REACT_APP_API_URL } from "../../shared/constant";


export const getToken = () => {
    let token = localStorage.getItem("startup-india-token")
    token = JSON.parse(token)
    if (token) {
        return token.token
    }
    return '';
}

export const getToken2 = () => {
    let token2 = localStorage.getItem("department-startup-india-token")
    token2 = JSON.parse(token2)
    console.log(token2)
    if (token2) {
        return token2.token
    }
    return '';
}



export const getMobileNumber = () => {
    let mobile = localStorage.getItem("mobile-number")
}
export const getDepartmentToken = () => {
    let tokenDep = localStorage.getItem("department-startup-india-token")
    tokenDep = JSON.parse(tokenDep)
    if (tokenDep) {
        return tokenDep.token
    }
    return '';
}


export const apiFunction = async (url, method, postData, token, token2, extraConfig) => {
    // console.log("sadsadas")
    url = REACT_APP_API_URL + url
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
    };


    if (token2) {
        let token2 = getToken2();
        // console.log(token)
        config = {
            ...config,
            headers: { "Authorization": `Bearer ${token2}` },
        };
    }

    if (token) {
        let token = getToken();
        // console.log(token)
        config = {
            ...config,
            headers: { "Authorization": `Bearer ${token}` },
        };
    }
    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }
    else if (extraConfig == "formData") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "multipart/form-data",
            },
        };
    }

    let data;

    await axios({ ...config })
        .then((res) => {
            data = res.data
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};