import React, { useState } from "react";
const IconCard = (props) => {
    return (
        <div className="col">
            <div className="p-2 icon-card">
                <a href={props.links} target="_blank"><img src={props.icons} style={{ height: "60px" }} /></a>
                <h6 style={{ color: "#fff", fontSize: "13px" }}>{props.text}</h6>
            </div>
        </div>
    )
}

export default IconCard;