import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import icon1 from './icon1.png'
import icon2 from './icon2.png'
import icon3 from './icon3.png'
import icon4 from './icon4.png'
import icon5 from './icon5.png'
import icon6 from './icon6.png'
import icon7 from './icon7.png'
import icon8 from './icon8.png'
import recognition from './recognition.png'
import './styles.css'
const StartupRecognition = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5">
          <h2 className="text-start fw-bold">Startup Recognition</h2>
          <div className="row aligned-center img-box-rec">
            <div className="col-sm-8">
              <div className="">
                <p style={{ textAlign: "justify" }}>
                  <ul className="ul_recog_style">
                    <li><img src={icon1} />The date of incorporation / registration is not prior to Ten years.</li>
                    <li><img src={icon2} />Entity must have incorporated as Private Limited or Partnership firm or a limited liability partnership.</li>
                    <li><img src={icon3} />Annual turnover not exceeding one hundred crore rupees, for any preceding financial year.</li>
                    <li><img src={icon4} />An entity not formed by splitting up or reconstruction of an existing business.</li>
                    <li><img src={icon5} />Entity is working towards innovation, development or improvement of products or processes or services.</li>
                    <li><img src={icon6} />Scalable business model with a high potential of employment generation or wealth creation.</li>
                    <li><img src={icon7} />Provided Start-up must be incorporated or registered in Bihar and having office in Bihar.</li>
                    <li><img src={icon8} />Provided further applicable taxes from the operations of the company are payable in Bihar.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-sm-4 text-end">
              <img src={recognition} className="w-100" />
            </div>

          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default StartupRecognition;
