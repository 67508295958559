import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import CategoryA from './CategoryA'


// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const CategoryMsgA = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <CategoryA/>
        
      
      </div>

    </>
  )
}

export default CategoryMsgA










