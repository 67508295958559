import React, { useState } from "react";
import biharLogo from './Seal_of_Bihar.svg'
import './styles.css';
const StartupIndiaFooter = (props) => {
  return (
    <>
      <div className="footer-startup p-3" style={{ backgroundColor: "rgba(33,158,180,0.9)", color: "#fff", fontSize: "10px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <div className="row">
                <div className="col-sm-1 text-right">
                  <img src={biharLogo} style={{ height: "80px" }} />
                </div>
                <div className="col-sm-7 ms-2" style={{ borderLeft: "1px solid black", textAlign: "left !important" }}>
                  <b style={{ fontSize: "12px" }}>DEPARTMENT OF INDUSTRIES</b><br />
                  Government of Bihar<br />
                  <i className="fa fa-copyright"></i> Copyright owned by <b>Startup Bihar</b>, All Rights Reserved<br />
                  Important links: | Dept. of Sci & Tech. Govt. of India | Startup India | Stand-Up India | PMEGP | MUDRA
                </div>
                <div className="col-sm-3 connect-with-us text-center">
                  <br />
                  <b style={{ fontSize: "12px" }}>CONNECT WITH US</b><br /><br />
                  <a href="https://www.facebook.com/BiharIndustriesDept" target="_blank"><i className="fa fa-facebook"></i></a> <a href="https://twitter.com/IndustriesBihar" target="_blank"><i className="fa fa-twitter ms-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-startup p-1" style={{ backgroundColor: "#ffdb0a", color: "#000", fontSize: "12px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10 text-center">
              <div className="row">
                <div className="col-sm-6">
                  Content provided by Department of Industries | Government of Bihar
                </div>
                <div className="col-sm-6">
                  Developed by <a href="https://codebuckets.in/" target="_blank">Codebucket Solutions Pvt. Ltd.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupIndiaFooter;
