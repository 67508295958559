

import { apiFunction } from "../../../apiCalls/functions";


export const fetchInfoData = async () => {
    return await apiFunction("account/get-siac-document", "get", {}, true, null)
}


export const checkRegNo = async (registrationId) => {
    let _data = {
        "registrationId": registrationId
    }
    console.log(_data)
    return await apiFunction("account/check-if-registered-entity", "post", _data, true, null)
}


export const updateStartupData = async (data) => {
    return await apiFunction("account/add-siac-document", "post", data, true, null)
}

export const changePassword = async (data) => {
    return await apiFunction("account/change-password", "post", data, true, null)
}

export const resentOTP = async (data) => {
    return await apiFunction("auth/resend-otp", "post", data, true, null)
}

export const forgotPassword = async (data) => {
    return await apiFunction("auth/forgot-password", "post", data, true, null)
}

export const resentOTPForgotPassword = async (data) => {
    return await apiFunction("auth/resend-forgot-password-otp", "post", { "email": data }, true, null)
}

export const updateForgotPassword = async (data) => {
    return await apiFunction("auth/verify-otp-and-reset-password", "post", data, true, null)
}

export const EnterOtp = async (data) => {
    return await apiFunction("auth/verify-otp", "post", data, false, null)
}