import React, { useEffect, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import "bootstrap/dist/css/bootstrap.css";
import "./Header.css";
import logo from "./logo-white.png";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, MenuItem, TextField, Typography, Button, Dialog, DialogActions, DialogContent, Paper, TableContainer, Grid } from "@mui/material";
import { loaderEnd, loaderStart } from "../loader";
// import { changePassword } from "../Incubator/information/actions";
import { actionNotifier } from "../notification";
import Modal from "@mui/material/Modal";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {
  getAdminProfileDetail,
  changePasswordDepartment,
  searchGlobal
} from "../basicAction";
import { changeTzToNormalDefault } from "../../shared/functions";
import { FaEye } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from 'react-redux';
import { recognition } from '../DepartmentDashboard/actions';
import { newData , CoworkingGet } from '../DepartmentDashboard/actions';

// import { Button } from "bootstrap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height:360,
  bgcolor: 'background.paper',
    border: '3px solid #000',
    boxShadow: 24,
    borderRadius: 12,
    p: 2,
   
};


const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height:310,
  bgcolor: 'background.paper',
  border: '3px solid #000',
  boxShadow: 24,
  borderRadius: 12,
  p: 2,
};

const DepartmentHeader = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [modalDataCoworking, setModalDataCoworking] = useState(false);
  const [search, setSearch] = useState();
  const [globalData, setGlobalData] = useState();
  const [modalSearch, setModalSearch] = useState();
  const navigate = useNavigate();
  const [checkedNew, setCheckedNew] = useState(false);
  const [checkedOld, setCheckedOld] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    rePassword: "",
  });
  const [profile, setProfile] = useState("");
  useEffect(() => {
    getAdminProfile();
    SearchGlobal();
  }, [search]);

  const onValue = (e, name) => {
    setState({ ...state, [name]: e.target.value });
  };

  const SearchGlobal = async () => {
    let val = {
      search: search
    }
    loaderStart()
    let data = await searchGlobal(val)
    if (data.status) {
         console.log(data)
         setGlobalData(data?.data?.rows )
        
        }
         loaderEnd()
  }

  const handleSubmit = () => {
    if (checkedNew) {
      dispatch(newData(true)); 
            dispatch(recognition(false)); 
            if(profile.username == 'psc'){
              navigate("/expevaluated");
            }else if(profile?.username == 'SMIC'){
              navigate("/smic-pending");
            }
            else if(profile?.username == 'IIT_Patna'){
                 navigate("/pendingexp");
            }
            else if(profile?.username == 'CIMP'){
                 navigate("/pendingexp");
            }
            else{
              navigate("/all-application");
            }
           
            
        
    }
    else if(checkedOld){
      dispatch(recognition(false)); 
      dispatch(newData(false));
      if(profile.username == 'psc'){
        navigate("/expevaluated");
      }else if(profile?.username == 'SMIC'){
        navigate("/smic-pending");
      }
      else if(profile?.username == 'CIMP'){
        navigate("/pendingexp");
      }
      else if(profile?.username == 'IIT_Patna'){
        navigate("/pendingexp");
      }
      else{
        navigate("/all-application");
      }
     
      
    }

}

const handleRecognition = () => {
 
   
          dispatch(recognition(true)); 
          dispatch(newData(true)); 
          dispatch(CoworkingGet(false)); 
          if(profile.username == 'psc'){
            navigate("/expevaluated");
          }else if(profile?.username == 'SMIC'){
            navigate("/smic-pending");
          }
          else if(profile?.username == 'IIT_Patna'){
               navigate("/pendingexp");
          }
          else if(profile?.username == 'CIMP'){
               navigate("/pendingexp");
          }
          else{
            navigate("/all-application");
          }
         
          
      
  
 

}


const handleSubmitCoworking = () => {
  if (checkedNew) {
   
          dispatch(recognition(false)); 
          navigate("/reportadmin");
          dispatch(newData(true)); 
      
  }
  else if(checkedOld){
    dispatch(recognition(false)); 
    navigate("/reportadmin");
    dispatch(newData(false));
  }

}




  const onSubmitData = async (e) => {
    e.preventDefault();

    if (state.currentPassword === state.newPassword) {
      actionNotifier.error("New Password and Current Password cannot be same");
    } else {
      if (state.newPassword === state.rePassword) {
        let _state = { ...state };
        delete _state.rePassword;
        let res = await changePasswordDepartment(_state);
        if (res.status) {
          actionNotifier.success("Success");
          loaderStart();
          setModal(false);
        } else {
          actionNotifier.error(res.message);
        }
        // console.log(res);
      } else {
        actionNotifier.error("Both password are not same");
      }
      loaderEnd();
    }
  };




  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      // console.log(data);
      // setPermission(data.data)
      setProfile(data?.data);
    }
  };

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
    
  };

  const searchSubmit = (event) => {
    setModalSearch(true);
    
  };

  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Submission Date",
      selector: (row) =>
        changeTzToNormalDefault(row?.createdAt).date,
      width: "140px",
    },
    
    {
      name: "Evaluated",
      selector: (row) =>
        row?.direct_account?.Application?.isEvaluated  ? 'True':'',
      width: "140px",
      sortable: true,
    } ,
 
     {
      name: "Name of Startup",
      selector: (row) => row?.direct_account?.nameOfStartUp,
      width: "150px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.direct_account?.firstName + "  " + row?.direct_account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.direct_account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.direct_account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.direct_account?.Application?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Assignee2.",
      cell: (row) => row?.direct_account?.Application?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,
    },
    {
      name: "Category Type",
      selector: (row) => row?.direct_account?.Application?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },
    {
      name: "L.M.S",
      selector: (row) =>
      row?.direct_account?.Application?.Lms[row?.direct_account?.Application?.Lms?.length - 1]?.status   ?
      row?.direct_account?.Application?.Lms[row?.direct_account?.Application?.Lms?.length - 1]?.status : 'pending' ,
      width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      
      name: <center>Meeting Status</center>,
      cell:row =>     
 
      <>
   {      row?.direct_account?.Application?.Meetings[row?.direct_account?.Application?.Meetings?.length - 1]?.status == "done"
                          ? "Complete"
                          : ''}
                           {row?.direct_account?.Application?.Meetings[row?.direct_account?.Application?.Meetings?.length - 1]?.status == "scheduled"
                          ? "Scheduled"
                          : ''}
                           {row?.direct_account?.Application?.Meetings[row?.direct_account?.Application?.Meetings?.length - 1]?.status == "rescheduled"
                          ? "Rescheduled"
                          : ''}

{!row?.direct_account?.Application?.Meetings[row?.direct_account?.Application?.Meetings?.length - 1]?.status 
                          ? "Pending"
                          : ''}
      </>,
       width:'150px',
       
         
        
          
         
},

    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },


    // {
      
    //   name: 'Submission ',
    //   selector:(row,index) => row?.Lms[row?.Lms?.length - 1]?.status   ?
    //   row?.Lms[row?.Lms?.length - 1]?.status : 'pending' 
      
    //   ,
    //       width:"140px",
    //       sortable: true,
        
          
         
    // },
  ];

  

  return (
    <div className="header-start">
      <div style={{ backgroundColor: "rgba(33,158,180,10)" }}>
        <div className="row w-100 justify-content-center">
          <div className="col-sm-10">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div>
                  <a className="navbar-brand" href="/">
                    <img
                      src={logo}
                      alt="No Img"
                      style={{ height: "45px", borderRadius: "5px" }}
                    />
                  </a>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">=</span>
                </button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                  className="collapse navbar-collapse"
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav startupindia">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={() => navigate("/department-dashboard")}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          Dashboard
                        </span>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className="nav-link"
                        aria-current="page"
                        onClick={() => setModalChoose(true)}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          View Application
                        </span>
                      </a>
                    </li> */}
                    <li className="nav-item dropdown">
                                            <a  className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span style={{ fontSize: "15px", fontWeight: "500" }}>
                                               View Application
                                              </span>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" onClick={() => handleRecognition()}  > Recognition Application</a></li>
                                                <li><a className="dropdown-item" onClick={()=>{
                                            // dispatch(recognition(false)); 
                                                    // navigate("/all-application");
                                                    // dispatch(newData(false)); 
                                                    dispatch(CoworkingGet(false)); 
                                                    setModalData(true)
                                                }}>Seed Fund Application</a></li>
                                                
                                                  <li>
                                                    
                                                    <a className="dropdown-item" onClick={()=>{
                                                
                                                   dispatch(CoworkingGet(true)); 
                                                  //  dispatch(newData(true)); 
                                                   navigate("/coworkinglist");
                                                  
                                                }} >                                            
                                            
                                               { profile?.username !== 'psc' &&   profile?.username !== 'SMIC' &&  profile?.username !== 'CIMP'&& profile?.username !== 'IIT_Patna' ? 'Co-Working Applications':'' }
                                               
                                               </a>
                                               
                                               </li>

                                              
                                            </ul>
                                        </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={() => {
                          setModalDataCoworking(true); 
                          
                        }

                      }
                      >
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          Report
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <input
                        type="text"
                        className="w-38 form-Control"
                        value={search}
                        onChange={onChangeHandler}
                        placeholder="Search"
                      />
                      {/* <button onClick={searchSubmit}>Search</button> */}
                      <Button  style={{backgroundColor:'blue',color:'white',marginLeft:8}}
              variant="outlined"
              
              onClick={searchSubmit}>
                Search
              </Button>
                    </li>
                  </ul>
                </div>

                <div className="bg-light rounded-3 ms-1">
                  <ul className="navbar-nav startupindia">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link  login_signup"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {profile.username}
                      </a>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <MenuItem onClick={() => setModal(true)}>
                          Change Password
                        </MenuItem>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <a className="nav-link" href="/department-logout">
                <i class="fa fa-sign-out fa-3x" aria-hidden="true">
                  {" "}
                </i>
              </a>
            </nav>
          </div>
        </div>
      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Change Password
            </Typography>
            <br />
            <form onSubmit={onSubmitData}>
              <TextField
                id="outlined-basic"
                label={"Current Password"}
                variant="outlined"
                required
                type="password"
                style={{ width: "100%" }}
                value={state.currentPassword}
                onChange={(e) => onValue(e, "currentPassword")}
              />
              <br />
              <br />
              <TextField
                id="outlined-basic"
                label={"New Password"}
                variant="outlined"
                required
                type="password"
                style={{ width: "100%" }}
                value={state.newPassword}
                onChange={(e) => onValue(e, "newPassword")}
              />
              <br />
              <br />
              <TextField
                id="outlined-basic"
                label={"Retype Password"}
                variant="outlined"
                required
                type="password"
                style={{ width: "100%" }}
                value={state.rePassword}
                onChange={(e) => onValue(e, "rePassword")}
              />
              <br />
              <br />
              <Button
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
      ) : null}

      {modalSearch ? (
        <Modal
          open={modalSearch}
          onClose={() => {setModalSearch(false);  setSearch('')}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        {/* <Dialog open={true} >
                    <DialogContent>
                    <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              columns={columns ? columns : ''}
              data={
                globalData ? globalData: ''
              }
              pagination
              subHeader
              selectableRows         
              subHeaderAlign="center"
              value={search}
              
              
            
            />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModalSearch(false)}
                            color="primary"
                            variant="outlined" >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> */}


                
         <Box
          sx={{
            style
          }}
        >
         
          <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              // fixedHeaderScrollHeight="500px"
              columns={columns ? columns : ''}
              data={
                globalData ? globalData: ''
              }
              // pagination
              subHeader
              // selectableRows         
              subHeaderAlign="center"
              value={search}
              
              
            
            />
         
</Box>

        </Modal>
      ) : null}

{modalData ? (
        <Modal
          open={modalData}
          onClose={() => setModalData(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
          
           
            

            <Grid item xs={8}>
            <Button
                                style={checkedNew && checkedOld  ? { backgroundColor: "#EB4747", color: "white",marginLeft :390} : {marginLeft :390}}
                                variant="secondary"
                                onClick={() => setModalData(false)} 
                               
                            >(X)</Button>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red",fontSize:30 }}><b><u>Please Select</u></b></h6></div>
                            <br/>
                            <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                        <h3 >
                        {/* <Checkbox
                            onClick={() => setCheckedNew(!checkedNew)}
                            {...label} id="check" style={{ marginTop: "-10px" }} /> */}

                               <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label=" New Data (01.12.2022)"
                                checked={checkedNew=== true }
                                    control={<Radio />} onClick={() => {
                                      setCheckedOld(false);
                                      setCheckedNew(true);
                                    }} />
                            </RadioGroup>
                          
                          
                         </h3>
                       
                    </div>
                   
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                                <h3 >
                                {/* <Checkbox
                                    onClick={() => setCheckedOld(!checkedOld)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} /> */}
                                     <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label="Old Data(19.7.2022 to 30.09.2022)"
                                checked={checkedOld === true  }
                                    control={<Radio />} onClick={() => {
                                      setCheckedOld(true);
                                      setCheckedNew(false);
                                    }} />
                            </RadioGroup>
                                  
                                  
                                   </h3>
                               
                            </div>


                        <form style={{marginBottom:20}}>
                            {/* <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>I have read all the instructions carefully.	</span>
                            </div> */}

                            {/* <Box style={{display: "flex", justifyContent:"space-between"}}>
                        <TextField id="outlined-basic" label="Name" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Place" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Date" disabled variant="outlined" />
                    </Box> */}


                            <br />
                            <Button
                                style={checkedNew && checkedOld  ? { backgroundColor: "#EB4747", color: "white",marginLeft :200} : {marginLeft :200}}
                                variant="contained"
                                onClick={() => handleSubmit()} 
                                disabled={!checkedNew && !checkedOld}
                            >Submit</Button>


                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}

{modalDataCoworking ? (
        <Modal
          open={modalDataCoworking}
          onClose={() => setModalDataCoworking(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
          
           
            

            <Grid item xs={8}>
            <Button
                                style={checkedNew && checkedOld  ? { backgroundColor: "#EB4747", color: "white",marginLeft :390} : {marginLeft :390}}
                                variant="secondary"
                                onClick={() => setModalData(false)} 
                               
                            >(X)</Button>
                        <br />
                        <div style={{ textAlign: "left", marginLeft: 30, diplay: "flex", justifyContent: "space-around" }}>
                            <h6 style={{ textAlign: "center", color: "red",fontSize:30 }}><b><u>Please Select</u></b></h6></div>
                            <br/>
                            <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                        <h3 >
                        {/* <Checkbox
                            onClick={() => setCheckedNew(!checkedNew)}
                            {...label} id="check" style={{ marginTop: "-10px" }} /> */}

                               <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label=" New Data (01.12.2022)"
                                checked={checkedNew=== true }
                                    control={<Radio />} onClick={() => {
                                      setCheckedOld(false);
                                      setCheckedNew(true);
                                    }} />
                            </RadioGroup>
                          
                          
                         </h3>
                       
                    </div>
                   
                        <div style={{ textAlign: "justify", marginLeft: 50, marginRight: "50px", diplay: "flex", justifyContent: "space-around" }}>
                        
                                <h3 >
                                {/* <Checkbox
                                    onClick={() => setCheckedOld(!checkedOld)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} /> */}
                                     <RadioGroup name="use-radio-group" defaultValue="first">
                                <FormControlLabel value="yes" label="Old Data(19.7.2022 to 30.09.2022)"
                                checked={checkedOld === true  }
                                    control={<Radio />} onClick={() => {
                                      setCheckedOld(true);
                                      setCheckedNew(false);
                                    }} />
                            </RadioGroup>
                                  
                                  
                                   </h3>
                               
                            </div>


                        <form style={{marginBottom:20}}>
                            {/* <div style={{ textAlign: "left", marginLeft: 60, fontWeight: "600" }}>
                                <Checkbox
                                    onClick={() => setChecked(!checked)}
                                    {...label} id="check" style={{ marginTop: "-10px" }} />
                                <span>I have read all the instructions carefully.	</span>
                            </div> */}

                            {/* <Box style={{display: "flex", justifyContent:"space-between"}}>
                        <TextField id="outlined-basic" label="Name" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Place" disabled variant="outlined" />
                        <TextField id="outlined-basic" label="Date" disabled variant="outlined" />
                    </Box> */}


                            <br />
                            <Button
                                style={checkedNew && checkedOld  ? { backgroundColor: "#EB4747", color: "white",marginLeft :200} : {marginLeft :200}}
                                variant="contained"
                                onClick={() => handleSubmitCoworking()} 
                                disabled={!checkedNew && !checkedOld}
                            >Submit</Button>


                        </form>
                    </Grid>
          </Box>
        </Modal>
      ) : null}






      
    </div>
  );
};

export default DepartmentHeader;
