import React, { useState } from 'react'
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, Link, } from "react-router-dom"
import EntityVentureDetails from "./Entity-Venture-Details"
import logo from "../Image/logo.png"
import "../../src/Style/Authentication.css"



const Navbar = () => {



    return (
        <>

            <Box>
                <Link to="/">
                    <img src={logo} alt="Paris" className="logo" ></img>
                </Link>
                <Box sx={{ marginTop: 10,display: "flex", justifyContent: "center" }}>
                    <div>


                        <ul >
                            <li><Link style={{ color: "black",}} to="/">Home</Link></li>
                            <hr />

                            <li><Link style={{ color: "black" }} to="/about">About us</Link></li>
                            <hr />

                            <li><Link style={{ color: "black" }} to="/startup-recognition">For Startups</Link></li>
                            <hr />

                            <li><a style={{ color: "black" }} href="#about"> Startup Ecosystem</a></li>
                            <hr />

                            <li><a style={{ color: "black" }} href="/#notification-section">Notification</a></li>

                            {/* <li><a style={{ color: "black" }} href={policy}>Bihar Startup Policy 2022 </a></li> */}


                        </ul>

                    </div>


                </Box>


            </Box>




        </>
    )
}

export default Navbar;

// changes done 2
