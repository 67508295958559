import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import ExpertRecommended from './ExpertRecommended'


// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const ExpRecomApplication = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' , justifyContent:'center' }}>
        <Sidebar />
        <ExpertRecommended/>
        
      
      </div>

    </>
  )
}

export default ExpRecomApplication










