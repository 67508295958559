import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  Button,
  Modal,
  Typography,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { changeTzToNormalDefault } from "../../../shared/functions";
import {
  getApplicationList,
  personalInteractionSend,
  searchApplication,
  getSsuList,
  getAssignedApplicationListEvaluated,
  getAssigneeList,
  getEvaluatedListCsv,
  getEvaluatedListCsvSsu,
  getAssignedApplicationDownloadedExcel,
  getAdminProfileDetail,
  getRecommendedCsv,
  personalInteractionGet
} from "../../basicAction";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { loaderEnd, loaderStart } from "../../loader";
import { actionNotifier } from "../../notification";
import DataTable from "react-data-table-component";
import "../stylesTable.css";
import { LensTwoTone } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  AcknowledgementId
} from "../actions";
import DepartmentHeader from "../DepartmentHeader";
import Sidebar from "../../Sidebar/Sidebar";


const PersonalInteraction = (props) => {
  const dispatch = useDispatch();
  const flags = JSON.parse(localStorage.getItem('stateRecog'));
  // const flags = useSelector((store) => store.recognition.recognition);
  // const newDataFlag = useSelector((store) => store.newData.newData);
  const newDataFlag = JSON.parse(localStorage.getItem('state'));
  const [applicationtype, setApplicationtype] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [allapplication, setAllapplication] = useState();
  const [search, setSearch] = useState("");
  const [dateSelector, setDateSelector] = useState("");
  const [SsuList, setSsuList] = useState();
  const [TotalSsu, setSSuTotal] = useState();
  const [evaluateDetails, setEvaluateDetails] = useState();
  const [total, setTotal] = useState(0);
  const [fildata, setFilData] = useState("");
  const [assignee, setAssignee] = useState([{}]);
  const [startDate, setDate] = useState(
    ''
  );
  const [modal, setModal] = useState(false);

  const [filteredResultsAasinTable, setFilteredResultsAasinTable] = useState(
    []
  );
  const [profile, setProfile] = useState("");
  const [isRecognition, setIsRecognition] = useState(false)
  const [filteredResults, setFilteredResults] = useState([]);
  const [assigneeTable, setAssigneeTable] = useState();
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [datesFil, setDatesFil] = useState(true);
  const [appId, setAppId] = useState();
  const [boolean, setBoolean] = useState('');
  const [remarks, setRemarks] = useState();

 


  const { id } = useParams();


  useEffect(() => {
    getAllApplication();
    getListsInteraction();
    // ssulist();
    assigneeList();
    GetAssignedList();
    getAdminProfile();
   
    // alert(newDataFlag);

  }, [page, rowsPerPage,isRecognition,flags,newDataFlag]);
  // list.applicationCategory?.name.toLowerCase().match(search.toLowerCase())

  useEffect(() => {
    try{
      if (search !== "All") {
  
        const result = SsuList
          ? SsuList?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()): 
         
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                  list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            })
          : assigneeTable?.rows?.filter((list) => {
              return (
                categoryFilter == true ? 
                list.applicationCategory?.name
                  .toLowerCase()
                  .match(search.toLowerCase()):
                 list.account?.acknowledgement?.acknowledgmentNo.toLowerCase().match(search.toLowerCase())||
                list.account?.mobileNumber
                  .toLowerCase()
                  .match(search.toLowerCase())||
                 list.account?.firstName.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo1?.name.toLowerCase().match(search.toLowerCase())||
                 list.assignedTo2?.name.toLowerCase().match(search.toLowerCase())
              );
            });
            SsuList?
        setFilteredResults(result)
        :
        setFilteredResultsAasinTable(result);
      
    } else {
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
    }
    catch{
      setFilteredResults(SsuList);
      setFilteredResultsAasinTable(assigneeTable?.rows);
    }
   
  }, [search]);




  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius:3,
    p: 4,
  };





  const getAdminProfile = async () => {
    let data = await getAdminProfileDetail();
    if (data.status) {
      setProfile(data?.data);
      console.log(data.data)
    }
  };


  const columns = [
    {
      id: "Sl. No",
      name: "Sl. No",
      // selector: (row,index)=>index+1,
      selector: (row, index) => index + 1,
      width: "68px",

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Registration Id",
      selector: (row) => row?.account?.acknowledgement?.acknowledgmentNo,
      width: "150px",
      sortable: true,
    

      // format: (value) => value.toLocaleString('en-US'),
    },

 
     {
      name: "Name of Startup",
      cell: (row) => row?.account?.nameOfStartUp,
      width: "150px",
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.account?.firstName + "  " + row?.account?.lastName,
      width: "180px",
      sortable: true,
     
   
     
    },
    {
      name: "Phone No.",
      selector: (row) => row?.account?.mobileNumber,
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Applied in Policy 2017.",
      selector: (row) => (row?.account?.StartUpDocuments ? "Yes" : "No"),
      width: "190px",
      sortable: true,
    },
    {
      name: "Assignee1.",
      cell: (row) => row?.assignedTo1?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
   
    {
      name: "Category1",
      selector: (row) => row?.applicationCategory?.name,
      width: "139px",
      sortable: true,
    },
    {
      name: "Assignee2.",
      cell: (row) => row?.assignedTo2?.name,
      // width: "160px",
      // sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },


    
   
    {
      name: "Category2",
      selector: (row) => row?.applicationCategory2?.name,
      width: "139px",
      sortable: true,
    },

    {
      name: "Total Marks",
      selector: (row) =>
      row?.ExpertPannel[row?.ExpertPannel.length - 1]?.marks,
      width: "125px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },


   



    {
      name: "Action",
      selector: (row) => (
        <NavLink
          target={"_blank"}
          to={`/view-application/${row?.account?.acknowledgement?.id}`}
        >
          <Button>
            <FaEye />
            View
          </Button>
        </NavLink>
      ),
      width: "130px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      name: "Enter Date",
      selector: (row) =>
      <>
                
                <TextField
                                                  
                                                id="outlined-basic" type="date"
                                                // label="dd-mm-yyy"
                                                // placeholder="Office Start Date"
                                                // format={"MM DD YYYY"}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                // value={state?.officeStartDate}
                                                name="Date"
                                                onChange={(e) => {
                                                   
                                                  setDate(e.target.value)
                                                    
                                                    
                                                }
                                                    }
                                                
                                            >
                                                 

                                            </TextField>
      </>,
     
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    
    {
      name: "Action",
      selector: (row) =>
        
         
            <Button  variant="outlined" onClick={ () => {
              setAppId(row?.id);
              setBoolean(true)
              
              recommendedSend(row?.id,true);
            }           }>
             
              Recommend
            </Button>
       
       ,
      width: "180px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    {
      name: "Action",
      selector: (row) =>
        
          
            <Button variant="outlined" onClick={ () => {
              setAppId(row?.id);
              setBoolean(false)
              setModal(true)
        
           
            } }>
              
              Not Recommend
            </Button>
          
       ,
      width: "200px",
      sortable: true,

      // format: (value) => value.toLocaleString('en-US'),
    },

    
 

  ];
  

  const recommendedSend = async (id,status,remarks)=>{

    let val = {
   
      // applicationId:  id  ,
      // recommended: (status).toString(),
      // date: changeTzToNormalDefault(startDate).date,

      applicationId: appId ? appId : id ,
      recommended:boolean ? (boolean).toString() : (status).toString(),
      date: changeTzToNormalDefault(startDate).date,
      remarks: boolean == false ? remarks : '',

    }
    

    if(id && status && startDate ){
      let data = await personalInteractionSend(val)
      if(data.status){

        actionNotifier.success("Application Submitted")
        
        setModal(false);
        getListsInteraction();
      }else{
        actionNotifier.error("Application Already Submitted")
        
        setModal(false);
        getListsInteraction();
      }
    } else{
      console.log('Some Error')
    }
    

    
  }

  const assigneeList = async () => {
    let data = await getAssigneeList();
    if (data.status) {
      // console.log(data.data)
      setAssignee(data);
    }
  };

  const getListsInteraction = async () => {
    if(flags == true){
      let val = {
        
        isRecognition:true
      };
    let data = newDataFlag == true ? await personalInteractionGet({
      round:2
    },val) : await personalInteractionGet({
      round:1
    },val)
    if (data.status) {
      // console.log(data.data)
      setFilteredResultsAasinTable(data?.data);
      setFilteredResults(data?.data)
    }
  }else{
    let val = {
        
      isRecognition:false
    };
  let data = newDataFlag == true ? await personalInteractionGet({
    round:2
  },val) : await personalInteractionGet({
    round:1
  },val)
  if (data.status) {
    // console.log(data.data)
    setFilteredResultsAasinTable(data?.data);
    setFilteredResults(data?.data)
  }
  }
  };



  const downloadAssignedTableCsv = async () => {
    if(flags == true){

      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:true
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
  
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b", "c", "d"],
        isRecognition:false
      };
      const data = await getAssignedApplicationDownloadedExcel(data1);
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
      
      };

  const downloadAssignedTableCsvSsu = async () => {

    if(flags == true){
      loaderStart();
      let data1 = {
        categories: ["a", "b"],
        isRecognition:true
      };
  
      const data = newDataFlag == true ?  await getRecommendedCsv(data1) :
      await getRecommendedCsv(data1) 

  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }else{
      loaderStart();
      let data1 = {
        categories: ["a", "b"],
        isRecognition:false
      };
  
      const data = newDataFlag == true ?  await getRecommendedCsv(data1) :
      await getRecommendedCsv(data1) 
  
      if (data.status == false) {
        actionNotifier.error("You are not authorized to perform this action");
      } else {
        let fileName = "Application" + Date.now() + ".csv";
        fileDownload(data, fileName);
      }
      loaderEnd();
    }
   
  };

  const getAllApplication = async () => {

    if(flags == true){
      let val = {
        search: search,
        isRecognition:true
      };
      let data = await getApplicationList(
        { page: page, items: 50000 },
        val
      );
      if (data.status) {
        setAllapplication({ ...data?.data });
        // console.log(data.data)
      }
    }
    else{
      let val = {
        search: search,
        isRecognition:false
      };
      let data = await getApplicationList(
        { page: page, items: 50000 },
        val
      );
      if (data.status) {
        setAllapplication({ ...data?.data });
        // console.log(data.data)
      }
    }
   
  };

  // const ssulist = async () => {

  //   if(flags == true){
  //     let val = {
  //       categories: ["a", "b", "c", "d"],
  //       isRecognition:true
  //     };
  
  //     let ssulist = newDataFlag == true ? await getSsuList({
  //       round:2
  //     },val) :
  //     await getSsuList({
  //       round:1
  //     },val)
  //     // console.log(categoryMenu.categories)
  //     setSsuList(ssulist?.data?.data);
  //     setFilteredResults(ssulist?.data?.data);
  //     setSSuTotal(ssulist?.data?.data);
  //   }else{
  //     let data = {
  //       categories: ["a", "b", "c", "d"],
  //       isRecognition:false
  //     };
  
  //     let ssulist = newDataFlag == true ? await getSsuList({
  //       round:2
  //     },data) :
  //     await getSsuList({
  //       round:1
  //     },data)
  //     // console.log(categoryMenu.categories)
  //     setSsuList(ssulist?.data?.data);
  //     setFilteredResults(ssulist?.data?.data);
  //     setSSuTotal(ssulist?.data?.data);
  //   }
 
    
  // };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const GetAssignedList = async () => {
    loaderStart();
    if(flags == true){
      let val = {
       
        isRecognition:true
        }
        
      let data = 
      newDataFlag == true ? await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:2
      },val) :
      await getAssignedApplicationListEvaluated({
        page: page,
        items: 50000,
        list: "evaluated",
        isRecognition: true,
        round:1
        
      },val)
    
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        // console.log(data.data);
        
      }
    }else{
      let val = {
       
        isRecognition:false
        }
        let data = 
        newDataFlag == true? await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:2
        },val) :
        await getAssignedApplicationListEvaluated({
          page: page,
          items: 50000,
          list: "evaluated",
          round:1
        },val)
      if (data.status) {
        setAssigneeTable({ ...data?.data });
        setFilteredResultsAasinTable(data?.data?.rows);
  
        console.log(data.data);
        
      }
    }
    
    loaderEnd();
   
  };


  const onChangeHandler = (event) => {
    setCategoryFilter(false);
    
    setSearch(event.target.value);

    console.log(event.target.value);
    
  };



  const handleChangeCategory = (event) => {
    console.log(event.target.value);
    setCategoryFilter(true);
    setSearch(event.target.value);
    console.log(search);
  };

  return (
    <>
     <DepartmentHeader />
     <div style={{ display: 'flex' }}>
        <Sidebar />
    <div style={{ display: "flex", flexDirection: "column", width: "92%" }}>
        <Box
          sx={{
            width: "96%",
            height: 30,
            // backgroundColor: "white",
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
        


        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "92%",
            marginTop: "1px",
            height:'100vh'
          }}
        >
          <TableContainer component={Paper}>

            {
              filteredResults ?
              <DataTable
              className="dataTables_wrapper"
              component={Paper}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              // title=" Recommended Table"
              columns={columns ? columns : ''}
              data={
                filteredResults ? filteredResults : ''
              }
              pagination
              subHeader
              subHeaderComponent={
                <>
                     {/* {filteredResults && filteredResults ? (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          ) : (
            <Button onClick={downloadAssignedTableCsvSsu} variant="text">
              Click here to download excel
            </Button>
          )} */}
          
          

<input
                    type="text"
                    placeholder="Search Here"
                    className="w-19 form-Control"
                    onChange={onChangeHandler}
                    style={{marginRight:18}}
                  />
                 


               
                
                  
       

             

              

                

           
              

                 
                </>
              }
              subHeaderAlign="center"
              value={search}
              
              
            
              // rowsPerPage={rowsPerPage}
              paginationComponentOptions={paginationComponentOptions}
              // paginationPerPage={
              //   SsuList ? SsuList?.length : assigneeTable?.count
              // }
              
            paginationTotalRows={SsuList ? filteredResults?.length : assigneeTable?.count}
            paginationPerPage={8}
            // onChangeRowsPerPage={(val) => setRowsPerPage(val)}
            paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30, 35]}
            // onChangePage={(val) => setPage(val)}
            />
            :
        ''
            }
          
          </TableContainer>
        </Box>
        
      </div>
      </div>
      {modal ? (
        <Modal
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2" >
              Submit Your Remarks
            </Typography>
            <br />           
             
            <TextareaAutosize
            style={{width:'100%'}}
					type="text" label="Remarks"
					// value={state.name}
          fullwidth
					required
					onChange={(e) => setRemarks(e.target.value)}
          multiline={true}
				/>
             
            
             
<br/><br/>
              <Button
                onClick={()=>{
                  recommendedSend(appId , boolean,remarks);
                }}
                type="submit"
                style={{ backgroundColor: "#EB4747" }}
                variant="contained"
                fullWidth
              >
                Submit 
              </Button>
            
          </Box>
        </Modal>
      ) : null}


    </>
  );
};

export default PersonalInteraction;
