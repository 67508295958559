import React, { useEffect } from 'react';

const Logout = () => {
    useEffect(() => {
        localStorage.removeItem('startup-india-token');
        window.open("/login", "_self")
    }, [])

    return (
        <>
            <h1>&nbsp;</h1>
        </>
    );
}

export default Logout;

