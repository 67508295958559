import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import Schedule from './Schedule'

// import ViewApplicationTableCopy from './ViewApplicationTable copy 2'

const ScheduleMain = () => {

  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Schedule/>
        
      
      </div>

    </>
  )
}

export default ScheduleMain










