import React, { useState } from "react";
import Header from "../../Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import './styles.css'
import img1 from './img1.png'
const SSU = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container-fluid">                    
          <div className="row aligned-center">
            <div className="col-sm-12">
              <img src={img1} className="w-100" />
            </div>
          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default SSU;
