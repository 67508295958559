import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import DepartmentHeader from '../DepartmentHeader'
import ViewApplicationTable from './ViewApplicationTable'

const SearchApplication = () => {
  return (
    <>
      <DepartmentHeader />
      {/* main content */}
      <div style={{ display: 'flex' }}>
        <Sidebar />
          
        <ViewApplicationTable />
      </div>

    </>
  )
}

export default SearchApplication