import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
const initialValue = {
    incubatorType: ""
};
const IndustriesContent = () => {
    const [incubator, setIncubator] = useState(initialValue);
    const onValue = (e) => {
        setIncubator({ ...incubator, [e.target.name]: e.target.value });
        console.log(incubator)
    };
    return (
        <>
            <TextField
                fullWidth
                label="No. of faculties dedicated for startup incubation."
                id="fullWidth"
                value=""
                onChange={(e) => { }}
            /><br /><br />
            <TextField
                fullWidth
                label="No. of Faculty availed mentorship certification course approved under the policy by PSC "
                id="fullWidth"
                value=""
                onChange={(e) => { }}
            /><br /><br />
            <div style={{ textAlign: "left" }}>
                <label>Upload the certificate of each faculty in one pdf</label>
                <TextField
                    type="file"
                    label={" "}
                    inputProps={{ accept: "application/pdf" }}
                    name="casteCertificateUpload"
                    fullWidth
                    onChange={(e) => { }}
                /></div>
            <br /><br />

            <TextField
                fullWidth
                label="Dedicated Space for startup eco system(in Sqr ft) "
                id="fullWidth"
                value=""
                type="text"
                onChange={(e) => { }}
            /><br /><br />
        </>
    )
}

export default IndustriesContent;
