import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab, Box, TextField, Stack, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate, Link } from "react-router-dom";
import RadioButtonsGroup from "./Radio-Button";
import axios from 'axios';
import { getToken } from "../apiCalls/functions"
import { actionNotifier } from "./notification"
import { FILE_URL, REACT_APP_API_URL } from "../shared/constant"
import { loaderStart, loaderEnd } from "./loader"
import { getAccountData } from "./basicAction"

const initialValue = {
    innovativeText: "",
    uniqueText: "",
    improveQualityText: "",
    GeneratemassiveWealthText: "",
    successStories: "",
    successStoriesText: "",
    CreateHighEmployementeText: "",
}
const ConceptIdeaOfStartup = () => {
    const navigate = useNavigate()
    const [conceptIdea, setConceptIdea] = useState(initialValue);
    const [getData, setGetData] = useState("");
    const [token, setToken] = useState(null)
    const [update, setUpdate] = useState(null);
    const [routeNavigate, setRouteNavigate] = useState("/main/businessModels")
    useEffect(() => {
        let token = getToken()
        setToken(token)
        getConceptIdea(token);
    }, [])

    function wordCount(s) {
        return s.replace(/-/g, ' ').trim().split(/\s+/g).length;
    }

    const onValue = (e) => {
        if (wordCount(e.target.value) <= 200) {
            setConceptIdea({ ...conceptIdea, [e.target.name]: e.target.value })
            console.log(conceptIdea);
        }
    }
    const onValueFile = (e) => {
        // let data=e.target.files[0]
        console.log(e.target.files[0]);
        setConceptIdea({ ...conceptIdea, [e.target.name]: e.target.files[0] })
        console.log(conceptIdea, "helllooo");
    }
    // form onSubmit check
    const onSubmitData = (e) => {
        e.preventDefault()
        if (update) {
            updateConceptIdea()
        }
        else {
            addConceptIdea()
        }
    }
    // put concept and idea api call here
    const updateConceptIdea = async () => {
        loaderStart()
        let _conceptIdea = { ...conceptIdea }
        var form_data
        form_data = new FormData();

        for (var key in _conceptIdea) {
            form_data.append(key, _conceptIdea[key]);
        }
        await axios.put(`${REACT_APP_API_URL}conceptoridea/`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate(routeNavigate)
            })
            .catch(error => {
                let _err = error.response.data
                actionNotifier.error(_err.message)
            })
        loaderEnd()

    }
    // post concept and idea api call here
    const addConceptIdea = async () => {
        loaderStart()
        let _conceptIdea = { ...conceptIdea }
        var form_data
        console.log(_conceptIdea)
        form_data = new FormData();
        for (var key in _conceptIdea) {
            form_data.append(key, _conceptIdea[key]);
        }
        await axios.post(`${REACT_APP_API_URL}conceptoridea/`, form_data,
            { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } })
            .then(result => {
                console.log(result)
                actionNotifier.success("Success")
                navigate(routeNavigate)
            })
            .catch(error => {
                let _err = error.response.data
                if (_err.message == "\nInvalid `.create()` invocation in\n/home/startup-bihar/test/startup-bihar-backend/src/services/user/concept-or-idea.service.js:78:8\n\n   75 );\n   76 \n   77 const data = await prismaModel.conceptOrIdea\n→  78   .create(\n  Foreign key constraint failed on the field: `accountId`") {
                    actionNotifier.error("Please Fill Basic Details and all the Previous Forms correctly")
                }
                else if (_err.message == "File size for successStories is too large. Max size is 15 MB") {
                    actionNotifier.error("File size for Video is too large. Max size is 15 MB")
                }


                else {
                    console.log(_err.message)
                    actionNotifier.error("Please Fill all required fields")
                }
            })

        loaderEnd()
    }

    // get concept and idea api call here
    const getConceptIdea = async (token) => {
        loaderStart()

        let initial = await getAccountData()
        console.log(initial)
        if (initial.status) {
            if (initial.data.isRegisteredEntity != "yes") {
                setRouteNavigate("/main/reviewForm")
            }
        }

        await axios.get(`${REACT_APP_API_URL}conceptoridea`,
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(result => {
                console.log(result.data.data)
                //    alert("Success")
                if (result.data.status) {
                    setConceptIdea({
                        innovativeText: result.data.data.innovativeText,
                        uniqueText: result.data.data.uniqueText,
                        improveQualityText: result.data.data.improveQualityText,
                        GeneratemassiveWealthText: result.data.data.GeneratemassiveWealthText,
                        successStoriesUrl: result.data.data.successStories,
                        successStoriesText: result.data.data.successStoriesText,
                        CreateHighEmployementeText: result.data.data.CreateHighEmployementeText,
                    })
                    setUpdate(true)
                }
            })
            .catch(error => {
                console.log(error);
            })
        loaderEnd()
    }


    return (
        <Box>
            <Box className='sub_box2'>
                {/* <br /> */}
                <br />
                <form onSubmit={onSubmitData}>
                    <Typography sx={{ color: "#EB4747", display: "flex", justifyContent: "flex-start", width: "100%", borderBottom: "2px solid #EB4747", fontWeight: "bold", fontSize: 20 }} >Concept & Idea of Startup</Typography>

                    {/* <br /> */}
                    <br />
                    
                    <TextField
                        id="outlined-basic"
                        placeholder="Write Your Ideas"
                        label={"Which problem area your startup is working on?/ आपका स्टार्टअप किस प्रॉब्लम एरिया पर काम कर रहा है? "}
                        multiline
                        minRows={2}
                        required
                        variant="outlined"
                        style={{ width: "100%" }}
                        name="innovativeText"
                        value={conceptIdea.innovativeText}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />

                    <TextField
                        id="outlined-basic"
                        fullwidth
                        label={"What is the innovation /uniqueness in your startup Idea? / आपके स्टार्टअप आइडिया में नयापन/विशिष्टता क्या है? "}
                        variant="outlined"
                        multiline
                        minRows={2}
                        required
                        style={{ width: "100%" }}
                        name="uniqueText"
                        value={conceptIdea.uniqueText}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />

                    <TextField
                        id="outlined-basic"
                        label={"Explain how your startup can generate employment opportunities?/ आपका स्टार्टअप कैसे रोजगार के अवसर पैदा कर सकता है? "}
                        variant="outlined"
                        multiline
                        minRows={2}
                        required
                        style={{ width: "100%" }}
                        name="CreateHighEmployementeText"
                        value={conceptIdea.CreateHighEmployementeText}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />

                    <TextField
                        id="outlined-basic"
                        label={"Explain the revenue generation strategy for your startup?/ आप अपने स्टार्टअप के लिए रेवेन्यू जेनेरशन कैसे करेंगे? "}
                        placeholder="Write Your Ideas"
                        variant="outlined"
                        multiline
                        minRows={2}
                        required
                        style={{ width: "100%" }}
                        name="GeneratemassiveWealthText"
                        value={conceptIdea.GeneratemassiveWealthText}
                        onChange={(e) => onValue(e)}
                    />

                    <br />
                    <br />

                    <TextField
                        id="outlined-basic"
                        label={"Explain how you are going to develop your product/service?/ आप अपने उत्पाद/सेवा को कैसे विकसित करने जा रहे हैं? "}
                        variant="outlined"
                        multiline
                        minRows={2}
                        required
                        style={{ width: "100%" }}
                        name="improveQualityText"
                        value={conceptIdea.improveQualityText}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />

                    <TextField
                        id="outlined-basic"
                        label={"Share the success story of your Idea (if any) and what is your growth plan?/ यदि आपके स्टार्टअप आईडिया की सफलता की कोई कहानी हो, तो उसे साझा करें और अपने स्टार्टअप की विकास योजना बताये। "}
                        variant="outlined"
                        multiline
                        minRows={2}
                        required
                        style={{ width: "100%" }}
                        name="successStoriesText"
                        value={conceptIdea.successStoriesText}
                        onChange={(e) => onValue(e)}
                    />
                    <br />
                    <br />
                    <Typography style={{ textAlign: "left" }} className='headLineLeft'>Upload video of success story of your startup (If any)/ अपने स्टार्टअप की सफलता की वीडियो अपलोड करें (यदि कोई हो)। </Typography>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        inputProps={{ accept: "video/mp4,video/x-m4v,video/*" }}
                        label={" "}
                        type="file"
                        style={{ width: "100%" }}
                        name="successStories"
                        // required={conceptIdea.successStoriesUrl ? false : true}
                        onChange={(e) => onValueFile(e)}

                    />
                    {
                        conceptIdea?.successStoriesUrl
                            ?
                            <div className="view-file">
                                <a target="_blank" rel="noreferrer" href={conceptIdea?.successStoriesUrl?.includes("https") ? conceptIdea?.successStoriesUrl : FILE_URL + conceptIdea?.successStoriesUrl}>
                                    View File
                                </a>
                            </div>
                            : null

                    }
                    <br />
                    <br />
                    <Button
                        type="submit"
                        style={{ backgroundColor: "#EB4747" }}
                        variant="contained"
                        fullWidth
                    >Submit</Button>
                </form>
            </Box>

            {/* <Box style={{ marginLeft: "-1260px", marginTop: "-35px", marginBottom: "40px" }}>
                <Typography style={{ fontSize: "12px", color: "red" }}>Designed & developed by Codebucket Solutions Pvt. Ltd.</Typography>

                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Copyright © 2022
                    Department of Industries
                    Government of Bihar
                </Typography>
            </Box> */}

        </Box>
    )
}

export default ConceptIdeaOfStartup;
